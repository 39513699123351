import React, { useCallback, useEffect, useState } from "react"
import { Typography, Radio, Row } from "antd"

export default ({ onChange }) => { // only_my_duty
    useEffect(() => {
        onChange && onChange("1")
    }, [])
    return <div>
        <Row>
            <span style={{margin: 10}}>发起采购申请：</span>
        </Row>
        <Row>
        <Radio.Group size='large' defaultValue="1" onChange={(e) => {
            onChange && onChange(e.target.value)
        }}>
            <Radio.Button value="1">您负责的物料</Radio.Button>
            <Radio.Button value="0">所有物料</Radio.Button>
        </Radio.Group>
    </Row>
    </div>
}


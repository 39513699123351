import React, {useCallback, useEffect, useState} from "react"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import DisplayComponent from "../../components/displays/Display"
import moment from 'moment'
import { useHistory } from "react-router-dom"
import { DatePicker } from "antd"
import LeaveDaysTableDisplay from "../../components/displays/hr/LeaveDaysTableDisplay"
import { renderActions } from "../../core/domUtil"
import { useActionExecutor } from "../../core/actionExecuter"

export default () => {
    const [daysResponse, getDays] = useHttpGet('/oa/getLeaveDaysTable')
    const [actionLoading, r, actionExecutor ] = useActionExecutor()
    const query = useRouteQuery()
    const history = useHistory()
    let month = query.month
    if (!month) {
        const today = new Date();
        month = (today.getFullYear() * 100) + (today.getMonth() + 1) + "";
    }

    useEffect(() => {
        getDays({month: month});
    }, [month, r])

    const refreshPage = useCallback(() => {
        getDays({month: month});
    }, [getDays])

    return <div>
        <PageHeaderWrapper/>
        <LoadingResponseWrapper
            {...daysResponse}
            refresh={refreshPage}
            renderContent={({leave_days, _actions_}) => {
                return <div>
                    <div style={{padding: '16px 16px 0 24px'}}>
                        <DatePicker size='middle'
                            value={moment(query.month || month, 'YYYYMM')}
                            onChange={(date, dateString) => {
                                const M = date.$M + 1
                                const month = date.$y + '' + (M < 10 ? '0' + M : M)
                                // setMonth(month);
                                history.replace(history.location.pathname + "?month=" + month)
                            }}
                            format={'YYYY 年 MM 月'}
                            picker="month" style={{width: '200px'}}/>
                        {renderActions(_actions_, actionExecutor)}
                    </div>
            
                    <DisplayComponent component='LeaveDaysTableDisplay' onQueryUpdate = {() => {
                        refreshPage()
                    }}  props={{leave_days, month, actionExecutor, actionLoading}}/>
                </div>
            }}
        />
    </div>
}

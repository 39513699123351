import React, {useCallback, useContext, useEffect, useState} from "react"
import {useHttpGet, useHttpPost, useRouteQuery} from "../../core/hooks"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import GridLayout, {WidthProvider} from "react-grid-layout"
import {Col, Row} from "antd"
import TemplateEditorFormItem from "../../components/df/TemplateEditorFormItem"
import {updateEditorOnItemValueChange} from "../../components/df/TemplateEditor"
import {useActionExecutor} from "../../core/actionExecuter"
import {renderActions} from "../../core/domUtil"
import {buildEditorRows} from "../../core/editorUtil"

const AutoWidthGridLayout = WidthProvider(GridLayout)

function setupEditor(editor) {
    const {rows} = editor
    const valueSet = {}
    // const convertedRows = []
    // for (const row of rows) {
    //     const convertedRow = {hidden: true, items: []}
    //     for (const item of row) {
    //         const {key, value, showCondition, source} = item
    //
    //         // if (showCondition && !conditionsMatched(showCondition, valueSet)) {
    //         //     item.hidden = true
    //         // } else {
    //             valueSet[key] = value
    //         // }
    //         // edit at 2020-03-18 Bill: 都显示出来方便拖拽位置
    //
    //         if (!item.hidden && source && source.uri) {
    //             source.compiledUri = buildUri(source.uri, valueSet)
    //         }
    //
    //         convertedRow.items.push(item)
    //         convertedRow.hidden = (convertedRow.hidden && item.hidden)
    //     }
    //
    //     convertedRow.items.length > 0 && convertedRows.push(convertedRow)
    // }

    return {
        valueSet,
        rows: buildEditorRows(rows, valueSet, [], [], true),
    }

}

function getLayoutAndElements({rows}, {minSpan}) {

    const layout = []
    const elements = []

    for (let {items, hidden} of rows) {
        let x = 0
        // if (hidden) {
        //     continue
        // }

        for (let element of items) {
            const {id, span, y, hidden} = element
            // if (hidden) {
            //     continue
            // }
            layout.push({
                i: 'ele_' + id,
                w: span,
                h: 1,
                minW: minSpan,
                minH: 1,
                maxH: 1,
                y,
                x,
            })
            x += span

            elements.push(element)
        }
    }

    return {layout, elements}
}

export default () => {

    const query = useRouteQuery()
    const [layoutResponse, getEditorLayout] = useHttpGet('/template/getEditorLayout')
    const [editorState, setEditorState] = useState(null)

    const [, actionResult, actionExecutor] = useActionExecutor()

    const [, saveEditorLayout] = useHttpPost('/template/saveEditorLayout')


    useEffect(() => {
        getEditorLayout(query)
    }, [query.id, actionResult])

    useEffect(() => {
        if (!layoutResponse.data) {
            return
        }
        const {editor} = layoutResponse.data
        setEditorState(setupEditor(editor))
    }, [layoutResponse.data])

    const onChangeHandlerGenerator = useCallback((item) => {
        return (e) => {
            const {key} = item
            const value = (typeof e === 'object' && e.target) ? e.target.value : e
            console.log({item, e, value})

            setEditorState(updateEditorOnItemValueChange(editorState, {key, value}))
        }
    }, [editorState])

    const headerProps = {}

    if (layoutResponse.data) {
        const {editor, actions, fixedState} = layoutResponse.data
        headerProps.title = editor.name
        if (actions) {
            headerProps.extra = <div>{renderActions(actions, actionExecutor, fixedState, 10)}</div>
        }
    }


    return <div>
        <PageHeaderWrapper
            {...headerProps}
        />

        <div className="page-content-wrapper">
            <LoadingResponseWrapper
                {...layoutResponse}
                refresh={getEditorLayout}
                renderContent={({fixedState, editor}) => {

                    if (!editorState) {
                        return <div/>
                    }

                    const {layout, elements} = getLayoutAndElements(editorState, editor)
                    const {valueSet} = editorState

                    const layoutChangeHandler = {
                        last: layout,
                        onLayoutChange: (changedLayout) => {
                            let changed = false
                            const lastLayout = layoutChangeHandler.last
                            for (let idx in changedLayout) {
                                const last = lastLayout[idx]
                                const current = changedLayout[idx]
                                if (last.w !== current.w || last.y !== current.y || last.x !== current.x) {
                                    changed = true
                                    break
                                }
                            }

                            layoutChangeHandler.last = changedLayout

                            if (!changed) {
                                console.log('layout not changed')
                                return
                            }

                            console.log(changedLayout)
                            saveEditorLayout({
                                id: query.id,
                                layout: JSON.stringify(changedLayout)
                            })
                        }
                    }

                    return <Row gutter={[16, 16]} type="flex">

                        <Col span={24} style={{backgroundColor: 'white'}}>

                            <AutoWidthGridLayout
                                layout={layout}
                                verticalCompact={false}
                                rowHeight={80}
                                draggableHandle={'.layout-editor-item-top'}
                                onLayoutChange={layoutChangeHandler.onLayoutChange}
                                cols={24}>

                                {elements.map((item) => {


                                    const {id, key, label, _actions_, extraProps, hidden, component, isDisplay} = item

                                    return <div
                                        className={'layout-editor-item ' + (hidden ? 'editor-item-hidden ' : '')}
                                        key={'ele_' + id}>

                                        <div className={'layout-editor-item-top'}>
                                            <div className={'layout-editor-item-label'}>{label}</div>
                                            {_actions_ && _actions_.length > 0 &&
                                            <div>{renderActions(_actions_, actionExecutor, fixedState, 3)}</div>}
                                        </div>

                                        {!isDisplay && component === 'ItemEditorList' && <div>EmbedEditor</div>}
                                        {isDisplay  && <div>{component}</div>}

                                        {!isDisplay && component !== 'ItemEditorList' && <TemplateEditorFormItem
                                            item={item}
                                            value={valueSet[key]}
                                            onChange={onChangeHandlerGenerator(item)}
                                            extraProps={extraProps}/>}


                                    </div>
                                })}

                            </AutoWidthGridLayout>

                        </Col>

                    </Row>
                }}
            />
        </div>
    </div>
}

import React, {useCallback, useEffect} from "react"
import Display from "../Display"
import {Tag} from "antd"

export default ({chartGroup, ...otherProps}) => {


    const {nodes, ...groupDisplay} = chartGroup

    return <div>

        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <div>
                {nodes.map(({color, name}, idx) => {
                    return <Tag key={idx} color={color}>{name}</Tag>
                })}
            </div>
        </div>

        <Display {...groupDisplay} {...otherProps}/>
    </div>

}

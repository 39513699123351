import React, {useEffect, useState} from "react"

import LeftMenuDisplay from "../LeftMenuDisplay"

export default (props) => {

    return <LeftMenuDisplay
        {...props}
        fixedFilterKey={'project_id'}
        outline={{uri: '/common/projectOptions'}}
        contentDisplay={{
            component: 'GoodsSkuPriceTable',
            props: {
                uri: '/sku/listPrices'
            }
        }}

    />
}

import React, {useCallback, useEffect, useMemo, useState} from "react"

import { Button, Card, Descriptions, Divider, Select, Space, Table, Cascader, Input, InputNumber, Row, Col} from "antd"
import {useHttpGet, useLocalStoredObject} from "../../../core/hooks"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {useActionExecutor} from "../../../core/actionExecuter"

import {renderActions, renderActionWith, renderItemFieldValue} from "../../../core/domUtil"
import {conditionsMatched} from "../../../core/conditionsMatcher"
import { Link } from "react-router-dom"

export default (props) => {

    const {uri, query, fixedQuery, navigation, readonly} = props
    const [response, requestApplyDetail] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    const [selects, setSelects] = useState({})

    const [canCommit, shouldEdit] = useMemo(() => {
        if (readonly) return [false, false]
        let orders = response && response.data && response.data.supplierOrders || []
        let ok = true;
        for (let {supplier_id, purchase_order_id, ...order} of orders) {
            if (purchase_order_id) return [true, false];
            const setkeys = [
                'supplier_contact_id', 'pay_type', 'warehouse_sub_id', 'tax_rate', 'invoice_type',
                'agent_supplier_id', 'ccl_type',
            ]
            for (let key of setkeys) {
                if (!selects[supplier_id]) {
                    selects[supplier_id]={}
                }
                if (!selects[supplier_id][key]) {
                    if (!order[key]) {
                        if (['agent_supplier_id', 'ccl_type'].indexOf(key) >= 0) {
                            if (order.currency === 2) {
                                ok = false;
                            }
                        } else {
                            ok = false;
                        }
                    } else {
                        selects[supplier_id][key] = order[key]
                    }
                }
            }
        }
        return [ok, true];
    }, [selects, response, readonly])
    useEffect(() => {
        requestApplyDetail({...query, ...fixedQuery})
    }, [query, requestApplyDetail, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestApplyDetail}
        renderContent={({profile, action, displayFields, supplierOrders, totalAmount, prepayAmount, expectLeadTime}) => {
            const {item, displayedItem, displayFields: detailFields} = profile
            const showKeys = ['plan_id', 'creator_id']
            const itemExcludeKeys = ['supplier_id'];

            const expandedFields = displayFields.filter(({y}) => y > 0)

            return <div className="page-content-wrapper" style={{ marginLeft: 0, marginRight: 0}}>
                {supplierOrders.map(({supplier_id, keyValuePairs, summary, items}, orderIdx) => {
                    const spanSUM = displayFields.filter(({y, key}) => y < 1 && itemExcludeKeys.indexOf(key) < 0).reduce((accumulator, field) => {
                        const {span} = field;
                        return accumulator + parseInt(span, 10)
                    }, 0)
                    const tableProps = {}
                    if (expandedFields.length) {
                        tableProps.expandable = {
                            expandedRowRender: (item) => <Descriptions size={'small'}>
                                {expandedFields.map((field) => {
                                    const {key, title} = field
                                    return <Descriptions.Item label={title} key={key}>
                                        {renderItemFieldValue(field, item)}
                                    </Descriptions.Item>
                                })}
                            </Descriptions>,
                            rowExpandable: () => true,
                        }
                    }
                    return <div key={orderIdx} className="orderApply" key={orderIdx}>
                    
                        <Table 
                            title={() => {
                                return <div>
                                <Row size='small' gutter={[6,16]}>
                                    {keyValuePairs.map(({ label, value, link, options, option_value, action, key, type }, idx) => {
                                        let span = 6;
                                        if (link) {
                                            return <Col span={span} key={'link_' + key} label={(
                                                <Label label={label} />
                                            )}>
                                                <Label label={label} />
                                                <Link target='_blank' to={link}>{value}</Link>
                                            </Col>
                                        }
                                        if (shouldEdit) {
                                            if (options && options.length) {
                                                const Selector = options[0].children ? Cascader : Select
                                                return <Col span={span} key={'select_' + key} label={(
                                                    <Label label={label} />
                                                )}>
                                                    <Label label={label} />
                                                    <Selector
                                                        style={{width: '70%'}}
                                                        value={selects[supplier_id] && selects[supplier_id][key] || option_value} 
                                                        options={options}
                                                        onChange={(value, k) => {
                                                            if (value < 0) {
                                                                actionExecutor.generateActionHandler(action)();
                                                            } else {
                                                                setSelects({
                                                                    ...selects,
                                                                    [supplier_id]: {
                                                                        ...selects[supplier_id],
                                                                        [key]: value
                                                                    }
                                                                })
                                                            }
                                                        }}/>
                                                </Col>
                                            }
                                        }
                                        return label ? <Col span={span} key={'text' + key} label={(
                                            <Label label={label} />
                                        )}><Label label={label} /> {value}</Col> : <Col span={span}/>
                                })}
                                </Row>
                            </div>
                            }}
                        rowKey={'id'} bordered={true} dataSource={items} pagination={false} size={'small'}
                            {...tableProps}>
                            {displayFields.filter(({y, key}) => y < 1 && itemExcludeKeys.indexOf(key) < 0).map((displayField, columnIdx) => {
                                const {key, title, span} = displayField
                                return <Table.Column dataIndex={key} title={title} key={columnIdx}
                                                     width={(span / spanSUM) * 100 + '%'}
                                                     render={(cellVal, record) => {
                                                         return <div>{renderItemFieldValue(displayField, record)}</div>
                                                     }}/>
                            })}
                        </Table>


                        <div style={{marginTop: '10px'}}>
                            {summary}
                        </div>

                    </div>
                })}
                <div>

                    {totalAmount > 0 && <div style={{marginTop: '10px', marginBottom: '20px'}}>
                        <div><b>采购金额总计</b>&nbsp;<b style={{color: 'red'}}>{renderItemFieldValue({
                            key: 'amount',
                            style: 'currency',
                            currency: 'cny'
                        }, {amount: totalAmount})}</b></div>
                        {prepayAmount > 0 && <div><b>预付金额总计</b>&nbsp;<b style={{color: 'red'}}>{renderItemFieldValue({
                            key: 'amount',
                            style: 'currency',
                            currency: 'cny'
                        }, {amount: prepayAmount})}</b></div>}
                        <div><b>最晚到料时间：</b>&nbsp;{expectLeadTime}</div>
                    </div>}
                    <div>
                    <div>采购备注</div>
                        <pre>
                        {displayedItem.remark}
                    </pre>
                    </div>

                </div>

                {!readonly && action && item.status == 0 && <Space>
                    <Button size='middle' type='primary' disabled={!canCommit}
                            onClick={actionExecutor.generateActionHandler({
                                ...action,
                                params: {
                                    ...action.params,
                                    save_params: JSON.stringify(selects),
                                }
                            })}>{action.title}</Button>
                    <Divider/>
                    {navigation && item.status == 0 && <Button size='middle' onClick={() => {
                        navigation && navigation.replace('/production/purchase_apply/create?keep=1')
                    }}>返回上一页</Button>}
                </Space>}

            </div>

        }}
    />

}
const Label = ({ label }) => {
    return <div style={{ fontWeight: '600', display: 'inline-block', width: '30%'}}>{label}: </div>
}
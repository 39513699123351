import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
    Col,
    Form,
    Input,
    Result,
    Row,
    Table,
    Typography,
    Select,
    DatePicker,
    Checkbox,
    Button,
    Radio,
    Card,
} from "antd";
import ReactEcharts from "echarts-for-react";
import { useHttpGet, useRouteQuery } from "../../../core/hooks";
import { useActionExecutor } from "../../../core/actionExecuter";
import LoadingResponseWrapper from "../../LoadingResponseWrapper";
import { renderActions, renderItemFieldValue } from "../../../core/domUtil";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/skeleton/Title";
import moment from "moment";
import { useHistory } from "react-router";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const TOOLTIP = {
    trigger: "axis",
    axisPointer: {
        type: "cross",
        snap: true,
        label: { show: false },
    },
    order: "valueDesc",
    formatter: param => {
        return (
            "<div>" +
            "<h3 style='color: white; margin: auto'>" +
            param[0].name +
            "</h3><hr style='color: white;'/>" +
            param
                .map(item => {
                    return item.value == null
                        ? ""
                        : "<span>" +
                              item.marker +
                              "<span><b>" +
                              item.seriesName +
                              "</b></span>:<span style='opacity: 0; margin-right:20px'> ¥" +
                              item.value +
                              "</span><span style='position:absolute; right:10px'> ¥" +
                              item.value +
                              "</span></span><br/>";
                })
                .join("") +
            "</div>"
        );
    },
    position: [10, 10],
};
let OPTION_TEMPLATE = {
    legend: {
        type: "scroll",
    },
    grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
    xAxis: [{ type: "category", data: [] }],
    yAxis: [{ type: "value" }],
};

function dataGroup(item, typeKey) {
    if (typeKey === "client_type") {
        return { id: item.type_sub_type, name: item.type_sub_type_name };
    } else if (typeKey === "location") {
        return {
            id: item.location,
            name: ["国内", "海外"][item.location] ?? "其它",
        };
    } else if (typeKey === "keeper") {
        return { id: item.keeper_id, name: item.keeper_name };
    } else {
        return { id: item.client_id, name: item.client_name };
    }
}

function reduceDataGroup(datas, groupType, groupsAll) {
    let totalMap = {};
    let dataList = [];
    if (groupType && groupType != "client") {
        for (let data of datas) {
            let { id: gId } = dataGroup(data, groupType);
            if (!totalMap[gId]) {
                totalMap[gId] = 0;
            }
            totalMap[gId] += parseFloat(data.total_money);
            dataList.push({
                name: data.client_name,
                type: "bar",
                stack: "split",
                emphasis: { focus: "series" },
                data: groupsAll.map(v =>
                    gId == v ? parseFloat(data.total_money).toFixed(2) : null
                ),
            });
        }

        // if (groupType ==='keeper') {
        //     let aTotal = groupsAll.map(
        //         gid => parseFloat(totalMap[gid]).toFixed(2) || 0
        //     )// 合计数据。
        //     // TODO-make a sorter
        // }

        dataList.unshift({
            name: "合计",
            type: "bar",
            stack: "total",
            barWidth: 7,
            groups: totalMap,
            emphasis: { focus: "series" },
            data: groupsAll.map(
                gid => parseFloat(totalMap[gid]).toFixed(2) || 0
            ),
        });
    } else {
        dataList = [
            // {
            //     data: parseFloat(data.total_money).toFixed(2),
            //     type: "bar",
            // },
        ];
    }
    return dataList;
}

function createGroupedOption(datas, groupType, groupsAll = []) {
    if (!groupsAll || groupsAll.length == 0) {
        groupsAll = Object.values(
            datas.reduce((r, c) => {
                let g = dataGroup(c, groupType);
                if (!r[g.id]) {
                    r[g.id] = g;
                }
                return r;
            }, {})
        );
    }
    if (!groupType || groupType === "client") {
        return {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                    label: { show: false },
                },
            },
            dataset: { source: datas },
            series: {
                type: "bar",
                encode: { x: "client_name", y: "total_money" },
            },
            grid: { left: "3%", right: "4%", bottom: "3%", containLabel: true },
            xAxis: [{ type: "category", axisLabel: { rotate: 30 } }],
            yAxis: [{ type: "value" }],
        };
    } else {
        let opt = JSON.parse(JSON.stringify(OPTION_TEMPLATE));
        opt.xAxis[0].data = groupsAll.map(g => g.name);
        opt.series = reduceDataGroup(
            datas,
            groupType,
            groupsAll.map(g => g.id)
        );
        opt.tooltip = { ...TOOLTIP };
        return opt;
    }
}

export default ({ uri, params, title, datePickerType = "year" }) => {
    let routeQuery = useRouteQuery();
    let by = routeQuery?.by || "year";
    let fmt =
        by === "month" ? "YYYYMM" : by === "quarter" ? "YYYY[Q]Q" : "YYYY";
    let range = routeQuery?.range || "default";
    let currentDayRange = useMemo(() => {
        let r = moment();
        let ranger = range;
        if (!range || range === "default") {
            ranger = r.format(fmt);
        }
        if (by === "year") {
            return r.year(ranger.substr(0, 4));
        } else if (by === "quarter") {
            return r
                .year(ranger.substr(0, 4))
                .month((ranger.substr(5, 1) - 1) * 3);
        } else {
            return r
                .year(ranger.substr(0, 4))
                .month(
                    !range.substr(4, 2) || isNaN(range.substr(4, 2))
                        ? r.month()
                        : range.substr(4, 2) - 1
                );
        }
    }, [by, range]);
    const [tongjiResponse, getTongji] = useHttpGet(uri);
    const refreshPage = useCallback(() => {
        getTongji({ by, range: currentDayRange.format(fmt) });
    }, [by, currentDayRange, getTongji]);

    const history = useHistory();

    useEffect(() => {
        refreshPage();
    }, []);

    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    return (
        <LoadingResponseWrapper
            {...tongjiResponse}
            refresh={refreshPage}
            renderContent={data => {
                const { group_orders, details: datas } = data;
                let option1 = createGroupedOption(
                    datas || [],
                    "client_type",
                    group_orders["client_type"]
                );
                let option2 = createGroupedOption(
                    datas || [],
                    "location",
                    group_orders["location"]
                );
                let option3 = createGroupedOption(
                    datas || [],
                    "keeper",
                    group_orders["keeper"]
                );
                let option4 = createGroupedOption(datas || [], "client");
                return (
                    <div ref={elementRef} style={{ margin: 20 }}>
                        <Row style={{ marginLeft: 30 }} gutter={(16, 16)}>
                            <Col span={4}>{title ? <h2>{title}</h2> : ""}</Col>
                            <Col>
                                <DatePicker
                                    picker={datePickerType}
                                    value={currentDayRange}
                                    disabledDate={current => {
                                        // Can not select days after today and today
                                        return (
                                            current &&
                                            current.year() < 2020 &&
                                            current > moment().endOf("day")
                                        );
                                    }}
                                    onChange={ms => {
                                        if (!ms) return;
                                        let selected = ms.format(fmt);
                                        history.replace(
                                            history.location.pathname +
                                                "?" +
                                                new URLSearchParams({
                                                    ...routeQuery,
                                                    by,
                                                    range: selected,
                                                }).toString()
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Card>
                                    <ReactEcharts
                                        option={option1}
                                        style={{ height: 500 }}
                                    />
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card>
                                    <ReactEcharts
                                        option={option2}
                                        style={{ height: 500 }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <ReactEcharts
                                        option={option3}
                                        style={{ height: 300 }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Card>
                                    <ReactEcharts
                                        option={option4}
                                        style={{ height: 500 }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                );
            }}
        />
    );
};

import React, { useEffect, useMemo, useRef } from "react";
import {
  Card,
  PageHeader,
  Button,
  Input,
  Descriptions,
  Steps,
  Spin,
  Avatar,
  Col,
  Result,
  Empty,
  List,
  Typography,
  Badge,
  Image,
  Row,
} from "antd";
import { useHttpGet } from "../../../core/hooks";
import { renderActionWith, renderItemFieldValue } from "../../../core/domUtil";
import moment from "moment";
import { FormatMoney } from "format-money-js";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
import Icon from "../../Icon";
import DisplayComponent from "../Display";
import ProfileDisplay from "../ProfileDisplay";
import DescriptionsDisplay from "../DescriptionsDisplay";
import { FileList } from "../FilesRefDisplay";
// import { useActionExecutor } from '../../../core/actionExecuter';
const { Title } = Typography;
const fm = new FormatMoney();
export default ({ id, bizId, type: bizType, actionResult, actionExecutor }) => {
  const componentRef = useRef();
  const [profileResponse, getProfile] = useHttpGet("/approval/getProfile");
  useEffect(() => {
    if (!id && !bizId) {
      return;
    }
    let params = bizType
      ? {
          type: bizType,
          biz_id: bizId,
        }
      : { id: id };
    getProfile(params);
  }, [bizId, bizType, id, actionResult]);
  if (profileResponse.loading) {
    return (
      <div className="full-content-center">
        <Spin />
      </div>
    );
  }
  if (!profileResponse.data) {
    return <Empty />;
  }
  let approvalDetail = profileResponse.data.approval_detail;
  let bizDetail = profileResponse.data.biz_detail.detail;
  let actions = profileResponse.data.actions;

  bizDetail.displayedItem.hexiao_money_amount = MoneyForKey(
    "hexiao_money_amount",
    bizDetail.item
  );
  bizDetail.displayedItem.payment_money_amount = MoneyForKey(
    "payment_money_amount",
    bizDetail.item
  );

  if (!bizDetail.displayedItem.status_label) {
    bizDetail.displayedItem.status_label = bizDetail.displayedItem.status;
  }

  let color = "blue";
  switch (bizDetail.displayedItem.status_label) {
    case "待审批":
      color = "orange";
      break;
    case "待支付":
      color = "blue";
      break;
    case "已通过":
    case "已完成":
      color = "green";
      break;
    case "被驳回":
    case "已撤回":
      color = "red";
      break;
    default:
      color = "blue";
  }

  bizDetail.displayedItem.status = (
    <Badge color={color} text={bizDetail.displayedItem.status_label} />
  );

  const { Step } = Steps;

  return (
    <div>
      <PageHeader
        title={approvalDetail.flow_id + "    #" + approvalDetail.code_name}
        extra={
          <div>
            {actions &&
              actions.map((action, i) => {
                return renderActionWith(
                  action,
                  actionExecutor,
                  {},
                  (title, action, onClick) => {
                    // 这种做法不是很好。应该想其它办法。
                    let type = "default";
                    if (title == "删除" || title == "撤回订单") {
                      type = "danger";
                    } else if (title == "提交审核" || title == "通过") {
                      type = "primary";
                    } else if (title == "拒绝") {
                      type = "dashed";
                    }
                    return (
                      <Button
                        size="middle"
                        style={{ marginLeft: 15 }}
                        onClick={onClick}
                        type={type}
                        key={"action " + i}
                      >
                        {title.toUpperCase()}
                      </Button>
                    );
                  }
                );
              })}
            {approvalDetail.status >= 3 && approvalDetail.status < 11 && (
              <ReactToPrint
                trigger={() => {
                  return (
                    <Button
                      style={{ marginLeft: 15 }}
                      icon={<PrinterOutlined />}
                    >
                      打印单据
                    </Button>
                  );
                }}
                documentTitle={approvalDetail.title}
                pageStyle={{
                  paddingTop: 50,
                  paddingLeft: 30,
                  paddingBottom: 50,
                  paddingRight: 50,
                }}
                content={() => componentRef.current}
              />
            )}
          </div>
        }
      >
        <div ref={componentRef}>
          <DescriptionsDisplay
            title={
              <div>
                <Title level={5}>基础信息</Title>
              </div>
            }
            {...bizDetail}
            actionExecutor={actionExecutor}
          />
          {profileResponse.data.extra && (
            <DisplayComponent {...profileResponse.data.extra} />
          )}

          {!profileResponse.data.biz_detail_items &&
            profileResponse.data.others &&
            profileResponse.data.others.map((other, i) => {
              return (
                <RelationList
                  key={"relation_" + i}
                  title={other.label}
                  data={other}
                />
              );
            })}
          <FileList title="附件" data={profileResponse.data.attach_files} />
          <FileList
            title="电子发票"
            data={profileResponse.data.invoice_files}
          />
          <div>
            <div style={{ margin: "30px 0 15px 0" }}>
              <Title level={5}>审批流程</Title>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Steps direction="vertical">
                {/*<Avatar size={40}>USER</Avatar>*/}
                {profileResponse.data.flow_nodes.map((item, i, a) => {
                  const status =
                    item.status == 1
                      ? "process"
                      : item.status == 2 || item.status == 11  // 撤回
                      ? "error"
                      : item.status == 3
                      ? "finish"
                      : item.status == 4
                      ? "finish"
                      : "waiting";
                  const title = item.title;
                  const approver =
                    item.username_to_approval &&
                    item.username_to_approval.join("、");
                  const time = item.operated_at
                    ? moment(item.operated_at * 1000).format("YYYY-MM-DD")
                    : " ";

                  const descCc =
                    item.username_to_cc.length > 0 && item.status == 3 ? (
                      <span>{"已抄送给 " + item.username_to_cc.join("、")}</span>
                    ) : item.username_to_cc.length > 0 && item.status == 1 ? (
                      <span>{"将会抄送给 " + item.username_to_cc.join("、")}</span>
                    ) : (
                      <span></span>
                    );
                  const descRecord =
                    item.records && item.records.length > 0 ? (
                      <div>
                        {item.records.map((record, j) => {
                          return (
                            <div key={"row_" + j}>
                              {record.username +
                                (["拒绝", '通过', '撤回'][record.operate_type] ?? "拒绝(其它操作)") +
                                "(#" +
                                moment(record.operated_at * 1000).format(
                                  "YYYY-MM-DD HH:mm"
                                ) +
                                ")" +
                                (record.message
                                  ? (record.operate_type === 2 ?"    撤回原因：" : "    审批意见：") + record.message
                                  : "")}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span />
                    );

                  const description = (
                    <div>
                      {descRecord}
                      {descCc}
                    </div>
                  );
                  return (
                    <Step
                      key={"step_" + i}
                      status={status}
                      title={item.title}
                      subTitle={approver}
                      description={description}
                    />
                  );
                })}
              </Steps>
            </div>
          </div>
        </div>
      </PageHeader>
    </div>
  );
};

function MoneyForKey(key, item) {
  let origalContent = parseFloat(item[key]);
  if (!origalContent) return "--";
  let content = origalContent.toFixed(2);
  let more = "";
  let type = "￥";
  if (
    item.hasOwnProperty("currency") &&
    item.currency > 1 &&
    key == "payment_money_amount"
  ) {
    content = parseFloat(item.payment_money_amount_usd).toFixed(2);
    type = "$";
  } else if (key === "payment_money_amount" && item.hexiao_money_amount) {
    let hexiao = parseFloat(item.hexiao_money_amount);
    content = (origalContent - hexiao).toFixed(2);
    if (hexiao) {
      more = ` = ${origalContent.toFixed(2)} - ${hexiao.toFixed(2)}`;
    }
  }
  return (
    <span>
      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
        {fm.from(+content, { symbol: type, decimals: 2 })}
      </span>
      {more}
    </span>
  );
}

function RelationList({ title, data }) {
  if (data) {
    let { content: { component, props } = {} } = data;
    if (!component) {
      return null;
    }
    return (
      <div>
        <div style={{ margin: "30px 0 15px 0" }}>
          <Title level={5}>{title}</Title>
        </div>
        <div>
          <div>
            <DisplayComponent
              key={"idx"}
              {...props}
              component={component}
              props={{
                pagerDisabled: true,
                readonly: props.readonly ?? true,
                showPadding: false,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

import React, { useCallback, useEffect } from "react";

import { Button, Col, Descriptions, Row, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
    AppleFilled,
    AppleOutlined,
    FacebookFilled,
    FacebookOutlined,
    GoogleCircleFilled,
    GoogleOutlined,
    GoogleSquareFilled,
    WechatFilled,
    WechatOutlined,
} from "@ant-design/icons";
import { useHttpGet, useHttpPost } from "../../../core/hooks";
import { useActionExecutor } from "../../../core/actionExecuter";
import LoadingResponseWrapper from "../../LoadingResponseWrapper";
import moment from "moment";
import { renderActions } from "../../../core/domUtil";

export default ({ dispaly_title, fixedQuery, uri, id }) => {
    const [detail, getDetail] = useHttpGet(uri);
    const [whitelistR, optWhiteList] = useHttpPost("/plus/whitelist");
    const [privateR, optPrivate] = useHttpPost("/QingUser/setPrivateFlag");
    const [developerR, optDeveloper] = useHttpPost(
        "/QingUser/registerDeveloper"
    );
    const [, actionResult, actionExecutor] = useActionExecutor();
    const refreshPage = useCallback(() => {
        getDetail();
    }, [uri]);

    useEffect(() => {
        getDetail();
    }, [whitelistR, privateR, developerR]);
    return (
        <LoadingResponseWrapper
            {...detail}
            refresh={refreshPage}
            renderContent={({
                nickname,
                created_at,
                developer_created_at,
                phone,
                phone_extra,
                email,
                sns,
                feedback_count,
                plus_whitelist,
                iot_uid,
                is_developer,
                is_private,
                _actions_,
            }) => {
                return (
                    <Descriptions
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>{dispaly_title}</div>
                                {_actions_?.length &&
                                    renderActions(
                                        _actions_,
                                        actionExecutor,
                                        {},
                                        5,
                                        {
                                            type: "link",
                                        }
                                    )}
                            </div>
                        }
                        size="large"
                        bordered={true}
                    >
                        <Descriptions.Item label="昵称">
                            <span>
                                {nickname} (ID: {id})
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="注册时间" span={2}>
                            <span>
                                {moment(created_at * 1000).format("YYYY-MM-DD")}
                            </span>
                        </Descriptions.Item>

                        <Descriptions.Item label="电话">
                            {phone ? (
                                <span>
                                    {phone_extra
                                        ? "(+" + phone_extra + ")"
                                        : ""}{" "}
                                    {phone}
                                </span>
                            ) : (
                                "--"
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="邮箱">
                            <span>{email ? email : "--"}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label="SNS">
                            <Tooltip
                                title={
                                    sns.wechat
                                        ? "已绑定:" + sns.wechat.nickname
                                        : "未绑定"
                                }
                            >
                                <WechatFilled
                                    style={{
                                        fontSize: 30,
                                        color: sns.wechat ? "#1AAD19" : "gray",
                                    }}
                                />
                            </Tooltip>
                            <Tooltip
                                title={
                                    sns.facebook
                                        ? "已绑定:" + sns.facebook.nickname
                                        : "未绑定"
                                }
                            >
                                <FacebookFilled
                                    style={{
                                        fontSize: 30,
                                        color: sns.facebook
                                            ? "#3b5998"
                                            : "gray",
                                    }}
                                />
                            </Tooltip>
                            <Tooltip
                                title={
                                    sns.apple
                                        ? "已绑定:" + sns.apple.nickname
                                        : "未绑定"
                                }
                            >
                                <AppleFilled
                                    style={{
                                        fontSize: 30,
                                        color: sns.apple ? undefined : "gray",
                                    }}
                                />
                            </Tooltip>
                            <Tooltip
                                title={
                                    sns.google
                                        ? "已绑定:" + sns.google.nickname
                                        : "未绑定"
                                }
                            >
                                {sns.google ? (
                                    <GoogleSquareFilled
                                        style={{
                                            fontSize: 30,
                                            color: "#dd4b39",
                                        }}
                                    />
                                ) : (
                                    <GoogleOutlined
                                        style={{ fontSize: 30, color: "gray" }}
                                    />
                                )}
                            </Tooltip>
                        </Descriptions.Item>
                        <Descriptions.Item label="用户的反馈">
                            <span>{feedback_count || 0}个会话</span>{" "}
                            <Link
                                to={"/qp/feedbacks?user_id=" + id}
                                target="_blank"
                            >
                                点击查看
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="青萍+ 白名单">
                            <span>{plus_whitelist ? "YES" : "NO"}</span>{" "}
                            <Button
                                onClick={() => {
                                    optWhiteList({
                                        qing_user_id: id,
                                        action: plus_whitelist
                                            ? "exit"
                                            : "join",
                                    });
                                }}
                            >
                                {plus_whitelist ? "点击退出" : "点击加入"}
                            </Button>
                        </Descriptions.Item>
                        <Descriptions.Item label="青萍物联用户">
                            {iot_uid ? (
                                <Link
                                    to={"/iot/user/" + iot_uid}
                                    target="_blank"
                                >
                                    点击查看
                                </Link>
                            ) : (
                                "未开通"
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="开发者账号">
                            {is_developer ? (
                                <span>
                                    {moment(developer_created_at * 1000).format(
                                        "YYYY-MM-DD"
                                    )}{" "}
                                    开通
                                </span>
                            ) : (
                                <>
                                    未开通
                                    <Button
                                        onClick={() => {
                                            optDeveloper({
                                                qing_user_id: id,
                                                enable: 1,
                                            });
                                        }}
                                    >
                                        {"点击开通"}
                                    </Button>
                                </>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="私有化">
                            {is_private
                                ? "已开启"
                                : is_developer
                                ? "未开启"
                                : "--"}
                            {is_developer && (
                                <Button
                                    onClick={() => {
                                        optPrivate({
                                            qing_user_id: id,
                                            private: is_private ? 0 : 1,
                                        });
                                    }}
                                >
                                    {is_private ? "点击关闭" : "点击开启私有化"}
                                </Button>
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                );
            }}
        />
    );
};

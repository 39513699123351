import React, {useCallback, useEffect, useState} from "react"
import {useHttpAction, useHttpGet} from "../../../core/hooks"
import {useActionExecutor} from "../../../core/actionExecuter"
import {Button, Col, Descriptions, Divider, Input, message, Row, Space, Table, Typography, List, PageHeader} from "antd"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import DescriptionsDisplay from "../DescriptionsDisplay"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import {conditionsMatched} from "../../../core/conditionsMatcher"
import {CheckCircleTwoTone, InfoCircleTwoTone} from "@ant-design/icons"
import { Link } from "react-router-dom"

const {Title, Paragraph} = Typography


export default ({orderId}) => {
    const [response, requestTaskDetail] = useHttpGet('/sale/getOrderDeliverInfo')

    useEffect(() => {
        requestTaskDetail({
            order_id: orderId
        })
    }, [orderId])


    return <LoadingResponseWrapper
        {...response}
        refresh={requestTaskDetail}
        renderContent={({displayFields, items, title, task_count, house_count}) => {
            // const entryAction = !showEditing ? retrieveEntryAction(base) : null
            const typeTitle = '出库'
            return <PageHeader title={"出库单"}>
                <Table
                    size={'small'}
                    bordered={true}
                    pagination={false}
                    dataSource={items}
                    rowKey={({id}) => id}>

                    <Table.Column dataIndex={'house_name'} title={'仓库'}
                        align={'center'} render={(value, record) => {
                            return {
                                children: value,
                                props: {
                                    rowSpan: record.house_name_row_span
                                }
                            }
                        }} />
                    <Table.Column dataIndex={'task_id'} title={'出库单'}
                        align={'center'}
                        
                        key={displayFields.length}
                        render={(cellVal, record) => {
                            return {
                                children: <Link to={'/stockio/detail/' + cellVal}>{record.task_id_code}</Link>,
                                props: {
                                    rowSpan: record.task_id_code_row_span
                                }
                            }
                    }}/>

                    {displayFields.map((displayField, columnIdx) => {
                        const {key, title} = displayField

                        return <Table.Column dataIndex={key} title={title} key={columnIdx}
                                             align={'center'}
                                             render={(cellVal, record) => {
                                                 return renderItemFieldValue(displayField, record)
                                             }}/>

                    })}
                    <Table.Column dataIndex={'status'} title={''}
                        align={'center'}
                        key={displayFields.length}
                        render={(cellVal, record) => {
                            return record.waiting_quantity <= 0 ?
                                <CheckCircleTwoTone
                                    twoToneColor="#52c41a"/> :
                                <InfoCircleTwoTone twoToneColor="#f2a537"/>
                        }}/>
                </Table>
            </PageHeader>
        }}
    />

}

import { Button, Cascader, Col, Input, Row, Table } from "antd";
import { FormatMoney } from "format-money-js";
import React, { useEffect, useMemo, useState } from "react";
import { useHttpGet } from "../../../core/hooks";
const moneier = new FormatMoney();
export default props => {
    const {
        props: { key, label, validator },
        valueSet,
        rows,
        onChange,
        valueSetUpdater,
    } = props;
    const { id, client_id } = valueSet;
    let { sale_order_id } = valueSet;
    const [goodsOptionsResponse, getSkuOptions] = useHttpGet(
        "/sale/getGoodsSelectOptions", {
            withOfflines: true,
        }
    );
    const [invoiceResponse, getOrderInfo] = useHttpGet(
        "/sale/getOrderInvoiceItems"
    );
    const [inputItems, setInputItems] = useState([]);

    sale_order_id = Array.isArray(sale_order_id)
        ? sale_order_id
        : typeof sale_order_id === "string"
        ? sale_order_id.split(",")
        : [sale_order_id];

    useEffect(() => {
        getSkuOptions();
    }, []);

    useEffect(() => {
        valueSetUpdater({
            sale_order_id: [],
            client_invoice_info_id: 0,
        });
    }, [client_id]);

    useEffect(() => {
        if (id) return;
        setInputItems([]);
        if (!sale_order_id || !sale_order_id.length) {
            return;
        }
        getOrderInfo({
            sale_order_id: sale_order_id,
        });
    }, [id, sale_order_id ? sale_order_id.join(",") : ""]);
    useEffect(() => {
        const { data = [], loading = false, success = false } = invoiceResponse;
        let items = [];
        if (success && !loading && data) {
            for (let row of data) {
                row.item_price = parseFloat(row.item_price);
                items.push(row);
            }
        }
        setInputItems(items);
    }, [invoiceResponse]);

    useEffect(() => {
        const filtered = inputItems.filter(
            c =>
                parseFloat(c.item_price) &&
                parseInt(c.quantity) &&
                (c.sku_id || c.item_name)
        );
        let totprice =
            Math.round(
                filtered.reduce((p, c, i) => {
                    return (
                        p +
                        (parseFloat(c.item_price) || 0) *
                            (parseInt(c.quantity) || 0)
                    );
                }, 0) * 100
            ) / 100;
        valueSetUpdater({
            money_amount: totprice,
            [key]: JSON.stringify(filtered),
        });
    }, [inputItems]);

    const goodsOptions = goodsOptionsResponse
        ? goodsOptionsResponse.data || []
        : [];

    return (
        <div>
            <Table
                loading={
                    invoiceResponse.loading || goodsOptionsResponse.loading
                }
                dataSource={inputItems ? [...inputItems, { empty: true }] : []}
                rowKey={v => `${v.sku_id}-${v.item_price}`}
                pagination={false}
                footer={() => <span>请将要开票的商品的具体名称写到这里明细中的<b>开票名称*</b>列</span>}
            >
                <Table.Column
                    title="SKU"
                    dataIndex="selected_sku_id"
                    width="15%"
                    render={(value, record, index) => {
                        return index >= inputItems.length ? (
                            validator ? (
                                <span style={{ color: "#ff4d4f" }}>
                                    {validator.help}
                                </span>
                            ) : (
                                ""
                            )
                        ) : (
                            <Cascader
                                key="sku_selector"
                                value={value}
                                style={{ width: "100%" }}
                                changeOnSelect={false}
                                options={goodsOptions}
                                displayRender={(labels, nodes) => {
                                    return nodes
                                        .map(v => {
                                            return v.label;
                                        })
                                        .join("/");
                                }}
                                onChange={(value, selectedOptions) => {
                                    const skuId = value[value.length - 1];
                                    inputItems[index]["selected_sku_id"] =
                                        value;
                                    inputItems[index]["sku_id"] = skuId;
                                    inputItems[index]["item_name"] =
                                        selectedOptions
                                            .map(({ label }) => label)
                                            .join(" ");
                                    inputItems[index]["item_type"] =
                                        selectedOptions[1].label;
                                    setInputItems([...inputItems]);
                                }}
                            ></Cascader>
                        );
                    }}
                />
                <Table.Column
                    title={<span>开票名称<span style={{color: 'red'}}>*</span></span>}
                    width="25%"
                    dataIndex="item_name"
                    render={(value, record, index) =>
                        index >= inputItems.length ? (
                            ""
                        ) : (
                            <div>
                            <Input
                                value={inputItems[index]["item_name"]}
                                // disabled={inputItems[index]['sku_id']}
                                contentEditable={inputItems[index]["sku_id"]}
                                autoComplete="off"
                                onChange={ev => {
                                    inputItems[index]["item_name"] =
                                        ev.target.value;
                                    setInputItems([...inputItems]);
                                }}
                            />
                                {inputItems[index].item_name_advice &&<>参考名称：<span style={{cursor: 'pointer'}} onClick={() => {
                                    inputItems[index]["item_name"] = inputItems[index]["item_name_advice"];
                                    setInputItems([...inputItems]);
                                }} >{inputItems[index].item_name_advice}</span></>}
                            </div>
                        )
                    }
                />
                <Table.Column
                    title="价格"
                    dataIndex="item_price"
                    width="20%"
                    render={(value, record, index) =>
                        index >= inputItems.length ? (
                            ""
                        ) : (
                            <Input
                                value={inputItems[index]["item_price"]}
                                type="number"
                                addonBefore="￥"
                                onChange={ev => {
                                    inputItems[index]["item_price"] =
                                        parseFloat(ev.target.value) || "";
                                    setInputItems([...inputItems]);
                                }}
                            />
                        )
                    }
                />
                <Table.Column
                    title="数量"
                    dataIndex="quantity"
                    width="10%"
                    render={(value, record, index) =>
                        index >= inputItems.length ? (
                            ""
                        ) : (
                            <Input
                                value={inputItems[index]["quantity"]}
                                type="number"
                                onChange={ev => {
                                    inputItems[index]["quantity"] =
                                        parseInt(ev.target.value) || "";
                                    setInputItems([...inputItems]);
                                }}
                            />
                        )
                    }
                /><Table.Column
                title="单位"
                dataIndex="unit"
                width="5%"
                render={(value, record, index) =>
                    index >= inputItems.length ? (
                        ""
                    ) : (
                        <Input
                            value={inputItems[index]["unit"]}
                            placeholder="个"
                            onChange={ev => {
                                inputItems[index]["unit"] = ev.target.value || "个";
                                setInputItems([...inputItems]);
                            }}
                        />
                    )
                }
            />
                <Table.Column
                    title="总金额"
                    width="10%"
                    render={(v, r, index) =>
                        index >= inputItems.length
                            ? ""
                            : moneier.from(
                                  r.quantity && r.item_price
                                      ? r.quantity * r.item_price
                                      : 0,
                                  { symbol: "￥", decimals: 4 }
                              )
                    }
                />
                <Table.Column
                    title="操作"
                    dataIndex="empty"
                    render={(v, r, index) =>
                        v ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    inputItems.push({ sku_id: 0 });
                                    setInputItems([...inputItems]);
                                }}
                            >
                                增加一行
                            </Button>
                        ) : (
                            <Button
                                type="link"
                                onClick={() => {
                                    inputItems.splice(index, 1);
                                    setInputItems([...inputItems]);
                                }}
                            >
                                删除
                            </Button>
                        )
                    }
                />
            </Table>
        </div>
    );
};

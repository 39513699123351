import React, { useCallback, useEffect } from "react";
import PageHeaderWrapper from "../PageHeaderWrapper";
import { useHttpGet, useRouteQuery } from "../../core/hooks";
import { Button } from "antd";
import { renderActions, renderActionWith } from "../../core/domUtil";
import { useActionExecutor } from "../../core/actionExecuter";
import { useHistory, useRouteMatch } from "react-router-dom";
import { buildSearchParams } from "../../core/uriBuilder";
import TopTabbedDisplay from "./TopTabbedDisplay";
import LoadingResponseWrapper from "../LoadingResponseWrapper";
import DisplayPage from "../../pages/home/DisplayPage";

const buildDisplayProps = (tabs, fixedFilterKey, routeQuery) => {
    return {
        component: "TopTabbedDisplay",
        props: {
            tabs: tabs.map(tab => {
                return {
                    ...tab,
                    // props: { ...routeQuery, ...tab.params },
                    content: {
                        ...tab.content,
                        props: {
                            ...tab.content.props,
                            params: { ...routeQuery, ...tab.params },
                        },
                    },
                };
            }),
            fixedFilterKey: fixedFilterKey || "_tab_activity_id_",
        },
    };
};
export default props => {
    let routeQuery = useRouteQuery();
    const { uri, tabKey, fixedQuery = {} } = props;
    const [tabsResponse, getTabs] = useHttpGet(uri);
    const [, actionResult, actionExecutor] = useActionExecutor();
    const refreshPage = useCallback(() => {
        getTabs({
            ...fixedQuery,
        });
    }, [getTabs]);

    useEffect(() => {
        getTabs();
    }, []);
    return (
        <div>
            <LoadingResponseWrapper
                {...tabsResponse}
                refresh={refreshPage}
                renderContent={({
                    tabs,
                    tab_key: responseTabKey,
                    _actions_ = [],
                }) => {
                    const displayProps = buildDisplayProps(
                        tabs,
                        responseTabKey ? responseTabKey : tabKey || "key",
                        routeQuery
                    );
                    return <DisplayPage {...displayProps} />;
                }}
            />
        </div>
    );
};

import React, {useEffect, useState, useCallback} from "react"
import {useHttpGet, useHttpPostJson, useRefresh, useRouteQuery} from "../../core/hooks"
import {
    Col,
    Row,
    Typography,
    Card,
    Button,
    PageHeader,
    Table,
    Form,
    Input,
    Select,
    Upload,
    Icon,
    Radio,
    Descriptions
} from "antd"
import {useHistory, useLocation} from "react-router-dom"
import PageHeaderWrapper from "../../components/PageHeaderWrapper";

const {Title} = Typography;
const {Column} = Table;
const {Option} = Select;

const OrderPackageNamePath = "order_package"
export default () => {
    const [getInNumber, setInNumber] = useState({})
    const headerProps = {}
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
        },
        {
            title: '商品',
            dataIndex: 'goods',
        },
        {
            title: '数量',
            dataIndex: 'plan_count',
            align:'center'
        },
        // {
        //     title: '已入库数量',
        //     dataIndex: 'out_cout',
        // },
        {
            title: '待入库数量',
            dataIndex: 'left_count',
            align:'center'

        },
        {
            title: '入库数量',
            dataIndex: 'in_out_count',
            align:'center',
            render:(value,record) =>{
                return <Input style={{width:80}} name={record.goods_id} defaultValue={value} onChange={e => setStockInNumber(e.target.value,record)}/>

            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
        },
    ];
    const data = [
        {
            key: '1',
            goods_id:'1203',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
        {
            key: '2',
            goods_id:'1204',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
        {
            key: '4',
            goods_id:'1205',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
    ];
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 16},
    };
    const uploadProps = {
        name: 'file',
        action: '//jsonplaceholder.typicode.com/posts/',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
            } else if (info.file.status === 'error') {
            }
        },
    };

    function setStockInNumber(value,record){
        let obj = {};
        const key = record['goods_id'];
        obj[key] = value;
        setInNumber(obj)
        console.log('bbbb',setInNumber)


    }
    return <div>
        <PageHeaderWrapper
            {...headerProps}>
        </PageHeaderWrapper>
        <div className="page-content-wrapper">
            <Card>
                <Row gutter={10}>
                    <Col span={5} style={{borderRight:'1px solid #f5f5f5'}}>
                        <div className="stockInfoList">
                            <p><span>入库单号：</span>IN-20200708</p>
                            <p><span>所属仓库：</span>飞歌成品仓库</p>
                            <p><span>入库类型：</span>生产入库</p>
                            <p><span>生产订单：</span>DH-20200708</p>
                            <p><span>申请人：</span>孙兰</p>
                            <p><span>申请时间：</span>2020/12/20 12：00</p>
                        </div>
                    </Col>
                    <Col span={18}  style={{paddingLeft:25}}>
                        <Row>
                            <Col span={12}>
                                <Typography>
                                    <Title level={4}>备注</Title>
                                    <p>
                                        京市海淀区文龙家园三里京市海淀区文龙家园三里京市海淀区文龙家园三里京市海淀区文龙家园三里
                                    </p>
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Typography>
                                    <Title level={4}>商品信息</Title>
                                    <div>
                                        <Table columns={columns} dataSource={data} size="middle"/>
                                    </div>
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form
                                    {...layout}
                                    name="basic"
                                    labelAlign="left"
                                    // initialValues={{ remember: true }}
                                    // onFinish={onFinish}
                                    // onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="入库单据"
                                    >

                                        <Upload name="logo" action="/upload.do" listType="picture">
                                            <Button>
                                                <Icon type={<Icon type="upload" />} /> 上传
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                    <Form.Item
                                        label="操作人"
                                        name="操作人"
                                    >
                                        <Select
                                            placeholder="操作人"
                                            allowClear
                                            defaultValue="1"
                                            disabled
                                        >
                                            <Option value="1">孙兰</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            提交
                                        </Button>
                                        <Button htmlType="button" style={{marginLeft:20}}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Card>
        </div>
    </div>
}
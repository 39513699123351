import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Card, Row, Col, Divider } from "antd";

import DisplayComponent from "./Display";

export default ({
    query: { subQuery, ...query } = {},
    tabs,
    tabsExtra,
    fixedFilterKey = "active_tab_id",
    onQueryUpdated,
    ...otherProps
}) => {
    const [activeTabKey, setActiveTabKey] = useState(
        query && query[fixedFilterKey]
    );
    useEffect(() => {
        setActiveTabKey(query && query[fixedFilterKey]);
    }, [query, fixedFilterKey]);
    const componentQuery = { ...query };
    if (activeTabKey) {
        componentQuery[fixedFilterKey] = activeTabKey;
    }

    const { showPadding } = otherProps;

    return (
        <Card
            bordered={false}
            bodyStyle={{ padding: !showPadding ? "0" : "24px" }}
        >
            <Tabs
                size="small"
                activeKey={activeTabKey}
                onChange={activeTabKey => {
                    if (onQueryUpdated) {
                        const updates = {};
                        updates[fixedFilterKey] = activeTabKey;
                        updates["subQuery"] = undefined;
                        onQueryUpdated(updates);
                    } else {
                        setActiveTabKey(activeTabKey);
                    }
                }}
                tabBarExtraContent={tabsExtra}
            >
                {tabs &&
                    tabs.map(
                        ({ label, value, content: { props, ...content } }) => {
                            if (subQuery) {
                                props.query = JSON.parse(subQuery);
                            }
                            return (
                                <Tabs.TabPane tab={label} key={"" + value}>
                                    <DisplayComponent
                                        {...content}
                                        {...otherProps}
                                        props={props}
                                        onQueryUpdated={
                                            onQueryUpdated
                                                ? keyvalues => {
                                                      onQueryUpdated({
                                                          subQuery: keyvalues
                                                              ? JSON.stringify(
                                                                    keyvalues
                                                                )
                                                              : undefined,
                                                      });
                                                  }
                                                : null
                                        }
                                    />
                                </Tabs.TabPane>
                            );
                        }
                    )}
            </Tabs>
        </Card>
    );
};

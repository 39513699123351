import React, {useCallback, useEffect, useState} from "react"
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"

import DisplayComponent from "./Display"

import {useActionExecutor} from "../../core/actionExecuter"
import {buildSearchParams} from "../../core/uriBuilder";
import {useHistory, useRouteMatch} from "react-router-dom";


export default (props) => {

    const {uri, query, fixedQuery, onQueryUpdated} = props
    const [response, requestProfile] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    const history = useHistory()
    const {url} = useRouteMatch()

    useEffect(() => {
        requestProfile({...query, ...fixedQuery})
    }, [fixedQuery, requestProfile, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestProfile}
        renderContent={({displays}) => {
            return <div className="page-content-wrapper">
                {displays.map((display, idx) => {
                    return <DisplayComponent
                        component={display.component}
                        actionExecutor={actionExecutor}
                        key={idx}
                        props={{
                            ...display.props,
                            query: query,
                            navigation: history,
                            url: url,
                            showPadding:false,
                            onQueryUpdated,
                            //  onQueryUpdated: (updates) => {
                            //     history.push({
                            //         pathname: url,
                            //         search: buildSearchParams(query, updates)
                            //     })
                            // }
                        }}

                    />
                })}
            </div>
        }}
/>

}

function valueMatched(matchedValue, operator, currentValue) {

    if (operator !== '*=') {
        if (Array.isArray(currentValue)) {
            currentValue = currentValue.length ? currentValue[currentValue.length - 1] : null
        }
    }

    switch (operator) {
        case '!=':
            if (Array.isArray(matchedValue)) {
                if (!currentValue) currentValue = null;
                return matchedValue.indexOf(currentValue) < 0 && matchedValue.indexOf('' + currentValue) < 0
            }

            if (matchedValue === null || (''+matchedValue).indexOf(null) >= 0) {
                return currentValue !== null && currentValue !== undefined && currentValue !== ''
            }

            return matchedValue !== ('' + currentValue)
        case '>=':
            return currentValue >= parseFloat(matchedValue)
        case '<=':
            return currentValue <= parseFloat(matchedValue)
        case '*=':
            const target = currentValue ? (!Array.isArray(currentValue) ? ('' + currentValue).split(',') : currentValue) : []
            const source = !Array.isArray(matchedValue) ? ('' + matchedValue).split(',') : matchedValue

            for (let t of target) {
                for (let s of source) {
                    if ('' + t === '' + s) {
                        return true
                    }
                }
            }

            return false
        case '=':
            if (Array.isArray(matchedValue)) {
                if (!currentValue) currentValue = null;
                return matchedValue.indexOf(currentValue) >= 0 || matchedValue.indexOf('' + currentValue) >= 0
            }

            if (matchedValue === null || ('' + matchedValue).indexOf(null) >= 0) {
                return currentValue === null || currentValue === undefined
            }

            return ('' + currentValue) === ('' + matchedValue)
        case '>':
            return currentValue > parseFloat(matchedValue)
        case '<':
            return currentValue < parseFloat(matchedValue)
        default:
            return true
    }
}

function conditionsMatched(condition, valueSet) {
    if (!valueSet) return true
    for (const {value, operator, key} of condition) {
        let currentValue = valueSet[key]
        if (!valueMatched(value, operator, currentValue)) {
            return false
        }
    }

    return true
}

export {
    conditionsMatched,
    valueMatched
}

import {Parser} from "expr-eval"
import _ from 'lodash'
import moment from "moment"

const timeSequenceLines = ({chart, metricSet, colors, dateFormat = 'HH:mm'}) => {

    const {value, source, title, range} = chart

    const valueSet = _.get(metricSet, source)
    const legends = Object.keys(valueSet).sort((a, b) => {
        return a.localeCompare(b)
    })

    const series = []
    const smooth = true
    const showSymbol = false
    for (let name of legends) {

        series.push({
            name,
            data: valueSet[name].map((item) => {
                return [item.startTime * 1000, Parser.evaluate(value, item)]
            }),
            type: 'line',
            smooth,
            showSymbol

        })
    }
    const yAxis = {
        type: 'value',
        showGrid: false,
        name: title,
        splitLine: {show: false},
        ...range
    }
    return {
        grid: {
            show: false
        },

        // title: {
        //     text: title,
        //     left: 'center'
        // },
        color: colors,

        xAxis: {
            type: 'time',
            showGrid: false,
            splitLine: {show: false},
            axisLabel: {
                formatter: function (value, index) {
                    // 格式化成月/日，只在第一个刻度显示年份

                    return moment(value).format(dateFormat)

                    // const date = new Date(value)
                    // const texts = [date.getHours(), ('' + date.getMinutes()).padStart(2, '0')]
                    // return texts.join(':')
                }
            }
        },

        yAxis,

        series
    }
}

const singleTouchableLine = ({chart, metricSet, colors, dateFormat = 'HH:mm'}) => {

    const {value, source, title, range, showDelta = false} = chart

    const valueSet = _.get(metricSet, source)
    const legends = Object.keys(valueSet).sort((a, b) => {
        return a.localeCompare(b)
    })

    const series = []
    const smooth = true
    const showSymbol = true
    for (let name of legends) {

        let data = []
        const arr = valueSet[name]
        if (showDelta) {
            for (let i = 1; i < arr.length; i++) {
                const item = arr[i]
                data.push([
                    item.startTime * 1000,
                    Parser.evaluate(value, item) - Parser.evaluate(value, arr[i - 1])
                ])
            }
        } else {
            data = arr.map((item) => {
                return [item.startTime * 1000, Parser.evaluate(value, item)]
            })
        }

        series.push({
            name:title,
            data,
            type: 'line',
            smooth,
            showSymbol
        })
    }
    const yAxis = {
        type: 'value',
        showGrid: false,
        // name: title,
        splitLine: {show: false},
        ...range
    }
    return {
        grid: {
            show: false
        },

        title: {
            text: title,
            left: 'center'
        },
        color: colors,

        tooltip: {
            'trigger': 'axis',
            'axisPointer': {'animation': false}
        },

        xAxis: {
            type: 'time',
            showGrid: false,
            splitLine: {show: false},
            axisLabel: {
                formatter: function (value, index) {
                    // 格式化成月/日，只在第一个刻度显示年份

                    return moment(value).format(dateFormat)

                    // const date = new Date(value)
                    // const texts = [date.getHours(), ('' + date.getMinutes()).padStart(2, '0')]
                    // return texts.join(':')
                }
            }
        },

        yAxis,

        series
    }
}

const buildNodeMergedChart = ({chart, metricSet}) => {

    const {target, source, seriesDefines, options} = chart
    const items = _.get(metricSet, source)[target]

    const series = []

    const legends = []
    for (let {value, props} of seriesDefines) {
        series.push({
            data: items.map((item) => {
                return [item.startTime * 1000, Parser.evaluate(value, item)]
            }),
            ...props,
        })
        legends.push(props.name)
    }

    return {
        ...options,
        series,
        legend: {
            bottom: '10px',
            data: legends
        }
    }


}

export default {
    buildNodeMergedChart,
    timeSequenceLines,
    singleTouchableLine
}

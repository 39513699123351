import React, {useCallback, useEffect, useState} from "react"
import {useHttpApi, useHttpGet, useLocalStoredObject} from "../../core/hooks"
import {Spin, Result, Button, Tree, Card} from "antd"

import {renderActions} from "../../core/domUtil"
import {useActionExecutor} from "../../core/actionExecuter"

export default props => {

    const {uri, query, localStoreKey, onListStateUpdate, fixedState, fixedQuery} = props

    const [, actionResult, actionExecutor] = useActionExecutor()
    const [itemsResponse, requestItems] = useHttpApi(uri, {
        method: 'GET',
        onSuccess: (data) => {
            onListStateUpdate && onListStateUpdate({itemsResponse: data, actionExecutor})
        }
    })

    const {error, data} = itemsResponse
    const [expandedRowKeys, setExpandedRowKeys] = useLocalStoredObject(localStoreKey + '_expand_keys', [])

    useEffect(() => {
        requestItems({...query, ...fixedQuery})
    }, [actionResult, requestItems])

    const renderTreeNodes = useCallback((items) => {
        return items.map(({id, children, title, href, _actions_}) => {

            const titleNode = <div className='tree-node-item'>
                <div className='title'>{title}</div>
                {!!_actions_ && _actions_.length > 0 &&
                <div className='actions'>{renderActions(_actions_, actionExecutor, {}, 10)}</div>}
            </div>

            // const titleNode = title

            if (children && children.length) {
                return <Tree.TreeNode
                    selectable={false} title={titleNode}
                    key={'' + id}>
                    {renderTreeNodes(children)}
                </Tree.TreeNode>
            }
            return <Tree.TreeNode selectable={false} key={id.toString()} title={titleNode}/>
        })
    })

    if (!data) {
        if (error) {
            return <div className="full-content-center">
                <Result
                    status="error"
                    title={error.desc}
                    extra={
                        <Button type="primary" key="console" onClick={() => {
                            requestItems({...query, ...fixedQuery})
                        }}>
                            重试
                        </Button>
                    }
                /></div>
        }

        return <div className="full-content-center">
            <Spin/>
        </div>
    }

    const {sortAction, items, actions = []} = data
    const onNodeDrop = (info) => {
        const {dropToGap, dropPosition, dragNode, node} = info
        const dropNodePos = node.props.pos.split('-')
        const p = dropPosition - dropNodePos[dropNodePos.length - 1]

        // const describe = dropToGap ? (`move ${dragNode.props.title} below/above ${node.props.title} : ${p}`) : `move ${dragNode.props.title} into ${node.props.title}`

        if (sortAction) {
            sortAction.params = {
                ...sortAction.params,
                from_id: dragNode.props.eventKey,
                to_id: node.props.eventKey,
                position: dropToGap ? p : 0
            }
            actionExecutor.performAction(sortAction)
        }
    }

    return <Card bordered={false}>
        <div className="action-search-bar" style={{marginTop: '5px'}}>
            {
                actions.length > 0 && <div className="actions-bar"
                                           style={{marginBottom: '22px'}}>{renderActions(actions, actionExecutor, fixedState, 6, {type: 'primary'})}</div>

            }
        </div>
        <Tree
            blockNode={true}
            draggable={items.length > 0 && !!sortAction}
            expandedKeys={expandedRowKeys}
            onExpand={setExpandedRowKeys}
            onDrop={onNodeDrop}>
            {renderTreeNodes(items)}
        </Tree>
    </Card>
}

import React, {useCallback, useEffect, useState} from "react"
import {Col, Form, Input, Result, Row, Select, Table, Typography} from "antd"
import {useHttpGet} from "../../../core/hooks"
import {useActionExecutor} from "../../../core/actionExecuter"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import Text from "antd/lib/typography/Text"
import Title from "antd/lib/skeleton/Title"
import SaleMonthlyStatTable from "./SaleMonthlyStatTable"
export default ({uri, title}) => {
  
    const [params, setParams] = useState({
        by: 'dove',
        dove_type: 'all',
        client_type: 'platforms',
        group_by: 'client'
    });

    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    return <div ref={elementRef} style={{ margin: 20 }}>
        {title ? <h2>{title}</h2> : ''}
        <Row style={{ marginLeft: 30 }} gutter={16, 16}>
            <Col span={4}>
                <Select style={{ width: '100%' }} value={params.dove_type} onChange={(v) => {
                    setParams({
                        ...params,
                        'dove_type': v,
                        'client_type': v === 'no_battery' ? 'all' : params.client_type
                    })
                }}>
                    <Select.Option value='all'>所有 Dove</Select.Option>
                    <Select.Option value='no_battery'>无电池版本</Select.Option>
                    <Select.Option value='with_battery'>有电池版本</Select.Option>
                </Select>
            </Col>
            <Col span={4}>
                <Select style={{ width: '100%' }} value={params.client_type} onChange={(v) => {
                    setParams({
                        ...params,
                        'client_type': v,
                    })
                }}>
                    <Select.Option value='all'>所有客户</Select.Option>
                    <Select.Option value='platforms'>仅销售平台</Select.Option>
                    <Select.Option value='no_platforms'>非销售平台</Select.Option>
                </Select>
            </Col>
            <Col span={4}>
                <Select style={{ width: '100%' }} value={params.group_by} onChange={(v) => {
                    setParams({
                        ...params,
                        'group_by': v,
                    })
                }}>
                    <Select.Option value='client'>按客户</Select.Option>
                    <Select.Option value='color'>按颜色</Select.Option>
                </Select>
            </Col>


        </Row>
        <SaleMonthlyStatTable uri={uri} params={params}></SaleMonthlyStatTable>
    </div>

}


import { Input, InputNumber } from "antd"
import React, { useEffect, useState } from "react"
import { useHttpAction, useHttpApi, useHttpGet, useRefresh } from "../../../core/hooks"

import { FormatMoney } from "format-money-js"
const moneier = new FormatMoney()
export default ({ props: { key, label}, valueSet, uri, rows, valueSetUpdater, }) => {
    const { client_id, platform_sku_id, sale_amount, money_amount } = valueSet;
    const [{ data: skuInfo }, getPlatformSkuInfo, clear] = useHttpGet('/sale/platformSkuInfo')
    const r = useRefresh()
    useEffect(() => {
        if (platform_sku_id) {
            getPlatformSkuInfo({ client_id, platform_sku_id })
        } else {
            clear()
        }
    }, [platform_sku_id])

    let sumMoney = sale_amount * money_amount;
    useEffect(() => {
        if (skuInfo) {
            valueSetUpdater && valueSetUpdater({
                money_amount: skuInfo.item_price,
                platform_sku_name: skuInfo.sku_name,
                sum_money: (skuInfo.item_price * sale_amount) || 0
            })
        }
    }, [skuInfo])


    useEffect(() => {
        valueSetUpdater && valueSetUpdater({ sum_money: sumMoney || 0 })
    }, [sumMoney])

    let itemProps = {
    }

    return <div style={{margin :8}}>
        {sumMoney ? moneier.from(sumMoney, {symbol: '￥', decimals:2}) : '--' }
    </div>
}
import { Button, Divider, Form, InputNumber, Table, Tabs } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { FormatMoney } from "format-money-js"
import { useHttpGet } from "../../../core/hooks"
import {renderActions, renderItemFieldValue, renderBatchItemActions} from "../../../core/domUtil"
const { TabPane } = Tabs;
export default (props) => {
    const { valueSet, rows, onChange, valueSetUpdater } = props
    const { manufacture_order_id, item_type = 0, io_type = 0, in_type = 0, out_type = 0, warehouse_sub_id } = valueSet
    const [selectedIds, setSelectedManufuckId] = useState([])
    const [totalTargetCount, setTotalTargetCount] = useState({})
    const [useCounts, setUseCounts] = useState({})
    const [forbidenSub, setForbidenSub] = useState({})
    const [materialCounts, setMaterialCounts] = useState({})
    const [manufactureResponse, getManufactureInfo, clearResponse] = useHttpGet('/production/getManufactureInfo');

    const isTakingMaterial = parseInt(out_type) === 8;
    const isReturnMaterial = parseInt(in_type) === 38;
    const isBuildProducts = parseInt(in_type) === 32;
    const showTargetTable = isBuildProducts || isTakingMaterial
    const showMaterialTable = isReturnMaterial || isTakingMaterial
    const useInputs = isReturnMaterial
    const inputTitle = isTakingMaterial ? '领料数量' : isReturnMaterial ? '退料数量' : '数量'
    const targetInputTitle = isTakingMaterial ? '齐套领料' : isBuildProducts ? '入库数量' : '数量'
    useEffect(() => {
        if (manufacture_order_id && warehouse_sub_id) {
            getManufactureInfo({
                warehouse_sub_id,
                manufacture_order_id,
                io_type, in_type, out_type, item_type
            })
        } else {
            clearResponse()
        }
    }, [manufacture_order_id, warehouse_sub_id, io_type, in_type, out_type])

    let { loading, error, data: {
        manufactureTargets = [],
        displayFields = [],
    } = {} } = manufactureResponse
    if (!displayFields) displayFields = []

    useEffect(() => {
        setSelectedManufuckId([])
    }, [manufactureResponse])

    useEffect(() => {
        let ids = [];
        for(let id in totalTargetCount) {
            if (totalTargetCount[id] > 0) {
                if (!selectedIds.includes(parseInt(id))) {
                    ids.push(parseInt(id));
                }
            }
        }
        if (ids.length > 0) {
            console.log('manu', 'set selectedIds', ids, selectedIds)
            setSelectedManufuckId([
                ...selectedIds,
                ...ids,
            ])
        }
    }, [totalTargetCount, selectedIds])

    console.log('manu', 'selectedIds', selectedIds)

    const takingMaterailCountsResult = JSON.stringify(isBuildProducts ? (manufactureTargets.map((value) => {
        return value.material_id ? {
            material_id: value.material_id,
            count: totalTargetCount[value.id] ? totalTargetCount[value.id] : 0
        } : {
            sku_id: value.sku_id,
            count: totalTargetCount[value.id] ? totalTargetCount[value.id] : 0
        }
    })) :([{'nothing' : 'false'}]).filter((v) => v.count))

    useEffect(() => {
        onChange && onChange(takingMaterailCountsResult)
    }, [takingMaterailCountsResult])



    return <div>
        {showTargetTable && <Table
            pagination={false}
            loading={loading}
            dataSource={manufactureTargets}
            rowKey={({ id }) => id}
            rowSelection={isTakingMaterial ? {
                selectedRowKeys: selectedIds,
                selections: true,
                type: 'checkbox',
                onSelect: ({id}, s) => {
                    if (!s) {
                        setTotalTargetCount({
                            ...totalTargetCount,
                            [id]: 0
                        })
                    }
                },
                onChange: (selectedKeys, records) => {
                    setSelectedManufuckId(selectedKeys)
                }
            }:undefined}
            size={'small'}>
            {[
                {title:'生产内容', key: 'manufacture_target_name'}, 
                {title:'品名', key: 'category_name'},
                {title:'描述', key: 'description'}, 
                {title:'BOM', key: 'bom_href', style: 'link'}, 
                {title:'计划生产', key: 'plan_quantity', style: 'count'},
                {title:'已入库', key: 'made_quantity', style: 'count'},
            ].map((displayField) => {
                return <Table.Column title={displayField.title} dataIndex={displayField.key} 
                    render={(cellVal, record) => {
                        return <ColumnWrapper columnProps={displayField} record={record}/>
                    }}>
                </Table.Column>
            })}
            <Table.Column title={targetInputTitle} render={(value, {id}) => {
                return <InputNumber min={0} value={totalTargetCount[id]} onChange={(number) => {
                    setTotalTargetCount({
                        ...totalTargetCount,
                        [id]: parseInt(number)||0
                    })
                }} />
            }}></Table.Column>
        </Table> || ''}
        <Divider />
    </div>
}
const ColumnWrapper = ({columnProps, record, readonly, actionExecutor}) => {
    return renderItemFieldValue(columnProps, record, readonly, actionExecutor)
}
import React, {
    useCallback,
    useEffect,
    useState,
    useRef,
    useMemo,
} from "react";
import { Upload, Modal, Button, Typography, Image, Popover } from "antd";
import { useHttpPost, useHttpGet, useHttpApi } from "../../core/hooks";
import {
    UploadOutlined,
    PlusOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    FileExcelOutlined,
    FileZipOutlined,
    PaperClipOutlined,
    FileImageOutlined,
    FileOutlined,
    FileTextOutlined,
    FilePptOutlined,
} from "@ant-design/icons";
import Icon from "../Icon";
const { Title } = Typography;
export default ({ ref_token, title, dispaly_title }) => {
    const [fileList, setFileList] = useState([]);
    const [, getRefFiles] = useHttpApi("/file/refrencedFiles", {
        method: "GET",
        onSuccess: data => {
            if (data.files) {
                setFileList(data.files);
            }
        },
    });

    useEffect(() => {
        if (ref_token) {
            getRefFiles({ ref_token: ref_token });
        }
    }, [ref_token]);

    return fileList?.length ? (
        <FileList title={title || dispaly_title} data={fileList} />
    ) : (
        "无"
    );
};
const iconStyle = { fontSize: 22 };
const iconMap = {
    jpg: <FileImageOutlined style={iconStyle} />,
    png: <FileImageOutlined style={iconStyle} />,
    jpeg: <FileImageOutlined style={iconStyle} />,
    gif: <FileImageOutlined style={iconStyle} />,
    bmp: <FileImageOutlined style={iconStyle} />,
    doc: <FileWordOutlined style={iconStyle} />,
    pdf: <FilePdfOutlined style={iconStyle} />,
    doc: <FileWordOutlined style={iconStyle} />,
    docx: <FileWordOutlined style={iconStyle} />,
    xls: <FileExcelOutlined style={iconStyle} />,
    xlsx: <FileExcelOutlined style={iconStyle} />,
    ppt: <FilePptOutlined style={iconStyle} />,
    pptx: <FilePptOutlined style={iconStyle} />,
    zip: <FileZipOutlined style={iconStyle} />,
    rar: <FileZipOutlined style={iconStyle} />,
    txt: <FileTextOutlined style={iconStyle} />,
    json: <FileTextOutlined style={iconStyle} />,
    xml: <FileTextOutlined style={iconStyle} />,
    default: <FileOutlined style={iconStyle} />,
};

export const FileList = ({ title, data }) => {
    return (
        data && (
            <div>
                <div style={{ margin: "30px 0 15px 0" }}>
                    <Title level={5}>{title}</Title>
                </div>
                <div>
                    <div>
                        <div>
                            {data.map(({ url, file_type, file_name }, i) => {
                                const fileName = file_name || `下载文件`;
                                if (file_type?.startsWith("image")) {
                                    return (
                                        <div key={"i_" + i}>
                                            <Image
                                                width={100}
                                                height={60}
                                                style={{ marginRight: 20 }}
                                                src={url}
                                                alt={fileName}
                                            />
                                        </div>
                                    );
                                }
                                let I = <PaperClipOutlined />;
                                for (let ext of Object.keys(iconMap)) {
                                    if (
                                        file_type?.endsWith(ext) ||
                                        file_name.endsWith("." + ext)
                                    ) {
                                        I = iconMap[ext];
                                    }
                                }
                                return (
                                    <div
                                        key={"i_" + i}
                                        style={{ padding: "2px 0" }}
                                    >
                                        <a
                                            target="_blank"
                                            href={url}
                                            title={fileName}
                                            style={{
                                                display: "inline-block",
                                                textAlign: "center",
                                            }}
                                        >
                                            {I} <span>{fileName}</span>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export const FileIcons = ({ files }) => {
    return (
        <div>
            {files.map(({ url, file_type, file_name }, i) => {
                const fileName = file_name || `文件 ${i + 1}`;
                let I = iconMap.default;
                for (let ext of Object.keys(iconMap)) {
                    if (
                        file_type?.endsWith(ext) ||
                        fileName.endsWith("." + ext)
                    ) {
                        I = iconMap[ext];
                    }
                }
                return file_type?.startsWith("image") ? (
                    <Popover
                        title={fileName}
                        content={
                            <Image
                                width={100}
                                style={{ marginRight: 20 }}
                                src={url}
                                alt={fileName}
                            />
                        }
                        trigger="hover"
                    >
                        <a>{I}</a>
                    </Popover>
                ) : (
                    <a
                        target="_blank"
                        href={url}
                        title={fileName}
                        style={{ display: "inline-block", textAlign: "center" }}
                    >
                        {I}
                    </a>
                );
            })}
        </div>
    );
};

import {
    Button,
    Card,
    Descriptions,
    PageHeader,
    Result,
    Spin,
    Steps,
    Table,
    List,
    Divider,
    Collapse,
    Col,
    Row,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { useActionExecutor } from "../../core/actionExecuter";
import AdminContext from "../../core/AdminContext";
import { useHttpGet, useRouteQuery, useHttpPost } from "../../core/hooks";
import { renderActions, renderActionWith } from "../../core/domUtil";
import ClientConaddTable from "../../components/biz/ClientConaddTable";
import { FormatMoney } from "format-money-js";
import OrderStockOutDisplay from "../../components/displays/sale/OrderStockOutDisplay";
import FilesRefDisplay from "../../components/displays/FilesRefDisplay";

const { Panel } = Collapse;
const { Column } = Table;
const { Step } = Steps;
export default () => {
    const { pageStates } = useContext(AdminContext);
    let query = useRouteQuery();
    const { path, url } = useRouteMatch();
    if (pageStates[path]) {
        const { fixedQuery, fixedState } = pageStates[path];
        query = {
            ...query,
            ...fixedQuery,
        };
    }
    const [orderDetailResponse, getOrderDetail] = useHttpGet(
        "/sale/getOrderDetail",
        {
            order_id: query.id,
        }
    );
    // const [makePlansResponse, makePlans] = useHttpPost('/sale/makeDeliverPlans', {
    //     order_id: query.id
    // })

    const [, actionResult, actionExecutor] = useActionExecutor();

    useEffect(() => {
        getOrderDetail();
    }, [actionResult, url]);

    const { loading, success, error, data } = orderDetailResponse;
    const {
        order,
        client,
        moneys,
        order_packages: orderPackages,
        deliver_plans: deliverPlans,
        order_overview: orderOverview = {},
        addresses: addressMap,
        contacts: contactMap,
    } = useMemo(() => {
        if (!data) {
            return {};
        }
        let money_plans = data.money_plans;
        let action = data.money_plan_edit_action;
        let [ok, wait, okTotal, waitTotal] = money_plans.reduce(
            (result, money, i) => {
                result[2] += parseFloat(money.confirmed_money_amount);
                result[3] +=
                    parseFloat(money.money_amount) -
                    parseFloat(money.confirmed_money_amount);
                if (money.status == 10) {
                    result[0].push(money);
                } else {
                    result[1].push(money);
                }

                return result;
            },
            [[], [], 0, 0]
        );
        data.moneys = { ok, wait, okTotal, waitTotal, action };
        return data;
    }, [data]);

    if (!order || loading) {
        return (
            <div className="full-content-center">
                <Spin />
            </div>
        );
    }

    if (error) {
        return (
            <div className="full-content-center">
                <Result
                    status="error"
                    title={error.desc}
                    extra={
                        <Button
                            type="primary"
                            key="console"
                            onClick={() => {
                                getOrderDetail();
                            }}
                        >
                            重试
                        </Button>
                    }
                />
            </div>
        );
    }
    const currency = order.currency == "usd" ? "$" : "￥";
    const { steps = [] } = orderOverview;
    return (
        <div>
            <PageHeaderWrapper
                title="订单详情"
                extra={
                    <div>
                        {data.actions.map((action, i) => {
                            return renderActionWith(
                                action,
                                actionExecutor,
                                {},
                                (title, action, onClick) => {
                                    // 这种做法不是很好。应该想其它办法。
                                    let type = "default";
                                    if (
                                        title == "删除" ||
                                        title == "撤回订单"
                                    ) {
                                        type = "danger";
                                    } else if (
                                        title == "提交审核" ||
                                        title == "批准"
                                    ) {
                                        type = "primary";
                                    } else if (title == "拒绝") {
                                        type = "dashed";
                                    }
                                    return (
                                        <Button
                                            size="large"
                                            style={{ marginLeft: 15 }}
                                            onClick={onClick}
                                            type={type}
                                            key={"action " + i}
                                        >
                                            {title.toUpperCase()}
                                        </Button>
                                    );
                                }
                            );
                        })}
                    </div>
                }
            ></PageHeaderWrapper>

            <Card bordered={false} style={{ margin: 20 }}>
                <PageHeader title="">
                    <Steps
                        current={orderOverview.current}
                        status={orderOverview.status || "process"}
                        labelPlacement="vertical"
                    >
                        {steps.map((step, i) => {
                            return (
                                <Step
                                    key={"step" + i}
                                    title={step.title}
                                    status={step.status}
                                    subTitle={
                                        step.money
                                            ? step.sub_title +
                                              formatMoney(step.money, currency)
                                            : step.sub_title
                                    }
                                    description={
                                        step.time > 0
                                            ? moment(step.time * 1000).format(
                                                  "YYYY-MM-DD HH:mm:ss"
                                              )
                                            : ""
                                    }
                                />
                            );
                        })}
                    </Steps>
                </PageHeader>
            </Card>
            <div style={{ margin: 20 }}>
                <Card bordered={false}>
                    <PageHeader title="基本信息" extra={<span></span>}>
                        <div style={{ textAlign: "left" }}>
                            <Descriptions
                                column={3}
                                bordered={true}
                                style={{ width: "75vw" }}
                            >
                                <Descriptions.Item label="订单编号">
                                    {order.code_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="客户">
                                    <Link to={"/sale/client/" + client.id}>
                                        {client.code_name} {client.name}
                                    </Link>
                                </Descriptions.Item>
                                <Descriptions.Item label="联系人">
                                    {client.contact.name} (电话：
                                    {client.contact.phone})
                                </Descriptions.Item>
                                <Descriptions.Item label="详单状态">
                                    {order.status_text}
                                </Descriptions.Item>
                                <Descriptions.Item label="申请人">
                                    {order.saler.name}
                                </Descriptions.Item>
                                <Descriptions.Item label="申请时间">
                                    {moment(order.created_at * 1000).format(
                                        "YYYY-MM-DD HH:mm:ss"
                                    )}
                                </Descriptions.Item>

                                <Descriptions.Item label="销售金额">
                                    {formatMoney(order.total_price, currency)}
                                    {order.currency !== "cny"
                                        ? " ≈ " +
                                          formatMoney(
                                              order.total_price_cny,
                                              "¥"
                                          )
                                        : null}
                                </Descriptions.Item>
                                <Descriptions.Item label="其它费用">
                                    {order.other_fee
                                        ? formatMoney(order.other_fee, currency)
                                        : "无"}
                                    {order.other_fee && order.currency !== "cny"
                                        ? " ≈ " +
                                          formatMoney(order.other_fee_cny, "¥")
                                        : null}
                                </Descriptions.Item>
                                <Descriptions.Item label="结束时间">
                                    {order.ended_at
                                        ? moment(order.ended_at * 1000).format(
                                              "YYYY-MM-DD HH:mm:ss"
                                          )
                                        : "未结束"}
                                </Descriptions.Item>
                                <Descriptions.Item label="备注" span={2}>
                                    {order.note}
                                </Descriptions.Item>
                                <Descriptions.Item label="附件">
                                    <FilesRefDisplay
                                        ref_token={order.attached_file}
                                    />
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </PageHeader>
                    <PageHeader title="销售信息">
                        <div>
                            {orderPackages.map((onePackage, i, a) => {
                                return (
                                    <div key={"order_p_" + i}>
                                        <Table
                                            key={"order_detail_" + i}
                                            size="middle"
                                            title={() => (
                                                <h3>
                                                    包裹 No.{i + 1} #
                                                    {onePackage.id}{" "}
                                                    {renderActions(
                                                        onePackage.actions ||
                                                            [],
                                                        actionExecutor
                                                    )}
                                                </h3>
                                            )}
                                            rowKey="id"
                                            dataSource={onePackage.items}
                                            pagination={false}
                                            footer={
                                                parseFloat(order.total_price)
                                                    ? data => {
                                                          let smallTotal =
                                                              data.reduce(
                                                                  (
                                                                      last,
                                                                      item
                                                                  ) => {
                                                                      return (
                                                                          last +
                                                                          (item.price *
                                                                              item.count ||
                                                                              0)
                                                                      );
                                                                  },
                                                                  0
                                                              );
                                                          return (
                                                              <div
                                                                  style={{
                                                                      textAlign:
                                                                          "right",
                                                                  }}
                                                              >
                                                                  小结：{" "}
                                                                  {formatMoney(
                                                                      smallTotal.toFixed(
                                                                          2
                                                                      ),
                                                                      currency
                                                                  )}
                                                              </div>
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        >
                                            <Column
                                                title="项目"
                                                dataIndex="project_code"
                                            />
                                            <Column
                                                title="产品"
                                                dataIndex="goods_name"
                                            />
                                            <Column
                                                title="型号"
                                                dataIndex="model"
                                            />
                                            <Column
                                                title="品牌"
                                                dataIndex="logo"
                                            />
                                            <Column
                                                title="颜色"
                                                dataIndex="color"
                                            />
                                            <Column
                                                title="SKU"
                                                dataIndex="sku_code"
                                            />
                                            <Column
                                                title="EAN"
                                                dataIndex="ean_code"
                                            />
                                            <Column
                                                title="数量"
                                                dataIndex="count"
                                                align="right"
                                            />
                                            <Column
                                                title="仓库"
                                                dataIndex="house_name"
                                            />
                                            {parseFloat(order.total_price) && (
                                                <Column
                                                    title="最终售价"
                                                    dataIndex="price"
                                                    align="right"
                                                    render={v => {
                                                        return formatMoney(
                                                            v,
                                                            currency
                                                        );
                                                    }}
                                                />
                                            )}
                                            {parseFloat(order.total_price) && (
                                                <Column
                                                    title="总金额"
                                                    key="total_price"
                                                    align="right"
                                                    render={(_, row, index) => {
                                                        return formatMoney(
                                                            row.price *
                                                                row.count,
                                                            currency
                                                        );
                                                    }}
                                                />
                                            )}
                                            {onePackage.has_actions && (
                                                <Column
                                                    title="操作"
                                                    dataIndex={"_actions_"}
                                                    key="act"
                                                    align="right"
                                                    render={(
                                                        actions,
                                                        row,
                                                        index
                                                    ) => {
                                                        return renderActions(
                                                            actions || [],
                                                            actionExecutor,
                                                            {
                                                                currency:
                                                                    order.currency,
                                                            }
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Table>
                                        <Collapse
                                            key={"order_address_" + i}
                                            style={{ margin: 10 }}
                                        >
                                            <Panel
                                                header={
                                                    onePackage.how_take
                                                        ? "物流信息"
                                                        : "物流信息"
                                                }
                                                key="1"
                                            >
                                                <ClientConaddTable
                                                    planDetail={onePackage}
                                                    contactInfo={
                                                        contactMap[
                                                            onePackage
                                                                .contact_id
                                                        ]
                                                    }
                                                    addressInfo={
                                                        addressMap[
                                                            onePackage
                                                                .address_id
                                                        ]
                                                    }
                                                />
                                            </Panel>
                                        </Collapse>
                                        <Divider key={"order_divider_" + i} />
                                    </div>
                                );
                            })}
                            {!parseFloat(order.total_price) ? (
                                <h3>无金额信息</h3>
                            ) : (
                                <h3>
                                    订单总额：
                                    <span style={{ color: "red" }}>
                                        {formatMoney(
                                            parseFloat(order.total_price) +
                                                parseFloat(order.other_fee),
                                            currency
                                        )}{" "}
                                        = 订单销售金额{" "}
                                        {formatMoney(
                                            order.total_price,
                                            currency
                                        )}{" "}
                                        + 其它费用{" "}
                                        {formatMoney(order.other_fee, currency)}
                                    </span>
                                </h3>
                            )}
                            {order.currency !== "cny" ? (
                                <span>
                                    预计人民币总额：
                                    <span style={{ color: "gray" }}>
                                        {formatMoney(
                                            parseFloat(order.total_price_cny) +
                                                parseFloat(order.other_fee_cny)
                                        )}{" "}
                                        = 订单销售金额{" "}
                                        {formatMoney(order.total_price_cny)} +
                                        其它费用{" "}
                                        {formatMoney(order.other_fee_cny)}
                                    </span>
                                </span>
                            ) : null}
                        </div>
                    </PageHeader>

                    <OrderStockOutDisplay orderId={query.id} />

                    {(moneys.ok && moneys.ok.length > 0) ||
                    (moneys.wait && moneys.wait.length > 0) ? (
                        <PageHeader
                            title={
                                <span>
                                    回款单
                                    <Link
                                        to={
                                            "/money/collection?order_id=" +
                                            order.id
                                        }
                                        target="_blank"
                                    >
                                        查看
                                    </Link>
                                    {moneys.action && "或"}
                                    {moneys.action &&
                                        renderActions(
                                            [moneys.action],
                                            actionExecutor
                                        )}
                                </span>
                            }
                        >
                            {moneys.wait && moneys.wait.length > 0 && (
                                <>
                                    <Divider orientation="left">
                                        未回款金额：
                                        {formatMoney(
                                            moneys.waitTotal,
                                            currency
                                        )}
                                    </Divider>
                                    <MoneyList
                                        moneys={moneys.wait}
                                        total={moneys.waitTotal}
                                    />
                                </>
                            )}
                            {moneys.ok && moneys.ok.length > 0 && (
                                <>
                                    <Divider orientation="left">
                                        已回款金额：
                                        {formatMoney(moneys.okTotal, currency)}
                                    </Divider>
                                    <MoneyList
                                        moneys={moneys.ok}
                                        total={moneys.okTotal}
                                    />
                                </>
                            )}
                        </PageHeader>
                    ) : undefined}
                </Card>
            </div>
        </div>
    );
};

function MoneyList({ moneys, total }) {
    return (
        <List
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
            }}
            dataSource={moneys}
            renderItem={item => (
                <List.Item>
                    <MoneyCard money={item} />
                </List.Item>
            )}
        />
    );
}

function MoneyCard({ money, confirm }) {
    const {
        id,
        currency: c,
        status,
        money_amount,
        confirmed_money_amount,
        bank_account: { account_display_name, confirm_type } = {},
        confirmor_id,
        confirmor: { name: confirmorName } = {},
        pay_time,
        pay_at,
        confirmed_at,
    } = money;
    const currency = c == "usd" ? "$" : "￥";

    let status2 =
        status == 10
            ? "OK"
            : status == 11
                ? "CANCEL"
            : confirmed_money_amount > 0
            ? confirmed_money_amount < money_amount
                ? "PARTLY"
                : "PARTLY"
            : pay_at * 1000 > new Date().getTime()
            ? "PAYING"
            : "SURING";

    return (
        <Card
            title={"#" + id}
            extra={
                <span style={{}}>
                    {status2 == "PARTLY"
                        ? "已部分回款"
                        : status2 == "OK"
                        ? "已回款"
                        : status2 == "SURING"
                        ? "待确认回款"
                        : status2 == "PAYING"
                        ? "待回款"
                        : status2 == "CANCEL"
                        ? "已撤回"
                        : status2}
                </span>
            }
        >
            <Descriptions column={1}>
                <Descriptions.Item
                    label={status == 10 ? "回款金额" : "计划回款金额"}
                >
                    {formatMoney(money_amount, currency)}
                </Descriptions.Item>
                <Descriptions.Item
                    label={status == 10 ? "实际回款日期" : "预计回款日期"}
                >
                    {moment(pay_at * 1000).format("YYYY-MM-DD")}
                </Descriptions.Item>
                <Descriptions.Item label={"应回款于"}>
                    {pay_time == 0 ? "发货前" : "发货后"}
                </Descriptions.Item>
                {status != 10 && confirmed_money_amount > 0 && (
                    <Descriptions.Item label={"已回款金额"}>
                        {formatMoney(confirmed_money_amount, currency)}
                    </Descriptions.Item>
                )}
                {confirmed_at > 0 && (
                    <Descriptions.Item label={"确款日期"}>
                        {moment(confirmed_at * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                        )}
                    </Descriptions.Item>
                )}
                {confirmor_id > 0 && (
                    <Descriptions.Item label={"操作人"}>
                        {confirmorName}
                    </Descriptions.Item>
                )}
                <Descriptions.Item label={"收款账户"}>
                    {account_display_name}
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
}

const moneier = new FormatMoney();
function formatMoney(value, prefix = "￥") {
    return value
        ? moneier.from(+ value, { symbol: prefix, decimals: 2 })
        : prefix + "0";
}

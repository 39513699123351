import React, {useCallback, useEffect, useState} from "react"
import {useHttpGet} from "../../core/hooks"
import {Tree} from "antd"

const OutlineTree = ({options, onSelect, ...otherProps}) => {

    const {withAll} = otherProps

    const renderTreeNodes = useCallback((data) => {
        return data && data.map(({label, value, children}) => {
            if (children && children.length) {
                return <Tree.TreeNode title={label} key={value}>
                    {renderTreeNodes(children)}
                </Tree.TreeNode>
            }
            return <Tree.TreeNode  key={value} title={label}/>
        })
    })

    return <Tree
        onSelect={onSelect}
        {...otherProps}>
        {withAll && <Tree.TreeNode title={'全部'} key={'all'}>
            {renderTreeNodes(options)}}
        </Tree.TreeNode>}
        {!withAll && renderTreeNodes(options)}
    </Tree>
}


const URISourcedComponent = ({uri, options, ...otherProps}) => {

    const [{data}, getOptions] = useHttpGet(uri)
    useEffect(() => {
        getOptions()
    }, [uri])

    if (!data) {
        return <span/>
    }

    return <OutlineTree options={data} {...otherProps}/>

}

export default (props) => {
    console.log('props',props);

    const {uri} = props

    if (uri && uri.length > 0) {
        return <URISourcedComponent {...props} />
    }

    return <OutlineTree  {...props}/>
}


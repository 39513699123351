import {useMqtt} from "../mqtt/useMqtt"
import {useCallback, useEffect} from "react"
import {uniqIdFromAccess} from "../mqtt/identityBuilder"

import {chartRequests} from "./requestStates"

export const useChartRequest = (mqtt, request) => {

    const mqttIdentity = uniqIdFromAccess(mqtt)
    const {publish, lastMessage, mqttState} = useMqtt(mqtt, {
        filter: (message) => {
            return message.requestId === request.requestId
        }
    })

    // const [response, setResponse] = useState({})
    const sendRequest = useCallback((request) => {
        const {requestId} = request
        const now = new Date().getTime()
        const lastRequestTime = chartRequests[requestId]
        if (now - lastRequestTime < 3000) {
            console.log(requestId + ':requesting')
            return
        }

        chartRequests[requestId] = now
        publish(request)

    }, [mqttIdentity, publish])

    useEffect(() => {

        sendRequest(request)

    }, [mqttIdentity, request.requestId])


    return {
        sendRequest,
        response: lastMessage,
        state: mqttState
    }

}

import React, { useEffect, useState, useCallback, useMemo, useRef } from "react"
import {  useHttpApi } from "../../core/hooks"
import {  Button, Table, Modal, Spin, Result } from "antd"
import { renderItemFieldValue } from "../../core/domUtil";
import DisplayPage from "../../pages/home/DisplayPage";
import DisplayComponent from "../displays/Display";
export default ({
  title = "请选择",
  visible = false,
  url, uri,
  selectedIds = [],
  entityName,
  templateId,
  moreParams,
  componentProps, 
  extraProps,
  keyName = "id",
  onSelect, onCancel,
  selector = 'radio'
}) => {
  const SELECT_TYPE = "radio" // or checkbox
  const [displayResponse, getDisplay, clearResponse] = useHttpApi('/account/getPageDisplay');
  const { loading: pageLoading, error: pageError, data: pageData = {}} = displayResponse
  const [selectedKeys, setSelectedKeys] = useState(selectedIds||[])
  const selectedItemsCache = useRef({}).current
  const callOnCancel = useCallback(() => {
    onCancel && onCancel()
    clearResponse()
  }, []);
  useEffect(() => {
    if (!url) {
      return;
    }
    getDisplay({
      path: url, url,
    })
  }, [url])
  useEffect(() => {
    if (visible) {
      setSelectedKeys(selectedIds||[])
    }
  }, [visible, selectedIds])

  const moreQueryParams = useRef({})
  moreQueryParams.current = moreParams
  let passProps = {
    selection: {
      selector: selector,
      selectedIds: selectedKeys,
      onSelect: (selectedIds, items) => {
        setSelectedKeys(selectedIds)
        items.forEach((t) => {
          if (t) {
            selectedItemsCache[t.id] = t
          }
        })
      }
    },
    showPadding: false,
    readonly: true,
    ...(componentProps||{}),
    ...(extraProps||{}),
  }

  // if (!pageLoading && !displayResponse.data && !pageData) {
  //   return null;
  // }

  if ((templateId || entityName || uri) && !url) {
    return <Modal width='65vw' title={title} key='modal_main'
      destroyOnClose={true}
      visible={visible} onCancel={() => {
      setSelectedKeys([])
      callOnCancel()
    }} onOk={() => {
      onSelect && onSelect(selectedKeys, selectedKeys.map((i) => selectedItemsCache[i]))
      setSelectedKeys([])
      clearResponse();
    }}>
      <DisplayComponent 
        component='TableDisplay' 
        props={{
          uri: uri||('/template/listItems?_template_id_='+(templateId||0) + '&_entity_name_=' + entityName), 
          fixedQuery: moreParams,
          fixedState: moreParams,
          ...passProps,
        }} />
    </Modal>
  }

  if (pageLoading) {
    return <Modal width='65vw' key='modal_main' title={title} visible={visible} okButtonProps={{ disabled: true }} onCancel={callOnCancel}>
      <div className="full-content-center">
        <Spin />
      </div>
    </Modal>
  }
  if (pageError) {
    return <Modal width='65vw' key='modal_main' title={title} 
      visible={visible} okButtonProps={{ disabled: true }} onCancel={callOnCancel}>
      <div className="full-content-center">
        <Result
          status="error"
          title={pageError.desc}
          extra={
            <Button type="primary" key="console" onClick={() => {
              getDisplay({
                path: url, url,
              })
            }}> 重试 </Button>
          }
        /></div>
    </Modal>
  }

  let componentName = pageData.component||'TableDisplay';

  return <Modal width='65vw' key='modal_main' title={title}
    destroyOnClose={true}
    visible={visible} onCancel={() => {
      setSelectedKeys([])
      callOnCancel()
  }} onOk={() => {
    onSelect && onSelect(selectedKeys, selectedKeys.map((i) => selectedItemsCache[i]))
    setSelectedKeys([])
    clearResponse();
  }}>
    <DisplayComponent 
        {...pageData}
        component={componentName}
        {...passProps} />
  </Modal>
}
import React, {useCallback, useEffect, useState} from "react"
import {Table, Button, DatePicker, Row, Col} from "antd"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import RightDetailListDisplay from "../../components/displays/RightDetailListDisplay"
import ApplyDetail from "../../components/displays/custom/ApplyDetail"
import { FormatMoney } from "format-money-js"
import moment from 'moment'
import { useActionExecutor } from "../../core/actionExecuter"
const fm = new FormatMoney();
export default () => {
    const [, actionResult, actionExecutor] = useActionExecutor()
    let query = useRouteQuery()
    return <div>
        <PageHeaderWrapper/>
        <DisplayPage
            component={'ApplyDetail'}
            props={{...query, actionResult, actionExecutor}}
            />
    </div>
}
import React, {useEffect} from "react"
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"
import {useActionExecutor} from "../../core/actionExecuter"

export default (props) => {

    const {uri, query, fixedQuery} = props
    const [response, requestHtmlContent] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        requestHtmlContent({...query, ...fixedQuery})
    }, [query, requestHtmlContent, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestHtmlContent}
        renderContent={({content}) => {
            return <div>

                <div  dangerouslySetInnerHTML={{__html: content}}>

                </div>


            </div>


        }}
    />

}

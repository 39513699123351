import React, {useState, useEffect, useCallback} from "react"
import {useHttpPost, useRefresh} from "../../core/hooks"
import {Divider, Button, PageHeader, Modal, Form, Input, Tooltip, InputNumber} from "antd"
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons"
import InputMoney from "../InputMoney"

export default function SalePriceEditor({visible, product, skuId, onClose, ...otherProps}) {
    const [form] = Form.useForm();
    const [saveRequest, savePrice] = useHttpPost('/sku/savePrices', {
        sku_id: skuId
    })

    useEffect(() => {
        if (saveRequest.success && !saveRequest.loading) {
            onClose && onClose(true)
        }
    }, [saveRequest.success, saveRequest.loading])
    return <Modal
        visible={visible}
        destroyOnClose={true}
        okText='保存'
        confirmLoading={saveRequest.loading}
        onCancel={() => {
            form.resetFields();
            onClose()
        }}
        onOk={() => {
            form.validateFields().then(v => {
                let req = {}
                req.sku_id = skuId;
                req.price_cny = v.price_cny || 0;
                req.price_usd = v.price_usd || 0;
                if (v.steps) {
                    req.step_count = v.steps.length;
                    v.steps.forEach((onePrice, index) => {
                        req['steps[' + index + '][price_cny]'] = onePrice.price_cny || 0
                        req['steps[' + index + '][price_usd]'] = onePrice.price_usd || 0
                        req['steps[' + index + '][count_min]'] = onePrice.count_min
                        req['steps[' + index + '][count_max]'] = onePrice.count_max
                    })
                }
                savePrice(req)
            }).catch(err => {
                console.log("onFinish err", err);
            })
        }}
        style={{width: "65wv"}}>
        <PageHeader
            ghost={false}
            title={product}
        >
            <InnerSalePriceEditor form={form} {...otherProps} />
        </PageHeader>
    </Modal>
}

function InnerSalePriceEditor({form, priceDetail}) {
    const refresh = useRefresh();

    const getPriceStep = useCallback((index) => {
        if (index === undefined) {
            return form.getFieldsValue();
        }
        if (index === 'steps') {
            return form.getFieldValue('steps');
        }
        if (index < 0) {
            return {
                price_cny: form.getFieldValue('price_cny'),
                price_usd: form.getFieldValue('price_usd'),
            };
        } else {
            return form.getFieldValue(['steps', index]);
        }
    }, [form])
    const setPrices = useCallback((prices) => {
        form.setFieldsValue({
            steps: prices
        });
        refresh();
    }, [form, refresh])
    const removePrice = useCallback((index) => {
        let prices = getPriceStep('steps');
        console.log("remove prices", index);
        prices.splice(index, 1);
        for (let index = 0; index < prices.length; index++) {
            const price = prices[index];
            if (index > 0) {
                if (prices[index - 1].count_max) {
                    price.count_min = prices[index - 1].count_max + 1;
                } else {
                    price.count_min = undefined;
                }
            }
        }
        setPrices([...prices])
    }, [getPriceStep, setPrices]);
    const addPrice = useCallback(() => {
        console.log("add prices");
        let prices = getPriceStep('steps');
        let newPrice = {}
        if (prices.length > 0 && prices[prices.length - 1].count_max) {
            newPrice = {
                count_min: prices[prices.length - 1].count_max + 1
            }
        }
        setPrices([...prices, newPrice])
    }, [getPriceStep, setPrices]);

    useEffect(() => {
        let priceDetailParsed = priceDetail ? (priceDetail.steps?priceDetail.steps:[]).reduce((parsed, curr, ind, arr) => {
            parsed.steps.push({
                ...curr,
                price_cny: parseFloat(curr.price_cny),
                price_usd: parseFloat(curr.price_usd)?parseFloat(curr.price_usd):'',
            })
            return parsed;
        }, {
            price_cny: parseFloat(priceDetail.price_cny),
            price_usd: parseFloat(priceDetail.price_usd)?parseFloat(priceDetail.price_usd):'',
            steps: []
        }):{}
        form.setFieldsValue(priceDetailParsed)
        refresh();
        return () => {
            form.resetFields();
        }
    }, [priceDetail])
    const onCountChange = useCallback((index, [count1, count2]) => {
        let prices = getPriceStep('steps') || [];
        prices[index] = {
            ...prices[index],
            count_min: parseInt(count1),
            count_max: count2?parseInt(count2):'',
        }
        if (index + 1 < prices.length) {
            if (!isNaN(count2)) {
                prices[index + 1] = {
                    ...prices[index + 1],
                    count_min:(parseInt(count2) + 1),
                }
            } else {
                prices[index + 1] = {
                    ...prices[index + 1],
                    count_min: "",
                }
            }
        }
        setPrices(prices)
        form.validateFields()
    }, [refresh, setPrices])

    let {
        price_cny: priceCny,
        price_usd: priceUsd
    } = getPriceStep(-1);
    let prices = getPriceStep('steps');

    return <Form name="prices-form" form={form}
                 onFinish={(v) => {
                     console.log("onFinish", v);
                 }} onFinishFailed={(v) => {
        console.log("onFinish Faild", v);
    }}>
        <PriceFormItem label="零售价"
                       price={{cny: priceCny, usd: priceUsd,}}
                       onPriceChange={(prices) => {
                           // onPriceChange(-1, prices)
                       }}/>
        {!prices || prices.length === 0 ? <div style={{textAlign: 'center'}}>
            {/* <Divider style={{ marginTop: 0 }} /> */}
            <Button style={{}} onClick={() => {
                addPrice()
            }}>+阶梯报价</Button>
        </div> : prices.map((price, index, array) => {
            return <StepPriceItem index={index} key={index} detail={price}
                                  maxCnyPrice={index === 0 ? priceCny : (prices[index - 1].price_cny)}
                                  fixMinCount={index === 0 ? undefined : (prices[index - 1].count_max + 1)}
                                  removeCallback={(index) => {
                                      removePrice(index)
                                  }}
                                  addCallback={index === prices.length - 1 ? addPrice : undefined}
                                  onCountChange={(counts) => {
                                      onCountChange(index, counts)
                                  }} onPriceChange={(prices) => {
                // onPriceChange(index, prices)
            }}
            />
        })}
    </Form>
}

function PriceFormItem({label, price: {cny, usd} = {}, namePrefix, onPriceChange, maxCnyPrice = 10000, children = []}) {
    const [usdHint, setUsdHint] = useState('美元价格')
    return <Form.Item label={label} style={{marginBottom: 0}} key={1}>
        <Form.Item key={10}
                   name={namePrefix ? [...namePrefix, 'price_cny'] : 'price_cny'}
                   style={{display: 'inline-block',}}
                   rules={[
                       {required: true, message: '价格必填'},
                       {type: 'number', max: maxCnyPrice, message: '价格' + cny+ '应该 ≤ ' + maxCnyPrice}
                   ]}
        >
            <InputMoney prefix="￥" autoComplete='off' onChange={(value) => {
                console.log('value', value);
                if (!isNaN(value) && !isNaN(parseFloat(value))) {
                    setUsdHint((parseFloat(value) / 7.09).toFixed(1) + "左右")
                    // onPriceChange([parseFloat(value), usd])
                } else {
                    setUsdHint("美元价格")
                    // onPriceChange([0, usd])
                }
            }}/>
        </Form.Item>
        <Form.Item key={12}
                   name={namePrefix ? [...namePrefix, 'price_usd'] : 'price_usd'}
                   style={{display: 'inline-block', margin: '0 0 0 16px'}}
        >
            <InputMoney prefix="$" autoComplete='off'
                        placeholder={usdHint}
                        onChange={(value) => {
                            if (isNaN(value) || isNaN(parseFloat(value)) || typeof value == 'undefined') {
                                // onPriceChange([cny, 0])
                            } else {
                                // onPriceChange([cny, parseFloat(value)])
                            }
                        }}/>
        </Form.Item>
        {children}
    </Form.Item>
}

function StepPriceItem({index, detail, removeCallback, addCallback, onCountChange, onPriceChange, maxCnyPrice, fixMinCount}) {
    const [maxHint, setMaxHint] = useState(detail.count_min ? (">" + (detail.count_min)) : "数量")
    useEffect(() => {
        setMaxHint(detail.count_min ? (">" + (detail.count_min)) : "数量")
    }, [detail.count_min])
    return [
        <Divider style={{marginTop: 0}} key={index}/>,
        <Form.Item label={"阶梯" + (index + 1)} style={{marginBottom: 0}} key={index}>
            <Input.Group compact>
                <Form.Item
                    key={index + 1}
                    name={['steps', index, 'count_min']}
                    style={{display: 'inline-block', width: '90px'}}
                    rules={[
                        {required: true, message: '数量范围必填'},
                    ]}>
                    <Input style={{textAlign: 'center'}} placeholder="最少数量" suffix="个"
                           autoComplete='off'
                           disabled={index > 0 ? true : false}
                           onChange={index > 0 ? undefined: (e) => {
                               let {value} = e.target;
                               value = isNaN(value) ? parseInt(detail.count_min) : parseInt(value)
                               onCountChange([value, detail.count_max]);
                           }}/>
                </Form.Item>
                <Input
                    className="site-input-split"
                    style={{
                        width: 30,
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: 'none',
                    }}
                    placeholder="~"
                    disabled
                />
                <Form.Item
                    key={index + 2}
                    name={['steps', index, 'count_max']}
                    style={{display: 'inline-block', width: '90px'}}
                    rules={[
                        {required: true, message: '数量范围必填'},
                        {type: 'number', min: detail.count_min + 1, message: '数量应该 > ' + (detail.count_min)}
                    ]}>
                    <Input
                        className="site-input-right"
                        style={{
                            textAlign: 'center',
                        }}
                        autoComplete='off'
                        onChange={(e) => {
                            let {value} = e.target;
                            console.log("oncountchatge", value);
                            value = isNaN(value) ? parseInt(detail.count_max) : parseInt(value)
                            onCountChange([detail.count_min, value]);
                        }}
                        placeholder={maxHint} suffix="个"
                    />
                </Form.Item>
            </Input.Group>
        </Form.Item>,
        <PriceFormItem label={"价格" + (index + 1)} namePrefix={['steps', index]}
                       price={{cny: detail.price_cny, usd: detail.price_usd}}
                       maxCnyPrice={maxCnyPrice} onPriceChange={onPriceChange}>
            <Tooltip placement="top" title="删除这个阶梯价格" style={{display: 'inline-block'}}>
                <Button danger icon={<DeleteOutlined/>} style={{marginLeft: 10, marginRight: 10}} onClick={() => {
                    removeCallback && removeCallback(index);
                }}/>
            </Tooltip>
            {addCallback && <Tooltip placement="top" title="增加阶梯价格" style={{display: 'inline-block'}}>
                <Button type='primary' icon={<PlusOutlined/>} onClick={() => {
                    addCallback && addCallback(index);
                }}/>
            </Tooltip>}
        </PriceFormItem>,
    ]
}

function getNumberOrString(v) {
    if (typeof v == 'string') {
        if (v.endsWith('.') || v.endsWith('.0')) {
        } else {
            v = parseFloat(v) || ""
        }
    } else {
        v = parseFloat(v) || ""
    }
    return v;
}

import React, {useCallback, useContext, useEffect} from "react"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import {Col, Row, Descriptions, Tabs, Button, Tag, Modal, message} from "antd"
import {useActionExecutor} from "../../core/actionExecuter"
import {renderActions, renderItemFieldValue} from "../../core/domUtil"
import {useRouteMatch} from "react-router-dom"
import ApprovalFlowEditor from "../../components/editors/ApprovalFlowEditor"

// import TemplateDisplayTable from "../../components/df/TemplateDisplayTable"
import AdminContext from "../../core/AdminContext"
import Text from "antd/lib/typography/Text"

// const ComponentsMap = {
//     TemplateDisplayTable
// }

export default (props) => {
    const {desBorderedColumn} = props;
    const {pageStyle = 'tab'} = props;
    const {pageStates} = useContext(AdminContext)
    let query = useRouteQuery()
    const {path, url} = useRouteMatch()
    let actionState = {}
    if (pageStates[path]) {
        const {fixedQuery, fixedState} = pageStates[path]
        query = {
            ...query,
            ...fixedQuery
        }
        actionState = fixedState
    }

    const [response, getDetail] = useHttpGet('/flow/getDetail')
    const [, actionResult, actionExecutor] = useActionExecutor()

    const handleNodeAdd = useCallback((fromNode, type, addAction) => {
        const action = JSON.parse(JSON.stringify(addAction))
        action.fixedState = {flow_id: query.id, node_type: type, _parent_id_: fromNode._parent_id_, from_node_id:fromNode.id}

        console.log(action)
        // approval -> just save it
        // condition
        // branch

        const actionHandler = actionExecutor.generateActionHandler(action)
        actionHandler()
    }, [query.id])

    const handleNodeAction = useCallback((node, action) => {
        const actionHandler = actionExecutor.generateActionHandler(action)
        actionHandler()
    }, [query.id])


    useEffect(() => {
        getDetail(query)
    }, [actionResult, url])

    const headerProps = {}
    let displayItem = null, displayedFields = null
    if (response.data) {
        const {detail, title} = response.data
        let {displayedItem, displayFields} = detail
        displayedFields = displayFields
        displayItem = displayedItem
        if (displayedItem._actions_ && displayedItem._actions_.length) {
            headerProps.extra = <div>{renderActions(displayedItem._actions_, actionExecutor, actionState, 10)}</div>
        }
        headerProps.title = title
    }

    return <div>
        <PageHeaderWrapper
            {...headerProps}>
            {displayedFields && displayedFields.length > 0 &&
            <Descriptions size={'small'} bordered={desBorderedColumn > 0} column={desBorderedColumn || 3}>
                {displayedFields.map((field) => {
                    const {key, title} = field
                    return <Descriptions.Item label={title} key={key}>
                        {renderItemFieldValue(field, displayItem)}
                    </Descriptions.Item>
                })}
            </Descriptions>}
        </PageHeaderWrapper>

        <div className="page-content-wrapper">
            <LoadingResponseWrapper
                {...response}
                refresh={getDetail}
                renderContent={({nodes, addAction}) => {

                    return <ApprovalFlowEditor
                        nodes={nodes}
                        onNodeAdd={(node, type) => {
                            handleNodeAdd(node, type, addAction)
                        }}
                        onNodeRemove={(node) => {
                            handleNodeAction(node, node.removeAction)
                        }}
                        onNodeEdit={(node) => {
                            handleNodeAction(node, node.editAction)
                        }}
                    />


                }}
            />

            <div>
                <Text type="warning" style={{fontSize:16}}>Tag说明：</Text>
                <div>
                    <ul>
                        <li>leader: 发起人的直属领导</li>
                        <li>department_leader: <b>费用担承部门</b>的最上级领导</li>
                        <li>biz_leader: 业务分管负责人。定义在<b>账务-费用科目</b>模块</li>
                        <li>一般情况下，如果申请的费用承担部门不是发起人的所在部门，则该审批的第一审批人是目标部门的最上级领导。TAG 为 leader 的审批节点将被忽略</li>
                        <li>TAG 的填写一般与业务代码(通常指本流程的Handler)相耦合，不应随意填写</li>
                        <li>审批通常顺序是 
                            <Tag>主管[leader]</Tag>->
                            <Tag>部门[department_leader]</Tag>->
                            <Tag>财务</Tag>->
                            <Tag>业务负责人[biz_leader]</Tag>, 请参考创建 {false && <Button onClick={() => {
                            Modal.confirm({
                                title: '确定生成默认流程吗？', 
                                content: '此操作将移除目前已有的节点，不可恢复',
                                onOk: () => {
                                    message.info('这功能还没做呢');
                                },
                                onCancel: () => {
                                    
                                }
                            })
                        }}>一键生成</Button>}</li>
                        
                    </ul>
                </div>
            </div>
        </div>

    </div>
}

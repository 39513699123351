import React, { useState, useEffect } from "react"
import { DatePicker, Table, Card, Col, Row, Descriptions, Tabs, Spin, Empty } from "antd"
import moment from 'moment'


import {
  Chart,
  Geom,
  Axis,
  Tooltip,
  Legend,
} from "bizcharts";

const { RangePicker } = DatePicker;
const DEFAULT_TIME_RANGE = [moment().subtract(7, 'days').startOf('day'), moment().endOf('day')];
// const DEFAULT_TIME_RANGE = [moment("2019-03-01"), moment("2019-04-01")];
export default ({ title, data, keys, name, formatter: { valueFormat, timeFormat = "MM-DD HH:mm" } = {}, onDataRangeChange }) => {
  let [range, setRange] = useState();
  let nameFormatter = typeof name == 'function' ? name : 
                      typeof name == 'string'? (keyName) => name :
                      typeof name == 'object'? (keyName) => { return typeof name[keyName] == 'string' ? name[keyName] : name[keyName].name } :
                      (v) => `${v}`;
  let valueFormatter = typeof valueFormat == 'function' ? valueFormat : 
                      typeof valueFormat == 'string'? (v, keyName) => { return `${v}${valueFormat || ''}` } :
                      typeof valueFormat == 'object'? (v, keyName) => { return `${v}${valueFormat[keyName] || ''}` } :
                      (v) => `${v}`;

  useEffect(() => {
    onDataRangeChange(DEFAULT_TIME_RANGE[0].startOf('day').unix(), DEFAULT_TIME_RANGE[1].endOf('day').unix());
  }, [])

  const columns = [
    {
      title: '时间',
      dataIndex: 'time',
      width: 100,
      render: (value, row, index) => {
        return <span>{moment(value).format(timeFormat)}</span>
      }
    }
  ];
  let keysOfChat = [];
  let valueScales = {};
  let valueAxisGeoms = [];
  if (data && data.length > 0) {
    if (!keys) {
      keys = Object.keys(data[0]);
    }
    // data.splice(10, 0, {time : 1590123600 * 1000})
    keys.forEach(key => {
      if (key != 'time') {
        let valueOfThisKey = (v) => {
          return v ? valueFormatter(v, key) : ''
        }
        keysOfChat.push(key);
        columns.push({
          title: nameFormatter(key),
          dataIndex: key,
          width: 100,
          render: (v) => valueOfThisKey(v)
        })
        valueScales[key] = {
          formatter: valueOfThisKey,
          range: [0.05, 0.95],
          alias: nameFormatter(key),
        }
        valueAxisGeoms.push(<Axis
          name={key}
          grid={null}
        />)
        valueAxisGeoms.push(<Geom
          type="line"
          position={"time*" + key}
          color={['#2ca02c','#24a9ae', "#ff7f0e",  '#913f92'][(keysOfChat.length - 1) % 4]}
          shape="smooth"
          size={2}
        />)
      }
    })
  }
  if (!title) {
    if (keysOfChat.length > 0) {
      title = keysOfChat.map(nameFormatter).join('&') + "历史数据"
    } else{
      title = "历史数据"
    }
  }

  return <div>
    <Row>
      <Col span={8} offset={0}>
        <h3>{title}</h3>
      </Col>
      <Col span={8} offset={8}>
        <RangePicker
          placeholder={['开始日期', '结束日期']}
          disabledDate={current => current.isAfter(moment().endOf('day'))}
          onChange={(selected, dateString) => {
            setRange(selected);
            onDataRangeChange(selected[0].startOf('day').unix(), selected[1].endOf('day').unix());
          }}
          defaultValue={DEFAULT_TIME_RANGE}
          value={range}
          allowClear={false}
          format="YYYY-MM-DD"
          ranges={{
            ["7天"]: DEFAULT_TIME_RANGE,
            ["一个月"]: [moment().startOf('day').subtract(1, 'month'), moment().endOf('day')],
          }}
        />
      </Col>
    </Row>
    {typeof data == 'undefined' ? <div className="full-content-center"><Spin /></div> : (data == null || data.length == 0) ? <div className="full-content-center"><Empty /></div> : <Row>
      <Col span={8}>
        <Table
          bordered={true}
          size='middle'
          columns={columns}
          dataSource={data}
          pagination={false}
          onRow={(record, index) => {
            return {
              onMouseEnter: (event) => {
                console.log("mouse", record, index);
              }
            }
          }}
          scroll={{ y: 340 }} />
      </Col>

      <Col span={16}>
        <Chart
          scale={{
            time: {
              type: "time",
              mask: timeFormat,
            },
            ...valueScales,
          }}
          height={420}
          data={data}
          forceFit
          placeholder
          padding={[50, 50, 100, 100]} >
          <Legend />
          <Axis name="time" />
          <Tooltip />
          <Legend />
          
          {valueAxisGeoms}
        </Chart>
      </Col>
    </Row>}
  </div>
}
import { Button, Divider, Form, InputNumber, Table, Tabs } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { FormatMoney } from "format-money-js"
import { useHttpGet } from "../../../core/hooks"
import {renderActions, renderItemFieldValue, renderBatchItemActions} from "../../../core/domUtil"
const { TabPane } = Tabs;
export default (props) => {
    const { valueSet, rows, onChange, valueSetUpdater } = props
    const { manufacture_order_id, item_type = 0, io_type = 0, in_type = 0, out_type = 0, warehouse_sub_id } = valueSet
    const [selectedIds, setSelectedManufuckId] = useState([])
    const [totalTargetCount, setTotalTargetCount] = useState({})
    const [useCounts, setUseCounts] = useState({})
    const [forbidenSub, setForbidenSub] = useState({})
    const [materialCounts, setMaterialCounts] = useState({})
    const [manufactureResponse, getManufactureInfo, clearResponse] = useHttpGet('/production/getManufactureInfo');

    const isTakingMaterial = parseInt(out_type) === 8;
    const isReturnMaterial = parseInt(in_type) === 38;
    const isBuildProducts = parseInt(in_type) === 32;
    const showTargetTable = isTakingMaterial
    const showMaterialTable = isReturnMaterial || isTakingMaterial

    const inputTitle = isTakingMaterial ? '领料数量' : isReturnMaterial ? '退料数量' : '数量'
    const targetInputTitle = isTakingMaterial ? '齐套领料' : isBuildProducts ? '入库数量' : '数量'
    useEffect(() => {
        if (manufacture_order_id && warehouse_sub_id) {
            getManufactureInfo({
                warehouse_sub_id,
                manufacture_order_id,
                io_type, in_type, out_type, item_type
            })
        } else {
            clearResponse()
        }
    }, [manufacture_order_id, warehouse_sub_id, io_type, in_type, out_type])

    let { loading, error, data: {
        manufactureTargets = [],
        itemsMap = {},
        displayFields = [],
        takingMap = {}
    } = {} } = manufactureResponse
    if (!displayFields) displayFields = []

    useEffect(() => {
        setSelectedManufuckId([])
    }, [manufactureResponse])

    useEffect(() => {
        let ids = [];
        for(let id in totalTargetCount) {
            if (totalTargetCount[id] > 0) {
                if (!selectedIds.includes(parseInt(id))) {
                    ids.push(parseInt(id));
                }
            }
        }
        if (ids.length > 0) {
            console.log('manu', 'set selectedIds', ids, selectedIds)
            setSelectedManufuckId([
                ...selectedIds,
                ...ids,
            ])
        }

    }, [totalTargetCount, selectedIds])

    const reducedTotalCountArray = useMemo(() => {
        let resultMaterailCount = {}
        let hasUseCountBomMat = {};

        Object.entries(useCounts).forEach(([bomId, mtCountMap]) => {
            Object.entries(mtCountMap).forEach(([mtId, count]) => {
                if (!resultMaterailCount[mtId]) resultMaterailCount[mtId] = 0;
                resultMaterailCount[mtId] += parseInt(count) || 0;
                //count 是 0 的也一样。
                hasUseCountBomMat[bomId + '_' + mtId] = true;
            })
        })

        Object.entries(totalTargetCount).forEach(([targetId, targetCount]) => {
            if (!parseInt(targetCount)) return; //count 是 0 的不计算
            let takingMapOfThis = takingMap[targetId];

            Object.entries(takingMapOfThis).forEach(([bomId, mtCountMap]) => {
                Object.entries(mtCountMap).forEach(([mtId, count]) => {
                    if (!resultMaterailCount[mtId]) resultMaterailCount[mtId] = 0;
                    if (hasUseCountBomMat[bomId + '_' + mtId]) {
                        return; // use count了，不计算
                    }
                    resultMaterailCount[mtId] += (count || 0) * (parseInt(targetCount) || 0);
                })
            })
        })


        return Object.entries(resultMaterailCount).map(([key, value]) => {
            return {
                material_id : key,
                count: parseInt(value)
            }
        }).filter(t => t.count)
    }, [useCounts, totalTargetCount])
    console.log('counter ', reducedTotalCountArray)

    const takingMaterailCountsResult = JSON.stringify(reducedTotalCountArray)
    useEffect(() => {
        onChange && onChange(takingMaterailCountsResult)
    }, [takingMaterailCountsResult])



    return <div>
        {showTargetTable && <Table
            pagination={false}
            loading={loading}
            dataSource={manufactureTargets}
            rowKey={({ id }) => id}
            rowSelection={isTakingMaterial ? {
                selectedRowKeys: selectedIds,
                selections: true,
                type: 'checkbox',
                onSelect: ({id}, s) => {
                    if (!s) {
                        setTotalTargetCount({
                            ...totalTargetCount,
                            [id]: 0
                        })
                    }
                },
                onChange: (selectedKeys, records) => {
                    setSelectedManufuckId(selectedKeys)
                }
            }:undefined}
            size={'small'}>
            {[
                {title:'生产内容', key: 'manufacture_target_name'}, 
                {title:'品名', key: 'category_name'},
                {title:'描述', key: 'description'}, 
                {title:'BOM', key: 'bom_href', style: 'link'}, 
                {title:'计划生产', key: 'plan_quantity', style: 'count'},
                {title:'已入库', key: 'made_quantity', style: 'count'},
            ].map((displayField) => {
                return <Table.Column title={displayField.title} dataIndex={displayField.key} 
                    render={(cellVal, record) => {
                        return <ColumnWrapper columnProps={displayField} record={record}/>
                    }}>
                </Table.Column>
            })}
            <Table.Column title={targetInputTitle} render={(value, {id}) => {
                return <InputNumber min={0} value={totalTargetCount[id]} onChange={(number) => {
                    setTotalTargetCount({
                        ...totalTargetCount,
                        [id]: parseInt(number)||0
                    })
                }} />
            }}></Table.Column>
        </Table> || ''}
        <Divider />
        <Tabs type="card">
            {manufactureTargets.map(target => {
                return <TabPane tab={target['manufacture_target_name']} key={'t_' + target['id']}>
                    <Table
                    defaultExpandAllRows={true}
                        pagination={false}
                        loading={loading}
                        dataSource={itemsMap[target['id']]}
                        rowKey={({ material_id }) => material_id}
                        size={'small'}>
                        {(displayFields||[]).map((displayField) => {
                            return <Table.Column title={displayField.title} dataIndex={displayField.key}
                                render={(cellVal, record) => {
                                    return <ColumnWrapper columnProps={displayField} record={record}/>
                                }}/>
                        })}
                        <Table.Column title={inputTitle} 
                        render={(v, record, i) => {
                            let targetId = target['id'];
                            let materialId = record?.material_id;
                            let bomId = record?.bom_id;
                            let useSelfCount = (useCounts.hasOwnProperty(bomId) && useCounts[bomId].hasOwnProperty(materialId))
                            let value =  0;

                            if (useSelfCount) {
                                value = useCounts[bomId][materialId] || 0
                            } else {
                                value = ((takingMap[targetId][bomId][materialId] || 0) * ( totalTargetCount [targetId] || 0)) || 0
                            }
                            if (!record.required_quantity) {
                                let subTo0 = forbidenSub[materialId]
                                return <div>
                                    <span>{subTo0 ? '0（不领用子物料）' : '领子物料'}</span>
                                </div>
                            }
                            return <div>
                                <InputNumber disabled={!useSelfCount} min={0} value={value} onChange={(number) => {
                                    if (!useCounts[bomId]) {
                                        useCounts[bomId] = {}
                                    }
                                    useCounts[bomId][materialId] = parseInt(number)||'';
                                    setUseCounts({...useCounts})
                                }}/>
                                {!useSelfCount && <Button type='link' onClick={() => {
                                    if (!useCounts[bomId]) {
                                        useCounts[bomId] = {}
                                    }
                                    useCounts[bomId][materialId] = parseInt(value);
                                    setUseCounts({...useCounts})
                                }}>修改数量</Button>}
                                {useSelfCount && <Button type='link' onClick={() => {
                                    delete(useCounts[bomId][materialId]);
                                    if (Object.keys(useCounts[bomId]).length == 0) {
                                        delete(useCounts[bomId]);
                                    }
                                    setUseCounts({...useCounts})
                                }}>使用齐套数量</Button>}
                            </div>
                        }}></Table.Column>
                    </Table>

                </TabPane>
            })}
            
        </Tabs>
        <Divider />
    </div>
}
const ColumnWrapper = ({columnProps, record, readonly, actionExecutor}) => {
    return renderItemFieldValue(columnProps, record, readonly, actionExecutor)
}
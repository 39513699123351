import React, { useCallback, useEffect } from "react";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper";
import { useHttpGet } from "../../core/hooks";
import DisplayPage from "../../pages/home/DisplayPage";

const buildDisplayProps = (tabs, tabContentProps, optherProps) => {
    return {
        component: "MonthlyTopTabbedDisplay",
        props: {
            ...optherProps,
            tabs,
            tabContentProps,
        },
    };
};

export default props => {
    const { uri, tabKey, fixedQuery = {} } = props;
    const [tabsResponse, getTabs] = useHttpGet(uri);
    const refreshPage = useCallback(() => {
        getTabs();
    }, [getTabs]);

    useEffect(() => {
        getTabs();
    }, []);

    return (
        <div>
            <PageHeaderWrapper />
            <LoadingResponseWrapper
                {...tabsResponse}
                refresh={refreshPage}
                renderContent={({ tabs, tabContentProps, ...optherProps }) => {
                    const displayProps = buildDisplayProps(
                        tabs,
                        tabContentProps,
                        optherProps
                    );
                    return <DisplayPage {...displayProps} />;
                }}
            />
        </div>
    );
};

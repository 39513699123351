import React, {useEffect} from "react"
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"

import {useActionExecutor} from "../../core/actionExecuter"
import DisplayComponent from "./Display"


export default (props) => {

    const {uri, query, fixedQuery} = props
    const [response, loadDisplay] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        loadDisplay({...query, ...fixedQuery})
    }, [query, loadDisplay, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={loadDisplay}
        renderContent={(response) => {
            return <DisplayComponent actionExecutor={actionExecutor} {...response}/>
        }}
    />

}

import React, {useCallback, useEffect, useState} from "react"
import {Button, Col, Descriptions, Divider, Input, message, Row, Space, Table, Typography, List} from "antd"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import {CheckCircleTwoTone, InfoCircleTwoTone} from "@ant-design/icons"

const {Title, Paragraph} = Typography


export default (props) => {
    const {items, displayFields, remark} = props
    return <div>
        <div>
            <Table
                size={'small'}
                pagination={false}
                dataSource={items}
                rowKey={({id}) => id}>
                <Table.Column dataIndex={'status'} title={''}
                              align={'center'}
                              key={'index'}
                              render={(_a, _b, index) => {
                                  return <b>{index + 1}</b>
                              }}/>

                {displayFields.map((displayField, columnIdx) => {
                    const {key, title} = displayField
                    return <Table.Column dataIndex={key} title={title} key={columnIdx}
                                         align={'center'}
                                         render={(cellVal, record) => {
                                             return renderItemFieldValue(displayField, record)
                                         }}/>

                })}

                <Table.Column dataIndex={'status'} title={''}
                              align={'center'}
                              key={'status'}
                              render={(cellVal, record) => {
                                  return record.waiting_quantity <= 0 ?
                                      <CheckCircleTwoTone
                                          twoToneColor="#52c41a"/> :
                                      <InfoCircleTwoTone twoToneColor="#f2a537"/>
                              }}/>

            </Table>
        </div>
        {remark && <div style={{marginTop:'10px'}}>
            <Typography>
            <Paragraph>
                <pre>{remark}</pre>
            </Paragraph>
        </Typography>
        </div>}


    </div>

}

import React, { useEffect, useState } from "react";
import { Button, Card, Table, Modal, message, Form } from "antd";
import { useHttpGet, useHttpPostJson } from "../../.../../../../core/hooks";

import LoadingResponseWrapper from "../../../../components/LoadingResponseWrapper";
import { formatTimestamp } from "../utils/formatDate";

const OrderList = ({ client_token }) => {
  const [listResponse, getList] = useHttpGet("/quickOrder/getMyOrders", {
    client_token,
  });

  const [cancelOrderResp, cancelOrder, resetPage] = useHttpPostJson(
    "/quickOrder/recallOrder",
    {
      version: "1.1",
    },
    {
      Authorization: "Bearer" + " " + client_token,
    }
  );
  useEffect(() => {
    if (cancelOrderResp.loading === false) {
      getList();
    }
  }, [cancelOrderResp]);

  useEffect(() => {
    getList();
  }, [client_token]);

  useEffect(() => {
    console.log("list: ", listResponse);
  }, [listResponse]);

  function f(number) {
    if (!number) return "-";
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "¥" + parts.join(".");
  }

  const renderAdditionalInfo = (record) => (
    <div style={{ padding: "20px" }}>
      <Form.Item label="发票信息">
        {record.quick_order?.invoice_info?.length !== 0 && record?.quick_order?.invoice_info !== null ? (
          <>
            {record?.quick_order?.invoice_info?.unit_name && (
              <div>单位名称：{record?.quick_order?.invoice_info?.unit_name}</div>
            )}
            {record?.quick_order?.invoice_info?.taxpayer_id && (
              <div>
                纳税人识别号：{record?.quick_order?.invoice_info?.taxpayer_id}
              </div>
            )}
            {record?.quick_order?.invoice_info?.bank_name && (
              <div>开户银行：{record?.quick_order?.invoice_info?.bank_name}</div>
            )}
            {record?.quick_order?.invoice_info?.bank_account && (
              <div>
                银行账号：{record?.quick_order?.invoice_info?.bank_account}
              </div>
            )}
            {record?.quick_order?.invoice_info?.reg_address && (
              <div>公司地址：{record?.quick_order?.invoice_info?.reg_address}</div>
            )}
            {record?.quick_order?.invoice_info?.reg_phone && (
              <div>银行账号：{record?.quick_order?.invoice_info?.reg_phone}</div>
            )}
          </>
        ) : (
          "-"
        )}
      </Form.Item>
      <Form.Item label="合同信息">
        {record.quick_order?.contract_info
          ? record.quick_order.contract_info.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : "不需要"}
      </Form.Item>

      <Form.Item label="邮箱">
        {record.quick_order?.email || "-"}
      </Form.Item>
    </div>
  );

  const statusMap = {
    0: { color: "#f94144", text: "待审批" }, // red 草稿
    1: { color: "#f94144", text: "待审批" }, // red 待审批
    2: { color: "gray", text: "被驳回" }, // gray 被驳回
    3: { color: "#faa307", text: "待发货" }, // yellow 已批准
    4: { color: "#faa307", text: "待确认收款" }, // yellow 待确认收款
    5: { color: "#faa307", text: "待发货" }, // yellow 待出库
    6: { color: "#1c7c54", text: "已发货" }, // green 已出库
    7: { color: "#faa307", text: "待确认收款" }, // yellow 尾款确认中
    8: { color: "gray", text: "已退货" }, // gray 退货标记
    10: { color: "#1c7c54", text: "已完成" }, // green 已完成
    11: { color: "gray", text: "已取消" }, // gray 已撤回
  };

  console.log("listResponse", listResponse);

  return (
    <div style={{ width: "100%", overflowX: "scroll" }}>
      <div style={{ minWidth: "60rem" }}>
        <LoadingResponseWrapper
          {...listResponse}
          renderContent={({ orders }) => (
            <Table
              dataSource={orders}
              expandable={{
                expandRowByClick: false,
                expandedRowRender: (record) => (
                  console.log("record", record),
                  (
                    <div>
                      <Card>
                        <Table
                          dataSource={record.items}
                          pagination={false}
                          bordered
                          size="small"
                          rowKey="id"
                        >
                          <Table.Column dataIndex="sku_name" title="商品" />
                          <Table.Column dataIndex="count" title="数量" />
                          <Table.Column
                            dataIndex="price"
                            title="价格"
                            render={(price) => (
                              <div>{f(Number(price)?.toFixed(1))}</div>
                            )}
                          />
                          <Table.Column
                            dataIndex="total"
                            title="小计"
                            render={(price) => (
                              <div>{f(Number(price)?.toFixed(1))}</div>
                            )}
                          />
                        </Table>
                      </Card>
                      {renderAdditionalInfo(record)}
                    </div>
                  )
                ),
              }}
              rowKey={(record) => record.created_at}
            >
              <Table.Column dataIndex="code_name" title="订单号" />
              <Table.Column
                // dataIndex="status_text"
                title="状态"
                render={(orders) => (
                  <div style={{ color: statusMap[orders.status].color }}>
                    {statusMap[orders.status].text || "-"}
                  </div>
                )}
              />

              <Table.Column
                dataIndex="total_price"
                title="总价(不含运费)"
                render={(price) => <div>{f(Number(price)?.toFixed(1))}</div>}
              />
              <Table.Column
                dataIndex="out_tasks"
                title="青萍出库单"
                render={(outTasks) => (
                  <div>
                    {outTasks?.length
                      ? outTasks.map((task) => [task, <br key={task} />])
                      : "-"}
                  </div>
                )}
              />
              <Table.Column
                dataIndex="out_kuaidi"
                title="快递单号"
                render={(outKuaidi) =>
                  outKuaidi?.length ? (
                    <div>
                      {outKuaidi.map(({ logistic_number }) => [
                        logistic_number,
                        <br key={logistic_number} />,
                      ])}
                    </div>
                  ) : (
                    "-"
                  )
                }
              />
              <Table.Column
                dataIndex="created_at"
                title="下单时间"
                render={(date) => (
                  console.log("date1", date),
                  (<div>{formatTimestamp(date, "YYYY/MM/DD hh:mm")}</div>)
                )}
              />

              <Table.Column
                title="收货地址"
                width={'22%'}
                render={(orders) => (
                  <>
                    {orders?.address && orders.address.length > 0
                      ? orders.address.map((e, index) => (
                          <div key={index} 
                          // style={{ maxWidth: '260px'}}
                          >
                            {e.area && `${e.area} `}
                            {e.address}
                            <br />
                            {e.recipient} &nbsp; {e.phone ? `+86 ${e.phone}` : ""}
                          </div>
                        ))
                      : "-"}
                  </>
                )}
              />

              <Table.Column
                key="operation"
                title="操作"
                render={(orders) =>
                  (orders.status === 0 || orders.status === 1) && (
                    <Button
                      type="link"
                      onClick={() =>
                        Modal.confirm({
                          title: "确认取消此订单吗？",
                          cancelText: "不取消",
                          okText: "确认取消",
                          okButtonProps: {
                            danger: true,
                          },
                          onOk: () => {
                            cancelOrder({ order_id: orders.order_id });
                            // message.success("取消成功")
                          },
                        })
                      }
                    >
                      取消订单
                    </Button>
                  )
                }
              />

            </Table>
          )}
        />
      </div>
    </div>
  );
};

export default OrderList;

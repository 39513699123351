import React, {useEffect, useState, useCallback} from "react"
import {useHttpGet, useHttpPostJson, useRefresh, useRouteQuery} from "../../core/hooks"
import {
    Col,
    Row,
    Typography,
    Card,
    Button,
    PageHeader,
    Table,
    Form,
    Input,
    Select,
    Tooltip,
    Spin,
    Radio,
    Descriptions
} from "antd"
import {useHistory, useLocation} from "react-router-dom"
import PageHeaderWrapper from "../../components/PageHeaderWrapper";

const {Title} = Typography;
const {Column} = Table;
const {Option} = Select;

const OrderPackageNamePath = "order_package"
export default () => {
    const [getOutNumber, setOutNumber] = useState({})

    const headerProps = {}
    const columns = [
        {
            title: '序号',
            dataIndex: 'key',
        },
        {
            title: '商品',
            dataIndex: 'goods',
        },
        {
            title: '数量',
            dataIndex: 'plan_count',
        },
        {
            title: '已出库数量',
            dataIndex: 'out_cout',
        },
        {
            title: '待出库数量',
            dataIndex: 'left_count',
        },
        {
            title: '出库数量',
            dataIndex: 'in_out_count',
            render:(value,record) =>{
                return <Input style={{width:80}} name={record.goods_id} defaultValue={value} onChange={e => setStockOutNumber(e.target.value,record)}/>

            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
        },
    ];
    const data = [
        {
            key: '1',
            goods_id:'1203',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
        {
            key: '2',
            goods_id:'1204',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
        {
            key: '4',
            goods_id:'1205',
            goods: '青萍温湿度气压计 白色 Wi-Fi版',
            plan_count: 32,
            out_cout: 5,
            left_count: 27,
            in_out_count: '',
            unit: '台',
        },
    ];
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 16},
    };
    
    function onFinish(values) {
        console.log('data',data);
        console.log('Received values of form: ', values);


    }
    function setStockOutNumber(value,record){
        let obj = {};
        const key = record['goods_id'];
        obj[key] = value;
        setOutNumber(obj)
        console.log('bbbb',getOutNumber)


    }


    return <div>
        <PageHeaderWrapper
            {...headerProps}>
        </PageHeaderWrapper>
        <div className="page-content-wrapper">
            <Card>
                <Row gutter={10}>
                    <Col span={5} style={{borderRight:'1px solid #f5f5f5'}}>
                        <div className="stockInfoList">
                            <p><span>出库单号：</span>IN-20200708</p>
                            <p><span>所属仓库：</span>飞歌成品仓库</p>
                            <p><span>出库类型：</span>销售出库</p>
                            <p><span>销售订单：</span>DH-20200708-562076</p>
                            <p><span>客户名称：</span>代傲</p>
                            <p><span>申请人：</span>孙兰</p>
                            <p><span>申请时间：</span>2020/12/20 12：00</p>
                        </div>
                    </Col>
                    <Col span={19} style={{paddingLeft:25}}>
                        <Row>
                            <Col span={12}>
                                <Typography>
                                    <Title level={4} style={{fontSize:16}}>物流信息</Title>
                                    <div>
                                        <p>物流方式：物流寄送</p>
                                        <p>指定物流公司：跨越物流</p>
                                        <p>运费付款方式：寄付</p>
                                        <p>预计交货日期：2020/12/20 12:00</p>
                                        <p><strong>收货人信息：孙兰 +86 18722098808</strong></p>
                                        <p><strong>详细地址：京市海淀区文龙家园三里京市海淀区文龙家园三里</strong></p>
                                    </div>
                                </Typography>
                            </Col>
                            <Col span={12}>
                                <Typography>
                                    <Title level={4} style={{fontSize:16}}>备注</Title>
                                    <div>
                                        详细地址:京市海淀区文龙家园三里京市海淀区文龙家园三里
                                    </div>
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Typography>
                                    <Title level={4} style={{fontSize:16}}>商品信息</Title>
                                    <div>
                                        <Table columns={columns} dataSource={data} size="middle"/>
                                    </div>
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form
                                    {...layout}
                                    name="basic"
                                    labelAlign="left"
                                    // initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    // onFinishFailed={onFinishFailed}
                                >
                                    <Form.Item
                                        label="快递公司"
                                        name="快递公司"
                                        rules={[{required: true, message: '请输入运单号'}]}

                                    >
                                        <Select
                                            placeholder="请选择快递公司"
                                            allowClear
                                        >
                                            <Option value="male">male</Option>
                                            <Option value="female">female</Option>
                                            <Option value="other">other</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="运单号"
                                        name="username"
                                        rules={[{required: true, message: '请输入运单号'}]}
                                    >
                                        <Input placeholder="请输入运单号"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="付款方式"
                                        name="付款方式"
                                        rules={[{required: true, message: '请选择运费付款方式'}]}
                                    >
                                        <Select
                                            placeholder="选择运费付款方式"
                                            allowClear
                                        >
                                            <Option value="male">male</Option>
                                            <Option value="female">female</Option>
                                            <Option value="other">other</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="操作人"
                                        name="操作人"
                                    >
                                        <Select
                                            placeholder="操作人"
                                            allowClear
                                            defaultValue="1"
                                            disabled
                                        >
                                            <Option value="1">孙兰</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary"  htmlType="submit">
                                            提交
                                        </Button>
                                        <Button htmlType="button" style={{marginLeft:20}}>
                                            取消
                                        </Button>
                                    </Form.Item>
                                </Form>

                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Card>
        </div>
    </div>
}
import React, {useCallback, useEffect, useReducer, useState, useRef} from "react"
import {useHttpApi} from "../../core/hooks"
import {
    Spin, Result, Button, Form, Row, Col, Modal
} from 'antd'
import TemplateSelectorModal from "./TemplateSelectorModal"
import TemplateEditorFormItem from "./TemplateEditorFormItem"
import {
    buildEditorRows,
    buildRequestParams,
    updateEditorOnValueChange,
    validateEditor
} from "../../core/editorUtil"
import DisplayComponent from "../displays/Display"

function setupEditorState(origin, editor, fixedState) {

    const passedFixedState = fixedState || {}

    const {fixed, defaults, actionUri} = editor
    const {rows, implicit} = origin
    const valueSet = {...defaults, ...fixed, ...passedFixedState}

    const defaultKeys = Object.keys(defaults)
    const fixedKeys = Object.keys(fixed)//.concat(Object.keys(passedFixedState))

    const convertedRows = buildEditorRows(rows, valueSet, defaultKeys, fixedKeys)

    return {
        origin,
        uri: actionUri,
        valueSet,
        rows: convertedRows,
        disabled: false,
        pureParams: {}
    }

}

function updateEditorOnItemValueChange(state, {key, value}) {

    const updatedState = updateEditorOnValueChange(state, {key, value})

    return {
        ...state,
        ...updatedState
    }
}

function buildFormRequestParams(state, passParams = {}, fixedParams = {}) {

    console.log(state)
    const {valueSet, rows, origin, pureParams} = state

    const values = {...passParams, ...valueSet, ...fixedParams}
    const params = buildRequestParams(rows, values)

    if (valueSet.id) {
        params.id = valueSet.id
    }

    const {predefinedParams, implicit} = origin
    if (implicit) {
        for (let k of implicit) {
            if (valueSet[k]) {
                params[k] = valueSet[k]
            }
        }
    }
    return {
        ...predefinedParams,
        ...params,
        ...pureParams
    }
}


function anyFileUploadStarted(state, onUploadComplete) {
    const {valueSet} = state
    const rows = [...state.rows]

    const preparedParams = buildFormRequestParams(state)

    let uploadingCount = 0
    let failCount = 0
    for (const row of rows) {
        if (row.hidden) {
            continue
        }
        for (const item of row.items) {
            if (item.hidden) {
                continue
            }

            if (item.component !== 'FileUpload' && item.component !== 'FileRefUpload') {
                continue
            }

            const {file, startUpload} = valueSet[item.key]
            if (!file || file.status == 'done') {
                // already uploaded
                continue
            }

            uploadingCount += 1
            startUpload(preparedParams, (success) => {
                !success && (failCount += 1)
                uploadingCount -= 1
                if (uploadingCount === 0) {
                    onUploadComplete(failCount === 0)
                }
            })
        }

    }

    return uploadingCount > 0
}


const TemplateEditor = ({valueSet, rows, disabled, dispatchFormAction, layout = 'vertical', setSelectorParams}) => {

    const generateOnChangeHandler = useCallback((item) => {
        return (e) => {
            const {id, key} = item
            const value = (typeof e === 'object' && e && e.target) ? e.target.value : e
            dispatchFormAction({
                type: 'onChange',
                params: {
                    id,
                    key,
                    value
                }
            })
        }
    }, [dispatchFormAction])

    const updateValueSet = useCallback((newValues, pureParams = false) => {

        if (pureParams) {
            dispatchFormAction({
                type: 'appendRequestParams',
                params: newValues
            })
            return
        }
        for (const k in newValues) {
            dispatchFormAction({
                type: 'onChange',
                params: {
                    key: k,
                    value: newValues[k]
                }
            })
        }

    }, [dispatchFormAction])


    return <Form layout={layout} autoComplete={'off'}>
        {rows.map(({hidden, items}, rowIdx) => {
            return !hidden && <Row type="flex" key={rowIdx} gutter={[16, 8]}>
                {items.map((item, itemIdx) => {
                    const {key, component, label, span, required, hidden, validator, valueFixed, extraProps, isDisplay} = item

                    if (hidden) {
                        return null
                    }

                    return <Col span={span} key={itemIdx}>
                        {isDisplay && <DisplayComponent
                            component={component}
                            onChange={(key) ?
                                generateOnChangeHandler({key: key}) : undefined
                            }
                            valueSetUpdater={updateValueSet}
                            props={{
                                props: {
                                    key: key,
                                    label, required,
                                    disabled: disabled || (extraProps && extraProps.disabled),
                                    validator
                                },
                                valueSet: valueSet,
                                rows,
                                ...extraProps,
                                extraProps,
                            }}
                        />}

                        {!isDisplay && <Form.Item label={label} required={required} {...validator}>
                            <TemplateEditorFormItem
                                item={item}
                                value={valueSet[key]}
                                required={required}
                                disabled={disabled || valueFixed || (extraProps && extraProps.disabled)}
                                onChange={generateOnChangeHandler(item)}
                                extraProps={extraProps}
                                setSelectorParams={setSelectorParams}/>
                        </Form.Item>}
                    </Col>
                })}
            </Row>
        })}
    </Form>

}


function editorStateReducer(state, {type, params, editor, fixedState}) {

    switch (type) {
        case 'init':
            return setupEditorState(params, editor, fixedState)
        case 'onChange':
            return updateEditorOnItemValueChange(state, params)
        case 'appendRequestParams':
            state.pureParams = {
                ...state.pureParams,
                ...params
            }
            return {
                ...state
            }
        case 'update':
            return {
                ...state,
                ...params
            }
        default:
            break
    }

}

const TemplateEditorInner = (props) => {
    const [selectorParams, setSelectorParams] = useState(false);
    const {action, sendActionRequest} = props
    const {
        onPropsReady = ({
                            title,
                            okText,
                            submitFunction,
                        }) => {
        },
        onFormEnableChanged = (enabled) => {
        }, onSuccess = () => {
        }, onCancel
    } = props
    const {editor, fixedState} = action
    const {requestUri, fixed, defaults: {_okText_, _title_, _cancelText_, _okType_}} = editor


    const [state, dispatchFormAction] = useReducer(editorStateReducer, {loading: true})
    const [response, requestTemplate] = useHttpApi(requestUri, {
        method: 'GET',
        onSuccess: (template) => {
            dispatchFormAction({
                type: 'init',
                params: template,
                editor,
                fixedState
            })
            onPropsReady({
                title: _title_ || editor.title,
                okText: _okText_ ? _okText_ : editor.submitTitle,
                cancelText: _cancelText_,
                okType: _okType_,
            })
        },
        onError: () => {

        }
    })

    useEffect(() => {
        requestTemplate()
    }, [requestTemplate])

    const enableFormEdit = useCallback((enabled) => {
        onFormEnableChanged(enabled)
        dispatchFormAction({
            type: 'update',
            params: {
                disabled: !enabled
            }
        })
    }, [dispatchFormAction])

    const submitFrom = useCallback((action) => {
        enableFormEdit(false)
        const doSubmit = () => {
            enableFormEdit(false)
            const params = buildFormRequestParams(state, {...action.fixedState, ...action.params}, fixed)
            for (const k in params) {
                if (typeof params[k] === 'object') {
                    params[k] = JSON.stringify(params[k])
                }
            }

            const {uri} = state
            sendActionRequest({
                uri,
                params: {
                    ...action.fixedState,
                    ...action.params,
                    ...fixed,
                    ...params
                }

            }, onSuccess, (error) => {
                Modal.error({
                    content: error.desc
                })
                enableFormEdit(true)
            })
        }

        const validateResult = validateEditor(state)
        if (validateResult === true) {
            // upload all files before submit
            if (anyFileUploadStarted(state, (allFilesUploaded) => {
                if (allFilesUploaded) {
                    doSubmit()
                } else {
                    enableFormEdit(true)
                }
            })) {
                return
            }
            doSubmit()
        } else {
            dispatchFormAction({
                type: 'update',
                params: {
                    rows: validateResult
                }
            })
            enableFormEdit(true)
        }

    }, [dispatchFormAction, state])

    useEffect(() => {
        onPropsReady({
            submitFunction: submitFrom
        })
    }, [submitFrom])
    const {error} = response

    return <div>
        {error && <div className="full-content-center">
            <Result
                status="error"
                title={error.desc}
                extra={<div>
                    <Button type="primary" onClick={() => {
                        requestTemplate()
                    }}> 重试 </Button>
                    <div style={{width: '24px', height: '20px'}}/>
                    {onCancel && <Button type="default" onClick={() => {
                        onCancel()
                    }}> 取消 </Button>}
                </div>
                }
            />
        </div>}

        {!error && state.loading && <div className="full-content-center">
            <Spin/>
        </div>}

        {!!state.valueSet && <TemplateEditor
            {...state}
            setSelectorParams={setSelectorParams}
            dispatchFormAction={dispatchFormAction}
        />}
        <TemplateSelectorModal
            visible={!!selectorParams} {...(selectorParams ? selectorParams : {})}
            onCancel={() => {
                setSelectorParams(false)
            }}/>
    </div>
}

const TemplateEditorModal = (props) => {
    const ref = useRef(() => {
    })
    const {action, sendActionRequest, closeModal, ...modalProps} = props

    const [modalDisplay, dispatchModalDisplay] = useReducer((lastState, updated) => {
        return {
            ...lastState,
            ...updated
        }
    }, {
        title: '',
        footer: null
    })

    return <Modal {...modalProps} {...modalDisplay}
                  onOk={() => {
                      ref.current(action)
                  }}>
        <TemplateEditorInner action={action} onPropsReady={(props) => {
            const {submitFunction, ...others} = props
            if (submitFunction) {
                ref.current = submitFunction
            }
            if (Object.keys(others).length > 0) {
                others.footer = undefined;
                // Trick make footer back
            }
            dispatchModalDisplay({
                ...others,
            })
        }} onFormEnableChanged={(enabled) => {
            dispatchModalDisplay({
                confirmLoading: !enabled,
                cancelButtonProps: {
                    disabled: !enabled
                }
            })
        }}
                             onSuccess={closeModal} onCancel={closeModal}
                             sendActionRequest={sendActionRequest}/>

    </Modal>
}

export {
    TemplateEditor,
    TemplateEditorModal,
    TemplateEditorInner,
    updateEditorOnItemValueChange
}

import React from "react"

import {Card, Col, Divider, Row} from "antd"
import MqttStatistic from "./MqttStatistic"
import presentModal from "../../presentModal"
import MqttChart from "./MqttChart"


export default ({title, items, mqtt, request, showPadding = false}) => {

    return <div className={showPadding ? 'statistic-group-wrapper' : ''}>
        {!!title && <Divider orientation="left">{title}</Divider>}
        <Row gutter={[16, 16]}>
            {items.map(({span, ...otherProps}, index) => {
                return <Col span={span} key={index}>
                    <Card className="cursor-pointer" onClick={() => {

                        const chartRequest = JSON.parse(JSON.stringify(request || otherProps.request))
                        const {valuePath, title: itemTitle, chartDelta = false} = otherProps
                        const [metricName, dataType, target, field] = valuePath.split('.')

                        const chart = {
                            value: field,
                            source: metricName + '.dataTiming',
                            title: (chartDelta ? '新增' : '') + itemTitle,
                            showDelta: chartDelta
                        }

                        for (const m of chartRequest.metrics) {
                            if (m.metricName === metricName) {
                                m.startTime = m.endTime - 86400 * 30
                                const f = m.fields[0]
                                f.name = field
                                m.fields = [f]
                                m.filter && (m.filter.instance = [target])
                                chartRequest.metrics = [m]
                                break
                            }
                        }
                        chartRequest.requestId = chartRequest.requestId + new Date().getTime()

                        const {dismiss} = presentModal({
                            closable: true,
                            maskClosable: false,
                            width: '80vw',
                            content: <MqttChart
                                mqtt={mqtt}
                                builder={'singleTouchableLine'}
                                dateFormat={'MM/DD'}
                                height={'480px'}
                                colors={['#000000', '#808080',
                                    '#2e8b57', '#7f0000', '#808000',
                                    '#7f007f', '#ff0000', '#ffa500', '#ffff00', '#7fff00', '#4169e1']}
                                request={chartRequest}
                                chart={chart}
                            />,
                            closeModal: () => {
                                dismiss()
                            }
                        })
                    }}>
                        <MqttStatistic mqtt={mqtt} request={request} {...otherProps} />
                    </Card>
                </Col>
            })}
        </Row>
    </div>
}

import {useEffect, useReducer, useState, useCallback, useRef} from 'react'

import http from "./http"
import {message} from "antd"
import {useLocation, useParams} from 'react-router-dom'

let _logoutHandler = () => {

}

function registerLogoutHandler(fn) {
    _logoutHandler = fn
}

function useLocalStoredValue(key, initialValue) {

    // lock key
    const keyRef = useRef(key)
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        const item = window.localStorage.getItem(key)
        return item || initialValue
    })

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = value => {
        if (key !== keyRef.current) {
            return
        }
        if (value === null) {
            window.localStorage.removeItem(key)
            setStoredValue(null)
            return
        }

        // Allow value to be a function so we have same API as useState
        const valueToStore =
            value instanceof Function ? value(storedValue) : value

        // Save to local storage
        window.localStorage.setItem(key, valueToStore)
        // Save state
        setStoredValue(valueToStore)
    }

    return [storedValue, setValue]
}

function useLocalStoredObject(key, initialValue) {

    const [value, setValue] = useLocalStoredValue(key, initialValue)

    let parsedJson = initialValue
    if (value && typeof value === 'string') {
        try {
            parsedJson = JSON.parse(value)
        } catch (e) {
            console.error(e)
        }
    }

    const setJsonObject = jsonObject => {
        if (jsonObject === null) {
            setValue(jsonObject)
            return
        }

        try {
            setValue(JSON.stringify(jsonObject))
        } catch (e) {
            console.error(e)
        }
    }

    return [parsedJson, setJsonObject]
}

function sendRequest(request) {

    const {method = 'GET', uri, headers, params = {}, fixedParams, defaultParams, onError, onSuccess, dispatchResponse} = request
    dispatchResponse({loading: true, error: undefined, success: false})
    const errorHandler = ({code, desc}) => {
        dispatchResponse({loading: false, error: {code, desc}, success: false})
        if (onError) {
            onError({code, desc})
            return
        }
        // default error handler
        message.error(desc)
    }

    let requestParams = params
    if (requestParams === null) {
        requestParams = (typeof defaultParams === 'function' ? defaultParams() : defaultParams) || {}
    }
    requestParams = {...requestParams, ...fixedParams}
    http.sendRequest(method, uri, headers, requestParams, (response) => {
        const {code, data, desc} = response

        if (code === -89897) {
            _logoutHandler()
            return
        }

        if (request.cancelled) {
            return
        }

        if (code < 0) {
            errorHandler({code, desc})
            return
        }

        onSuccess && onSuccess(data)
        dispatchResponse({loading: false, data, success: true})
    }, (error) => {
        !request.cancelled && errorHandler(error)
    })
}

const responseReducer = (last, response) => {
    return {
        ...last,
        ...response
    }
}

function useHttpSend(request) {

    const [response, dispatchResponse] = useReducer(responseReducer, {
        loading: false,
    })

    request.dispatchResponse = dispatchResponse
    useEffect(() => {
        if (!request.uri) {
            return
        }
        sendRequest(request)
        return () => {
            request.cancelled = true
        }

    }, [request])

    return response
}

const requestReducer = (last, requestArgs) => {
    return {
        ...last,
        ...requestArgs
    }
}

function useHttpRequest(uri, options) {
    const [account] = useLocalStoredObject('x_qp_account')
    const fixedParams = {
        rq_type: 'ajax'
    }
    if (account && account.token) {
        if (options.headers) {
            options.headers['Token'] = account.token
        }
        fixedParams.token = account.token
    }
    const [request, dispatchRequest] = useReducer(requestReducer, {
        uri: null,
        ...options,
        fixedParams
    })

    const response = useHttpSend(request)
    const clearResult = useCallback(() => {
        request.dispatchResponse && request.dispatchResponse({
            loading: false, success: undefined, data: undefined, error: undefined
        })
    }, [request])
    return [
        response, dispatchRequest, clearResult
    ]
}


function useHttpApi(uri, options = {
    defaultParams: {},
    fixedParams: null,
    method: 'GET',
    onSuccess: null,
    onError: null
}) {

    const [response, dispatchRequest, clearResult] = useHttpRequest(uri, options)
    const sendRequest = useCallback((requestParams = null, onSuccess = null, onError = null) => {
        const params = (typeof requestParams === 'function' ? requestParams() : requestParams)
        const composed = {
            uri,
            params,
        }
        if (onSuccess) {
            composed.onSuccess = onSuccess;
        }
        if (onError) {
            composed.onError = onError;
        }
        dispatchRequest(composed)
    }, [dispatchRequest, uri])

    return [
        response,
        sendRequest,
        clearResult
    ]
}

function useHttpPost(uri, defaultParams = {},headers = {}) {
    return useHttpApi(uri, {
        defaultParams,
        method: 'POST',
        headers,
    })
}

function useHttpPostJson(uri, defaultParams = {},headers = {}) {
    if (uri.indexOf('?') > 0) {
        uri = uri + '&rq_type=ajax'
    } else {
        uri = uri + '?rq_type=ajax'
    }
    return useHttpApi(uri, {
        defaultParams,
        method: 'POST',
        headers : {...headers , 'Content-Type' : 'application/json'},
        json: true,
    })
}


function useHttpGet(uri, defaultParams = {},headers = {}) {
    return useHttpApi(uri, {
        defaultParams,
        method: 'GET',
        headers,
    })
}

function useOpenPage(uri, initParams = {}) {
    const [account] = useLocalStoredObject('x_qp_account')
    if (!initParams) {
        initParams = {}
    }
    initParams.rq_type = 'new_page'
    if (account && account.token) {
        initParams.token = account.token
    }

    return useCallback((params) => {
        http.openUrlInNewWindow(params.uri || uri, { ...params, ...initParams})
    }, [uri, JSON.stringify(initParams)])

}

function useHttpAction(setActionResult) {

    const [response, dispatchRequest] = useHttpRequest('action_uri', {method: 'POST'})
    const sendActionRequest = useCallback((action, onSuccess, onError) => {
        const {uri, params, fixedState} = action
        const requestParams = {...params, ...fixedState}
        dispatchRequest({
            uri,
            params: requestParams,
            onSuccess: (result) => {
                setActionResult({
                    action: action,
                    result
                })
                onSuccess && onSuccess(result)
            },
            onError
        })
    }, [dispatchRequest, setActionResult])

    return [
        response,
        sendActionRequest
    ]
}

function useRouteQuery() {
    const params = useParams()
    const location = useLocation()
    const search = new URLSearchParams(location.search)

    const query = {}
    for (let key of search.keys()) {
        query[key] = search.get(key)
    }

    return {
        ...params,
        ...query
    }
}

function useRefresh() {
    const [i, refreshI] = useState(0);

    return useCallback(() => {
        refreshI(i + 1)
    }, [i])
}

function useIsInLark() {
    // url?FA_ENV=iframe-webview
    // ua= **Lark/6.10.3 LarkLocale/zh_CN EEMicroApp/1.9.28.6 miniprogram/navApplication
    if (window.location.search?.includes('FA_ENV=iframe-webview')) {
        return true;
    }
    if (window.location.search?.includes('LARK_ENV=1')) {
        return true;
    }
    return window.navigator.userAgent?.includes(' Lark/')
}


export {
    useLocalStoredValue,
    useLocalStoredObject,
    useHttpPost,
    useHttpPostJson,
    useHttpGet,
    useHttpApi,
    useOpenPage,
    useRouteQuery,
    useHttpAction,
    registerLogoutHandler,
    useRefresh,
    useIsInLark
}

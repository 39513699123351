import React, {useCallback, useEffect, useState} from "react"
import {
    Button, Input, Switch, Select, Cascader, InputNumber,
    Radio, DatePicker, Upload, Checkbox, Tree, Slider, Row, Col, Form, Descriptions, Result, Spin, Alert, Tooltip, Table
} from 'antd';
import {JsonEditor} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

import {CloseCircleFilled, MinusCircleOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import moment from 'moment';
import {useHttpPost, useHttpGet} from "../../core/hooks"
import {buildEditorRows, updateEditorOnValueChange} from "../../core/editorUtil"
import FileRefUploader from '../../components/FileRefUploader'
import {renderItemFieldValue} from "../../core/domUtil";
import InputMoney from "../InputMoney";
import DisplayComponent from "../displays/Display";
import {conditionsMatched} from "../../core/conditionsMatcher";
import { FormatMoney } from "format-money-js";
const { RangePicker } = DatePicker;
const formatMoney = new FormatMoney()

function findFullValues(val, arr, searchResult) {

    if (searchResult.found) {
        return
    }

    for (const e of arr) {
        searchResult.value.push(e.value)
        if (('' + e.value) === ('' + val)) {
            searchResult.found = true
            return
        }

        if (e.children && e.children.length) {
            findFullValues(val, e.children, searchResult)
        }

        if (searchResult.found) {
            return
        }

        searchResult.value.pop()
    }

}

function getFullValues(value, options) {

    if (!value || value.length === 0) {
        return []
    }

    const result = {found: false, value: []}
    findFullValues(value[value.length - 1], options, result)
    return result.value
}

const DFSelect = props => {
    let {options, defaultValue, value, mode, valueType, onChange, placeholder, ...otherProps} = props
    let separatorsArray = [];
    let passedValue = value
    if (mode === 'tags' || mode === 'multiple') {
        defaultValue = defaultValue ? `${defaultValue}`.split(",") : []
        passedValue = (value && value !== '0') ? (Array.isArray(value) ? value : ('' + value).split(',')) : undefined
        options = options && options.map(({value, label}) => {
            return {value: `${value}`, label: label}
            // option 会把value是数字的转成int，
            // 而 tags 和 multiple 比较时候是 string的（比如：'1', '2' ）
            // 所以这里把 value 也搞成 string 的
        })
        separatorsArray = [',', '，', ' '];

    } else {
        // defaultValue = defaultValue ? parseInt(defaultValue) : defaultValue;
        if ((typeof passedValue === 'string') && options.length && (typeof options[0].value === 'number')) {
            passedValue = parseInt(passedValue, 10)
        }

        let valueValid = false
        for (const i in options) {
            if (passedValue === options[i].value) {
                valueValid = true
                break
            }
        }

        if (!valueValid) {
            passedValue = undefined
        }

    }

    useEffect(() => {
        if (value === undefined && defaultValue) {
            onChange && onChange(defaultValue)
        }
    }, [])

    return <Select dropdownMatchSelectWidth={false} value={passedValue} {...otherProps} placeholder={placeholder} allowClear showSearch
                   optionFilterProp="children"
                //    filterOption={(input, option) =>
                //        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                //    }
                   mode={mode} tokenSeparators={separatorsArray} onChange={(v) => {
        if (Array.isArray(v)) {
            onChange(v.join(','));
        } else {
            onChange(v);
        }
    }}>
        {options ? options.map(({label, value}) => {
            return <Select.Option key={value} value={value}>
                {label}
            </Select.Option>
        }) : []}
    </Select>

}

const DFDataSelector = props => {
    const [response, getMini, clearResponse] = useHttpGet('/template/getMiniDisplay')
    const { value, placeholder, source, parsed_uri_params, setSelectorParams, label, disabled, onChange, ...extraProps } = props
    const { _template_id_: templateId, mode: selectMode } = parsed_uri_params;
    const { compiledUri } = source
    const multi = selectMode === 'multiple'
    const convertedValue = Array.isArray(value) ? value : typeof value === 'string' ? value.split(',') : value ? [value] : []

    const hasValue = !!convertedValue.length

    useEffect(() => {
        if (hasValue) {
            getMini({
                id: convertedValue,
                _template_id_: templateId,
                label: label || '',
                multiple: multi?1:0,
            })
        } else {
            clearResponse()
        }
    }, [value])
    let componentPrefix;
    if (response.loading) {
        componentPrefix = <Spin/>
    }
    if (response.error || (response.data && !response.data.displayFields)) {
        componentPrefix = <Alert type='error' showIcon message={'无法显示所选内容。 id=' + value} />
    }
    if (hasValue && response.data) {
        let detail = response.data
        if (detail.displayedItem) {
            componentPrefix = detail.displayFields.length ? <Descriptions bordered size='small'>
                {detail.displayFields.map(field => {
                    return <Descriptions.Item
                        label={field.title}>{renderItemFieldValue(field, detail.displayedItem)}</Descriptions.Item>
                })}
            </Descriptions> : null
        } else if (detail.items && detail.items.length) {
            componentPrefix = <Table
                size={'small'}
                pagination={false}
                dataSource={detail.items}
                rowKey={({id}) => id}>
                <Table.Column dataIndex={'ri'} title={''}
                              align={'center'}
                              key={'index'}
                              render={(_a, _b, index) => {
                                  return <b>{index + 1}.</b>
                              }}/>
                {detail.displayFields.map((displayField, columnIdx) => {
                    const {key, title} = displayField
                    return <Table.Column dataIndex={key} title={title} key={columnIdx}
                        align={'center'}
                        render={(cellVal, record) => {
                            return renderItemFieldValue(displayField, record)
                        }}/>

                })}
            </Table>
        }
        
    }

    return <Row align={hasValue && multi? 'top' : 'middle'} gutter={[16, 16]}>
        <Col span={disabled ? 24 : 20}>{hasValue ? componentPrefix : <span style={{ color: 'gray' }}>{placeholder ||'--'}</span>}</Col>
        {!disabled && <Col>
            {hasValue ? <Tooltip title='清空选择'>
                <Button type='dashed' icon={<CloseCircleFilled />} onClick={() => {
                    onChange([])
                }} />
            </Tooltip> : ''}
        </Col>}
        {!disabled && <Col>
            <Button onClick={() => {
                setSelectorParams && setSelectorParams({
                    selectedIds: convertedValue,
                    uri: compiledUri || undefined,
                    url: parsed_uri_params.url|| undefined,
                    title: '请选择' + label,
                    selector: multi ? 'checkbox' : 'radio',
                    extraProps: extraProps,
                    moreParams: parsed_uri_params,
                    onSelect: (keys, rows) => {
                        setSelectorParams(false)
                        onChange(keys)
                    }
                })
            }}>选择{label}</Button>
        </Col>}
    </Row>
}

const DFRadioGroup = props => {
    const {options, value, ...otherProps} = props
    return <Radio.Group value={value} {...otherProps}>
        {options && options.map(({label, value}) => {
            return <Radio.Button key={value} value={value}>
                {label}
            </Radio.Button>
        })}
    </Radio.Group>

}

const DFCascader = props => {

    const {onChange, changeOnSelect, options, resultType, ...otherProps} = props
    let {value, defaultValue} = props
    if (defaultValue && !Array.isArray(defaultValue)) {
        defaultValue = [defaultValue];
    }
    const changeOnSelectValue = changeOnSelect === 1 ? true : false;

    // const [selectedValue, setSelectedValue] = useState(value)

    // useEffect(() => {
    //     let fullByValue = getFullValues(value, options);
    //     if (fullByValue.length === 0 && defaultValue) {
    //         setSelectedValue(getFullValues(defaultValue, options)) // 如果没有Value，就选中默认值
    //     } else {
    //         setSelectedValue(fullByValue)
    //     }
    // }, [defaultValue])


    let fullPathValue = value
    if (value && value.length <= 1) {
        fullPathValue = getFullValues(value, options)
        if (fullPathValue.length === 0 && defaultValue) {
            fullPathValue = getFullValues(defaultValue, options) // 如果没有Value，就选中默认值
        }
    }

    function filter(inputValue, path) {
        return path.some(option => (option.label || '').toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }

    // useEffect(() => {
    //     if (resultType !== 'array') {
    //         onChange((value && value.length) ? value[value.length - 1] : value)
    //     }
    // }, [])

    return <Cascader
        autoComplete='off'
        options={options}
        showSearch={{filter}}
        onChange={(values) => {
            onChange(values)
        }}
        changeOnSelect={changeOnSelectValue}
        allowClear
        {...otherProps}
        value={fullPathValue}

    />
}
//
const DFTreeSelect = props => {
    const {onChange, options, value, ...otherProps} = props
    const [selectedKeys, setSelectedKeys] = useState(value)

    const renderTreeNodes = useCallback((data) => {
        return data.map(({label, value, children}) => {
            if (children && children.length) {
                return <Tree.TreeNode selectable={false} title={label} key={value}>
                    {renderTreeNodes(children)}
                </Tree.TreeNode>
            }
            return <Tree.TreeNode selectable={false} key={value} title={label}/>
        })
    })

    return <Tree
        checkable={true}
        checkedKeys={selectedKeys}
        onCheck={(keys) => {
            setSelectedKeys(keys)
            onChange(keys)
        }}
        {...otherProps}>
        {renderTreeNodes(options)}
    </Tree>
}


const DFInput = props => {
    const {inputType, lines, multiLines, onlyNumbers, min, max, placeholder, currency, ...otherProps} = props
    const placeholderText = placeholder ? '输入' + placeholder : '';

    if (currency) {
        let theCurrency = currency
        if (!isNaN(theCurrency)) {
            theCurrency = '￥'
        }
        return <InputMoney autoComplete='off' prefix={theCurrency} {...otherProps} />
    }
    if (multiLines || lines > 1) {
        return <Input.TextArea autoComplete='off' autosize="true" rows={lines || 5}
                               placeholder={placeholderText} {...otherProps}/>
    }
    if (onlyNumbers) {
        return <InputNumber autoComplete='off' min={(min && parseFloat(min)) || undefined} placeholder={placeholder}
                            max={(max && parseFloat(max)) || undefined} {...otherProps} style={{width: '100%'}}/>
    }

    return <Input autoComplete='off' placeholder={placeholderText} {...otherProps}/>
}
const DFSlider = props => {
    const {value, min, max, range, ...otherProps} = props
    return <Slider value={(value && parseFloat(value)) || undefined} min={(min && parseFloat(min)) || undefined}
                   max={(max && parseFloat(max)) || undefined}  {...otherProps}/>
}

const DFSwitch = props => {

    const {value, ...otherProps} = props
    return <Switch checked={!!value} {...otherProps}/>
}

const DFDate = props => {
    const { value, showTime, onChange, timeType = 'unix', initValue, defaultValue, ...otherProps } = props

    let showTimeValue = true;
    if (showTime == 'true' || showTime > 0) {
        showTimeValue = true;
    } else if (showTime == 'false' || showTime == 0) {
        showTimeValue = false;
    } else if (!showTime) {
        showTimeValue = true;
    } else if (showTime.startsWith('{') && showTime.endsWith('}')) {
        try {
            showTimeValue = JSON.parse(showTime)
        } catch (e) {
            showTimeValue = true
        }
    }
    let defMoment = undefined;
    let valMoment = undefined;
    if (defaultValue || initValue) {
        if (initValue) {
            defMoment = initValue == ":{now}" ? moment() : moment(initValue * 1000);
        } else if (defaultValue) {
            defMoment = defaultValue == ":{now}" ? moment() : moment(defaultValue * 1000);
        }
        if (!showTimeValue && defMoment && defMoment.isValid()) {
            defMoment = defMoment.startOf('day')
        }
    }
    if (value) {
        valMoment = moment(value * 1000)
        if (!showTimeValue && valMoment && valMoment.isValid()) {
            valMoment = valMoment.startOf('day')
        }
    }
    useEffect(() => {
        if (onChange) {
            if (initValue) {
                onChange(defMoment.unix())  // 加载时不管有没有值，都按 initValue 来
            } else if (valMoment) {
                onChange(valMoment.unix())
            } else if (defMoment) {
                onChange(defMoment.unix())
            }
        }
    }, [])
    // if (m) {
    //     onChange && onChange(m.unix())
    // }
    return <DatePicker value={valMoment} defaultValue={defMoment} showTime={showTimeValue} style={{width: '100%'}}
                       format={showTimeValue ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
                       showToday={true}
                       showNow={true}
                       {...otherProps}
                       onChange={(m) => {
                           if (!showTimeValue) {
                               onChange(m.startOf('day').unix())
                           } else {
                               onChange(m.unix())
                           }
                       }}
    />
}

const DFRangeDate = props => {
    let { value, onChange, timeType = 'unix', showTime = false, placeholder, ...otherProps} = props
    if (typeof (value) === 'string') {
        value = value.split(',');
        value.push(value[0])
    }
    if (['unix', 'YYYYMM', 'YYYYMMDD'].indexOf(timeType) < 0) {
        timeType = 'unix';
    }
    const [valueFrom, valueTo] = value || [0, 0];
    let m = value ? [
        timeType === 'unix' ? moment(valueFrom * 1000) : moment('' + valueFrom, timeType),
        timeType === 'unix' ? moment(valueTo * 1000) : moment('' + valueTo, timeType)] : undefined;
    let callOnChange = useCallback((moment1, moment2) => {
        if (onChange) {
            if (moment1 && !moment1.unix() && moment2 && !moment2.unix()) {
                onChange(undefined)
            } else if (!moment1 && !moment2) {
                onChange(undefined)
            } else if (timeType === 'unix') {
                if (!showTime) {
                    onChange([moment1 && moment1.startOf('day').unix(), moment2 && moment2.endOf('day').unix()])
                } else {
                    onChange([moment1 && moment1.unix(), moment2 && moment2.unix()])
                }
            } else {
                onChange([moment1 && moment1.startOf('day').format(timeType), moment2 && moment2.endOf('day').format(timeType)])
            }
        }
    }, [onChange, timeType])
    useEffect(() => {
        if (m) {
            const dateTime = m
            callOnChange(dateTime[0], dateTime[1])
        }
    }, [])
    return <RangePicker value={m} style={{width: '100%'}} allowClear={true}
        ranges={{
            '上上个月': [moment().add(-2, 'month').startOf('month'), moment().add(-2, 'month').endOf('month')],
            '上个月': [moment().add(-1, 'month').startOf('month'), moment().add(-1, 'month').endOf('month')],
            '本月': [moment().startOf('month'), moment().endOf('month')],
            '上上周': [moment().add(-2, 'week').startOf('week'), moment().add(-2, 'week').endOf('week')],
            '上周': [moment().add(-1, 'week').startOf('week'), moment().add(-1, 'week').endOf('week')],
            '本周': [moment().startOf('week'), moment().endOf('week')],
            '今天': [moment().startOf('day'), moment().endOf('day')],
        }}
        onChange={(date) => {
            const dateTime = date || [undefined, undefined]
            callOnChange(dateTime[0], dateTime[1])
        }}
        showTime={showTime}
        {...otherProps}

    />
}

// download: "firmwares/:{year}:{month}/:{update_param}_:{version}"
// hashField: "file_hash"
// hashType: "md5"
// hashTypeField: "file_hash_type"
// sizeField: "file_size"
const DFUploader = ({onChange, value, download, hashField, hashType, hashTypeField, sizeField, bucket,...restProps}) => {

    const [fileList, setFileList] = useState((value && value.file) ? [value.file] : [])
    // const
    const handleUploadResponse = useCallback((response) => {
        if (response.path) {
            const file = fileList[0]
            const {path, uri, more} = response
            file.status = 'done'
            file.url = uri
            file.path = path
            file.more = more
            setFileList([file])

            onChange && onChange({
                file
            })
            file.onComplete && file.onComplete(true)
        } else {
            if (!fileList || fileList.length === 0) {
                return
            }
            const file = fileList[0]
            file.status = 'error'
            setFileList([file])
            onChange && onChange({
                file
            })
            file.onComplete && file.onComplete(false)
        }
    }, [fileList])

    const [response, startUpload] = useHttpPost('/file/upload')

    useEffect(() => {
        if (!response.error && !response.data) {
            return
        }
        if (response.error) {
            handleUploadResponse(response.error)
        } else {
            handleUploadResponse(response.data)
        }

    }, [response])

    return <Upload
        onChange={(changed) => {
            let {file, fileList} = changed
            if (file.status === 'error' || file.status === undefined) {

                const uploadParams = {};
                Object.entries({
                    download,
                    hashField,
                    hashType,
                    hashTypeField,
                    sizeField,
                    bucket
                }).map(([key, value]) => {
                    if (value) {
                        uploadParams[key] = value
                    }
                })

                onChange && onChange({
                    startUpload: (valueSet, onComplete) => {
                        file.onComplete = onComplete
                        file.status = 'uploading'
                        setFileList([file])
                        startUpload({
                            ...valueSet,
                            ...uploadParams,
                            file
                        })
                    },
                    file
                })
            } else if (fileList.length === 0) {
                onChange({
                    file: null
                })
            }

            if (fileList.length > 1) {
                fileList = [fileList[fileList.length - 1]]
            }
            setFileList(fileList)
        }}
        {...restProps}
        fileList={fileList}
        openFileDialogOnClick={true}
        beforeUpload={() => false}>
        <Button>
            <UploadOutlined/> 选择文件
        </Button>
    </Upload>
}


const URISourcedComponent = ({onChange, value, item, disabled, extraProps = {}}) => {
    const {component, source} = item
    const {compiledUri} = source

    const [{data}, getOptions] = useHttpGet(compiledUri)
    useEffect(() => {
        getOptions()
    }, [compiledUri])

    if (!data) {
        return <span/>
    }
    if (component === 'Cascader' && !Array.isArray(value)) {
        value = typeof value === 'string' ? value.split(',').map((v) => parseInt(v)) : [value];
    }

    item.source.options = data
    let itemProps = {
        onChange,
        value,
        options: data,
        disabled: item.disabled || disabled,
    }
    if (component === 'Cascader') {
        itemProps['changeOnSelect'] = item.changeOnSelect
    }
    const ItemComponent = Components[component]
    return <ItemComponent {...itemProps} {...extraProps}/>
}


const ItemEditor = ({rows, disabled, valueSet, onChange, alignWidth = false, setSelectorParams}) => {

    const generateOnChangeHandler = useCallback((item) => {
        return (e) => {
            const {key} = item
            const value = (typeof e === 'object' && e && e.target) ? e.target.value : e
            onChange && onChange([{key, value}])
        }
    }, [onChange])

    const innerUpdateValueSet = (newValues) => {
        onChange && onChange(Object.keys(newValues).map(key => {
            return { key, value: newValues[key] }
        }))
    }
    return <div>
        {rows.map(({hidden, items}, rowIdx) => {
            let rowSumSpan = items.reduce((p, {span, hidden}, i) => {
                return p + (hidden ? 0 : span)
            }, 0)
            // console.log('span sum', rowSumSpan, items)
            return !hidden && <Row type="flex" key={rowIdx} gutter={[16, 8]}>
                {items.map((item, itemIdx) => {
                    const {
                        key,
                        component,
                        label,
                        span,
                        required,
                        hidden,
                        validator,
                        valueFixed,
                        extraProps,
                        isDisplay
                    } = item
                    let thisSpan = alignWidth ? Math.min(parseInt((span / rowSumSpan) * 24), 2 * span) : span;
                    return !hidden && <Col span={thisSpan} key={itemIdx}>
                        {isDisplay && <DisplayComponent
                            component={component}
                            onChange={key ?
                                generateOnChangeHandler({key: key}) : undefined
                            }
                            props={{
                                props: {
                                    key: key,
                                    label, required,
                                    disabled: disabled || (extraProps && extraProps.disabled),
                                    validator
                                },
                                valueSet: valueSet,
                                valueSetUpdater: innerUpdateValueSet,
                                rows,
                                ...extraProps,
                                extraProps
                            }}
                        />}
                        {!isDisplay && <Form.Item required={required} {...validator}>
                            <FieldComponent
                                item={item}
                                value={valueSet[key]}
                                disabled={disabled || valueFixed}
                                onChange={generateOnChangeHandler(item)}
                                extraProps={{...extraProps, placeholder: label}}
                                setSelectorParams={setSelectorParams}/>
                        </Form.Item>}
                    </Col>
                })}
            </Row>
        })}
    </div>
}

const ItemEditorList = ({
                            editor,
                            disabled,
                            value: editingItems,
                            onChange,
                            label,
                            multiple,
                            addAllowed,
                            removeAllowed,
                            setSelectorParams,
                            required,
                            fromParentState,
                            fixParentKeys,
                            sumColumnKeys,
                            hide_header = false,
                            align_width = false
                        }) => {

    const addItem = useCallback(() => {
        const itemEditor = JSON.parse(JSON.stringify(editor))
        const rows = buildEditorRows(itemEditor.rows, { ...fromParentState }, undefined, fixParentKeys)
        editingItems.push({ rows, valueSet: { ...fromParentState } })
        onChange && onChange(editingItems)
    }, [fromParentState, editor, onChange])
    let headerItem = editor.rows[0].filter(({showCondition}) => {
        return !showCondition || conditionsMatched(showCondition, fromParentState)
    });
    let headerRowSumSpan = headerItem.reduce((p, {span, hidden}, i) => {
        return p + (hidden ? 0 : span)
    }, 0)
    let sumColumnKeyTypeMap = sumColumnKeys.reduce((last, curr) => {
        let [key, type] = curr.split(':');
        last[key] = type || 'text'
        return last
    }, {})
    console.log('editingItems', editingItems)
    return <div>

        {!hide_header && editor.rows.length === 1 &&
        <div style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'top'}}>
            <Row type="flex" style={{flex: 1,}} gutter={[8, 8]}>
                {headerItem.map(({span, label,}, idx) => {
                    let thisSpan = align_width ? Math.min(parseInt((span / headerRowSumSpan) * 24), 2 * span) : span;
                    return <Col span={thisSpan} key={idx}>
                        <b>{label}</b>
                    </Col>
                })}
            </Row>
            {multiple && <div style={{margin: '8px', width: '10px', height: '14px'}}/>}
        </div>}

        {editingItems.map(({rows, valueSet, deleted}, index) => {

            if (deleted) {
                return null
            }

            return <div key={index} style={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'top'}}>
                {
                    (removeAllowed) && <MinusCircleOutlined style={{margin: '8px'}} onClick={() => {
                        if (editingItems[index].valueSet.id > 0) {
                            editingItems[index].deleted = true
                        } else {
                            editingItems.splice(index, 1)
                        }
                        onChange && onChange(editingItems)
                        // setEditingItems([...editingItems])
                    }}/>
                }
                <div style={{flex: 1}}>
                    <ItemEditor rows={rows}
                                disabled={disabled}
                                valueSet={valueSet}
                                onChange={(valuesToUpdate) => {
                                    editingItems[index] = updateEditorOnValueChange({ rows, valueSet }, ...valuesToUpdate)
                                    onChange && onChange(editingItems)
                                }}
                                alignWidth={align_width}
                                setSelectorParams={setSelectorParams}
                    />
                </div>

                {multiple && <div style={{margin: '8px'}}><b>{(index + 1) + '.'}</b></div>}
            </div>
        })}
        {sumColumnKeys && sumColumnKeys.length ? <div key={'sum'} style={{ display: 'flex', alignItems: 'top' }}>
            {multiple && <div style={{ margin: '8px' }}><b>{'合.'}</b></div>}
            <Row type="flex" style={{ flex: 1, }} gutter={[8, 8]}>
                {headerItem.map(({ span, label, key }, idx) => {
                    let thisSpan = align_width ? Math.min(parseInt((span / headerRowSumSpan) * 24), 2 * span) : span;
                    let sum = sumColumnKeyTypeMap[key] ? editingItems.reduce((last, current) => {
                        return last + parseFloat(current.valueSet[key]) || 0
                    }, 0) : 0
                    return <Col span={thisSpan} key={idx}>
                        <b>{sumColumnKeyTypeMap[key] === 'money' ?
                            formatMoney.from(sum, { symbol: '￥', decimals: 2 }) : sumColumnKeyTypeMap[key] === 'money_usd' ?
                                formatMoney.from(sum, { symbol: '$', decimals: 2 }) : sumColumnKeyTypeMap[key] ?
                                    Math.round(sum * 100) / 100 : ''}</b>
                    </Col>
                })}
            </Row>
        </div>: null}
        {(addAllowed && (multiple || (editingItems.length === 0 && required))) && <div>
            <Button type="link" onClick={addItem} style={{textAlign: 'left', padding: '0'}}>
                <PlusOutlined/> {'添加' + (label || '')}
            </Button>
        </div>}

    </div>
}

const JSONEditor = (props) => {

    const {value, onChange} = props
    const jsonVal = value ? JSON.parse(value) : {}
    return <JsonEditor value={jsonVal}
                       onChange={(updatedValue) => {
                           onChange && onChange(JSON.stringify(updatedValue))
                       }
                       }/>
}

const Components = {
    Input: DFInput,
    Slider: DFSlider,
    InputNumber,
    Select: DFSelect,
    RadioGroup: DFRadioGroup,
    Cascader: DFCascader,
    CheckboxGroup: Checkbox.Group,
    Switch: DFSwitch,
    DatePicker: DFDate,
    DateRangePicker: DFRangeDate,
    FileUpload: DFUploader,
    FileRefUpload: FileRefUploader,
    TreeSelect: DFTreeSelect,
    SourceDataSelector: DFDataSelector,
    JSONEditor,
    ItemEditorList
}

const FieldComponent = (props) => {
    const {onChange, value, item, disabled, required, extraProps = {}} = props
    const {component, source} = item

    if (source && source.compiledUri && component !== 'SourceDataSelector') {
        return <URISourcedComponent
            {...props}
        />
    }
    let valueInt = [];
    if (component === 'CheckboxGroup') {
        if (value) {
            valueInt = Array.isArray(value) ? value.map(parseInt) : [parseInt(value)]
        }
    }

    const ItemComponent = Components[component] || Components.Input
    let itemProps = {
        onChange,
        value: component === 'CheckboxGroup' ? valueInt : value,
        disabled: item.disabled || disabled,
        label: item.label,
        required: required,
    }
    itemProps.parsed_uri_params = {}
    if (source) {
        itemProps.options = source.options
        if (source.parsedParams) {
            itemProps.parsed_uri_params = source.parsedParams
        }
    }
    if (props.setSelectorParams) {
        itemProps.setSelectorParams = props.setSelectorParams
        itemProps.parsed_uri_params = { ...itemProps.parsed_uri_params, ...extraProps}
    }

    if (item.fromParentState) {
        itemProps.fromParentState = item.fromParentState
        if (item.fixParentKeys) {
            itemProps.fixParentKeys = item.fixParentKeys
        }
    }
    itemProps.sumColumnKeys = item.sumColumnKeys


    return <ItemComponent {...itemProps} {...extraProps} source={source}/>
}

export default FieldComponent




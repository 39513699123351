// UserInfoBox.jsx

import React from 'react';
import './index.css'
import { UsergroupDeleteOutlined } from '@ant-design/icons';

const UserInfoBox = ({ companyName, contactName, contactPhone }) => {
  return (
    <div className="site-item-container">
      <div className="site-user-info">
        <UsergroupDeleteOutlined className="site-user-logo" />
        <div className="site-user-name-box">
          <div className="user-info-name" style={{ color: "rgba(0,0,0,0.7)" }}>
            <span>企业：</span>
            <span>联系人：</span>
          </div>
          <div className="user-info-name" style={{ color: "#000" }}>
            <span>{companyName}</span>
            <span>{contactName}  {contactPhone ? `+86 ${contactPhone}` : '--'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoBox;

import { Cascader, Select } from "antd"
import React, { useEffect } from "react"
import { useHttpGet } from "../../../core/hooks"


export default ({ props: {key, label}, valueSet, category, rows, valueSetUpdater, onChange, ...other }) => {
    const [{ data }, getOptions] = useHttpGet('/template/itemOptions?_entity_name_=Supplier')
    const value = valueSet[key];
    useEffect(() => {
        getOptions(category ?{category}:undefined)
    }, [category]);
    if (!data) {
        return <span />
    }
    let itemProps = {
        onChange,
        value,
        options: data,
    }

    return <div>
        <div style={{margin: 8}}>{label}</div>
        <Select {...itemProps} style={{width: '100%'}}/>
    </div>
}
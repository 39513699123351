/**
 * Created by crazyant on 04/05/2017.
 */

function sendRequest(method = 'GET', url, headers = undefined, parameters, onSuccess, onError) {
    const xhr = new XMLHttpRequest()
    const handler = (event) => {
        const {status, response, responseText, statusText} = xhr
        if (status === 200) {
            let responseStr = response || responseText
            try {
                onSuccess && onSuccess(JSON.parse(responseStr))
            } catch ({message}) {
                onError && onError({code:-50000, desc:message})
            }
        } else {
            onError && onError({
                code: -50000,
                desc: statusText || responseText || 'request error'
            })
        }
    }

    xhr.onload = handler
    xhr.onabort = handler
    xhr.onerror = handler
    xhr.ontimeout = handler

    let requestUri = url
    if ((url.indexOf('http') !== 0)) {
        requestUri = (process.env.REACT_APP_API_HOST || '') + url
    }

    if (method === 'GET') {
        let esc = encodeURIComponent
        let queryStr = Object.keys(parameters).map(k => esc(k) + '=' + esc(parameters[k])).join('&')
        if (requestUri.indexOf('?') > 0) {
            requestUri = requestUri + '&' + queryStr
        } else {
            requestUri = requestUri + '?' + queryStr
        }
        xhr.open(method, requestUri, true)
        if (headers) {
            for (const key in headers) {
                if (headers[key]) {
                    xhr.setRequestHeader(key, headers[key])
                }
            }
        }
        xhr.send()
    } else {
        xhr.open(method, requestUri, true)
        let isJson = false;
        if (headers) {
            for (const key in headers) {
                if (headers[key]) {
                    xhr.setRequestHeader(key, headers[key])
                    if (key == 'Content-Type') {
                        isJson = headers[key] == 'application/json'
                    }
                }
            }
        }
        if (isJson) {
            xhr.send(JSON.stringify(parameters))
        } else {
            const data = new FormData()
            for (let k in parameters) {
                data.append(k, parameters[k])
            }
            xhr.send(data)
        }
    }
}

function openUrlInNewWindow(url, parameters = {}) {
    let requestUri = url
    if ((url.indexOf('http') !== 0)) {
        requestUri = (process.env.REACT_APP_API_HOST || '') + url
    }

    let esc = encodeURIComponent
    let queryStr = Object.keys(parameters).map(k => esc(k) + '=' + esc(parameters[k])).join('&')
    if (requestUri.indexOf('?') > 0) {
        requestUri = requestUri + '&' + queryStr
    } else {
        requestUri = requestUri + '?' + queryStr
    }
    let link = window.document.createElement('a');
    link.href = requestUri
    link.target = '_blank'
    link.click()
}

export default {
    sendRequest,
    openUrlInNewWindow,
}

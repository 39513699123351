import React, { useState } from "react";
import { Form, Input, Button, Cascader, Modal } from "antd";
import SelectAddrTable from "./SelectAddrTable";
const UserAddressForm = ({
  addressId,
  addrResponse,
  onUpdateAddress,
  setAddrContent,
  client_token,
  handleKeyDown
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleSelectFromAddressLibrary = () => {
    setShowDialog(true);
  };

  const handleClearOrModify = () => {
    if (addressId) {
      onUpdateAddress(0); // 修改
    } else {
      setAddrContent({}); // 清空
    }
  };

  return (
    <>
      <div className="site-item-container addressInfo">
        <div className="align-center">
          <div className="item-box-title">收件信息</div>
          <p>
            <Button type="link" onClick={handleSelectFromAddressLibrary}>
              从地址库选择
            </Button>
            <Button type="link" onClick={handleClearOrModify}>
              {addressId ? "修改" : "清空"}
            </Button>
          </p>
        </div>

        <Form.Item label="所在地区" name="city_id" required>
          <Cascader
            className="address_cascader"
            placeholder="选择城市"
            disabled={addressId}
            options={addrResponse.data}
          />
        </Form.Item>

        <Form.Item label="详细地址" name="address" required>
          <Input.TextArea
            disabled={addressId}
            placeholder="详细地址（城区、街道、门牌号）"
            inputMode="text"
            onKeyDown={handleKeyDown}
          />
        </Form.Item>

        <Form.Item label="收件人" name="name" required>
          <Input disabled={addressId} placeholder="收件人" inputMode="text" onKeyDown={handleKeyDown}/>
        </Form.Item>

        <Form.Item label="手机号码" name="phone" required>
          <Input disabled={addressId} placeholder="电话" inputMode="tel" onKeyDown={handleKeyDown}/>
        </Form.Item>
      </div>

      <Modal
        title="地址库"
        visible={showDialog}
        footer={null}
        onOk={null}
        onCancel={() => setShowDialog(false)}
      >
        <SelectAddrTable
          client_token={client_token}
          onSelectId={(id, content) => {
            if (id && content) {
              setAddrContent(content);
            }
            setTimeout(() => setShowDialog(false), 500);
          }}
          selectedId={addressId}
        />
      </Modal>
    </>
  );
};

export default UserAddressForm;

import React, {useEffect} from "react"

import ReactEcharts from 'echarts-for-react'
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {MqttStates} from "../../../core/mqtt/states"

import {useChartRequest} from "../../../core/v/useChartRequest"
import EChartsOptionBuilder from "../../../core/v/echartsOptionBuilder"

import _ from 'lodash'
import {Result} from "antd"

export default ({mqtt, request, chart, colors,
                    dateFormat = 'HH:mm', builder='timeSequenceLines', height = '360px'}) => {

    const {sendRequest, response, state} = useChartRequest(mqtt, request)

    const wrappedResponse = {
        loading: !response,
        error: state === MqttStates.ERROR ? 'mqtt error' : null,
        data: response
    }

    useEffect(() => {

        if (!chart.refreshInterval) {
            return
        }

        const interval = setInterval(() => {
            const startTime = Math.ceil(new Date().getTime() / 1000) - 1740
            for (let metric of request.metrics) {
                metric.startTime = startTime
                metric.endTime = startTime + 1740
            }

            sendRequest(request)
        }, chart.refreshInterval * 1000);

        return () => {
            clearInterval(interval);
        }
    }, [chart.refreshInterval, request.requestId]);

    return <LoadingResponseWrapper
        {...wrappedResponse}
        refresh={() => {
            // publish()
            sendRequest(request)
        }}
        renderContent={({metricSet}) => {

            const optionBuilder = EChartsOptionBuilder[builder]
            if (!optionBuilder) {
                return <Result status="error" title={'Builder Not Found:' + builder}/>
            }

            const values = _.get(metricSet, chart.source)
            if (!values) {
                return <Result status="error" title={'NO DATA'}/>
            }

            const chartOption = optionBuilder({chart, metricSet, colors, dateFormat})
            return <ReactEcharts
                style={{height, width: '100%'}}
                option={chartOption}
            />

        }}
    />
}


import React, {useCallback, useEffect, useState} from "react"
import {Table, Button, DatePicker, Row, Col} from "antd"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import { FormatMoney } from "format-money-js"
import moment from 'moment'
import { useActionExecutor } from "../../core/actionExecuter"
const fm = new FormatMoney();
export default () => {
    const statusColor = [
        'blue', 'blue', 'red', 'green', 'gray', 'gray', 'gray', 'gray'
    ]
    const [, actionResult, actionExecutor] = useActionExecutor()
    let query = useRouteQuery()
    console.warn('query', query);
    return <div>
        <PageHeaderWrapper/>
        <DisplayPage
            component={'TableDisplay'}
            props={{...query, actionResult, actionExecutor, pagerDisabled:true, 
                uri: '/approval/getPayingList'}}
            />
    </div>
}
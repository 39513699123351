import React, {useContext, useReducer} from 'react'
import {Input, Button, Form, Menu} from 'antd'

import { UserOutlined, NumberOutlined } from '@ant-design/icons';

import './signin.css'
import AdminContext from "../../core/AdminContext"
import {useHttpApi, useIsInLark} from "../../core/hooks"
import {BrowserRouter as Router, Link} from "react-router-dom";
import FeishuButton from '../../components/FeishuButton'

function mergeValues(lastValues, changedValues) {
    return {
        ...lastValues,
        ...changedValues
    }
}

export default () => {
    
    const {account, setAccount, forceLogout} = useContext(AdminContext)

    const [formValues, updateFormValues] = useReducer(mergeValues, {
        email: account ? account.account : undefined
    })

    const [accountValidator, updateAccountValidator] = useReducer(mergeValues, {
        help: '',
        hasFeedback: false,
        validateStatus: null
    })

    const [codeValidator, updateCodeValidator] = useReducer(mergeValues, {
        help: '',
        hasFeedback: false,
        validateStatus: null
    })

    const isInLark = useIsInLark();


    const [codeResponse, sendCodeRequest] = useHttpApi('/account/requestVerifyCode', {
        method: 'POST',
        onSuccess: () => {
            updateAccountValidator({validateStatus: 'success', help: '', hasFeedback: true})
        },
        onError: ({desc}) => {
            updateAccountValidator({validateStatus: 'error', help: desc, hasFeedback: true})
        }
    })
    const [verifyResponse, sendVerifyRequest] = useHttpApi('/account/verifyEmail',
        {
            method: 'POST',
            onSuccess: setAccount,
            onError: ({desc}) => {
                updateCodeValidator({validateStatus: 'error', help: desc, hasFeedback: true})
            }
        })

    const handleSubmit = (e) => {
        e && e.preventDefault && e.preventDefault()

        if (!codeResponse.success) {
            sendCodeRequest(formValues)
            return
        }
        sendVerifyRequest(formValues)
    }

    return <div className="login-container-wrapper">
        <div className="login-container">
            <Form onFinish={handleSubmit} className={"login-form"}>
                <div className={"login-logo"}/>
                <Form.Item required={true} {...accountValidator}>
                    <Input
                        onChange={({target: {value}}) => {
                            updateFormValues({email: value})
                            updateAccountValidator({validateStatus: null, help: ''})
                        }}
                        value={formValues.email}
                        disabled={codeResponse.loading || codeResponse.success}
                        prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder="@qingping.co"
                        onPressEnter={handleSubmit}
                        allowClear={true}
                    />
                </Form.Item>

                {codeResponse.success && <Form.Item required={true} {...codeValidator}>
                    <Input
                        onChange={({target: {value}}) => {
                            updateFormValues({code: value})
                            updateCodeValidator({validateStatus: null, help: ''})
                        }}
                        value={formValues.code}
                        disabled={verifyResponse.loading}
                        prefix={<NumberOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                        placeholder="验证码"
                        onPressEnter={handleSubmit}
                        allowClear={true}
                    />
                </Form.Item>}
                <Form.Item>
                    <Button
                        disabled={!formValues.email || !/.+@[^@]+\.[^@]*[^.]$/.test(formValues.email) || (codeResponse.success && !formValues.code)}
                        loading={verifyResponse.loading || codeResponse.loading}
                        type="primary" htmlType="submit" className={"login-form-button"}>
                        {codeResponse.success ? '登录' : '获取验证码'}
                    </Button>
                </Form.Item>
            </Form>
            <span style={{width: '100%', textAlign: 'center', display: 'block'}}>或</span>
            <FeishuButton redirect={true} redirectTo={encodeURIComponent(window.location.href)}autoClick={!forceLogout && isInLark} style={{width: '100%'}} />
        </div>
        <div className='copyright'>
            <span>© 2020 青萍科技</span> &nbsp;&nbsp;
            <a href='http://www.beian.miit.gov.cn' target="_blank">粤ICP备19156805号</a>
        </div>
    </div>
}

import mqtt from 'mqtt'

import {uniqIdFromAccess} from "./identityBuilder"
import {shareMqttClients, shareMqttStates} from './globals'
import {attachSharedListeners} from "./attachSharedListeners"
import {addSubscriber, hasSubscribers, removeSubscriber} from "./subscriberManager"
import {MqttStates} from "./states"

export const createOrJoinMqtt = (access, mqttClientRef, optionsRef, setMqttState, setLastMessage) => {

    const identity = uniqIdFromAccess(access)
    const {brokerUrl, options, subTopic} = access

    if (shareMqttClients[identity] === undefined) {
        console.log(identity + ':setup mqtt client', access)
        const client = mqtt.connect(brokerUrl, options)

        shareMqttStates[identity] = MqttStates.CONNECTING
        setMqttState(MqttStates.CONNECTING)
        attachSharedListeners(client, identity, subTopic, optionsRef?.current?.parseMessageJson ?? true)
        shareMqttClients[identity] = client
    } else {
        console.log(identity + ':use exists mqtt client', 'mqtt state', shareMqttStates[identity])
        setMqttState(shareMqttStates[identity])
    }

    const subscriber = {
        setLastMessage,
        setMqttState,
        optionsRef
    }

    addSubscriber(identity, subscriber)
    mqttClientRef.current = shareMqttClients[identity]

    return () => {
        removeSubscriber(identity, subscriber)
        if (!hasSubscribers(identity)) {
            console.log(identity + ':no subscriptions, end connection')
            shareMqttClients[identity].end()
            delete shareMqttClients[identity]
        }
    }

}

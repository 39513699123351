const subscribers = {}

function getSubscribers(key) {
    return Array.from(subscribers[key])
}

function hasSubscribers(key) {
    return subscribers[key].size > 0
}

function addSubscriber(key, subscriber) {
    if (subscribers[key] === undefined) {
        subscribers[key] = new Set()
    }
    subscribers[key].add(subscriber)
}

function removeSubscriber(key, subscriber) {
    subscribers[key] && subscribers[key].delete(subscriber)
}

export {
    getSubscribers,
    hasSubscribers,
    addSubscriber,
    removeSubscriber
}

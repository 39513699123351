import React, {useEffect, useState} from "react"
import {useHttpApi, useLocalStoredObject} from "../../core/hooks"
import {Spin, Table, Result, Button, Card, Descriptions} from "antd"

import {renderActions, renderItemFieldValue, renderBatchItemActions} from "../../core/domUtil"
import {useActionExecutor} from "../../core/actionExecuter"
import {DndProvider} from "react-dnd"
import Backend from 'react-dnd-html5-backend'
import DragAndDroppableTableRow from "./DragAndDroppableTableRow"
import {conditionsMatched} from "../../core/conditionsMatcher"

const ColumnWrapper = ({columnProps, record}) => {
    return renderItemFieldValue(columnProps, record)
}

function batchActionIds(actions) {

    if (!actions || actions.length === 0) {
        return []
    }

    const ids = []
    for (let a of actions) {
        if (a.batchAllowed) {
            ids.push(a.id)
        }
    }

    return ids
}

export default props => {
    const {uri, query, localStoreKey, onListStateUpdate, onPageChange, fixedState,
        search, pager = {page: 1, pageSize: 20}, sortBy, pageFromUrl=true, pagiabled=true, actionable=false} = props
    const [internalPager, setPager] = useState(pager)
    useEffect(() => {
        if (pageFromUrl) {
            setPager(pager)
        }
    }, [pager.page, pager.pageSize, pageFromUrl, setPager])
    const [itemsResponse, requestItems] = useHttpApi(uri, {
        method: 'GET',
        onSuccess: (data) => {
            onListStateUpdate && onListStateUpdate({itemsResponse: data, actionExecutor})
        }
    })

    const [actionExecuting, actionResult, actionExecutor] = useActionExecutor()
    // const [sorter, setSorter]
    // const [columnWidths, setColumnWidths] = useState({})
    const {loading, error, data = {items: [], displayFields: []}} = itemsResponse
    const {items, displayFields, count, sortAction} = data
    const [expandedRowKeys, setExpandedRowKeys] = useLocalStoredObject(localStoreKey + '_expanded_keys', [])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])


    useEffect(() => {
        requestItems({
            ...query,
            ...search,
            page: internalPager.page,
            page_size: pagiabled?internalPager.pageSize:999,
            _sorts_: sortBy
        })

        setSelectedRowKeys([])
    }, [search, internalPager.page, internalPager.pageSize, sortBy, actionResult, requestItems, query])

    if (displayFields.length === 0) {
        if (loading) {
            return <div className="full-content-center">
                <Spin/>
            </div>
        }

        if (error) {
            return <div className="full-content-center">
                <Result
                    status="error"
                    title={error.desc}
                    extra={
                        <Button type="primary" key="console" onClick={() => {
                            requestItems({
                                ...query,
                                ...search,
                                page: internalPager.page,
                                page_size: internalPager.pageSize,
                                _sorts_: sortBy
                            })
                        }}>
                            重试
                        </Button>
                    }
                /></div>
        }
    }


    const tableProps = {
        size: 'middle',
        onChange: ({page, pageSize}, f, sorter) => {
            console.log("another onchange", page, pageSize)

            const sorts = Array.isArray(sorter) ? sorter : [sorter]
            let sortByParts = []
            for (let {field, order} of sorts) {
                if (field && order) {
                    sortByParts.push(field + ':' + (order === 'ascend' ? 'asc' : 'desc'))
                }
            }
            onPageChange && onPageChange({
                pageSize,
                sort: sortByParts.length > 0 ? sortByParts.join(',') : '_',
            })
            if (!pageFromUrl) {
                setPager({
                    page: 1, pageSize
                })
            }
        },
        onRow: ({id, _actions_, title}) => {
            const onRowWrapper = {
                // onDoubleClick: () => {
                //     if (!_actions_) {
                //         return
                //     }
                //     for (let {type, uri} of _actions_) {
                //         if (type === 5) {
                //             history.push(uri, {title})
                //             break
                //         }
                //     }

                // }
            }

            if (sortAction) {
                onRowWrapper.itemId = id;
                onRowWrapper.moveRow = (fromId, toId) => {
                    if (sortAction) {
                        sortAction.params = {
                            ...sortAction.params,
                            from_id: fromId,
                            to_id: toId,
                            position: -1
                        }
                        actionExecutor.performAction(sortAction)
                    }
                }
            }

            return onRowWrapper
        }
    }

    // if (count > pager.page_size) {
    tableProps.pagination = pagiabled ? {
        total: count,
        current: internalPager.page,
        pageSize: internalPager.pageSize,
        showTotal: (total) => {
            return '共 ' + total
        },
        size: 'small',
        showSizeChanger: true,
        onChange: (page, pageSize) => {
            console.log('page++++++++',page,pageSize,pageFromUrl);

            onPageChange && onPageChange({page,pageSize})
            if (!pageFromUrl) {
                setPager({
                    page, pageSize
                })
            }
        }
    }: false
    // }

    if (sortAction) {
        tableProps.components = {
            body: {
                row: DragAndDroppableTableRow
            }
        }
    }

    const expandedFields = displayFields.filter(({y}) => y > 0)
    if (expandedFields.length) {
        tableProps.expandable = {
            expandedRowRender: (item) => <Descriptions size={'small'}>
                {expandedFields.map((field) => {
                    const {key, title} = field
                    return <Descriptions.Item label={title} key={key}>
                        {renderItemFieldValue(field, item)}
                    </Descriptions.Item>
                })}
            </Descriptions>,
            rowExpandable: () => true,
        }

        tableProps.expandedRowKeys = expandedRowKeys
        tableProps.onExpandedRowsChange = setExpandedRowKeys
    }

    let hasSingleItemAction = false
    const batchAllowedItemsGroup = {}
    const itemBatchActionMap = {}
    if (items.length) {
        for (let item of items) {
            if (item._actions_ && item._actions_.length > 0) {
                for (let a of item._actions_) {
                    if (a.batchAllowed) {
                        if (batchAllowedItemsGroup[a.id]) {
                            batchAllowedItemsGroup[a.id].push(item.id)
                        } else {
                            batchAllowedItemsGroup[a.id] = [item.id]
                        }
                        itemBatchActionMap[a.id] = a
                    } else {
                        hasSingleItemAction = true
                    }
                }
            }
        }
    }

    const commonBatchActions = []
    if (actionable && Object.keys(batchAllowedItemsGroup).length > 0) {

        let hideDefaultSelections = true
        const commonActionIds = []

        if (selectedRowKeys.length > 0) {
            for (let actionId in batchAllowedItemsGroup) {
                const groupItemKeys = batchAllowedItemsGroup[actionId]
                let isCommonAction = true
                for (const selectedRowKey of selectedRowKeys) {
                    if (groupItemKeys.indexOf(selectedRowKey) < 0) {
                        isCommonAction = false
                        break
                    }
                }
                if (isCommonAction) {
                    commonActionIds.push(parseInt(actionId, 10))
                    commonBatchActions.push(itemBatchActionMap[actionId])

                    if (groupItemKeys.length === items.length) {
                        hideDefaultSelections = false
                    }

                }
            }

        } else {
            for (let actionId in batchAllowedItemsGroup) {
                if (batchAllowedItemsGroup[actionId].length === items.length) {
                    hideDefaultSelections = false
                    break
                }
            }
        }

        tableProps.rowSelection = {
            hideDefaultSelections,
            selectedRowKeys,
            onChange: setSelectedRowKeys,
            getCheckboxProps: (item) => {
                const batchIds = batchActionIds(item._actions_)
                if (selectedRowKeys.length === 0) {
                    return {
                        disabled: batchIds.length === 0
                    }
                }

                let disabled = true
                for (let cId of commonActionIds) {
                    if (batchIds.indexOf(cId) >= 0) {
                        disabled = false
                        break
                    }
                }

                return {
                    disabled
                }
            },

        }
    }
    let spanSUM = 0;
    displayFields.filter(({y}) => (y === 0)).map((displayField, columnIdx)=>{
        const {span} = displayField;
        spanSUM += span;
    })

    const table = <Table
        loading={actionExecuting || loading}
        dataSource={items}
        rowKey={({id}) => id}
        {...tableProps}>
        {displayFields.filter(({y, show_condition = []}) => (y === 0 && conditionsMatched(show_condition, query))).map((displayField, columnIdx) => {
            const {key, title, sortable,span} = displayField
            const sortProps = {sorter: false}
            if (sortable) {
                sortProps.sorter = {
                    multiple: columnIdx
                }
            }
            const functionPCT = hasSingleItemAction ?88 : 100;
            return <Table.Column dataIndex={key} title={title} key={columnIdx} width={span>=2?span/(spanSUM)*functionPCT + '%':null}
                                 {...sortProps}
                                 render={(cellVal, record) => {

                                     return <ColumnWrapper columnProps={displayField} record={record}/>
                                 }}/>

        })}

        {actionable && hasSingleItemAction && <Table.Column dataIndex={'_actions_'} title={'操作'} fixed={'right'}
                                              render={(actions) => {
                                                  return renderActions((actions && actions.filter((a) => !a.batchAllowed)) || [], actionExecutor, fixedState, 3)
                                              }}
        />}

    </Table>

    return <div>

        {commonBatchActions.length > 0 && selectedRowKeys.length > 0
        && <div className="table-operations">{renderBatchItemActions(commonBatchActions,
            actionExecutor, items.filter((item) => {
                return selectedRowKeys.indexOf(item.id) >= 0
            }))}</div>
        }

        {!!sortAction && <DndProvider backend={Backend}>
            {table}
        </DndProvider>}
        {!sortAction && table}


    </div>
}

import React, {useEffect, useState} from "react"

import LoadingResponseWrapper from "../../LoadingResponseWrapper"


import {Table, Typography} from 'antd'
import {useHttpApi, useHttpGet, useRouteQuery} from "../../../core/hooks"
import {useActionExecutor} from "../../../core/actionExecuter"
import TableDisplay from "../TableDisplay"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import DisplayComponent from "../Display"

const {Text} = Typography;

export default (props) => {

    const {valueSet, rows, valueSetUpdater} = props
    const {keep} = useRouteQuery()
    const [applyId, setApplyId] = useState(valueSet.id || 0)
    
    const [purchaseItemsResponse, requestPurchaseItems] = useHttpGet('/production/purchaseItems')
    const [, queryPlan] = useHttpApi('/production/queryPlanInfo', {
        'method': 'GET',
        onSuccess: (plan) => {
            valueSetUpdater && valueSetUpdater({
                project_id: plan.project_id
            })
        }
    })

    const [, prepareApply] = useHttpApi('/production/preparePurchaseApply', {
        method: 'POST',
        onSuccess: (apply) => {
            setApplyId(apply.id)
            valueSetUpdater && valueSetUpdater(apply)
        }
    })
    const [summary, setSummary] = useState(null)

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        if (applyId === 0) {
            prepareApply({
                keep: keep || 0,
                copy_apply_id: valueSet.copy_apply_id||0,
            })
        }
        applyId > 0 && requestPurchaseItems({apply_id: applyId})
    }, [applyId, actionResult, prepareApply, requestPurchaseItems])

    const planId = valueSet?valueSet.plan_id:0;
    useEffect(() => {
        if (planId) {
            queryPlan({id: planId})
        }
    }, [planId])

    return <div>
        <div className="ant-col ant-form-item-label"><label>采购明细</label></div>
        <LoadingResponseWrapper
            {...purchaseItemsResponse}
            refresh={() => {
                applyId > 0 && requestPurchaseItems({applyId})
            }}
            renderContent={({itemDisplayProps}) => {

                return <div>
                    <TableDisplay
                        {...itemDisplayProps.props}
                        onListStateUpdate={({itemsResponse}) => {

                            const {items} = itemsResponse


                            const s = items.reduce((carry, item) => {
                                if (carry.date.localeCompare(item.expect_lead_time) < 0) {
                                    carry.date = item.expect_lead_time
                                }
                                carry.amount += parseFloat(item.price_amount)

                                return carry
                            }, {date: '0', amount: 0})
                            s.amount = s.amount.toFixed(2)
                            setSummary(s)

                        }}
                    />

                    {summary && summary.amount > 0 && <div style={{marginTop:'20px',marginBottom:'10px', lineHeight:'28px'}}>
                        <div><b>金额总计</b>&nbsp;<b style={{color: 'red',fontSize:'16px'}}>{renderItemFieldValue({
                            key: 'amount',
                            style: 'currency',
                            currency: 'cny'
                        }, summary)}</b></div>
                        <div><b>最晚到料时间：</b>&nbsp;{summary.date}</div>
                    </div>}
                </div>

            }}
        />
    </div>

}

import { Button, Form, InputNumber, Table } from "antd"
import React, {useCallback, useEffect, useState} from "react"
import moment from 'moment'
import { FormatMoney } from "format-money-js"
import {useHttpGet} from "../../../core/hooks"
import { renderItemFieldValue } from "../../../core/domUtil"
import Modal from "antd/lib/modal/Modal"
import TableDisplay from "../TableDisplay"
import TemplateSelectorModal from "../../df/TemplateSelectorModal"

export default (props) => {
    const { props: {key, label}, valueSet, rows, onChange, valueSetUpdater} = props
    const { id, supplier_id, order_type} = valueSet

    const [itemCounts, setItems] = useState({})
    const [selectedLogIds, setSelectedLogs] = useState([])
    const [selectorVisible, setSelectorVisible] = useState(false);

    const [itemsResponse, getItems, clear] = useHttpGet('/production/getStatementItems');

    useEffect(() => {
        setItems({})
        clear()
        if (id) {
            getItems({ id, order_type})
        }
    }, [id])

    useEffect(() => {
        setItems({})
        clear()
    }, [supplier_id])

    const {loading, error, data: items = []} = itemsResponse


    useEffect(() => {
        const logToQuantity = {};
        for (let item of items) {
            logToQuantity[item.instock_task_log_id] = item.quantity
        }
        const responseLogs = Object.keys(logToQuantity)
        setSelectedLogs(responseLogs)
        setItems(logToQuantity)
    }, [itemsResponse])

    const moneier = new FormatMoney()
    const columns = [
        {
            title: '交货日期',
            key: 'date_created',
            render: (v) => v?moment(v*1000).format('YYYY-MM-DD'):''
        },{
            title: '入库单号',
            key: 'task_id_code',
        },{
            title: '订单编号',
            key: 'order_id_code',
        },
        order_type == 3?{
            title: '项目和版本',
            key: 'project_code',
        }:{
            title: '品类',
            key: 'category',
        },
        {
            title: '物料编号/品名',
            key: 'name',
        },{
            title: '可结算数量',
            key: 'processed_quantity',
            style: 'count',
        },{
            title: '结算数量',
            key: 'quantity',
            render: (value, record, index) => {
                if (index >= items.length) return value
                return <InputNumber 
                    defaultValue={parseInt(value)}
                    max={record.processed_quantity < 0 ? 0 : record.processed_quantity}
                    min={record.processed_quantity < 0 ? record.processed_quantity : 0}
                    value={itemCounts[record.instock_task_log_id]||record.quantity}
                    onChange={(v) => {
                        let itemId = record.instock_task_log_id
                        setItems({
                            ...itemCounts,
                            [itemId]: parseInt(v) || 0,
                        })
                    }}
                ></InputNumber>
            },
        },{
            title: '单价',
            key: 'price',
            style: 'currency',
            extra_props: { currency: ':currency' },
        },{
            title: '结算金额',
            key: 'statement_price',
            style: 'currency',
            extra_props: { currency: ':currency'},
        },
    ]
    const currency = items && items.length ? (items[0].currency||1) : 1
    console.log('items', items)
    items.forEach((item, index) => {
        item.statement_quantity = parseInt(itemCounts[item.instock_task_log_id]) || parseInt(item.quantity) || 0
        item.statement_price = ((item.price * item.statement_quantity)||0).toFixed(4)
    })
    let totalPrice = Math.ceil(parseFloat(items.reduce((p, c) => {
        return p + parseFloat(c.statement_price)
    }, 0)) * 100) / 100
    useEffect(() => {
        let data = items.map(item => {
            let rowData = {
                id: item.id || 0,
                instock_task_log_id: item.instock_task_log_id,
                quantity: itemCounts[item.instock_task_log_id] || item.quantity || 0,
            }
            if (item['sku_id']) {
                rowData.item_key = 'sku_id'
            } else {
                rowData.item_key = 'material_id'
            } /// required
            const checkHasKeys = ['purchase_order_id', 'manufacture_order_id', rowData.item_key];
            return checkHasKeys.reduce((p, key, ) => {
                if (item[key]) {
                    p[key] = item[key]
                }
                return p;
            }, rowData);
        })

        valueSetUpdater && valueSetUpdater({
            ...valueSet,
            statement_total_price: totalPrice,
            pay_currency: currency,
            [key]: JSON.stringify(data),
        })
    }, [totalPrice, itemCounts])
    console.log('selectedLogIds render', selectedLogIds)
    return <div>
        <Button disabled={!supplier_id} style={{marginBottom:15}} onClick={() => {
            setSelectorVisible(
                order_type === '1' ? { // 采购单
                    task_type: '31,10'
                } : order_type === '3' ? { // 生产订单
                    task_type: '32,12'
                } : {}
            )
        }}>选择入库记录</Button>
        <Table 
            pagination={false}
            loading={loading}
            size={'small'}
            dataSource={!items.length ? items : [...items, { quantity: '合计', 'currency': currency, statement_price: totalPrice}]}
            rowKey={({id}, index) => index}>
            {columns.map((field) => {
                const {title, key, render} = field
                return <Table.Column title={title} key={'col_' + key} dataIndex={key} render={
                    render? render:(value, record) => {
                    return renderItemFieldValue(field, record)
                }}>
                </Table.Column>
            })}
        </Table>
        <TemplateSelectorModal
            key='task_selector'
            visible={!!selectorVisible}
            uri={'/inventory/supplierTaskLogsForStatement'}
            moreParams={{
                statement_id: id||0,
                supplier_id,
                statement_order_type: order_type,
                ...(selectorVisible||{})
            }}
            onCancel={() => { setSelectorVisible(false) }}
            selector='checkbox'
            componentProps={{pagerDisabled : true}}
            onSelect={(keys, rows) => {
                setSelectorVisible(false)
                if (keys.length && keys.join(',') !== selectedLogIds.join(',')) {
                    clear()
                    getItems({
                        id: id||0,
                        task_logs: keys,
                        order_type,
                    })
                }
                if (!keys.length) {
                    clear()
                }
                setSelectedLogs(keys.sort())
            }}
            selectedIds={selectedLogIds}
            title='请选择入库记录' />
    </div>
}
import IotDeviceItemProfilePage from "./IotDeviceItemProfilePage";
import OrderEditPage from "./OrderEditPage";
import OrderViewPage from "./OrderViewPage";
import DashboardPage from "./DashboardPage";
import FinanceDiaryPage from "./finance/FinanceDiaryPage";
import EETReport from "./EETReport";
import GoodsStockOut from "./GoodsStockOut";
import GoodsStockIn from "./GoodsStockIn";
import PayloadParser from "./PayloadParser";
import FlowEditPage from "./FlowEditPage";
import GoodsSaleForecastMonthly from "./GoodsSaleForecastMonthly";
import FeedbackPage from "../home/FeedbackPage";
import ApplyListPage from "./ApplyListPage";
import ApplyDetailPage from "./ApplyDetailPage";
import PayingDisplayPage from "./PayingDisplayPage";
import MemberLeaveDaysPage from "./MemberLeaveDaysPage";
import ProductionPlanAnalysis from "./ProductionPlanAnalysis";
import TmallActivityPage from "./TmallActivityPage";
import PaysAndTypesPage from "./PaysAndTypesPage";
import MaterialOrderPrint from "./MaterialOrderPrint";
import DeviceWatcher from "./DeviceWatcher";
import ExchangeRatePage from "./ExchangeRatePage";
import OperatorTools from "./OperatorTools";

const pages = [
    //
    { component: DashboardPage, path: "/dashboard" },
    { component: OrderEditPage, path: "/sale/order/create" },
    { component: OrderViewPage, path: "/sale/order/:id" },
    { component: OrderEditPage, path: "/sale/order/:id/edit" },
    // 物联页面
    { component: IotDeviceItemProfilePage, path: "/iot/device/:id" },

    { component: EETReport, path: "/eet/report/:id" },

    // 资金流水
    { component: FinanceDiaryPage, path: "/finance/diary" },
    //库存管理
    { component: GoodsStockOut, path: "/goods/stock/out/:id" },
    { component: GoodsStockIn, path: "/goods/stock/in/:id" },
    { component: PayloadParser, path: "/tools/payload_parser" },
    { component: DeviceWatcher, path: "/tools/device_watcher" },
    { component: OperatorTools, path: "/tools/operator_tools" },
    { component: FlowEditPage, path: "/approval_flow/:id" },

    //GoodsSaleForecastMonthly
    { component: GoodsSaleForecastMonthly, path: "/sale/forecast/monthly" },
    // {component: GoodsSaleStatDaily, path: '/sale/stat/daily'},       // 改为了  UriMontylyTopDisplay
    // {component: GoodsSaleStatMonthly, path: '/sale/stat/monthly'},   // 改为了  UriTopTabbedDisplay
    { component: FeedbackPage, path: "/qp/feedbacks" },
    { component: ApplyListPage, path: "/my_apply_list" },
    { component: ApplyListPage, path: "/my_deal_list", props: {to_me: 'yes'}},
    { component: ApplyDetailPage, path: "/apply_detail/:type/:bizId" },
    { component: ApplyDetailPage, path: "/apply_detail/:id" },
    { component: PayingDisplayPage, path: "/money/payings/export" },

    { component: MemberLeaveDaysPage, path: "/oa/leave_days" },
    { component: TmallActivityPage, path: "/tmall_activity" },
    { component: ProductionPlanAnalysis, path: "/production/plan_prepare/:id" },

    { component: PaysAndTypesPage, path: "/money/pay_amounts" },
    { component: MaterialOrderPrint, path: "/material/order/print/:id" },

    { component: ExchangeRatePage, path: "/finance/exchange_rate" },
];

export { pages };

import React, {useRef, useState} from "react"
import {useHttpAction} from "../../core/hooks"
import {Button, Card, Divider, Space} from "antd"
import {TemplateEditorInner} from "../../components/df/TemplateEditor"
import {buildUri} from "../../core/uriBuilder"

export default ({query, action, navigation, redirectUrl, templateLinkPath}) => {

    const ref = useRef(() => {
    })
    const [okText, setOkText] = useState(undefined)
    const [, setActionResult] = useState(null)
    const [{loading}, sendActionRequest] = useHttpAction(setActionResult)
    action.editor.defaults = { ...query, ...action.editor.defaults, }
    action.editor.fixed = {...action.editor.fixed}
    return <div>
        <Card bordered={false}>
            <TemplateEditorInner
                onSuccess={(data) => {
                    if (!navigation) {
                        return
                    }
                    if (data && data.redirect) {
                        navigation.replace(data.redirect)
                    } else if (data && data.follow_url) {
                        navigation.replace(data.follow_url)
                    } else if (templateLinkPath) {
                        navigation.replace(buildUri(templateLinkPath, data))
                    } else if (redirectUrl) {
                        console.log(redirectUrl)
                        navigation.replace(buildUri(redirectUrl, data))
                    } else {
                        navigation.goBack()
                    }
                }}
                onCancel={() => {
                    navigation && navigation.goBack()
                }}
                onPropsReady={({okText, submitFunction}) => {
                    if (okText) {
                        setOkText(okText)
                    }
                    if (submitFunction) {
                        ref.current = submitFunction
                    }
                }}
                sendActionRequest={sendActionRequest}
                action={action}/>
            {okText && <Space>
                <Button disabled={loading} size='middle' type='primary' onClick={() => {
                    ref.current(action)
                }}>{okText}</Button>
                <Divider/>
                <Button disabled={loading} size='middle' onClick={() => {
                    navigation && navigation.goBack()
                }}>取消</Button>
            </Space>}
        </Card>
    </div>

}

import React, { useCallback, useEffect, useState } from "react";
import {
    Col,
    Form,
    Input,
    Result,
    Row,
    Table,
    Typography,
    Select,
    DatePicker,
} from "antd";
import { useHttpGet, useRouteQuery } from "../../../core/hooks";
import { useActionExecutor } from "../../../core/actionExecuter";
import LoadingResponseWrapper from "../../LoadingResponseWrapper";
import { renderActions, renderItemFieldValue } from "../../../core/domUtil";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/skeleton/Title";
import moment from "moment";
import { useHistory } from "react-router";
export default ({ uri, params, title }) => {
    let routeQuery = useRouteQuery();
    const [, , actionExecutor] = useActionExecutor();
    const [tongjiResponse, getTongji] = useHttpGet(uri);
    const refreshPage = useCallback(() => {
        getTongji(params);
    }, [uri, getTongji, params]);

    const history = useHistory();

    useEffect(() => {
        refreshPage();
    }, [refreshPage]);

    const renderTableColumn = (column, index, upperFix = false) => {
        const { title, children, key, fixed } = column;

        if (children && children.length) {
            return (
                <Table.ColumnGroup
                    key={index}
                    title={title}
                    fixed={fixed || undefined}
                >
                    {children.map((item, iindex) => {
                        return renderTableColumn(
                            item,
                            iindex,
                            fixed || upperFix || false
                        );
                    })}
                </Table.ColumnGroup>
            );
        }
        return (
            <Table.Column
                key={key}
                title={title}
                dataIndex={key}
                fixed={
                    fixed
                        ? fixed
                        : upperFix
                        ? upperFix
                        : index === 0
                        ? "left"
                        : undefined
                }
                width={
                    !key
                        ? undefined
                        : key.endsWith("total")
                        ? 120
                        : key.startsWith("sale_amount_")
                        ? 70
                        : 100
                }
                className={
                    "" //key.startsWith('sale_money_') >= 0 ? 'forecastColumnStyle' : ''
                }
                render={(value, saleRow, index) => {
                    if (!saleRow) return null;
                    if (
                        saleRow.rowSpanColumnKey === key &&
                        (saleRow.rowSpan === 0 || saleRow.rowSpan)
                    ) {
                        return {
                            children: value,
                            props: {
                                rowSpan: saleRow.rowSpan,
                            },
                        };
                    } else {
                        return renderItemFieldValue(column, saleRow, true);
                    }
                }}
            ></Table.Column>
        );
    };

    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    return (
        <LoadingResponseWrapper
            {...tongjiResponse}
            refresh={refreshPage}
            renderContent={({
                columns,
                items = [],
                month,
                actions: topActions,
                editable: actionAllowed = false,
            }) => {
                if (!columns) {
                    return (
                        <Result status="error" title={"项目下未配置商品信息"} />
                    );
                }
                let [m1, m2] = month.split(",").map(month => {
                    const m = moment(month + "01");
                    return m;
                });
                return (
                    <div ref={elementRef} style={{ margin: 20 }}>
                        {topActions && topActions.length > 0 && (
                            <div
                                className="action-search-bar"
                                style={{ marginBottom: "10px" }}
                            >
                                {renderActions(topActions, actionExecutor, {})}
                            </div>
                        )}
                        <Row style={{ marginLeft: 30 }} gutter={(16, 16)}>
                            <Col span={4}>{title ? <h2>{title}</h2> : ""}</Col>
                            <Col span={8}>
                                <DatePicker.RangePicker
                                    picker="month"
                                    value={[m1, m2]}
                                    onChange={ms => {
                                        if (!ms) return;
                                        let month = ms
                                            .map(m => m.format("YYYYMM"))
                                            .join(",");
                                        history.replace(
                                            history.location.pathname +
                                                "?" +
                                                new URLSearchParams({
                                                    ...routeQuery,
                                                    month,
                                                }).toString()
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <Table
                            dataSource={items}
                            size={"small"}
                            bordered
                            scroll={{ x: tableWidth }}
                            rowClassName={record => {
                                if (record?.type === "total_summary") {
                                    return "totalSummaryCol";
                                } else if (record?.type === "type_summary") {
                                    return "typeSummaryCol";
                                }
                            }}
                            expandable={{ defaultExpandAllRows: true }}
                            pagination={false}
                        >
                            {columns.map(renderTableColumn)}
                        </Table>
                    </div>
                );
            }}
        />
    );
};

import React, { useState } from "react";
import { Form, Radio, Button, message, Upload } from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import FileRefUploader from "../../../../../components/FileRefUploader";
import "./index.css";

const PaymentForm = ({ startUploadFunc, setStartUploadFunc }) => {
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const [fileRef, setFileRef] = useState(undefined);

  return (
    <div className="site-item-container">
      <div className="align-center">
        <div className="item-box-title">支付方式</div>
      </div>

      <Form.Item noStyle name="pay_method" required>
        <div className="invoice-layout">
          <Radio.Group
            style={{ width: "100%" }}
            onChange={handleChange}
            value={paymentMethod}
          >
            <Radio value="payment">先支付后发货</Radio>
            <Radio value="postpay">先发货后付款</Radio>
          </Radio.Group>
        </div>
      </Form.Item>

      {paymentMethod === "payment" && (
        <div className="payment">
          <strong>对公转账</strong>
          <Form.Item label="开户银行">
            招商银行股份有限公司北京太阳宫支行
          </Form.Item>
          <Form.Item label="银行账号">110917585810401</Form.Item>
          <Form.Item label="公司名称">青萍科技（北京）有限公司</Form.Item>
          <strong>支付宝转账</strong>
          <Form.Item label="支付宝账号">pay@qingping.co</Form.Item>
          {/* <div>
            <strong>上传付款凭证</strong>
          </div>
          <br /> */}
          {/* <FileRefUploader
            ref_token={fileRef}
            category="feedback-files/reply"
            onChange={({ startUpload, file }) => {
              // console.log("files", file);
              if (file.status === "done" && file.ref_token) {
                setFileRef(file.ref_token);
              }
              if (startUpload) {
                // console.log('upload img start');
                setStartUploadFunc(() => startUpload);
              }
            }}
          /> */}
        </div>
      )}
    </div>
  );
};

export default PaymentForm;

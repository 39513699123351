import React, {  useEffect,  useState, useMemo, useCallback, useRef } from "react"
import { useRouteQuery, useHttpGet, } from "../../../core/hooks"

import { useLocation, useHistory } from 'react-router-dom'
import { Spin, Table, Result, Button, Card, Descriptions, List, PageHeader, Form, Cascader, Row, DatePicker, Col, Select, InputNumber, Input, Upload, message, Modal, Alert, Divider, Radio } from "antd"

import moment from "moment"
import InputMoney from "../../../components/InputMoney"
import { UploadOutlined, ExclamationCircleOutlined, ReloadOutlined, AccountBookOutlined } from "@ant-design/icons"

import Column from "antd/lib/table/Column"

export default ({ }) => {
    const handlerRef = useRef()
    const [refreshResult, refreshDiary] = useHttpGet('/money/refreshBalance')
    const [makeDiaryResult, makeFinanceDiary] = useHttpGet('/money/makeFinanceDiary')
    const [accountDiary, getAccountDiary] = useHttpGet('/money/getAccountsInfo')
    const history = useHistory()
    const { pathname } = useLocation()
    const { day = 0 } = useRouteQuery()
    const [dayResponse, setDay] = useState(parseInt(day) || moment().startOf('day').unix() - 24 * 60* 60)
    useEffect(() => {
        if (parseInt(day)) {
            setDay(parseInt(day))
        } else {
            setDay(moment().startOf('day').unix() - 24 * 60* 60) // 昨天
        }
    }, [day])
    useEffect(() => {
        dayResponse && getAccountDiary({
            day: dayResponse
        })
        return () => {
            window.removeEventListener('message', loginOkToMakeFileCallback)
        }
    }, [dayResponse])
    useEffect(() => {
        if (!refreshResult.loading && refreshResult.success) {
            window.location.href = window.location.href
        }
    }, [refreshResult])
    const activeAccounts = accountDiary.data ? accountDiary.data.active_accounts : []
    const idleAccounts = accountDiary.data ? accountDiary.data.idle_accounts : []
    const feishuUrl = accountDiary.data ? accountDiary.data.feishu_url : undefined
    const feishuToken = accountDiary.data ? accountDiary.data.feishu_token : undefined
    let sums = {};
    let sumUsd = {};
    if (activeAccounts.length > 0) {
        sums = activeAccounts.reduce((result, current, ) => {
            if (!current.account_is_usd) {
                result.money_in_amount += parseFloat(current.money_in_amount)
                result.money_out_amount += parseFloat(current.money_out_amount)
                result.month_money_in_amount += parseFloat(current.month_money_in_amount)
                result.month_money_out_amount += parseFloat(current.month_money_out_amount)
                result.balance += parseFloat(current.balance)
            }
            return result;
        }, {
            balance: 0, money_in_amount: 0, money_out_amount: 0, 
            month_money_in_amount: 0, month_money_out_amount: 0, bank_account: <h3>合计：</h3>
        })
        sumUsd = activeAccounts.reduce((result, current, ) => {
            if (current.account_is_usd) {
                result.money_in_amount += parseFloat(current.money_in_amount)
                result.money_out_amount += parseFloat(current.money_out_amount)
                result.month_money_in_amount += parseFloat(current.month_money_in_amount)
                result.month_money_out_amount += parseFloat(current.month_money_out_amount)
                result.balance += parseFloat(current.balance)
            }
            return result;
        }, {
            account_is_usd: true, balance: 0, money_in_amount: 0, money_out_amount: 0, 
            month_money_in_amount: 0, month_money_out_amount: 0, bank_account: <h3>合计(美元)：</h3>
        })
    }
    useEffect(() => {
        console.log(makeDiaryResult)
        if (makeDiaryResult.success && makeDiaryResult.data.url) {
            Modal.success({
                title: "本日报告已生成",
                content: <a target='_blank' href={makeDiaryResult.data.url}>{makeDiaryResult.data.url}</a>,
                okText: '查看', 
                onOk:() => {
                    window.open(makeDiaryResult.data.url, '_blank'); 
                }
            })
        }
    }, [makeDiaryResult])

    const loginOkToMakeFileCallback = useCallback(({data}) => {
        console.warn("message:", data);
        let accessToken = data.access_token
        if (!accessToken && data.userInfo) {
            accessToken = data.userInfo.access_token
        }
        if (accessToken) {
            handlerRef.current && handlerRef.current.close()
            window.removeEventListener('message', loginOkToMakeFileCallback)
            makeFinanceDiary({
                access_token: accessToken,
                day: dayResponse
            })

        }
    }, [dayResponse])

    return <PageHeader title="财务日报">
        <Card>
            <Row gutter={[10, 10]}>
                <Col>
                    <DatePicker value={moment((dayResponse) * 1000)} onChange={(m) => {
                        history.replace(pathname + "?day=" + m.unix())
                    }} />
                </Col>
                <Col offset={12}>
                    <Button icon={<ReloadOutlined />} loading={refreshResult.loading} disabled={refreshResult.loading} onClick={() => {
                        refreshDiary({
                            day: dayResponse
                        })
                    }}>重新计算</Button>
                </Col>

                <Col>
                    <Button icon={<AccountBookOutlined />} loading={makeDiaryResult.loading} disabled={makeDiaryResult.loading}  onClick={() => {
                        if (feishuToken) {
                            makeFinanceDiary({
                                access_token: feishuToken,
                                day: dayResponse
                            })
                        } else {
                            // const redirect = window.location.protocol + "//" + window.location.host + "/feishu";
                            // const redirect = ""//"http://qingos.co/feishu";
                            // const feishuUrl = 'https://open.feishu.cn/open-apis/authen/v1/index?app_id={app_id}&redirect_uri={redirect_uri}&state={state}'
                            // const hendler = window.open(feishuUrl + redirect, '_blank', 'width=700, height=500, left=200, top=200');
                            // const feishuUrl = '/feishu?code=aSdPAyRRBogVpyPkm9IKrg'
                            const hendler = window.open(feishuUrl, '_blank', 'width=700, height=700, left=200, top=200'); 
                            handlerRef.current = hendler
                            window.addEventListener("message", loginOkToMakeFileCallback)
                        }
                    }}>生成日报表格</Button>
                </Col>
            </Row>
        </Card>
        <Card title="活跃账户情况">
            <Table loading={accountDiary.loading} dataSource={activeAccounts.length > 0 ? 
                [...activeAccounts, sums, sumUsd]: activeAccounts} pagination={false} bordered={true} rowKey={(v) => v.id}>
                <Column title="银行" dataIndex='bank_account'/>
                <Column title="余额" dataIndex='balance' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
                <Column title="今日收入" dataIndex='money_in_amount' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
                <Column title="今日支出" dataIndex='money_out_amount' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
                <Column title="本月收入" dataIndex='month_money_in_amount' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
                <Column title="本月支出" dataIndex='month_money_out_amount' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
                <Column title="公司" dataIndex='note' />
                <Column title="功能" dataIndex='functions' />
            </Table>
        </Card>
        <Card title="非活跃账户情况">
            <Table loading={accountDiary.loading} dataSource={idleAccounts} pagination={false} bordered={true} rowKey={(v) => v.id}>
                <Column title="公司" dataIndex='note' />
                <Column title="银行" dataIndex='bank_account' />
                <Column title="功能" dataIndex='functions' />
                <Column title="余额" dataIndex='balance' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />
            </Table>
        </Card>
        <InOutFlowList day={dayResponse} onDayChange={day => {
            setDay(parseInt(day))
        }} />
    </PageHeader>
}

const InOutFlowList = ({day, onDayChange}) => {
    const [focusedType, setFocusedType] = useState('in')
    const [cashflowResult, getCashFlowDiary] = useHttpGet('/money/getDiaryCashflow')
    useEffect(() => {
        getCashFlowDiary({day: day})
    }, [day])
    const cashflow = cashflowResult.data || {in: [], out: [], transfer: []};
    useEffect(() => {
        if (!cashflowResult.loading && cashflowResult.data && cashflowResult.data.day) {
            onDayChange && onDayChange(cashflowResult.data.day)
        }
    }, [cashflowResult])


    return <Card title={<Radio.Group 
        defaultValue="in" 
        value={focusedType} 
        buttonStyle="solid" 
        onChange={(event) => {
            setFocusedType(event.target.value)
        }}>
        <Radio.Button value="in">收入</Radio.Button>
        <Radio.Button value="out">支出</Radio.Button>
        <Radio.Button value="transfer">调拨</Radio.Button>
        {cashflow.none && <Radio.Button value="none">其它</Radio.Button>}
    </Radio.Group>}>
        <Table loading={cashflowResult.loading} pagination={false} dataSource={cashflow[focusedType]}  rowKey={(v) => "row" + v.id}>
            <Table.Column title={'发生时间'} dataIndex={'created_at'} key='columnkey_at' render={(v, r, i) => {
                return v ? moment(v * 1000).format('YYYY-MM-DD HH:mm') : null
            }} />
            <Table.Column title={'银行（标识）'} dataIndex={'bank_account'} key='columnkey_bank' render={(value, record) => {
                return value
            }} />
            {focusedType === 'out' && <Table.Column title={'一级分类'} dataIndex={'parent_type'} key='columnkey_p_type' />}
            <Table.Column title={focusedType !== 'out' ? '类型' : '二级分类'} dataIndex={'type'} key='columnkey_type' />
            <Table.Column title={'项目'} dataIndex={'project_code'} key='columnkey_project' />
            <Table.Column title={'数量'} dataIndex={'count'} key='columnkey_count' />
            {focusedType !== 'out' && <Table.Column title={'收入'} dataIndex={'money_in_amount'} key='columnkey_in' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />}
            {focusedType !== 'in' && <Table.Column title={'支出'} dataIndex={'money_out_amount'} key='columnkey_out' render={(v, record) => formatMoney(v, record.account_is_usd ? '$' : '¥')} />}
            <Table.Column title={'摘要'} width={"100px"} dataIndex={'summary'} key='columnkey_summary' />
        </Table>

    </Card>
}


function formatMoney(value, prefix = '￥') {
    if (!value) return "";
    if (typeof value == 'number') value = value.toFixed(2)
    return `${prefix}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
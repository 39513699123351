import React, { useContext, useEffect, useState, useMemo } from "react"
import { useHttpGet, useRouteQuery } from "../../core/hooks"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import { Card, Col, Row, Descriptions, List, Table, Spin, Empty, Statistic } from "antd"
import HistoryChart from "../../components/biz/HistoryChart"
import { useActionExecutor } from "../../core/actionExecuter"
import { renderActions, renderItemFieldValue } from "../../core/domUtil"
import { useRouteMatch } from "react-router-dom"

import TemplateDisplayTable from "../../components/df/TemplateDisplayTable"
import AdminContext from "../../core/AdminContext"
import moment from 'moment'

const KeyToInfo = {
    temperature: { name: '温度', unit: "℃" },
    humidity: { name: '湿度', unit: "%" },
    prob_temperature: { name: '外接温度', unit: "℃", mute: true },
    prob_humidity: { name: '外接湿度', unit: "%", mute: true },
    pressure: { name: '气压', unit: "kPa" },
    pm25: { name: 'PM2.5', unit: "μg/m3" },
    pm10: { name: 'PM10', unit: "μg/m3" },
    co2: { name: 'CO2', unit: "ppm" },
    co2_percent: { name: 'CO2', unit: "%" },
    tvoc: { name: 'TVOC', unit: "ppb" },
    tvoc_index: { name: 'VOC指数', unit: "指数" },
    noise: { name: '噪音', unit: "dB" },
    lumen: { name: '光照度', unit: "lux" },
    signal: { name: '信号', unit: "dB" },
    battery: { name: '电量', unit: "%" },
}
export default () => {
    const { pageStates } = useContext(AdminContext)
    let query = useRouteQuery()
    const { path, url } = useRouteMatch()
    let actionState = {}
    if (pageStates[path]) {
        const { fixedQuery, fixedState } = pageStates[path]
        query = {
            ...query,
            ...fixedQuery
        }
        actionState = fixedState
    }

    const [responseID, getInfoData] = useHttpGet('/iot/deviceInfoData')
    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        getInfoData(query)
    }, [actionResult, url])

    let { mac, model } = useMemo(() => {
        if (responseID.data) {
            return responseID.data.info || {mac: 'error', model: 'error'}
        }
        else return {}
    }, [responseID.loading, responseID.data])
    const headerProps = {
        title: '设备详情'
    }
    let headerItem = []
    if (responseID.data && responseID.data.info) {
        let { item: device, displayedItem } = responseID.data.profile.detail
        let info = {
            ...device,
            ...responseID.data.info,
            ...responseID.data.info.settings,
            settings: undefined
        }

        headerItem = ['mac', 'sn', ['deveui', 'imei'], 'model', 'version', 'boot_version'].map((key) => {
            if (Array.isArray(key)) {
                for (const inkey of key) {
                    if (info[inkey]) {
                        key = inkey;
                        break;
                    }
                }
                if (Array.isArray(key)) {
                    return <span />
                }
            }
            return <Descriptions.Item label={key.toUpperCase()} key={key}>
                <span>{info[key]}</span>
            </Descriptions.Item>
        });

        headerItem.push(
            <Descriptions.Item label={'状态'} key={'status'}>
                <span>{responseID.data.data.online ? "在线" : "离线"}</span>
            </Descriptions.Item>
        );
        headerProps.title = responseID.data.profile.title;
        headerItem.push(
            <Descriptions.Item label={'注册时间'} key={'create_at'}>
                <span>{moment(device.created_at * 1000).format('YYYY MM-DD, HH:mm:ss')}</span>
            </Descriptions.Item>
        );
        headerItem.push(
            <Descriptions.Item label={'所属公司'} key={'company'}>
                {renderItemFieldValue({ key: "company_id", style: 'link' }, displayedItem)}
                分组：{renderItemFieldValue({ key: "group_id", style: 'link' }, displayedItem)}
            </Descriptions.Item>
        );

        headerItem.push(<Descriptions.Item label={'device_id'.toUpperCase()} key={'device_id'}>
            <span>{info['device_id']}</span>
        </Descriptions.Item>)

    }
    let configs = [];
    if (responseID.data && responseID.data.config && Object.keys(responseID.data.config).length) {
        let config = responseID.data.config
        if (config.report_interval) {
            configs.push({
                string: `上报周期 ${config.report_interval / 60} 分钟`
            })
        }
        if (config.collect_interval) {
            configs.push({
                string: `采集周期 ${config.collect_interval / 60} 分钟`
            })
        }
        if (Object.keys(config.alarm).length > 0) {
            let opers = {
                lt: '小于',
                gt: '大于'
            }
            config.alarm.map(({ metric_name, operator, threshold }) => {   
                return `当${(KeyToInfo[metric_name] || { name: metric_name }).name} ${opers[operator] || operator} ${threshold}${(KeyToInfo[metric_name] || { unit: '' }).unit}时`
            }).forEach(v => {
                configs.push({
                    string: v,
                })
            })
        }
    }

    return <div>
        <PageHeaderWrapper
            {...headerProps}>
            <Descriptions size={'small'}>
                {headerItem}
            </Descriptions>
        </PageHeaderWrapper>
        <div className="page-content-wrapper">
            <LoadingResponseWrapper
                {...responseID}
                refresh={getInfoData}
                renderContent={({ data: currentData = {}, config }) => {
                    return <Row gutter={[16, 16]}>
                        <Col span={18}>
                            <Card style={{height: 280}} bordered={true}>
                                <h3>读数 {moment.version}</h3>
                                <Row gutter={[10, 40]}>
                                    {Object.keys(KeyToInfo).map((key, index) => {
                                        let info = KeyToInfo[key];
                                        if (!currentData[key]) {
                                            if (index < 2) {
                                                return <Col key={'empty' + key} span={4}>
                                                    <Card>
                                                        <Statistic 
                                                            title={info.name} 
                                                            value={"--"} 
                                                            precision={1} 
                                                            suffix={info.unit}
                                                            />
                                                    </Card>
                                                </Col>
                                            } else {
                                                return null;
                                            }
                                        }
                                        let alarmed = currentData['alarm'].indexOf(key) >= 0
                                        let color = alarmed ? '#cf1322' : 'black'
                                        let weight = alarmed ? 'bold' : '500'
                                        return <Col key={'block' + key} span={(key == 'pressure' || key == 'signal') ? 5 : 4}>
                                            <Card>
                                                <Statistic 
                                                    title={info.name} 
                                                    value={currentData[key]} 
                                                    precision={1} 
                                                    suffix={info.unit}
                                                    valueStyle={{ fontSize: 30,color: color, fontWeight: weight }}/>
                                            </Card>
                                        </Col>
                                    })}
                                </Row>
                                <h5>数据时间：{currentData.timestamp ? moment(currentData.timestamp * 1000).format('YYYY MM-DD, HH:mm:ss') : '--'}</h5>
                            </Card>
                        </Col>
                        <Col span={6}>
                            <Card bordered={!true}>
                                <Table
                                    size='small'
                                    bordered={true}
                                    footer={() => `共 ${configs.length} 项`}
                                    columns={[{
                                        title: '设置项',
                                        dataIndex: 'string',
                                        render: (value, row, index) => {
                                            return value
                                        }
                                      }]}
                                    dataSource={configs}
                                    pagination={false}
                                    scroll={{ y: 160 }} />
                            </Card>
                        </Col>
                    </Row>
                }}
            />
        </div>
        <div className="page-content-wrapper">
            {[['temperature', 'prob_temperature', 'humidity'], ['pm25', 'pm10', 'tvoc', 'tvoc_index', 'co2', 'co2_percent'], 'pressure', ['battery', 'signal']].map(key => {
                return <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card size={'small'}>
                            <HistoryView type={key} mac={mac} model={model} />
                        </Card>
                    </Col>
                </Row>
            })}
        </div>

    </div>
}

function HistoryView({ mac, model, type }) {
    let typeArray = (typeof type == 'string') ? [type] : Array.isArray(type) ? type : Object.keys(type);
    let [typeShowArray, setShowArray] = useState(new Array(typeArray.length).fill(false))
    let title = typeArray.map(v => KeyToInfo[v]).filter((v, i) => !v.mute && typeShowArray[i]).map(v => v.name).join('&') + "历史数据"
    const [[start, end], setStartEndTime] = useState([undefined, undefined])
    const [responseH, getHistory] = useHttpGet('/iot/deviceReportData')
    const lintData = useMemo(() => {
        if (!mac || !model) return undefined
        if (mac === 'error' || model === 'error') return []
        if (responseH.loading) return undefined;
        if (typeof responseH.data == 'undefined') return undefined;
        if (responseH.data) {
            let ldata = responseH.data || [];
            let hasBadValue = false;
            let showArray = [...typeShowArray]
            ldata = ldata.reduce((result, current, index, array) => {
                let onHis = typeArray.reduce((onObj, ttype, typeIdx) => {
                    if (current[ttype] == undefined || current[ttype] >= 99999) {
                        // Not Good Value
                    } else {
                        onObj[ttype] = current[ttype]
                        showArray[typeIdx] = true
                    }
                    return onObj;
                }, { time: parseInt(current.timestamp) * 1000 });
                if (Object.keys(onHis).length > 1) {
                    if (!hasBadValue && typeArray.length > 1) {
                        hasBadValue = Object.keys(onHis).length <= typeArray.length
                    }
                    result.push(onHis)
                }
                return result
            }, [])
            if (hasBadValue) {
                ldata = fillBadData(ldata, typeArray);
            }
            setShowArray(showArray)
            return ldata;
        }
        return []
    }, [responseH.data, responseH.loading])
    useEffect(() => {
        if (mac && model && start && end) {
            if (mac === 'error' || model === 'error') return;
            getHistory({
                mac: mac,
                model: model,
                metric: type,
                time_from: start,
                time_to: end,
            })
        }
    }, [mac, model, start, end])
    useEffect(() => {
    }, [lintData])

    return <HistoryChart title={title} name={KeyToInfo} formatter={{
        valueFormat: (v, key) => {
            return v + (KeyToInfo[key] || { unit: '' }).unit
        },
    }} onDataRangeChange={(start, end) => {
        setStartEndTime([start, end]);
    }} data={lintData} keys={typeArray.filter((v, i) => typeShowArray[i])} />
}

function fillBadData(data, typeArray) {
    // 这里是填充数据。有些数据不全时，会显示不出曲线。比如信号，数据很少，和电量放一起时，就没线条了。

    let tempFirst = data[0]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (Object.keys(element).length > typeArray.length) {
            tempFirst = element;
        } else {
            typeArray.forEach((v, i) => {
                if (element[v] === undefined && tempFirst[v]) {
                    element[v] = tempFirst[v]
                }
            })
        }
    }
    return data

}
import React, { useEffect, useRef, useCallback, useState } from 'react'
import { useHttpGet } from '../core/hooks'
import { Modal, message, Button } from 'antd';


export default({style, redirect, autoClick = false, redirectTo = null, titleLoggedIn = '已登录', callback=(accessToken) => {
  message.info('已登录: ' + accessToken)
}}) => {
  const handlerRef = useRef()
  const buttonRef = useRef()
  const [feishuInfo, getFeishuToken] = useHttpGet('/account/feishu');
  const [feishuAccessToken, setAccessToken] = useState();
  useEffect(() => {
    getFeishuToken({
      redirectTo,
    });
  }, [])
  const loginOkToMakeFileCallback = useCallback(({data}) => {
    console.warn("message:", data);
    let accessToken = data.access_token
    if (!accessToken && data.userInfo) {
        accessToken = data.userInfo.access_token
    }
    setAccessToken(accessToken);
    if (accessToken) {
        handlerRef.current && handlerRef.current.close()
        window.removeEventListener('message', loginOkToMakeFileCallback)
        callback(accessToken)
    }
  }, [window])
  const feishuToken = feishuAccessToken ? feishuAccessToken : feishuInfo.data ? feishuInfo.data.feishu_token : undefined
  const feishuUrl = feishuInfo.data ? feishuInfo.data.feishu_url : undefined

  useEffect(() => {
    if (autoClick && buttonRef.current) {
      if (feishuUrl) {
        setTimeout(() => {
          buttonRef.current.click()
        }, 1000)
      }
    }
  }, [autoClick, feishuUrl])
  return <Button disabled={!feishuUrl || feishuInfo.loading} ref={buttonRef} style={style} loading={feishuInfo.loading} onClick={() => {
    if (feishuToken) {
      callback(feishuToken)
    } else {
      if (redirect) {
        window.location.href = feishuUrl
      } else {
        window.addEventListener("message", loginOkToMakeFileCallback)
        const hendler = window.open(feishuUrl, '_blank', 'width=700, height=700, left=200, top=200'); 
        handlerRef.current = hendler
      }
      
    }
  }}>
    {feishuToken ? titleLoggedIn: '飞书登录'}
  </Button>
}
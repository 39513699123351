import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    useHttpApi,
    useLocalStoredObject,
    useOpenPage,
    useRefresh,
} from "../../core/hooks";
import {
    Spin,
    Table,
    Result,
    Button,
    Alert,
    Descriptions,
    Pagination,
    Card,
    Divider,
    Tabs,
    Drawer,
    Select,
} from "antd";

import {
    renderActions,
    renderItemFieldValue,
    renderBatchItemActions,
} from "../../core/domUtil";
import { EMPTY_VAR_STR } from "../../core/editorUtil";
import { useActionExecutor } from "../../core/actionExecuter";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import DragAndDroppableTableRow from "../df/DragAndDroppableTableRow";
import SearchHeader from "../displays/SearchHeader";
import { conditionsMatched } from "../../core/conditionsMatcher";
import TopTabbedDisplay from "./TopTabbedDisplay";
import DisplayComponent from "./Display";
import Icon, { CloseCircleOutlined } from "@ant-design/icons";
import { buildSearchParams, buildQuery } from "../../core/uriBuilder";
import AdminContext from "../../core/AdminContext";
import { Link } from "react-router-dom";
import DescriptionsDisplay from "./DescriptionsDisplay";

const ColumnWrapper = ({
    columnProps,
    record,
    readonly,
    nolink,
    actionExecutor,
}) => {
    return renderItemFieldValue(
        columnProps,
        record,
        nolink,
        readonly ? undefined : actionExecutor
    );
};
const { TabPane } = Tabs;
export default props => {
    const {
        uri,
        query,
        localStoreKey,
        onListStateUpdate,
        fixedQuery,
        fixedState,
        onQueryUpdated,
        searchDisabled,
        pagerDisabled = false,
        bordered = false,
        readonly = false,
        nolink = false,
        scrollx = false,
        exportable = false,
        localSortable = false,
        sub_detail = false,
        fixPageSize = false,
        defaultExpandAllRows = false,
    } = props;
    const context = useContext(AdminContext);
    const { siderCollapsed } = context || { siderCollapsed: false };
    const { selectedIds, onSelect, selector = "radio" } = props.selection || {};
    const [listQuery, setListQuery] = useState(query);
    const [itemsResponse, requestItems] = useHttpApi(uri, {
        method: "GET",
        onSuccess: data => {
            setFilters({});
            onListStateUpdate &&
                onListStateUpdate({ itemsResponse: data, actionExecutor });
        },
    });
    const tableItemsExporter = useOpenPage(uri, {
        ...fixedQuery,
        _export_: true,
    });

    const [profileResponse, requestProfile] = useHttpApi(
        typeof sub_detail === "string" && sub_detail.startsWith("/")
            ? sub_detail
            : "/template/getProfile"
    );

    const [subDetailParams, setSubDetailParams] = useState();
    const [filters, setFilters] = useState({});
    const [localSorts, setLocalSorts] = useState({});
    const [actionExecuting, actionResult, actionExecutor] = useActionExecutor();

    useEffect(() => {
        if (subDetailParams) {
            if (typeof subDetailParams === "object") {
                requestProfile({
                    ...subDetailParams,
                    _template_id_: fixedQuery ? fixedQuery._template_id_ : 0,
                });
            } else {
                requestProfile({
                    id: subDetailParams,
                    _template_id_: fixedQuery ? fixedQuery._template_id_ : 0,
                });
            }
        }
    }, [subDetailParams, actionResult]);
    const subDetailData = profileResponse.success
        ? profileResponse.data
        : undefined;
    const {
        loading,
        error,
        data = { items: [], displayFields: [] },
    } = itemsResponse;
    const {
        items,
        displayFields,
        count,
        sortAction,
        searchHeaderUri,
        actions = [],
        topAlert = null,
        topDescription = null,
    } = data;

    const [expandedRowKeys, setExpandedRowKeys] = useLocalStoredObject(
        localStoreKey + "_expanded_keys",
        []
    );
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const handleQueryUpdates = useCallback(
        updates => {
            if (onQueryUpdated) {
                onQueryUpdated({
                    ...query,
                    ...updates,
                });
            } else {
                setListQuery({
                    ...listQuery,
                    ...updates,
                });
            }
        },
        [onQueryUpdated, query, listQuery]
    );

    useEffect(() => {
        // setFilters({})
        requestItems({ ...fixedQuery, ...listQuery });
        //{...listQuery, ...fixedQuery, }
        /**
         * 这里之前请求是先list 再 fixed，但是会有个bug就是当search里定义了和fix相冲突的的字段时，
         * 会使用fixed的定义，这样会导致 search 不起做用。
         * 比如单据查询中，fix是 status=:not 0 的，在过虑时，想只列出某些状态的，
         * 如果写在前面，会被后面的覆盖。但不知道现在这么改会不会导致其它问题。
         * 此note提示后人将来如果有问题，请考虑以上这个情况不出问题。
         */
        setSelectedRowKeys([]);
    }, [fixedQuery, listQuery, actionResult]);

    useEffect(() => {
        setListQuery(query);
    }, [query]);

    if (displayFields.length === 0) {
        if (loading) {
            return (
                <div className="full-content-center">
                    <Spin />
                </div>
            );
        }
        if (error) {
            return (
                <div className="full-content-center">
                    <Result
                        status="error"
                        title={error.desc}
                        extra={
                            <Button
                                type="primary"
                                key="console"
                                onClick={() => {
                                    requestItems({
                                        ...fixedQuery,
                                        ...listQuery,
                                    });
                                }}
                            >
                                重试
                            </Button>
                        }
                    />
                </div>
            );
        }
    }
    const filterToQueryKeys = (displayFields || [])
        .filter(({ extra_props = [] }) => {
            return extra_props.filter_as_search;
        })
        .map(p => p.key);
    const tableProps = {
        size: "small",
        onChange: ({ current, pageSize }, newFilters, sorter, extra) => {
            if (extra.action == "filter") {
                setFilters(newFilters);

                console.log("on filter action", newFilters);
                let sets = Object.entries(newFilters)
                    .filter(
                        ([key, value]) => filterToQueryKeys.indexOf(key) >= 0
                    )
                    .map(([key, value]) => {
                        return [key, value || EMPTY_VAR_STR];
                    });
                if (sets.length) {
                    setListQuery({ ...query, ...Object.fromEntries(sets) });
                }

                return;
            }

            if (extra.action == "sort" && sortLocally) {
                setLocalSorts({ [sorter.columnKey]: sorter.order });
                return;
            }
            const updates = {
                page: current,
                page_size: pageSize,
            };

            const sorts = Array.isArray(sorter) ? sorter : [sorter];
            let sortByParts = [];
            for (let { field, order } of sorts) {
                if (field && order) {
                    sortByParts.push(
                        field + ":" + (order === "ascend" ? "asc" : "desc")
                    );
                }
            }

            if (sortByParts.length) {
                updates._sorts_ = sortByParts.join(",");
            } else {
                updates._sorts_ = undefined;
            }

            handleQueryUpdates(updates);
        },

        onRow: record => {
            const onRowWrapper = {};
            const { id, _actions_, title } = record;
            if (sortAction) {
                onRowWrapper.itemId = id;
                onRowWrapper.moveRow = (fromId, toId) => {
                    if (sortAction) {
                        sortAction.params = {
                            ...sortAction.params,
                            from_id: fromId,
                            to_id: toId,
                            position: -1,
                        };
                        actionExecutor.performAction(sortAction);
                    }
                };
            }
            if (onSelect) {
                onRowWrapper.onClick = () => {
                    if (selector === "radio") {
                        onSelect([id], [record]);
                    } else if (selector === "checkbox") {
                        let index = selectedIds.indexOf(id);
                        if (index >= 0) {
                            let selects = [...selectedIds];
                            selects.splice(index, 1);
                            onSelect(selects, [record]);
                        } else {
                            onSelect([...selectedIds, id], [record]);
                        }
                    }
                };
            } else if (sub_detail) {
                onRowWrapper.onClick = () => {
                    let detailAction = (record._actions_ || []).reduce(
                        (p, v, i) => {
                            if (p) return p;
                            if (v.type == 5 && v.params && v.params.id) {
                                return v;
                            }
                        },
                        undefined
                    );
                    if (detailAction) {
                        setSubDetailParams({
                            id: id,
                            _template_id_:
                                detailAction.params._template_id_ ||
                                (fixedQuery ? fixedQuery._template_id_ : 0),
                            original_id: id,
                        });
                    } else {
                        if (record._sub_detail_) {
                            if (record._sub_detail_ !== "disabled") {
                                setSubDetailParams({
                                    ...record._sub_detail_,
                                    original_id: id,
                                });
                            } else {
                                /// 不可点
                            }
                        } else {
                            // if (fixedQuery && fixedQuery._template_id_) {
                            //     setSubDetailParams({
                            //         id: id,
                            //         _template_id_: fixedQuery._template_id_,
                            //         original_id: id
                            //     });
                            // } else {
                            //     /// 不可点
                            // }
                        }
                    }
                };
            }

            return onRowWrapper;
        },

        rowClassName: (record, index) => {
            return record.id ===
                (subDetailParams
                    ? subDetailParams.original_id || subDetailParams.id
                    : 0)
                ? "ant-table-row-selected"
                : record.rowClassName ?? "";
        },
        defaultExpandAllRows: defaultExpandAllRows,
        pagination: false,
    };

    const {
        page = 1,
        page_size = 50,
        _sorts_,
        ...mainQueries
    } = listQuery || {};

    const sorters = {};

    if (_sorts_) {
        const parts = _sorts_.split(",");
        for (let part of parts) {
            const [key, order] = part.split(":");
            sorters[key] = order === "asc" ? "ascend" : "descend";
        }
    }

    // if (count > pager.page_size) {
    const pagination = count
        ? {
              total: count,
              current: parseInt(page) || 1,
              pageSize: fixPageSize ? parseInt(fixPageSize) : page_size,
              showTotal: total => {
                  return "共" + total + "条";
              },
              size: "small",
              showSizeChanger: fixPageSize ? false : true,
              onChange: (page, pageSize) => {
                  handleQueryUpdates({ page, page_size: pageSize });
              },
          }
        : null;
    // }

    if (sortAction) {
        tableProps.components = {
            body: {
                row: DragAndDroppableTableRow,
            },
        };
    }

    const expandedFields = displayFields.filter(({ y }) => y > 0);
    if (!onSelect && expandedFields.length) {
        tableProps.expandable = {
            expandedRowRender: item => (
                <Descriptions size={"small"}>
                    {expandedFields.map(field => {
                        const { key, title } = field;
                        return (
                            <Descriptions.Item label={title} key={key}>
                                {renderItemFieldValue(field, item)}
                            </Descriptions.Item>
                        );
                    })}
                </Descriptions>
            ),
            rowExpandable: () => true,
        };
    }

    tableProps.expandedRowKeys = expandedRowKeys;
    tableProps.onExpandedRowsChange = setExpandedRowKeys;

    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    let hasSingleItemAction = false;
    const batchAllowedItemsGroup = {};
    const itemBatchActionMap = {};
    if (items && items.length) {
        for (let item of items) {
            if (item._actions_ && item._actions_.length > 0) {
                for (let a of item._actions_) {
                    if (a.batchAllowed) {
                        if (batchAllowedItemsGroup[a.id]) {
                            batchAllowedItemsGroup[a.id].push(item.id);
                        } else {
                            batchAllowedItemsGroup[a.id] = [item.id];
                        }
                        itemBatchActionMap[a.id] = a;
                    } else {
                        hasSingleItemAction = true;
                    }
                }
            }
        }
    }

    if (onSelect) {
        tableProps.rowSelection = {
            type: selector,
            selectedRowKeys: selectedIds,
            onChange: (selectedKeys, selectedRows) => {
                onSelect(selectedKeys, selectedRows);
            },
            preserveSelectedRowKeys: true,
        };
        hasSingleItemAction = false;
    } else if (Object.keys(batchAllowedItemsGroup).length > 0 && !readonly) {
        tableProps.rowSelection = {
            // hideDefaultSelections,
            selectedRowKeys,
            onChange: setSelectedRowKeys,
        };
    }

    const { spanSUM, sortLocally } = displayFields.reduce(
        (last, { y, show_condition = [], span, sortable }) => {
            if (y === 0 && conditionsMatched(show_condition, fixedQuery)) {
                last.spanSUM += span;
            }
            if (localSortable) {
                // table props requires local sort and no sortable fileds, then it could be sort locally.
                if (last.sortLocally) {
                    last.sortLocally = !sortable;
                }
            }
            return last;
        },
        { spanSUM: 0, sortLocally: localSortable }
    );

    const batchActionIds = onSelect ? [] : Object.keys(itemBatchActionMap);
    const showSubDetail =
        subDetailParams && profileResponse && !profileResponse.error;

    if (scrollx) {
        tableProps.scroll = { x: tableWidth };
    }

    const handleFilterSearch = (dataIndex, selectedKeys, confirm) => {
        confirm();
        setFilters({ [dataIndex]: selectedKeys || undefined });
        setListQuery({ ...query, [dataIndex]: selectedKeys || EMPTY_VAR_STR });
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const table = (
        <Table
            loading={actionExecuting || loading}
            dataSource={items}
            bordered={bordered}
            sticky={sub_detail ? true : false}
            rowKey={({ id }) => id}
            {...tableProps}
        >
            {displayFields
                .filter(
                    ({ y, show_condition = [] }) =>
                        y === 0 && conditionsMatched(show_condition, fixedQuery)
                )
                .map((displayField, columnIdx) => {
                    const {
                        extra_props: {
                            hidden = false,
                            filter_as_search,
                            filter_as_select,
                        } = {},
                    } = displayField;
                    if (hidden) return undefined;
                    const {
                        key,
                        title,
                        sortable,
                        span,
                        filter_options,
                        style,
                    } = displayField;
                    const isnumbers = style == "count" || style == "currency";
                    const sortProps = { sorter: false };
                    if (sortable) {
                        sortProps.sorter = {
                            multiple: columnIdx,
                        };
                        sortProps.sortOrder = sorters[key] || false;
                        if (!sortable) {
                            sortProps.sorter = (a, b) =>
                                (a._raws_ || a)[key] - (b._raws_ || b)[key];
                        }
                    } else if (sortLocally && isnumbers) {
                        sortProps.sorter = (a, b) =>
                            (a._raws_ || a)[key] - (b._raws_ || b)[key];
                        sortProps.sortOrder = localSorts[key] || false;
                    }
                    return (
                        <Table.Column
                            dataIndex={key}
                            title={title}
                            key={key}
                            align={isnumbers ? "right" : undefined}
                            width={
                                scrollx
                                    ? (span || 2) * 50
                                    : (span / spanSUM) *
                                          (hasSingleItemAction ? 88 : 100) +
                                      "%"
                            }
                            fixed={columnIdx < scrollx ? "left" : undefined}
                            filters={
                                filter_options && !filter_as_select
                                    ? filter_options.map(o => {
                                          if (typeof o === "object") {
                                              return {
                                                  text: o.label,
                                                  value: o.value,
                                              };
                                          } else {
                                              return {
                                                  text: "" + o,
                                                  value: o,
                                              };
                                          }
                                      })
                                    : undefined
                            }
                            filterDropdown={
                                filter_as_select && filter_options
                                    ? FilterSelect({
                                          dataIndex: key,
                                          handleFilterSearch,
                                          handleReset,
                                          options: filter_options,
                                      })
                                    : undefined
                            }
                            filtered={filters[key]}
                            onFilter={
                                filter_as_search
                                    ? undefined
                                    : (value, record) => record[key] == value
                            }
                            filteredValue={filters[key]}
                            {...sortProps}
                            render={(cellVal, record) => {
                                return (
                                    <ColumnWrapper
                                        columnProps={displayField}
                                        record={record}
                                        nolink={nolink}
                                        actionExecutor={actionExecutor}
                                    />
                                );
                            }}
                        />
                    );
                })}

            {hasSingleItemAction && !readonly && (
                <Table.Column
                    dataIndex={"_actions_"}
                    title={"操作"}
                    fixed={"right"}
                    width={scrollx ? 100 : "12%"}
                    render={actions => {
                        return renderActions(
                            (actions && actions.filter(a => !a.batchAllowed)) ||
                                [],
                            actionExecutor,
                            fixedState,
                            3
                        );
                    }}
                />
            )}
        </Table>
    );
    return (
        <div>
            <div
                style={{ padding: props.showPadding ? "24px" : "0" }}
                ref={elementRef}
            >
                {topDescription && <DescriptionsDisplay {...topDescription} />}

                {topAlert && (
                    <Alert
                        style={{ marginTop: 5 }}
                        {...topAlert}
                        description={
                            topAlert.links &&
                            topAlert.links.length &&
                            topAlert.links.map(([{ title, href }]) => {
                                return [
                                    <Link to={href} target="_blank">
                                        {title}
                                    </Link>,
                                    " / ",
                                ];
                            })
                        }
                    ></Alert>
                )}

                {/* 注意这里。以前 当 readonly 时，action-search-bar 不显示。这里改为readonly 时不显示 action，search 还是显示的。 */}
                <div className="action-search-bar" style={{ marginTop: "5px" }}>
                    {!readonly && actions.length > 0 && (
                        <div
                            className="actions-bar"
                            style={{ marginBottom: "22px" }}
                        >
                            {renderActions(
                                actions.filter(({ showCondition }) => {
                                    if (!showCondition) {
                                        return true;
                                    }
                                    return conditionsMatched(
                                        showCondition,
                                        fixedQuery
                                    );
                                }),
                                actionExecutor,
                                { ...fixedState, ...fixedQuery },
                                3,
                                { type: "primary" }
                            )}
                        </div>
                    )}
                    {!searchDisabled && searchHeaderUri && (
                        <div className="search-bar">
                            <SearchHeader
                                fixedQuery={fixedQuery}
                                extraState={fixedState}
                                uri={searchHeaderUri}
                                query={mainQueries}
                                onQueryChange={handleQueryUpdates}
                                isOutlined={false}
                                tableExporter={
                                    exportable && items.length
                                        ? tableItemsExporter
                                        : undefined
                                }
                            />
                        </div>
                    )}
                </div>

                {!!sortAction && (
                    <DndProvider backend={Backend}>{table}</DndProvider>
                )}
                {!sortAction && table}

                {(batchActionIds.length > 0 || !pagerDisabled) && (
                    <div className="table-bottom-bar">
                        {!readonly && (
                            <div>
                                {batchActionIds.length > 0 && (
                                    <div
                                        className="table-operations"
                                        style={{
                                            margin: 0,
                                            paddingTop: "5px",
                                        }}
                                    >
                                        {renderBatchItemActions(
                                            batchActionIds.map(
                                                actionId =>
                                                    itemBatchActionMap[actionId]
                                            ),
                                            actionExecutor,
                                            items.filter(item => {
                                                return (
                                                    selectedRowKeys.indexOf(
                                                        item.id
                                                    ) >= 0
                                                );
                                            }),
                                            batchAllowedItemsGroup
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        {!pagerDisabled && pagination && (
                            <Pagination {...pagination} />
                        )}
                    </div>
                )}
            </div>

            {sub_detail && (
                <Drawer
                    placement="bottom"
                    closable={true}
                    onClose={() => setSubDetailParams()}
                    visible={showSubDetail}
                    height={window.innerHeight / 2}
                    style={{ marginLeft: siderCollapsed ? 0 : 225 }}
                    getContainer={false}
                >
                    <div style={{ marginRight: siderCollapsed ? 0 : 240 }}>
                        <TabsCard
                            tabs={subDetailData ? subDetailData.tabs : []}
                            detailActions={
                                subDetailData
                                    ? (subDetailData.detail &&
                                          subDetailData.detail.displayedItem &&
                                          subDetailData.detail.displayedItem
                                              ._actions_) ||
                                      []
                                    : []
                            }
                            loading={profileResponse.loading}
                            onClose={() => setSubDetailParams()}
                            actionExecutor={actionExecutor}
                        />
                    </div>
                </Drawer>
            )}
        </div>
    );
};

const TabsCard = ({
    tabs = [],
    detailActions = [],
    loading = false,
    onClose,
    actionExecutor,
}) => {
    const [focusedKey, setFocused] = useState();
    const tabLabes = (loading ? [] : tabs || []).map(({ value, label }) => {
        return {
            key: value,
            tab: label,
        };
    });

    const contents = useMemo(() => {
        let c = {};
        for (const tab of tabs) {
            c[tab.value] = tab;
        }
        return c;
    }, [tabs]);
    const currentKey =
        focusedKey ||
        (tabs.length && tabs[0].value) ||
        Object.keys(contents)[0];
    const content = (contents[currentKey] || {}).content;
    const renderActionItems = [
        ...detailActions,
        ...((contents[currentKey] || {}).actions || []),
    ];
    const actions = actionExecutor
        ? renderActions(renderActionItems, actionExecutor, {}, 10)
        : [];
    return (
        <Tabs
            onChange={key => setFocused(key)}
            activeKey={focusedKey}
            tabBarExtraContent={
                <div style={{ marginRight: 30 }}>{actions}</div>
            }
            tabBarStyle={{ marginBottom: "0" }}
            // type="card"
            size={"small"}
        >
            {tabLabes.map(pane => (
                <TabPane tab={pane.tab} key={pane.key}>
                    <div style={{ overflow: "auto" }}>
                        {content && (
                            <DisplayComponent
                                {...content}
                                readonly={true}
                                pagerDisabled={true}
                                loaing={loading}
                            />
                        )}
                    </div>
                </TabPane>
            ))}
        </Tabs>
    );
};

const FilterSelect = ({
    dataIndex,
    handleFilterSearch,
    handleReset,
    options,
}) => {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 200 }}>
            <Select
                style={{ width: "100%" }}
                options={options}
                optionFilterProp="label"
                dropdownMatchSelectWidth={false}
                showSearch
                allowClear
                placeholder="搜索"
                onChange={v => {
                    setSelectedKeys(v);
                    handleFilterSearch(dataIndex, v, confirm);
                }}
            ></Select>
        </div>
    );
};

import React, { useCallback, useEffect, useState } from "react";
import { Table, Button, DatePicker, Row, Col, Badge } from "antd";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper";
import DisplayPage from "../home/DisplayPage";
import { useHttpGet, useRouteQuery } from "../../core/hooks";
import RightDetailListDisplay from "../../components/displays/RightDetailListDisplay";
import ApplyDetail from "../../components/displays/custom/ApplyDetail";
import { FormatMoney } from "format-money-js";
import moment from "moment";
import { useHistory, useRouteMatch } from "react-router-dom";
import { buildSearchParams } from "../../core/uriBuilder";
const fm = new FormatMoney();
export default props => {
    const routeQuery = useRouteQuery();
    const history = useHistory();
    const { url } = useRouteMatch();
    return (
        <div>
            <PageHeaderWrapper />
            <RightDetailListDisplay
                listItemUri={
                    "/approval/listItems?" + new URLSearchParams(props)
                }
                query={routeQuery}
                itemRender={item => {
                    const rawStatus = item.status;
                    // return '';
                    let color = "";
                    switch (rawStatus) {
                        case 1:
                            color = "orange";
                            break;
                        case 2:
                        case 11:
                            color = "red";
                            break;
                        case 10:
                            color = "green";
                            break;
                        default:
                            color = "blue";
                    }
                    return (
                        <div>
                            <div style={{ alignItems: "center" }}>
                                <Badge color={color} text={item.status_label} />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <span>{item.title}</span>
                                <span style={{ flex: 1 }}></span>
                                <span>
                                    {item.money_amount
                                        ? fm.from(+ item.money_amount, {
                                              symbol: item.currency || "￥",
                                              decimals: 2,
                                          })
                                        : ""}
                                </span>
                            </div>
                            <div>
                                {item.created_at
                                    ? moment(item.created_at * 1000).format(
                                          "YYYY-MM-DD"
                                      )
                                    : ""}
                            </div>
                        </div>
                    );
                }}
                fixedFilterKey="id"
                contentDisplay={{
                    component: "ApplyDetail",
                    props: {},
                }}
                onQueryUpdated={updates => {
                    history.push({
                        pathname: url,
                        search: buildSearchParams(routeQuery, updates),
                    });
                }}
            />
        </div>
    );
};

import { Form, InputNumber, Table } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { FormatMoney } from "format-money-js"
import { useHttpGet } from "../../../core/hooks"
import { renderItemFieldValue } from "../../../core/domUtil"


export default (props) => {
    const { props: { key, label }, valueSet, rows, onChange, valueSetUpdater } = props
    const { statement_id, purchase_order_id } = valueSet
    let { money_rate: moneyRate, tax_rate: taxRate } = valueSet
    const [serviceFee, setNumber] = useState({})
    const [orderResponse, getOrderItems, clear] = useHttpGet('/production/getUSDOrderItems');

    useEffect(() => {
        if (purchase_order_id) {
            clear()
            setNumber({})
            getOrderItems({
                purchase_order_id: purchase_order_id,
                statement_id: statement_id || 0,
            });
        }
    }, [statement_id, purchase_order_id])

    const { loading, error, data: { supplier_id, agent_supplier_id, items = [] } = {} } = orderResponse
    useEffect(() => {
        const mapped = (items || []).reduce((last, curr, i) => {
            const { id, material_id, price_usd, quantity, tax_fee_amount, service_fee_amount, duty_fee_amount } = curr
            last[i] = { id, material_id, price_usd, quantity, tax_fee_amount, service_fee_amount, duty_fee_amount }
            return last
        }, {})
        setNumber(mapped)
    }, [orderResponse])
    const calcedItems = useMemo(() => {
        return (items || []).map((record, i) => {
            let input = serviceFee && serviceFee[i] || {}
            record = {...record, ...input}
            record.item_total_price_usd = (input.quantity * record.price_usd) || 0
            record.item_total_price_cny = (record.item_total_price_usd * (parseFloat(moneyRate) || 1))
            record.total_other_cny = (parseFloat(record.tax_fee_amount || 0) || 0) + (parseFloat(record.service_fee_amount || 0) || 0) + (parseFloat(record.duty_fee_amount || 0) || 0)

            return record;
        })
    }, [serviceFee, items, moneyRate, taxRate])
    const totalRow = calcedItems.reduce((last, current) => {
        Object.keys(last).forEach((key) => {
            last[key] += parseFloat(current[key]) || 0
        })
        return last
    }, {
            item_total_price_usd: 0, item_total_price_cny: 0,
            tax_fee_amount: 0, service_fee_amount: 0, duty_fee_amount: 0,
            total_other_cny: 0
    })
    totalRow['qingping_pn'] = '合计'
    console.log('serviceFee', serviceFee, calcedItems, totalRow)

    useEffect(() => {
        let allParams = JSON.stringify(serviceFee)
        // onChange(allParams);
        valueSetUpdater({
            ...valueSet,
            [key]: allParams,
            supplier_id, agent_supplier_id, purchase_order_id
        })
    }, [serviceFee, orderResponse])


    const moneier = new FormatMoney()
    const columns = [
        {
            title: '物料编号',
            key: 'qingping_pn',
        }, {
            title: '品名',
            key: 'category',
        }, {
            title: '下单数',
            key: 'order_quantity',
            style: 'count',
        }, {
            title: '已入库',
            key: 'stock_in_quantity',
            style: 'count',
        }, {
            title: '合同数量',
            key: 'quantity',
            render: (value, record, index) => {
                if (index >= calcedItems.length) return <span/>
                return <InputIndexNumberKey
                    field={'quantity'}
                    index={index}
                    defaultValue={value}
                    value={value}
                    callback={(field, value) => {
                        let input = serviceFee[index] || record
                        input[field] = value
                        console.log('set', field, value)
                        setNumber({
                            ...serviceFee,
                            [index]: input,
                        })
                    }}
                />
            }
        }, {
            title: '外币单价(USD)',
            key: 'price_usd',
            style: 'currency',
            extra_props: { decimals: 6 },
        }, {
            title: '货值(USD)',
            key: 'item_total_price_usd',
            style: 'currency',
            extra_props: { decimals: 2 },
        }, {
            title: '汇率',
            render: (value) => parseFloat(moneyRate)
        }, {
            title: '服务合同货值(CNY)',
            key: 'item_total_price_cny',
            style: 'currency',
            extra_props: { decimals: 2 },
        }, {
            title: '增值税',
            key: 'tax_fee_amount',
            style: 'currency',
            render: (value, record, index) => {
                return <InputIndexNumberKey
                    field={'tax_fee_amount'}
                    index={index}
                    defaultValue={value}
                    value={value}
                    callback={(field, value) => {
                        let input = serviceFee[index] || record
                        input[field] = value
                        console.log('set', field, value)
                        setNumber({
                            ...serviceFee,
                            [index]: input,
                        })
                    }}
                />
            }
        }, {
            title: '关税',
            key: 'duty_fee_amount',
            style: 'currency',
            render: (value, record, index) => {
                return <InputIndexNumberKey
                    field={'duty_fee_amount'}
                    index={index}
                    defaultValue={value}
                    value={value}
                    callback={(field, value) => {
                        let input = serviceFee[index] || record
                        input[field] = value
                        console.log('set', field, value)
                        setNumber({
                            ...serviceFee,
                            [index]: input,
                        })
                    }}
                />
            }
        }, {
            title: '服务费',
            key: 'service_fee_amount',
            style: 'currency',
            render: (value, record, index) => {
                return <InputIndexNumberKey
                    field={'service_fee_amount'}
                    index={index}
                    defaultValue={value}
                    value={value}
                    callback={(field, value) => {
                        let input = serviceFee[index] || record
                        input[field] = value
                        console.log('set', field, value)
                        setNumber({
                            ...serviceFee,
                            [index]: input,
                        })
                    }}
                />
            }
        }, {
            title: '税+费 总额(CNY)',
            key: 'total_other_cny',
            style: 'currency',
            extra_props: { decimals: 2 },
        },
    ]
    return <Table
        pagination={false}
        loading={loading}
        dataSource={calcedItems.length? [...calcedItems, totalRow]:[]}
        rowKey={({ id }) => id}
        size={'small'}>
        {columns.map((field) => {
            const { title, key, type, render } = field
            return <Table.Column title={title} dataIndex={key} render={
                (value, record, index) => {
                    if (index >= calcedItems.length || !render) return renderItemFieldValue(field, record)
                    return render(value, record, index)
                }}>
            </Table.Column>
        })}
    </Table>
}

function InputIndexNumberKey({ index, field, defaultValue, value, callback}) {
    return <InputNumber
        min={0}
        prefix='￥'
        defaultValue={defaultValue}
        value={isNaN(value)?'':value}
        onChange={(v) => {
            console.log('input', field ,index, v)
            callback(field, isNaN(v) ? 0 : v)
        }}
    ></InputNumber>
}
import React, {useCallback, useContext, useEffect, useState} from "react"
import {useLocation, useParams, useRouteMatch} from "react-router-dom"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "./DisplayPage"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"

export default () => {

    const { path, url } = useRouteMatch()
    const location = useLocation()

    const [pageDisplay, getPageDisplay] = useHttpGet('/account/getPageDisplay')

    const refreshPage = useCallback(() => {
        getPageDisplay({
            path,
            url,
            params: location.search,
        })
    }, [path, url, location.search, getPageDisplay])

    useEffect(() => {

        getPageDisplay({
            path,
            url,
            params: location.search,
        })

    }, [path, url, location.search])

    // const []


    return <div>
        <PageHeaderWrapper/>
        <LoadingResponseWrapper
            {...pageDisplay}
            refresh={refreshPage}
            renderContent={(displayProps) => {
                return <DisplayPage {...displayProps}/>
            }}
        />
    </div>


}


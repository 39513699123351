import { Button, Divider, Form, InputNumber, Table, Tabs } from "antd"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import { FormatMoney } from "format-money-js"
import { useHttpGet } from "../../../core/hooks"
import {renderActions, renderItemFieldValue, renderBatchItemActions} from "../../../core/domUtil"
const { TabPane } = Tabs;
export default (props) => {
    const { valueSet, rows, onChange, valueSetUpdater } = props
    const { manufacture_order_id, item_type = 0, io_type = 0, in_type = 0, out_type = 0, warehouse_sub_id } = valueSet

    const [useCounts, setUseCounts] = useState({})

    const [manufactureResponse, getManufactureInfo, clearResponse] = useHttpGet('/production/getManufactureMaterials');

    const isTakingMaterial = parseInt(out_type) === 8;
    const isReturnMaterial = parseInt(in_type) === 38;
    const isBuildProducts = parseInt(in_type) === 32;
    const showTargetTable = isBuildProducts || isTakingMaterial
    const showMaterialTable = isReturnMaterial || isTakingMaterial
    const useInputs = isReturnMaterial
    const inputTitle = isTakingMaterial ? '领料数量' : isReturnMaterial ? '退料数量' : '数量'
    const targetInputTitle = isTakingMaterial ? '齐套领料' : isBuildProducts ? '入库数量' : '数量'
    useEffect(() => {
        if (manufacture_order_id && warehouse_sub_id) {
            getManufactureInfo({
                warehouse_sub_id,
                manufacture_order_id,
                io_type, in_type, out_type, item_type
            })
        } else {
            clearResponse()
        }
    }, [manufacture_order_id, warehouse_sub_id, io_type, in_type, out_type])

    let { loading, error, data: {
        items = [],
        displayFields = [],
    } = {} } = manufactureResponse
    if (!displayFields) displayFields = []


    const inputFilledMaterial = useMemo(() => {
        let filterMaterials = [];
        items.forEach((row) => {
            let materialId = row.material_id;
            if (useCounts[materialId]) {
                filterMaterials.push({
                    material_id: materialId,
                    count: useCounts[materialId] || 0,
                })
            }
        });
        return filterMaterials
    }, [useCounts])


    const takingMaterailCountsResult = JSON.stringify(inputFilledMaterial)

    useEffect(() => {
        onChange && onChange(takingMaterailCountsResult)
    }, [takingMaterailCountsResult])

    return <div>
        <Table
            pagination={false}
            loading={loading}
            dataSource={items}
            rowKey={({ material_id }) => material_id}
            size={'small'}>
            {(displayFields||[]).map((displayField) => {
                return <Table.Column title={displayField.title} dataIndex={displayField.key}
                    render={(cellVal, record) => {
                        return <ColumnWrapper columnProps={displayField} record={record}/>
                    }}/>
            })}
            <Table.Column title={inputTitle}
            render={(v, record, i) => {
                let materialId = record?.material_id;
                let useSelfCount = useInputs || (useCounts.hasOwnProperty(materialId))
                let value =  useCounts[materialId] || 0;
                
                return <div>
                    <InputNumber disabled={!useSelfCount} min={0} value={value} onChange={(number) => {
                        useCounts[materialId] = parseInt(number)||'';
                        setUseCounts({...useCounts})
                    }}/>
                </div>
            }}></Table.Column>
        </Table>
        <Divider />
    </div>
}
const ColumnWrapper = ({columnProps, record, readonly, actionExecutor}) => {
    return renderItemFieldValue(columnProps, record, readonly, actionExecutor)
}
import React, {
    useEffect,
    useState,
    useReducer,
    useRef,
    useCallback,
} from "react";
import {
    useHttpApi,
    useHttpGet,
    useHttpPost,
    useRefresh,
    useRouteQuery,
} from "../../core/hooks";
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper";
import {
    Layout,
    Button,
    Modal,
    Input,
    Select,
    Form,
    Drawer,
    Comment,
    Tooltip,
    Spin,
    Popover,
    message,
    Pagination,
    Tag,
} from "antd";
import { Link, useHistory } from "react-router-dom";

import {
    CloseCircleFilled,
    EditOutlined,
    FieldTimeOutlined,
    FileDoneOutlined,
    FileImageOutlined,
    FileTextOutlined,
    FilterOutlined,
    UserOutlined,
} from "@ant-design/icons";

import "react-chat-elements/dist/main.css";
import { ChatList, MessageList } from "react-chat-elements";
import moment from "moment";
import "./feedback.css";
import { FileIcons } from "../../components/displays/FilesRefDisplay";
import FileRefUploader from "../../components/FileRefUploader";
// import html2pdf from 'html2pdf.js'

import { format as formatDateAgo } from "timeago.js";
import Icon from "../../components/Icon";

const { Header, Sider, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;
const SIDE_WIDTH = 300;

function formatDate(date) {
    const now = new Date();
    if (now.getFullYear() == date.getFullYear()) {
        if (now.getMonth() == date.getMonth()) {
            if (now.getDate() == date.getDate()) {
                if (now.getHours() < date.getHours() + 1.5) {
                    return formatDateAgo(date, "zh_CN");
                }
                return moment(date).format("HH:mm"); // formatDateAgo(date, 'zh_CN')
            }
            return formatDateAgo(date, "zh_CN") + moment(date).format(" HH:mm");
        }
        return moment(date).format("MM-DD HH:mm");
    }
    return moment(date).format("YYYY-MM-DD");
}

export default () => {
    const refresh = useRefresh();
    const [searchForm] = Form.useForm();
    const history = useHistory();
    const query = useRouteQuery();
    const {
        app_id = "0",
        status = "unarchived",
        focus_id,
        page = 1,
        page_size = 20,
        app_user_id,
        user_id,
    } = query;

    const updateQuyer = useCallback(
        p => {
            const newQuery = {
                ...query,
                ...p,
            };
            if (newQuery.page_size == 20) {
                delete newQuery.page_size;
            }
            Object.entries(newQuery).forEach(([key, value]) => {
                if (!value) {
                    delete newQuery[key];
                }
            });
            history.replace({
                pathname: history.location.pathname,
                search: new URLSearchParams(newQuery).toString(),
            });
            refresh();
        },
        [history, query]
    );
    const [
        { data: { items: convs, count: totalCount = 0 } = {} } = {},
        getConvs,
    ] = useHttpGet("/feedback/list", {});
    const [convInfo, setConvInfo] = useState();

    let [apps, setApps] = useState([{ label: "所有应用", value: 0 }]);
    const [appsList, getAppsList] = useHttpApi("/feedback/apps", {
        method: "GET",
        onSuccess: data => {
            setApps([
                { label: "所有应用", value: "0" },
                ...data.map(({ name, id }) => {
                    return { label: name, value: "" + id };
                }),
            ]);
        },
    });

    useEffect(() => {
        getAppsList();
    }, []);

    useEffect(() => {
        const values = {
            app_id: app_id || 0,
            status: status || "unarchived",
            app_user_id: app_user_id || 0,
            user_id: user_id || 0,
        };
        searchForm.setFieldsValue(values);
        refresh();
        getConvs({ page, page_size, ...values });
    }, [page, page_size, app_id, status, app_user_id, user_id]);

    useEffect(() => {
        if (!convs || !convs.length) {
            return;
        }
        const current = convs.find((item, index, a) => {
            return item.id == focus_id;
        });
        if (current) {
            setConvInfo({ ...current });
        } else if (!convInfo) {
            setConvInfo({ id: focus_id });
        }
    }, [focus_id, convInfo?.id, convs]);

    let conversations = undefined;
    if (convs && convs.length) {
        conversations = convs.map(v => {
            if (convInfo && v.id === convInfo.id) {
                Object.entries(convInfo).forEach(([key, value]) => {
                    v[key] = value;
                });
            }
            return {
                convInfo: v,
                title: v.title,
                subtitle: v.sub_title || v.subTitle,
                date: new Date(v.updated_at * 1000),
                dateString: formatDate(new Date(v.updated_at * 1000)),
                unread: v.unread_count,
                avatar: v.icon,
                statusColor: focus_id == v.id ? "#333" : "",
            };
        });
    }
    console.log("searchForm", searchForm.getFieldsValue());
    return (
        <div style={{ height: "100vh" }}>
            <Layout style={{ height: "100vh" }}>
                {/* <Spin spinning={false}/> */}
                <Header
                    className="filter-header"
                    style={{
                        display: "flex",
                        height: "80px",
                        alignItems: "center",
                        backgroundColor: "white",
                    }}
                >
                    <div style={{}}>
                        <Form
                            form={searchForm}
                            name="basic"
                            layout="inline"
                            initialValues={{
                                app_id: "0",
                                status: "unarchived",
                                keyword: "",
                                app_user_id: 0,
                                user_id: 0,
                            }}
                            onFinish={values => {
                                updateQuyer({
                                    page: 1,
                                    app_id: values.app_id,
                                    status: values.status,
                                    keyword: values.keyword || "",
                                    app_user_id: 0,
                                    user_id: 0,
                                });
                            }}
                        >
                            <Form.Item name="app_id">
                                <Select
                                    style={{ width: 120 }}
                                    onChange={(v, v2) => {
                                        searchForm.submit();
                                    }}
                                    value={app_id}
                                    options={apps}
                                    allowClear
                                />
                            </Form.Item>
                            <Form.Item name="status">
                                <Select
                                    style={{ width: 180 }}
                                    onChange={(v, v2) => {
                                        searchForm.submit();
                                    }}
                                    value={status}
                                    allowClear
                                >
                                    <Option value="unarchived">
                                        所有未归档会话
                                    </Option>
                                    <Option value="new_message">
                                        等待回复的会话
                                    </Option>
                                    <Option value="replied">
                                        已回复的会话
                                    </Option>
                                    <Option value="archived">
                                        已归档的会话
                                    </Option>
                                    <Option value="deleted">
                                        已删除的会话
                                    </Option>
                                    <Option value="all">所有会话</Option>
                                </Select>
                            </Form.Item>
                            {(user_id || app_user_id) && (
                                <Form.Item name="app_user_id">
                                    <div className={"ant-input"}>
                                        <span>
                                            <UserOutlined />{" "}
                                            {user_id ? "青萍" : "App"}用户:
                                            {user_id || app_user_id}
                                        </span>
                                        <Button
                                            style={{
                                                display: "inline-block",
                                                height: "20px",
                                                width: "20px",
                                            }}
                                            size="small"
                                            icon={<CloseCircleFilled />}
                                            type="text"
                                            onClick={() => {
                                                updateQuyer({
                                                    app_user_id: 0,
                                                    user_id: 0,
                                                });
                                            }}
                                        />
                                    </div>
                                </Form.Item>
                            )}
                        </Form>
                        {/*<div><Button type='primary' onClick={() => {htmlToPdf()}}></Button></div>*/}
                    </div>
                </Header>
                <Layout>
                    <Sider theme="light" width={SIDE_WIDTH}>
                        <ChatList
                            className="feedbackLeft"
                            onClick={data => {
                                updateQuyer({ focus_id: data.convInfo.id });
                            }}
                            dataSource={conversations}
                        />
                        <div
                            style={{
                                textAlign: "right",
                                borderTopColor: "black",
                                borderTopWidth: "1px",
                            }}
                        >
                            <Pagination
                                size="small"
                                showLessItems={true}
                                showSizeChanger={false}
                                pageSize={page_size || 20}
                                current={parseInt(page) || 1}
                                total={totalCount}
                                onChange={(page, page_size) => {
                                    updateQuyer(
                                        page_size == 20
                                            ? { page }
                                            : { page, page_size }
                                    );
                                }}
                            />
                        </div>
                    </Sider>

                    <MessagePanel
                        convInfo={convInfo || {}}
                        updateConv={newInfo => {
                            if (convInfo && newInfo.id === convInfo.id) {
                                setConvInfo({ ...convInfo, ...newInfo });
                            }
                        }}
                        onUserSelect={({ app_id, app_user_id, user_id }) => {
                            if (user_id) {
                                updateQuyer({ user_id });
                            } else {
                                updateQuyer({ app_id, app_user_id });
                            }
                        }}
                    />
                </Layout>
            </Layout>
        </div>
    );
};

function MessagePanel({ convInfo, updateConv, onUserSelect }) {
    const [messages, setMessages] = useState([]);
    const [messageResponse, getMessages] = useHttpApi(
        "/feedback/messagesExtras",
        {
            method: "GET",
            onSuccess: ({ messages: data, device: deviceInfo }) => {
                const messages = data.map((v, index) => {
                    let text = v.content;
                    let contentType = "text";
                    if (v.content_type == 5 || v.content_type == "json") {
                        contentType = "system";
                        let json = JSON.parse(v.content);
                        if (json.archived == 1) {
                            text = (v.replier_name || "客服") + "取消归档";
                        } else if (json.archived == 2) {
                            text = (v.replier_name || "客服") + "归档会话";
                        } else if (json.archived == 3) {
                            text = "[🗑️用户删除了会话]";
                        } else if (json.upload_log) {
                            text = "[📄用户上传了日志]";
                            contentType = "text";
                        } else if (json.archived == 4) {
                            text = (v.replier_name || "客服") + "删除了会话";
                        }
                    }

                    return {
                        text:
                            v.attach_files && v.attach_files.length ? (
                                <div>
                                    <div> {text} </div>
                                    <FileIcons files={v.attach_files} />
                                </div>
                            ) : (
                                <div> {text} </div>
                            ),
                        dateString:
                            (v.message_type == 1
                                ? (v.replier_name || "客服") + " 于: "
                                : "") +
                            formatDate(new Date(v.created_at * 1000)),
                        position: v.message_type == 0 ? "left" : "right",
                        type: contentType,
                        data: v.content_type == 3 ? { uri: v.log_file } : {},
                        device: deviceInfo,
                    };
                });
                if (deviceInfo) {
                    let goodKeys = [
                        "mac",
                        "name",
                        "model",
                        "version",
                        "isHomeKitAccessory",
                    ];

                    messages.unshift({
                        text: (
                            <Tooltip title={JSON.stringify(deviceInfo)}>
                                <code>
                                    {goodKeys.map(key => {
                                        let v = deviceInfo[key];
                                        if (v) {
                                            if (true === v) {
                                                v = "YES";
                                            }
                                            return (
                                                <span key={"if_" + key}>
                                                    <strong>
                                                        {key.toUpperCase()}
                                                    </strong>
                                                    : {v}
                                                    <br />
                                                </span>
                                            );
                                        }
                                    })}
                                </code>
                            </Tooltip>
                        ),
                        dateString: "设备信息",
                        position: "left",
                        type: "text",
                    });
                }
                setMessages(messages);
            },
        }
    );

    const refreshMessages = useCallback(() => {
        getMessages({ conv_id: convInfo.id });
    }, [convInfo.id]);

    const [archiveRes, archive] = useHttpPost("/feedback/archive");
    const [unarchiveRes, unarchive] = useHttpPost("/feedback/unarchive");

    useEffect(() => {
        if (!convInfo || !convInfo.id) return;
        refreshMessages();
    }, [convInfo.id]);

    const [replierVisible, showReplier] = useState(false);
    const [drawerVisible, showDrawer] = useState(false);
    if (!convInfo || !convInfo.id) {
        return <div>{JSON.stringify(convInfo)}</div>;
    }
    return (
        <Content className="feedbackRight">
            <div className="chatListRight">
                {messages && (
                    <LoadingResponseWrapper
                        data={messages}
                        renderContent={v => {
                            return (
                                <MessageList
                                    className="message-list"
                                    lockable={true}
                                    toBottomHeight={"100%"}
                                    dataSource={messages || []}
                                    onContextMenu={(v, v2, eve) => {
                                        // console.log("onContextMenu", v, v2, eve)
                                        return false;
                                    }}
                                    onClick={item => {
                                        if (item.data && item.data.uri) {
                                            window.location.href =
                                                item.data.uri;
                                        }
                                    }}
                                />
                            );
                        }}
                    />
                )}
            </div>
            <div
                className="feedbackFunction"
                style={{
                    backgroundColor: "white",
                    borderTopColor: "black",
                    borderTopWidth: "1px",
                    height: "100px",
                }}
            >
                {convInfo.user_id && (
                    <Button
                        type="link"
                        onClick={() => {
                            onUserSelect && onUserSelect(convInfo);
                        }}
                        icon={<FilterOutlined />}
                        size={"large"}
                    >
                        此用户的反馈(qing_uid {convInfo.user_id})
                    </Button>
                )}
                {!convInfo.user_id && convInfo.app_user_id && (
                    <Button
                        type="link"
                        onClick={() => {
                            onUserSelect && onUserSelect(convInfo);
                        }}
                        icon={<FilterOutlined />}
                        size={"large"}
                    >
                        此用户的反馈(uid {convInfo.app_user_id})
                    </Button>
                )}

                {convInfo.profile && (
                    <Link
                        className="ant-btn ant-btn-link ant-btn-lg"
                        to={convInfo.profile}
                        target="_blank"
                    >
                        <UserOutlined />
                        用户详情
                    </Link>
                )}

                {convInfo.archived == 1 && (
                    <Button
                        key="archive"
                        type="link"
                        onClick={() => {
                            archive({ conv_id: convInfo.id }, () => {
                                updateConv({
                                    id: convInfo.id,
                                    updated_at: new Date().getTime() / 1000,
                                    archived: 2,
                                    unread_count: 0,
                                });
                                refreshMessages();
                            });
                        }}
                        icon={<FileDoneOutlined />}
                        size={"large"}
                    >
                        归档
                    </Button>
                )}

                {convInfo.archived == 2 && (
                    <Button
                        key="unarchive"
                        type="link"
                        onClick={() => {
                            unarchive({ conv_id: convInfo.id }, () => {
                                updateConv({
                                    id: convInfo.id,
                                    updated_at: new Date().getTime() / 1000,
                                    archived: 1,
                                    unread_count: 1,
                                });
                                refreshMessages();
                            });
                        }}
                        icon={<FieldTimeOutlined />}
                        size={"large"}
                    >
                        取消归档
                    </Button>
                )}

                {/* {convInfo.unread_count > 0 && <Button key="needreply" type="primary"
            onClick={() => {
                updateConv({ unread_count: 0 })
                needReply({ conv_id: convInfo.id })
            }}
            icon={<FormOutlined />} size={'large'}>
            标记为已读
        </Button>} */}
                <Button
                    type="link"
                    icon={<FileTextOutlined />}
                    size={"large"}
                    onClick={() => {
                        if (convInfo) showDrawer(true);
                    }}
                >
                    备注
                </Button>
                <Button
                    type="link"
                    icon={<EditOutlined />}
                    size={"large"}
                    onClick={() => {
                        if (convInfo) {
                            showReplier(true);
                        }
                    }}
                >
                    回复
                </Button>
            </div>
            <Replier
                convId={convInfo.id}
                visible={replierVisible}
                onClose={() => {
                    showReplier(false);
                }}
                onReply={message => {
                    updateConv({
                        id: convInfo.id,
                        updated_at: new Date().getTime() / 1000,
                        unread_count: 0,
                        subTitle: message,
                    });
                    refreshMessages();
                }}
            />

            <RemarksDrawer
                convId={convInfo.id}
                visible={drawerVisible}
                onClose={() => {
                    showDrawer(false);
                }}
            />
        </Content>
    );
}

function Replier({ convId, visible, onClose, onReply }) {
    const [inputReply, setInputReply] = useState(undefined);
    const [fileRef, setFileRef] = useState(undefined);
    const [ok, reply] = useHttpGet("/feedback/reply", {});

    const uploaderRef = useRef(cb => {
        cb && cb();
    });

    useEffect(() => {
        if (ok.success) {
            onReply(inputReply);
            onClose();
            setInputReply("");
            setFileRef(undefined);
        }
    }, [ok]);
    return (
        <Modal
            title="回复用户"
            onOk={() => {
                if (inputReply) {
                    uploaderRef.current &&
                        uploaderRef.current((result, fileRefToken) => {
                            if (result) {
                                reply({
                                    conv_id: convId,
                                    content: inputReply,
                                    attach_files: fileRefToken || null,
                                });
                            } else {
                                message.error("上传文件失败");
                            }
                        });
                }
            }}
            onCancel={() => {
                setInputReply("");
                onClose();
            }}
            destroyOnClose={true}
            confirmLoading={ok.loading}
            visible={visible}
        >
            <TextArea
                placeholder="请认真输入给用户的回复"
                lines={5}
                value={inputReply}
                onChange={({ target: { value } }) => {
                    setInputReply(value);
                }}
            />
            <FileRefUploader
                ref_token={fileRef}
                category="feedback-files/reply"
                onChange={({ startUpload, file }) => {
                    console.log("files", file);
                    if (file.status === "done" && file.ref_token) {
                        setFileRef(file.ref_token);
                    }
                    if (startUpload) {
                        uploaderRef.current = startUpload;
                    }
                }}
            />
        </Modal>
    );
}

function RemarksDrawer({ convId, visible, onClose }) {
    const [inputRemark, setInputRemark] = useState(undefined);
    const [deletingId, setDeletingId] = useState(undefined);
    const [remarkResponse, getRemarks] = useHttpGet("/feedback/remarks", {});
    const [addRes, addRemark] = useHttpGet("/feedback/addRemark", {});
    const [delRes, delRemark] = useHttpGet("/feedback/delRemark", {});

    useEffect(() => {
        if (!convId) return;
        getRemarks({ conv_id: convId });
        setInputRemark(undefined);
        setDeletingId(undefined);
    }, [convId]);
    useEffect(() => {
        if (addRes.loading == false && addRes.success) {
            inputRemark &&
                remarkResponse &&
                remarkResponse.data != undefined &&
                remarkResponse.data.push({
                    id: addRes.data,
                    content: inputRemark,
                    created_at: new Date().getTime() / 1000,
                    updated_at: new Date().getTime() / 1000,
                });
            setInputRemark(undefined);
        }
        if (delRes.loading == false && delRes.success && deletingId) {
            if (remarkResponse && remarkResponse.data) {
                remarkResponse.data = remarkResponse.data.filter(v => {
                    return v.id != deletingId;
                });
            }
            setDeletingId(undefined);
        }
    }, [addRes, delRes, deletingId]);

    return (
        <Drawer
            title="备注记录"
            placement="right"
            closable={true}
            onClose={() => {
                onClose();
            }}
            visible={visible}
            getContainer={true}
        >
            <LoadingResponseWrapper
                loading={remarkResponse.loading}
                data={remarkResponse.data || []}
                renderContent={remarks => {
                    return (
                        <>
                            {remarks.map(remark => {
                                return (
                                    <Comment
                                        key={"remark_" + remark.id}
                                        actions={[
                                            <span
                                                key="comment-basic-reply-to"
                                                onClick={() => {
                                                    confirm({
                                                        title: "确定删除这个备注吗？",
                                                        onOk: () => {
                                                            setDeletingId(
                                                                remark.id
                                                            );
                                                            delRemark({
                                                                conv_id: convId,
                                                                remark_id:
                                                                    remark.id,
                                                            });
                                                        },
                                                    });
                                                }}
                                            >
                                                删除此备注
                                            </span>,
                                        ]}
                                        author={
                                            <span>
                                                {remark.replier_name || "客服"}
                                            </span>
                                        }
                                        content={<p>{remark.content}</p>}
                                        datetime={
                                            <Tooltip
                                                title={moment(
                                                    remark.created_at * 1000
                                                ).format("YYYY-MM-DD HH:mm:ss")}
                                            >
                                                <span>
                                                    {formatDateAgo(
                                                        new Date(
                                                            remark.created_at *
                                                                1000
                                                        ),
                                                        "zh_CN"
                                                    )}
                                                </span>
                                            </Tooltip>
                                        }
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
            <TextArea
                value={inputRemark}
                onChange={({ target: { value } }) => {
                    console.log(value);
                    setInputRemark(value);
                }}
                rows={2}
                style={{ marginBottom: 5 }}
            />
            <Button
                style={{ float: "right" }}
                onClick={() => {
                    if (inputRemark) {
                        let newRemark = inputRemark;
                        addRemark({
                            conv_id: convId,
                            remark: newRemark,
                        });
                    }
                }}
            >
                添加备注
            </Button>
        </Drawer>
    );
}

import DisplayComponent from "../components/displays/Display"
import {conditionsMatched} from "./conditionsMatcher"
import {buildUri} from "./uriBuilder"

const EMPTY_VAR_STR = '_65KHF53aChpTAbzBxM4jwg9U6$5SlJQ_'

const FieldComponents = {
    Input: 'Input',
    Select: 'Select',
    RadioGroup: 'RadioGroup',
    Cascader: 'Cascader',
    CheckboxGroup: 'CheckboxGroup',
    FileUpload: 'FileUpload',
    FileRefUpload: 'FileRefUpload',
    TreeSelect: 'TreeSelect',
    ItemEditorList: 'ItemEditorList',
    Switch: 'Switch',
    DatePicker: 'DatePicker',
    SourceDataSelector: 'SourceDataSelector',
}

function isValidOptionValue(options, val) {

    if (!options || options.length === 0) {
        return false
    }

    return options.map(({value}) => {
        return '' + value
    }).indexOf('' + val) >= 0
}

function updateInnerEditor(item, valueSet, updatedKeys = null) {
    const {key, value, extraProps} = item
    const { editor, from_parent, fix_parent_keys, sum_column_keys } = extraProps
    const fromParentKeys = from_parent ? from_parent.split(',') : []
    const fixParentKeys = fix_parent_keys ? fix_parent_keys.split(',') : []

    item.sumColumnKeys = sum_column_keys ? sum_column_keys.split(',') : []

    if (updatedKeys && updatedKeys.length) {
        if (!fromParentKeys.length) {
            return;
        }
        let found = false;
        for (let updatedKey of updatedKeys) {
            if (fromParentKeys.indexOf(updatedKey) >= 0) {
                found = true;
                break
            }
        }
        if (!found) {
            return
        }
        // 为什么这么写我也不知道。是根据之前的逻辑改的。
    }

    if (fromParentKeys.length > 0) {
        const parentValues = {}
        for (const parentKeyToKey of fromParentKeys) {
            const [parentKey, childKey] = parentKeyToKey.split(":");
            parentValues[childKey || parentKey] = valueSet[parentKey]
        }
        item.fromParentState = parentValues
        item.fixParentKeys = fixParentKeys
    }


    const currentItems = valueSet[key]
    if (currentItems && currentItems.length > 0 && currentItems[0].rows) {
        const newItems = []
        for (let item of currentItems) {
            const newValueSet = {...item.valueSet}
            for (const parentKey of fromParentKeys) {
                newValueSet[parentKey] = valueSet[parentKey]
            }
            const itemEditor = JSON.parse(JSON.stringify(editor))
            const rows = buildEditorRows(itemEditor.rows, newValueSet, undefined, fixParentKeys)
            newItems.push({
                rows: rows,
                valueSet: newValueSet
            })
        }
        valueSet[key] = newItems
    } else {
        const editingItems = []
        const values = value || (extraProps.emptyFirst ? [] : [{}])
        for (let innerValueSet of values) {
            for (const parentKey of fromParentKeys) {
                innerValueSet[parentKey] = valueSet[parentKey]
            }
            const itemEditor = JSON.parse(JSON.stringify(editor))
            const rows = buildEditorRows(itemEditor.rows, innerValueSet, undefined, fixParentKeys)
            editingItems.push({
                rows,
                valueSet: innerValueSet
            })
        }
        valueSet[key] = editingItems
    }
}

function buildEditorRows(rows, valueSet = {}, defaultKeys = [], fixedKeys = [], ignoreShowCondition = false) {
    const convertedRows = []

    for (const row of rows) {
        const convertedRow = {hidden: true, items: []}
        for (const item of row) {

            const {key, disabled, value, component, showCondition, source, extraProps} = item
            item.valueFixed = fixedKeys.indexOf(key) >= 0
            // item.valueFixed = disabled
            item.hidden = !ignoreShowCondition && showCondition && !conditionsMatched(showCondition, valueSet);

            if (valueSet[key] === undefined && !item.valueFixed && (defaultKeys.indexOf(key) < 0)) {
                valueSet[key] = value
            }

            if (component === 'ItemEditorList') {
                updateInnerEditor(item, valueSet, null)
            }

            if (!item.hidden) {
                if (source) {
                    if (source.uri) {
                        source.compiledUri = buildUri(source.uri, valueSet)
                        if (source.compiledUri.indexOf('?') > 0) {
                            const parsedParams = new URLSearchParams(source.compiledUri.substr(source.compiledUri.indexOf('?')))
                            source.parsedParams = {};
                            for (const [key, value] of parsedParams) {
                                source.parsedParams[key] = value
                            }
                        }

                    }
                }

            }

            convertedRow.items.push(item)
            convertedRow.hidden = convertedRow.hidden && item.hidden
        }

        convertedRow.items.length > 0 && convertedRows.push(convertedRow)
    }

    return convertedRows
}

function updateEditorOnValueChange({rows, valueSet}, ...updatePairs) {
    const updatedValueSet = {...valueSet}
    const updatedRows = [...rows]
    const updatedKeys = [];
    updatePairs.forEach(({ key: updatedKey, value }) => {
        updatedValueSet[updatedKey] = value
        updatedKeys.push(updatedKey)
    })
    
    for (const row of updatedRows) {
        row.hidden = true
        for (const item of row.items) {
            const {showCondition, key, source, component} = item
            if (showCondition) {
                item.hidden = !conditionsMatched(showCondition, updatedValueSet)
            }
            if (item.hidden) {
                // delete valueSet[key]
            } else {
                if (updatedValueSet[key] === undefined) {
                    updatedValueSet[key] = item.value
                }

                if (source && source.uri) {
                    source.compiledUri = buildUri(source.uri, updatedValueSet)
                    if (source.compiledUri.indexOf('?') > 0) {
                        const parsedParams = new URLSearchParams(source.compiledUri.substr(source.compiledUri.indexOf('?')))
                        source.parsedParams = {};
                        for (const [key, value] of parsedParams) {
                            source.parsedParams[key] = value
                        }
                    }
                }

                if (component === 'ItemEditorList') {
                    updateInnerEditor(item, updatedValueSet, updatedKeys)
                }

                if (item.validator) {
                    const validateResult = validateEditorField(item, updatedValueSet[key])
                    if (validateResult === true) {
                        item.validator = null
                    }
                }
            }
            row.hidden = (row.hidden && item.hidden)
        }
    }

    return {
        rows: updatedRows,
        valueSet: updatedValueSet
    }
}

function validateEditorField(item, val) {

    const { component, required } = item
    const label = item.label || ''
    switch (component) {
        case FieldComponents.Input:
            if (required) {
                if (!val) {
                    return '请填写' + label
                }

                if (typeof val === 'string') {
                    val = val.trim()
                    if (val.length === 0) {
                        return '请填写' + label
                    }
                }
            }

            if (item.regex && item.regex.length) {
                let re = new RegExp(item.regex)
                if (!re.test(val)) {
                    return label + '格式不对'
                }
            }
            break
        case FieldComponents.Select:
            if (!required)
                break;
            if (val === null || val === undefined || val === '') {
                return '请选择' + label
            }
            if (item.extraProps && (['multiple', 'tags'].includes(item.extraProps.mode)) && val.length > 0) {
                val = val.split(',')
            }

            if (!Array.isArray(val)) {
                if (!isValidOptionValue(item.source.options, val)) {
                    return '请选择' + label
                }
            } else {
                if (val.length === 0) {
                    return '请选择' + label
                }
            }
            break
        case FieldComponents.Cascader:
            if (required && (!val || (Array.isArray(val) && val.length === 0))) {
                return '请选择' + label
            }
            break
        case FieldComponents.SourceDataSelector:
            if (required && (!val || (Array.isArray(val) && val.length === 0))) {
                return '请选择' + label
            }
            break

        case FieldComponents.RadioGroup:
            if (required && (val === null || val === undefined || !isValidOptionValue(item.source.options, val))) {
                return '请选择' + label
            }
            break
        case FieldComponents.CheckboxGroup:
            if (required && (!val || val.length === 0)) {
                return label + '必须至少选择一个'
            }
            break
        case FieldComponents.FileUpload:
            if (required && !val.file) {
                return '请选择' + label
            }
            break
        case FieldComponents.FileRefUpload:
            if (required && !val.file) {
                return '请选择文件' + label
            }
            break;
        case FieldComponents.TreeSelect:
            break
        case FieldComponents.ItemEditorList:

            for (const v of val) {
                if (v.deleted) continue
                const itemEditor = v
                const validateResult = validateEditor(itemEditor)
                if (validateResult !== true) {
                    itemEditor.rows = validateResult
                    return '请完善' + label
                }
            }

            if (required && val.length === 0) {
                return '请完善' + label
            }

            break

        case FieldComponents.DatePicker:
            if (required) {
                if (!val) {
                    return '请选择' + label
                }
            }
            break
        default:
            if (required) {
                let result = !!val
                if (result && Array.isArray(val)){
                    result = !! val.length
                }
                if (result && typeof val === 'object') {
                    result = !! Object.keys(val).length
                }
                if (result) {
                    result = !(val === '{}' || val === '[]')
                }
                if (!result) {
                    console.log('validate component', component, label, val, "Error")
                    return '请完善' + label
                }
            }
            console.log('validate component', component, label, val, "PASS")
            break

    }

    return true
}


function validateEditor(state) {

    const {valueSet} = state
    const rows = [...state.rows]

    let validated = true
    for (const row of rows) {
        if (row.hidden) {
            continue
        }
        for (const item of row.items) {
            if (item.hidden) {
                continue
            }

            const help = validateEditorField(item, valueSet[item.key])
            if (help === true) {
                item.validator = null
                continue
            }
            validated = false
            if (item.component !== FieldComponents.ItemEditorList) {
                item.validator = {
                    help,
                    validateStatus: 'error',
                    hasFeedback: true
                }
            }
        }
    }

    return validated ? true : rows
}

function processFieldValue(item, val) {
    const {component} = item
    switch (component) {
        case FieldComponents.Input:
            return val ? val : (item.value ? EMPTY_VAR_STR : undefined)
        case FieldComponents.Switch:
            return val ? 1 : 0;
        case FieldComponents.Cascader:
            if (val) {
                if (Array.isArray(val) && val.length > 0) {
                    if (item.extraProps && item.extraProps.resultType === 'array') {
                        return val.join(',');
                    } else {
                        return val[val.length - 1];
                    }
                } else {
                    return val;
                }
            } else {
                return (item.value ? EMPTY_VAR_STR : undefined)
            }
        case FieldComponents.CheckboxGroup:
        case FieldComponents.TreeSelect:
            return (val && val.length > 0) ? val.join(',') : (item.value ? EMPTY_VAR_STR : undefined)
        case FieldComponents.FileUpload:
            if (!val || !val.file) {
                return EMPTY_VAR_STR
            }

            if (val.file.more) {
                const ret = {}
                ret[item.key] = val.file.path
                return {
                    ...ret,
                    ...val.file.more
                }
            }
            return val.file ? val.file.path : EMPTY_VAR_STR
        case FieldComponents.FileRefUpload:
            return val && val.file && val.file.ref_token ? val.file.ref_token : EMPTY_VAR_STR
        case FieldComponents.ItemEditorList:
            return val.map(({rows, valueSet, deleted}) => {
                const editorParams = buildRequestParams(rows, valueSet)
                const requestParams = {...valueSet, ...editorParams}
                return deleted ? {action: 'delete', value: requestParams.id}
                    : (requestParams.id > 0 ? {action: 'update', value: requestParams} : {
                        action: 'create',
                        value: requestParams
                    })
            })
        default:
            break
    }

    return val
}

function buildRequestParams(rows, values) {
    const params = {}
    for (const row of rows) {
        if (row.hidden) {
            continue
        }
        for (const item of row.items) {
            let {key, component} = item
            if (item.hidden) {
                continue
            }
            if (!key && item.extraProps && item.extraProps.key) {
                key = item.extraProps.key
            }
            const transformedValue = processFieldValue(item, values[key])
            if (transformedValue === undefined) {
                continue
            }

            params[key] = transformedValue
            if (component === FieldComponents.ItemEditorList) {
                continue
            }
            if (Array.isArray(transformedValue)) {
                params[key] = transformedValue.join()
            }

            if (typeof transformedValue === 'object' && !Array.isArray(transformedValue)) {
                delete params[key]
                for (const tk in transformedValue) {
                    params[tk] = transformedValue[tk]
                }
            }

        }
    }
    return params
}

function retrieveValueByLabel(label, rows, valueSet) {
    for (const row of rows) {
        const {hidden, items} = row
        if (hidden) {
            continue
        }

        for (let item of items) {
            if (item.hidden) {
                continue
            }

            if (item.label === label && item.key) {
                return valueSet[item.key]
            }
        }
    }

    return undefined
}

export {
    buildEditorRows,
    updateEditorOnValueChange,
    validateEditor,
    processFieldValue,
    buildRequestParams,
    retrieveValueByLabel,
    EMPTY_VAR_STR
}

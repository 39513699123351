import React, {useCallback, useEffect, useState} from "react"
import {useHttpApi, useHttpGet, useLocalStoredObject} from "../../core/hooks"
import {Spin, Result, Button, Tree, Card, Col, Row} from "antd"

import SourcedTree from "../df/SourcedTree"
import DisplayComponent from "./Display"


export default ({query, outline, outlineSpan, fixedFilterKey, onQueryUpdated, contentDisplay}) => {

    const {component: contentComponent, props: componentProps} = contentDisplay
    const [selectedKey, setSelectedKey] = useState(query[fixedFilterKey])

    useEffect(() => {
        setSelectedKey(query[fixedFilterKey])
    }, [query])

    const componentQuery = {...query}
    if (selectedKey) {
        componentQuery[fixedFilterKey] = selectedKey
    }

    return <Card bordered={false}><Row style={{display: 'flex'}}>

        <Col className='leftTree'>
            <SourcedTree
                defaultExpandAll={true}
                showLine={true}
                withAll={true}
                selectedKeys={[selectedKey]}
                onSelect={(keys) => {
                    const filteredValue = keys.length ? keys[0] : undefined
                    if (onQueryUpdated) {
                        const updates = {page:1, page_size:20}
                        updates[fixedFilterKey] = filteredValue === 'all' ? undefined : filteredValue
                        onQueryUpdated(updates)
                    } else {
                        setSelectedKey(filteredValue)
                    }
                }}
                {...outline} />
        </Col>
        <Col flex="1 1">
            <DisplayComponent
                component={contentComponent}
                props={{
                    ...componentProps,
                    query: componentQuery,
                    onQueryUpdated: onQueryUpdated ? onQueryUpdated : null,
                    showPadding: false
                }}
            />
        </Col>
    </Row>
    </Card>
}

import React from "react";

import { DatePicker } from "antd";
import moment from "moment";

import DisplayComponent from "./Display";

const date = new Date();
const M = date.getMonth() + 1;
const month = M < 10 ? "0" + M : M;
const year = date.getFullYear().toString();
const thisMonth = year + month;

const buildDisplayProps = (tabs, month, tabContentProps) => {
    const { fixedFilterKey, component, uri } = tabContentProps;

    return {
        component: "TopTabbedDisplay",
        props: {
            fixedFilterKey,
            tabs: tabs.map(tab => {
                const query = {};
                query[fixedFilterKey] = tab.value;
                query.month = month;
                return {
                    ...tab,
                    content: {
                        component,
                        props: {
                            uri,
                            query,
                        },
                    },
                };
            }),
        },
    };
};

export default ({ query, onQueryUpdated, tabs, tabContentProps }) => {
    const displayProps = buildDisplayProps(
        tabs,
        query.month || thisMonth,
        tabContentProps
    );

    return (
        <div>
            <div style={{ padding: "16px 16px 0 24px" }}>
                <DatePicker
                    size="middle"
                    value={moment(query.month || thisMonth, "YYYYMM")}
                    onChange={(date, dateString) => {
                        const M = date.$M + 1;
                        const month = date.$y + "" + (M < 10 ? "0" + M : M);
                        onQueryUpdated && onQueryUpdated({ month });
                    }}
                    format={"YYYY 年 MM 月"}
                    picker="month"
                    style={{ width: "200px" }}
                />
            </div>
            <DisplayComponent
                {...displayProps}
                query={query}
                onQueryUpdated={onQueryUpdated}
            />
        </div>
    );
};

import React, { useCallback, useEffect, useState } from "react";

import TemplateEditorFormItem from "../df/TemplateEditorFormItem";
import { useHttpGet, useOpenPage } from "../../core/hooks";
import { Button, Col, Form, Row } from "antd";
import { buildUri } from "../../core/uriBuilder";
import { conditionsMatched } from "../../core/conditionsMatcher";

export default ({
    uri,
    query,
    onQueryChange,
    extraState = {},
    fixedQuery = {}, // 仅用于设置一个disable
    isOutlined,
    tableExporter,
}) => {
    const [valueSet, setValueSet] = useState(query || {});
    const [response, requestHeader] = useHttpGet(uri);
    const [uriImplicitValues, setImplicit] = useState({});
    const { data } = response;

    useEffect(() => {
        setValueSet(query || {});
    }, [query]);

    useEffect(() => {
        let search = new URLSearchParams(uri.substr(uri.indexOf("?")));
        for (let key of search.keys()) {
            uriImplicitValues[key] = search.get(key);
        }
        setImplicit({ ...uriImplicitValues });
        requestHeader();
    }, [uri]);

    const emitSearch = useCallback(
        values => {
            onQueryChange && onQueryChange({ ...values, page: 1 });
        },
        [onQueryChange]
    );

    const resetSearchParams = useCallback((values, rows) => {
        const currentValueSet = { ...values };
        for (let items of rows) {
            for (let item of items) {
                currentValueSet[item.key] = undefined;
            }
        }
        return currentValueSet;
    }, []);

    let findOtherComponent = false;
    if (data && data.rows.length > 0) {
        outer: for (let index = 0; index < data.rows.length; index++) {
            const items = data.rows[index];
            for (let i2 = 0; i2 < items.length; i2++) {
                const { component } = items[i2];
                // if (component != 'Select' && component != 'Cascader') {
                findOtherComponent = true;
                break outer;
                // }
            }
        }
    }
    const moreFix = {};
    if (data && data.implicit && data.implicit.length) {
        for (let key of data.implicit) {
            if (uriImplicitValues[key]) {
                moreFix[key] = uriImplicitValues[key];
            }
        }
    }
    const exportable = !!tableExporter;
    const buildButtonArea = exportable
        ? span => {
              return (
                  <Col span={span} style={{ textAlign: "left", padding: "0" }}>
                      <Button
                          size="small"
                          style={{ marginTop: 4 }}
                          onClick={() => {
                              // exportFunction && exportFunction({...valueSet})
                              tableExporter({ ...valueSet });
                          }}
                      >
                          导出
                      </Button>
                  </Col>
              );
          }
        : () => undefined;

    const onChangeHandlerGenerator = useCallback(
        item => {
            return e => {
                const { component, key } = item;
                let value =
                    typeof e === "object" && e.target ? e.target.value : e;
                const newValues = { ...valueSet };
                const oldValue = newValues[key];
                if (value !== undefined && value !== "") {
                    newValues[key] = value;
                } else {
                    newValues[key] = "";
                }

                if (component === "Cascader") {
                    if (Array.isArray(value) && value.length > 0) {
                        value = value[value.length - 1];
                        newValues[key] = value;
                    }
                    setValueSet(newValues);
                    emitSearch(newValues);
                } else if (
                    component === "Select" ||
                    component === "DateRangePicker" ||
                    component === "DatePicker"
                ) {
                    setValueSet(newValues);
                    if (
                        JSON.stringify(oldValue) !== JSON.stringify(value) &&
                        oldValue !==
                            (value && value.join ? value.join() : value)
                    ) {
                        emitSearch(newValues);
                    } else {
                    }
                } else {
                    setValueSet(newValues);
                }
            };
        },
        [emitSearch, valueSet, setValueSet]
    );

    let leftSpan = 24;
    const InsideForm = (
        <Form
            layout={"horizontal"}
            onFinish={() => {
                emitSearch(valueSet);
            }}
        >
            {data &&
                data.rows.map((items, rowIdx) => {
                    leftSpan = 24;
                    return (
                        <Row
                            type="flex"
                            key={rowIdx}
                            gutter={[8, 8]}
                            style={{ paddingBottom: 0 }}
                        >
                            {items.map((item, itemIdx) => {
                                const {
                                    key,
                                    label,
                                    showCondition,
                                    placeholder,
                                    span,
                                    required,
                                    extraProps,
                                    source,
                                } = item;
                                if (
                                    showCondition &&
                                    !conditionsMatched(showCondition, {
                                        ...extraState,
                                        ...moreFix,
                                    })
                                ) {
                                    return;
                                }
                                let newProps = Object.assign(
                                    !extraProps ? {} : extraProps,
                                    {
                                        placeholder:
                                            extraProps && extraProps.placeholder
                                                ? extraProps.placeholder
                                                : label,
                                    }
                                );

                                leftSpan -= span;
                                if (source && source.uri) {
                                    let f = { ...extraState, ...moreFix };
                                    source.compiledUri = buildUri(
                                        source.uri,
                                        f
                                    );
                                }
                                const finallKey =
                                    extraProps && extraProps.key
                                        ? extraProps.key
                                        : key;
                                item.key = finallKey;
                                return (
                                    <Col
                                        span={span < 8 ? span : 6}
                                        key={itemIdx}
                                    >
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            label=""
                                            required={required}
                                        >
                                            <TemplateEditorFormItem
                                                item={item}
                                                disabled={
                                                    fixedQuery?.[finallKey] ||
                                                    false
                                                }
                                                value={
                                                    fixedQuery?.[finallKey] ??
                                                    valueSet[finallKey] ??
                                                    extraState?.[finallKey]
                                                }
                                                onChange={onChangeHandlerGenerator(
                                                    item
                                                )}
                                                extraProps={newProps}
                                            />
                                        </Form.Item>
                                    </Col>
                                );
                            })}
                            <Button
                                htmlType="submit"
                                style={{ display: "none" }}
                            />
                            {exportable && buildButtonArea(1)}
                        </Row>
                    );
                })}
        </Form>
    );

    return (
        <div className={isOutlined ? "isOutlinedStyle" : "commonStyle"}>
            {InsideForm}
        </div>
    );
};

import React from "react"
import { DatePicker } from "antd"
import moment from "moment"

export default ({ value, onChange = (f) => { }, ...others}) =>  {
  return <DatePicker
    value={ value ? moment(value * 1000) : undefined}
    { ...others}
    onChange={(moment) => {
      onChange(moment?moment.unix():null)
    }}
  />
}

import React, {useEffect, useState} from "react"
import {useHttpGet} from "../../../core/hooks"
import {Input, InputNumber, Table} from "antd"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {renderItemFieldValue} from "../../../core/domUtil"
import { EditFilled } from "@ant-design/icons"


// function inputNumbersValid(inputNumbers) {
//     let total = 0
//     for (let id in inputNumbers) {
//         if (inputNumbers[id] < 0) {
//             return false
//         }
//         total += parseInt(inputNumbers[id], 10)
//     }
//
//     return total > 0
// }

export default (props) => {

    const {valueSet, rows, valueSetUpdater, uri} = props

    console.log(valueSet)

    const {id: task_id} = valueSet
    const [response, requestItemsContent] = useHttpGet(uri)

    const [editingMap, setEditingMap] = useState({})
    useEffect(() => {
        requestItemsContent({id: task_id})
    }, [requestItemsContent, task_id])


    return <LoadingResponseWrapper
        {...response}
        refresh={requestItemsContent}
        renderContent={({itemsContent}) => {

            return <Table
                size={'small'}
                bordered={true}
                pagination={false}
                dataSource={itemsContent.items}
                rowKey={({id}) => id}>

                <Table.Column dataIndex={'status'} title={''}
                              align={'center'}
                              key={itemsContent.displayFields.length + 3}
                              render={(_a, _b, index) => {
                                  return <b>{index + 1}</b>
                              }}/>

                {itemsContent.displayFields.map((displayField, columnIdx) => {
                    const {key, title} = displayField
                    if (key === 'description') return null;
                    let isQuantity = key.indexOf('_quantity') > 0
                    return <Table.Column dataIndex={key} title={isQuantity ? <div>
                        {title}<EditFilled alt='按此列快捷填写' style={{cursor: 'pointer', display: 'inline'}} onClick={() => {
                            itemsContent.items.forEach((record) => {
                                const {id, _rows_} = record
                                let rowValue = _rows_ ?? record
                                editingMap[id] = rowValue[key]
                            })
                            setEditingMap({...editingMap})
                            valueSetUpdater && valueSetUpdater({quantities: JSON.stringify(editingMap)}, true)
                        }} />
                    </div> : title} key={columnIdx}
                                         align={'center'}
                                         render={(cellVal, record) => {
                                             if (isQuantity){
                                                const {id} = record
                                                return <div style={{cursor: 'pointer'}} onClick={() => {
                                                    editingMap[id] = cellVal
                                                    setEditingMap({...editingMap})
                                                    valueSetUpdater && valueSetUpdater({quantities: JSON.stringify(editingMap)}, true)
                                                }}>
                                                    {renderItemFieldValue(displayField, record)}
                                                </div>
                                             } else {
                                                return renderItemFieldValue(displayField, record)
                                             }
                                         }}/>

                })}

                <Table.Column dataIndex={'commit_number'} title={'数量'}
                              align={'center'}
                              key={itemsContent.displayFields.length}
                              render={(cellVal, record) => {
                                  const {id} = record
                                  return <InputNumber value={editingMap[id]}
                                                // max={record.waiting_quantity || record.planned_quantity}
                                                // 为了让服务端弹出错误 TODO 这里应该写一个前端warning
                                                min={0}
                                                style={{width: 120}}
                                                onChange={(e) => {
                                                    editingMap[id] = (typeof e === 'object' && e && e.target) ? e.target.value : e
                                                    setEditingMap({...editingMap})
                                                    valueSetUpdater && valueSetUpdater({quantities: JSON.stringify(editingMap)}, true)
                                                }}/>

                              }}/>

            </Table>


        }}
    />

}

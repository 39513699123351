import React from "react"
import {Spin, Result, Button} from "antd"

export default ({refresh, error, data, loading, renderContent}) => {
    if (error) {
        return <div className="full-content-center">
            <Result
                status="error"
                title={error.desc}
                extra={
                    !!refresh && <Button type="primary" key="console" onClick={() => {
                        refresh()
                    }}>重试</Button>
                }/>
        </div>
    }

    if (loading) {
        return <div className="full-content-center"><Spin/></div>
    }
    if (!loading && !data) {
        return <div className="full-content-center"></div>
    }
 
    return renderContent(data)
}

import React, {useState} from "react"
import {Form, Card, Input, Button, Result, Radio} from "antd"

import ReactJson from "react-json-view"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import {useHttpGet} from "../../core/hooks"

export default () => {

    const [form] = Form.useForm()
    const [response, parsePayload] = useHttpGet('/iot/parsePayload')
    const [parserEnabled, setParserEnabled] = useState(false)
    const [hexKeyVisible, setHexKeyVisible] = useState(true)

    // const


    return <div>
        <PageHeaderWrapper title={'蓝牙广播/Pheasant协议解析'}/>
        <Card bordered={true} style={{margin: 20}}>
            <div>
                <Form
                    layout={'vertical'}
                    onFinish={(values) => {

                        for (const key in values) {
                            if (values[key] === undefined) {
                                delete values[key]
                            }
                        }

                        parsePayload(values)
                    }}
                    onValuesChange={(changedValues, values) => {
                        setParserEnabled(values.payload && values.payload.length > (hexKeyVisible ? 10 : 20))
                        setHexKeyVisible(values.type === 'bin')
                    }}
                    form={form}>
                    <Form.Item label="类型" name={'type'} >
                        <Radio.Group defaultValue={'bin'}>
                            <Radio.Button key='bin' value='bin'>
                                二进制协议
                            </Radio.Button>
                            <Radio.Button key='adv' value='adv'>
                                广播
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="PAYLOAD" name={'payload'}>
                        <Input.TextArea placeholder={hexKeyVisible ? "支持 base64, hex 以及类似 0x47,0x43 这种字符串" : "HEX"}/>
                    </Form.Item>
                    {hexKeyVisible && <Form.Item label="HEX KEY" name={'hex_key'}>
                        <Input placeholder="HEX KEY"/>
                    </Form.Item>}
                    <Form.Item>
                        <Button disabled={!parserEnabled} type="primary" htmlType="submit">解析</Button>
                    </Form.Item>
                </Form>
            </div>

            <div>
                {response && !response.error && response.data &&
                <ReactJson src={response.data} theme="monokai" name={false} displayDataTypes={false}/>}

                {response && response.error && <Result status="error" title={response.error.desc}/>}
            </div>

        </Card>

    </div>


}

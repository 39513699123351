import { Card, Cascader, Select } from "antd"
import React, { useEffect, useMemo } from "react"
import { useHttpApi, useHttpGet } from "../../../core/hooks"


export default ({ props: { key, label } = {}, valueSet, value, valueSetUpdater, onChange, noneDefault, ...other }) => {
    const selValue = (valueSet && key) ? valueSet[key] : (value || 0);
    const [{ data }, getOptions] = useHttpGet('/common/projectOptions?onlyProjects=1')
    useEffect(() => {
        getOptions()
    }, []);

    let dataMap = useMemo(() => {
        return (data || []).reduce((last, curr) => {
            last[curr.value] = curr.label
            return last;
        }, { 0: noneDefault})
    }, [data])

    let projectOptions = useMemo(() => {
        return data ? noneDefault ? [{ value: 0, label: noneDefault }, ...data] : [...data] : [];
        
    }, [data])

    if (!data) {
        return <Select style={{ width: '100%' }} />
    }
    let itemProps = {
        onChange,
        value: selValue,
        options: projectOptions,
    }

    return <div>
        <div>{label}</div>
        <Select {...itemProps} style={{ width: '100%' }} allowClear onChange={(v) => {
            onChange(v||0, dataMap[v||0]?dataMap[v||0]:undefined)
         }}/>
    </div>
}
import React, {useEffect, useRef} from 'react'

export default ({lines}) => {

    console.log(lines)

    return <div className={'prev-container'}>

        {lines.map((line, idx) => {

            return <pre key={idx} className={'prev-line'}>
                {line}
            </pre>

        })}

    </div>

}

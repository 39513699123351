import React, {useEffect, useState} from "react"
import {Input, Result, Table, Typography} from "antd"
import {useHttpGet} from "../../../core/hooks"
import {useActionExecutor} from "../../../core/actionExecuter"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {renderActions} from "../../../core/domUtil"

const {Text} = Typography
const buildEditingInfo = (dailyStat) => {

    const info = {}
    for (let k in dailyStat) {

        const v = dailyStat[k]

        if (typeof v !== 'object') {
            continue
        }

        if (!v.editable) {
            continue
        }

        info[k] = v.editValues

    }

    return info

}

export default (props) => {
    const {uri, query} = props

    const [forecastResponse, requestForecast] = useHttpGet(uri)
    const [forecastQuery, setForecastQuery] = useState(query)

    const [actionExecuting, actionResult, actionExecutor] = useActionExecutor()

    const [editingMap, setEditingMap] = useState({})

    useEffect(() => {
        requestForecast(forecastQuery)
    }, [forecastQuery.month, forecastQuery.project_id, actionResult])

    useEffect(() => {
        setForecastQuery(query)
    }, [query])

    const renderTableColumn = (column, index) => {
        const {title, children, key, fixed} = column

        if (children && children.length) {
            return <Table.ColumnGroup
                key={index}
                title={title}>
                {children.map(renderTableColumn)}
            </Table.ColumnGroup>
        }
        return <Table.Column
            key={key}
            title={title}
            dataIndex={key}
            width={key === 'client' ? 160 : 120}
            className={
                key.indexOf('forecast_sale') >= 0 ? 'forecastColumnStyle' : ''
            }
            fixed={fixed}
            render={(cellVal, forecast, index) => {

                const {value, style, name, format, editable, editValues} = cellVal

                const valueFormat = value == 0 ?
                    <span
                        style={{color: '#bcbcbc'}}> - </span> : (format === 'money' ? ('¥' + parseFloat(value).toLocaleString('en')) : parseFloat(value).toLocaleString('en'))

                const editingInfo = editingMap[index]
                if (!editingInfo || !editable) {
                    if (style === 'good') {
                        return <Text type="success">{name || valueFormat}</Text>
                    } else if (style === 'bad') {
                        return <Text type="danger">{name || valueFormat}</Text>
                    } else {
                        return name || valueFormat
                    }
                }

                return <span>
                    {Object.keys(editValues).map((editKey, editIndex) => {
                        return <Input key={editIndex} value={editValues[editKey]} style={{width: 100, marginTop: 5}}
                                      prefix={editKey.startsWith('money') ? '￥' : ''}
                                      onChange={(e) => {
                                          const editValues = editingInfo[key]
                                          editValues[editKey] = (typeof e === 'object' && e && e.target) ? e.target.value : e
                                          editingInfo[key] = editValues
                                          editingMap[index] = editingInfo
                                          setEditingMap({...editingMap})
                                      }}/>
                    })}
                </span>


            }}>
        </Table.Column>
    }


    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    return <LoadingResponseWrapper
        {...forecastResponse}
        refresh={() => {
            requestForecast(forecastQuery)
        }}
        renderContent={({columns, items = [], actions: topActions, editable: actionAllowed = false}) => {

            if (!columns) {
                return <Result status="error" title={'项目下未配置商品信息'}/>
            }

            return <div ref={elementRef}>
                {topActions && topActions.length > 0 && <div className="action-search-bar"
                    style={{ marginBottom: '10px' }}>{renderActions(topActions, actionExecutor, forecastQuery, 3, { type: 'button'})}</div>}
                <Table
                    dataSource={items}
                    size={'small'}
                    bordered
                    sticky
                    scroll={{x: tableWidth}}
                    rowClassName={record => {
                        if (record.type === 'total_summary') {
                            return 'totalSummaryCol'
                        } else if (record.type === 'type_summary') {
                            return 'typeSummaryCol'
                        }
                    }}
                    rowKey={(record) => 'row' + record.day.name}
                    pagination={false}>
                    {columns.map(renderTableColumn)}
                    {actionAllowed && <Table.Column title='操作' fixed='right' width={90}
                                                    render={(cellVal, dailyStat, index) => {
                                                        const {editable, editAction} = dailyStat

                                                        if (editable) {

                                                            const editingInfo = editingMap[index]
                                                            if (!editingInfo) {
                                                                return <span>
                                                                        <a onClick={() => {
                                                                            const newMap = {...editingMap}
                                                                            newMap[index] = buildEditingInfo(dailyStat)
                                                                            setEditingMap(newMap)
                                                                        }}>编辑</a>
                                                                    </span>
                                                            } else {

                                                                return <span>
                                                      <a onClick={() => {
                                                          editAction.params.stat = JSON.stringify(editingInfo)
                                                          const handler = actionExecutor.generateActionHandler(editAction)
                                                          handler()
                                                          delete editingMap[index]
                                                          setEditingMap({...editingMap})
                                                      }}>保存</a>
                                                                    &nbsp;&nbsp;
                                                                    <a onClick={() => {
                                                                        delete editingMap[index]
                                                                        setEditingMap({...editingMap})
                                                                    }}>取消</a>

                                                  </span>
                                                            }


                                                        }

                                                        return ''

                                                    }}/>}
                </Table>

            </div>
        }}
    />

}

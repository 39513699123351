import React ,{useEffect ,useState} from "react";
import { Form, Input, Button ,message} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
  useHttpPost,
} from "../../../../core/hooks";

const LoginForm = ({
  setUser,
  setClientToken,
  checkClintResp,
}) => {
  const [loginResp, login] = useHttpPost("/quickOrder/login");
  const [hasInputVcode, setIsVcode] = useState(false);
  useEffect(() => {
    // 处理登录响应变化
    if (loginResp.success) {
      if (hasInputVcode) {
        // 处理验证码登录成功情况
        if (loginResp.data?.client_token) {
          let { client_token, ...user } = loginResp.data;
          const displayName = user.name + (user.client_name ? "@" + user.client_name : "");
          user.displayName = displayName;
          setUser(user);
          setClientToken(client_token);
          message.success("欢迎：" + displayName);
          setIsVcode(false);
        }
      } else {
        // 需要输入验证码
        setIsVcode(true);
      }
    }
  }, [loginResp, hasInputVcode]);
  

  return (
    <div className="login-container-wrapper">
      <div className="login-container">
        <Form
          onFinish={(values) => {
            const { login_account, vcode } = values;
            login({ login_account, vcode: vcode || "" });
          }}
          className={"login-form"}
        >
          <div className={"login-logo"} />
          <Form.Item name={"login_account"} required={true}>
            <Input
              disabled={loginResp.loading || checkClintResp.loading}
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="输入手机号码"
              autoFocus={!hasInputVcode}
              allowClear={true}
            />
          </Form.Item>

          <Form.Item name={"vcode"} required={false}>
            <Input
              disabled={
                !hasInputVcode || loginResp.loading || checkClintResp.loading
              }
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="输入验证码"
              autoFocus={hasInputVcode}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loginResp.loading || checkClintResp.loading}
              type="primary"
              htmlType="submit"
              className={"login-form-button"}
            >
              {hasInputVcode ? "登录" : "发送验证码"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;

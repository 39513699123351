import React from "react";
import { Dropdown, Space } from "antd";
import { useHistory } from 'react-router-dom';
import { UserOutlined, CaretDownOutlined } from "@ant-design/icons";
import LogoIcon from "../../../../assets/logo_app.png";
import "./header.css";

const CustomHeader = ({
  tabIndex,
  setTabIndex,
  user,
  setClientToken,
  setUser,
  setGetUserLoading
}) => {
  const history = useHistory();

  const tabItems = [
    { id: "0", label: "下订单" },
    { id: "1", label: "订单追踪" },
  ];
  const items = [
    {
      key: "1",
      danger: true,
      label: (
        <div onClick={() => {
          setClientToken(null);
          setUser(null);
          setGetUserLoading(false)
        }}>
          退出登录
        </div>
      ),
    },
  ];

  return (
    <header>
      <div className="titleContainer">
        <div className="titleLeft">
          <img src={LogoIcon} className="logo" alt="" />
          <ul>
            {tabItems.map((item) => (
              <li key={item.id}>
                <button
                  onClick={() => {
                    setTabIndex(item.id)
                    history.push(`?tabIndex=${item.id}`)
                  }}
                  className={tabIndex === item.id ? "check-btn" : ""}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomLeft"
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <UserOutlined />
                {user?.displayName || "用户"}
                <CaretDownOutlined  />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      
    </header>
  );
};

export default CustomHeader;

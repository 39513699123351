import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHttpGet, useRouteQuery } from "../../core/hooks";
import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper";
import {
    Card,
    Col,
    Row,
    Descriptions,
    List,
    Table,
    Spin,
    Empty,
    Statistic,
} from "antd";
import HistoryChart from "../../components/biz/HistoryChart";
import { useActionExecutor } from "../../core/actionExecuter";
import { renderActions, renderItemFieldValue } from "../../core/domUtil";
import { useRouteMatch } from "react-router-dom";

import TemplateDisplayTable from "../../components/df/TemplateDisplayTable";
import AdminContext from "../../core/AdminContext";
import moment from "moment";
import { Chart, Geom, Axis, Tooltip, Legend } from "bizcharts";
import DataSet from "@antv/data-set";
import Slider from "bizcharts-plugin-slider";

function formatDayInt(text) {
    if (text % 100 == 0) return text;
    if (text % 100 == 1) {
        return (
            parseInt(text / 10000) + "年" + (parseInt(text / 100) % 100) + "月"
        );
    }
    return (parseInt(text / 100) % 100) + "月" + (text % 100) + "日";
}

export default () => {
    const [exchangeRateResponse, getExchangeRate] = useHttpGet(
        "/money/exchangeRate"
    );

    useEffect(() => {
        getExchangeRate();
    }, []);

    let now = moment().startOf("day");
    const dateRange = new DataSet({
        state: {
            start: now.add(-1, "months").unix(),
            end: now.unix(),
        },
    });
    const data1 = useMemo(() => {
        return (exchangeRateResponse.data || []).map(d => {
            let mom = moment(d.day_str);
            return {
                currency: d.currency,
                exchange_rate: parseFloat(d.exchange_rate),
                day: mom.format("YYYY-MM-DD"),
                datetime: mom.unix(),
            };
        });
    }, [exchangeRateResponse.data]);
    const dv = dateRange.createView("origin").source(data1);
    dv.transform({
        type: "filter",
        callback(obj) {
            return (
                obj.datetime >= dateRange.state.start &&
                obj.datetime <= dateRange.state.end
            );
        },
    });
    // const data = useMemo(() => {
    //     return (data1 || []).filter((data) => {
    //         return data.datetime >= start && data.datetime <= end
    //     })
    // }, [start, end, data1])

    // console.log('slide data', data1, start, end, data)

    return (
        <div>
            <PageHeaderWrapper title={"汇率"}></PageHeaderWrapper>
            <div className="page-content-wrapper">
                {exchangeRateResponse.loading ? (
                    <Spin />
                ) : (
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Chart
                                height={400}
                                data={dv}
                                forceFit
                                filter={[
                                    [
                                        "currency",
                                        currency =>
                                            currency === "usd" ||
                                            currency === "eur",
                                    ],
                                ]}
                            >
                                <Legend slidable={true} />
                                <Axis
                                    name="day"
                                    title={null}
                                    tickLine={null}
                                    line={{
                                        stroke: "#E6E6E6",
                                    }}
                                />
                                <Axis name="exchange_rate" />
                                <Tooltip crosshairs={{ type: "y" }} />
                                <Geom
                                    type="line"
                                    position="day*exchange_rate"
                                    size={2}
                                    tooltip={[
                                        "day*exchange_rate*currency",
                                        (day, value, currency) => {
                                            return {
                                                name: currency, // 要显示的名字
                                                value: value,
                                                title: day,
                                            };
                                        },
                                    ]}
                                    color="currency"
                                />
                                <Geom
                                    type="point"
                                    position="day*exchange_rate"
                                    size={4}
                                    shape={"circle"}
                                    style={{ lineWidth: 1 }}
                                    color="currency"
                                />
                            </Chart>
                            <Slider
                                fillerStyle={{
                                    fill: "#BDCCED",
                                    fillOpacity: 0.3,
                                }}
                                xAxis="day"
                                yAxis="exchange_rate"
                                // scales={{
                                //     time: {
                                //         type: "day",
                                //         tickCount: 10,
                                //         mask: "YYYY-MM-DD"
                                //     }
                                // }}
                                data={data1}
                                start={dateRange.state.start * 1000}
                                end={dateRange.state.end * 1000}
                                onChange={obj => {
                                    const {
                                        startValue,
                                        endValue,
                                        startText,
                                        endText,
                                    } = obj;
                                    console.log("slide value", obj);
                                    // setRange([startValue / 1000, endValue/ 1000])
                                    dateRange.setState(
                                        "start",
                                        startValue / 1000
                                    );
                                    dateRange.setState("end", endValue / 1000);
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

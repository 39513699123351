import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Input,
  Button,
  Result,
  Col,
  Row,
  Table,
  Checkbox,
  Tooltip,
  message,
} from "antd";

import PageHeaderWrapper from "../../components/PageHeaderWrapper";
import { useHttpGet } from "../../core/hooks";
import { useMqtt } from "../../core/mqtt/useMqtt";
import _ from "lodash";
import { Base64 } from "js-base64";

import moment from "moment";
import {
  FileFilled,
  CopyOutlined,
  UnlockOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { MqttStates } from "../../core/mqtt/states";
import "jsoneditor-react/es/editor.min.css";
import { parseUint8Arr } from "../../core/BinaryPacketParser";
import presentModal from "../../components/presentModal";
import DeviceLogViewer from "../../components/displays/custom/DeviceLogViewer";
const { TextArea } = Input;
const MAX_MESSAGE = 500;

const goodorder = [
  "pm25",
  "pm10",
  "co2",
  "tvoc",
  "tvoc_index",
  "temperature",
  "humidity",
  "noise",
  "lumen",
  "battery",
  "prob_temperature",
  "prob_humidity",
  "co2_percent",
];
const DeviceStateDisplay = ({
  mqtt,
  displayFields,
  refreshInterval,
  refreshCommand,
  isStarted,
  valuePath,
  forDebug = true,
  isBinaryData = false,
  mac,
}) => {
  const isJSONData = !isBinaryData;
  const [fields, setFields] = useState([]);
  const [messages, setMessages] = useState([]);
  const [publishMessage, setPublishMessage] = useState(
    typeof refreshCommand === "object"
      ? JSON.stringify(refreshCommand)
      : refreshCommand
  );
  const jsonOk = useMemo(() => {
    if (isJSONData && publishMessage) {
      try {
        JSON.parse(publishMessage);
        return true;
      } catch (e) {}
    }
    return false;
  }, [isJSONData, publishMessage]);
  const { publish, mqttState, subscribe } = useMqtt(mqtt, {
    parseMessageJson: isJSONData,
    onMessage: (message, topic) => {
      if (!isStarted) {
        return;
      }
      console.log(message, "from tipic:", topic);
      if (isJSONData) {
        let valueSet = _.get(message, valuePath, null);
        const item = {
          type: message.type,
          json: JSON.stringify(message),
        };
        if (!valueSet) {
          if (forDebug) {
            return;
          } else {
            valueSet = message;
          }
        } else {
          if (!forDebug && fields.length <= 3) {
            let keys = goodorder.filter((k) =>
              Object.keys(valueSet).includes(k)
            );
            if (fields.join(",") !== keys.join(",")) {
              setFields(keys);
            }
          }

          for (let k in valueSet) {
            if (forDebug || goodorder.indexOf(k) >= 0) {
              item[k] = valueSet[k].value;
            } else if (k === "timestamp" && valueSet[k].value) {
              item[k] = moment(valueSet[k].value * 1000).format("HH:mm:ss"); // February 7th 2021, 2:56:39 pm
            }
          }
        }

        if (!item.timestamp) {
          item.timestamp = moment().format("HH:mm:ss");
        }
        if (topic === mqtt.pubTopic) {
          item.type = "【命令】";
        }

        const newMessages = [item, ...messages];
        setMessages(
          newMessages.slice(0, MAX_MESSAGE + (newMessages.length % 2))
        );
      } else {
        // 二进制数据。
        const isCommand = topic === mqtt.pubTopic;
        const json = message instanceof Uint8Array
          ? Base64.fromUint8Array(message)
          : String.fromCharCode(...message);
        const raw = message instanceof Uint8Array
          ? Base64.fromUint8Array(message)
          : String.fromCharCode(...message);
        //Array.from(message).map((i) => '0x' + i.toString(16).padStart(2, '0')).join(' ');
        const item = {
          timestamp: moment().format("HH:mm:ss"),
          type: isCommand ? "【命令】" : "数据",
          json: json,
          raw: raw,
        };
        const newMessages = [item, ...messages];
        setMessages(
          newMessages.slice(0, MAX_MESSAGE + (newMessages.length % 2))
        );
      }
    },
    filter: (msg) => {
      return isJSONData ? (forDebug ? msg && msg.mac === mac : true) : true;
    },
  });

  useEffect(() => {
    console.log("mqtt state = ", mqttState);
    if (!forDebug && mqttState === MqttStates.SUBSCRIBED) {
      if (isBinaryData) {
        publish(Base64.fromBase64(publishMessage))
      } else {
        publish(publishMessage);
      }
      subscribe(mqtt.pubTopic);
    }
  }, [forDebug, mqttState, refreshCommand, isBinaryData]);

  useEffect(() => {
    if (!isStarted) {
      return () => {};
    }
    const timer = setInterval(
      () => {
        if (isBinaryData) {
          publish(Base64.fromBase64(publishMessage))
        } else {
          publish(publishMessage);
        }
      },
      refreshInterval * 1000,
      refreshCommand
    );

    return () => {
      clearInterval(timer);
    };
  }, [refreshInterval, refreshCommand, mqtt.pubTopic, isStarted, isBinaryData]);

  return (
    <div>
      <Row gutter={[8, 8]} style={{ marginBottom: 10 }}>
        <Col span={8}>
          <TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            autoComplete="off"
            spellCheck={false}
            onChange={(event) => {
              setPublishMessage(event.target.value);
            }}
            value={publishMessage}
            disabled={mqttState !== MqttStates.SUBSCRIBED}
            placeholder={"message"}
          />
        </Col>
        <Col>
          <Button
            disabled={mqttState !== MqttStates.SUBSCRIBED}
            onClick={(event) => {
              if (isBinaryData) {
                publish(Base64.fromBase64(publishMessage))
              } else {
                publish(publishMessage);
              }
              const newM = {
                timestamp: moment().format("HH:mm:ss"),
                type: "--> 发送",
                json: publishMessage,
                raw: publishMessage,
              };
              const newMessages = [newM, ...messages];
              setMessages(newMessages);
            }}
            type="primary"
          >
            发送
          </Button>
          <div>
            {isJSONData && (
              <span>
                JSON:{" "}
                {jsonOk ? (
                  <Tooltip placement="right" title="点击格式化，双击压缩">
                    <CheckCircleTwoTone
                      onDoubleClick={(event) => {
                        setPublishMessage(
                          JSON.stringify(JSON.parse(publishMessage))
                        );
                        event.preventDefault();
                      }}
                      onClick={(event) => {
                        setPublishMessage(
                          JSON.stringify(JSON.parse(publishMessage), null, 4)
                        );
                        event.preventDefault();
                      }}
                      twoToneColor="#52c41a"
                    />
                  </Tooltip>
                ) : (
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                )}
              </span>
            )}
          </div>
        </Col>
      </Row>
      {refreshCommand ? (
        <span>
          将每 {refreshInterval} 秒 自动发送:
          {typeof refreshCommand === "object"
            ? JSON.stringify(refreshCommand)
            : refreshCommand}
        </span>
      ) : null}
      <Table
        size={"small"}
        dataSource={messages}
        bordered={true}
        rowClassName={(item, k) => {
          return k === 0
            ? messages.length % 2
              ? "row-fade-background1"
              : "row-fade-background0"
            : "";
        }}
        rowKey={(value, index) => "row" + index}
        pagination={false}
      >
        {displayFields.map(({ key, label, dicts }) => (
          <Table.Column
            key={key}
            dataIndex={key}
            width={80}
            title={label}
            render={
              key === "json"
                ? (value, r, i) => {
                    return (
                      <Tooltip placement="right" title={value}>
                        <CopyOutlined
                          size={30}
                          onClick={() => {
                            navigator.clipboard.writeText(value);
                            message.info("已复制");
                          }}
                        />
                      </Tooltip>
                    );
                  }
                : dicts
                ? (value, r) => {
                    return dicts[value] || value;
                  }
                : null
            }
          />
        ))}
        {isJSONData ? (
          fields.map((field, f1) => (
            <Table.Column
              key={field}
              dataIndex={field}
              title={field}
              render={(value, record, i) => {
                if (record.type !== "12" && record.type !== "17") {
                  return {
                    props: { colSpan: f1 == 0 ? fields.length : 0 },
                    children: f1 == 0 ? record.json : null,
                  };
                }
                return value?.toFixed?.(2) ?? value;
              }}
            />
          ))
        ) : (
          <Table.Column
            key={"raw"}
            dataIndex={"raw"}
            title={"原始数据"}
            render={(value) => {
              return (
                <span>
                  {value}
                  <QuestionCircleFilled
                    style={{ marginLeft: 10 }}
                    size={40}
                    onClick={() => {
                      presentModal({
                        closable: true,
                        maskClosable: true,
                        width: "50vw",
                        content: (
                          <DeviceLogViewer
                            content={value}
                            uri={"/link/decodeContent"}
                            params={{ content: value }}
                          />
                        ),
                      });
                    }}
                  />
                </span>
              );
            }}
          />
        )}
      </Table>
    </div>
  );
};

export default () => {
  const [form] = Form.useForm();
  const [started, setStarted] = useState(false);
  const [response, getDeviceWatcher] = useHttpGet("/link/getDeviceWatcher");
  const [watcherEnabled, setWatcherEnabled] = useState(false);

  return (
    <div>
      <PageHeaderWrapper title={"远程设备观测"} />
      <div className={"page-content-wrapper"}>
        <Form
          layout={"horizontal"}
          onFinish={(values) => {
            for (const key in values) {
              if (values[key] === undefined) {
                delete values[key];
              }
            }

            if (!started) {
              getDeviceWatcher(values);
              setStarted(true);
            } else {
              setStarted(false);
            }
          }}
          onValuesChange={(changedValues, values) => {
            setWatcherEnabled(values.mac && values.mac.length >= 5);
          }}
          form={form}
        >
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Form.Item wrapperCol={24} name={"mac"}>
                <Input disabled={started} placeholder="MAC" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name={"forDebug"}
                initialValue={false}
                valuePropName="checked"
              >
                <Checkbox disabled={!watcherEnabled || started}>调试</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  disabled={!watcherEnabled}
                  type="primary"
                  htmlType="submit"
                >
                  {started ? "停止" : "开始"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {response && !response.loading && !response.error && response.data && (
          <DeviceStateDisplay {...response.data} isStarted={started} />
        )}
        {response && response.error && (
          <Result status="error" title={response.error.desc} />
        )}
      </div>
    </div>
  );
};

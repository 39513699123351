import React, {useCallback, useEffect, useState} from "react"
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"

import DisplayComponent from "./Display"

import {useActionExecutor} from "../../core/actionExecuter"
import { Card } from "antd"


export default (props) => {

    const {uri, query, fixedQuery} = props
    const [response, requestProfile] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        requestProfile({...query, ...fixedQuery})
    }, [query, requestProfile, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestProfile}
        renderContent={({displays}) => {
            return <div className="page-content-wrapper">
                {displays.map((display, idx) => {
                    if (display.component === 'TopTabbedDisplay' && display.props && display.props.tabs) {
                        return <div>
                            {display.props.tabs.map(({content, label}, idxi) => {
                                return <Card title={label} style={{marginTop: 5}}>
                                    <DisplayComponent {...content} actionExecutor={actionExecutor} key={idx + "-" + idxi}/>
                                </Card>
                            })}
                        </div>
                    }
                    return <DisplayComponent {...display} actionExecutor={actionExecutor} key={idx}/>
                })}
            </div>


        }}
    />

}

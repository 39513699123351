import React, { useEffect, useState } from "react"
import {    useHttpGet } from "../../core/hooks"
import {    useHistory } from 'react-router-dom'
import { Button, Card, List, Row, Col, Tag, Tabs, Badge, Skeleton, Empty, Typography } from "antd"

import moment from "moment"
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, ClockCircleOutlined } from "@ant-design/icons"
import Icon from "../../components/Icon";
const { Title } = Typography;
const { TabPane } = Tabs;

export default (props) => {
    const [dashboard, loadDashboard] = useHttpGet('/dashboard/setup')
    useEffect(() => {
        loadDashboard()
    }, [])
    let doCount = dashboard.data && dashboard.data.to_me && dashboard.data.to_me.count || 0
    let suspendCount = dashboard.data && dashboard.data.to_me_suspend && dashboard.data.to_me_suspend.count || 0
    return <div style={{ margin: 20 }}>
        <Tabs defaultActiveKey={'to_me'}>
            <TabPane tab="我发起的" key="start_by_me">
                <TaskList initLoading={dashboard.loading} initData={dashboard.data && dashboard.data.start_by_me} type='start_by_me' />
            </TabPane>
            <TabPane tab={<span>待处理{doCount ? <Badge count={doCount} /> : ""}</span>} key="to_me">
                <TaskList initLoading={dashboard.loading} initData={dashboard.data && dashboard.data.to_me} type='to_me' />
            </TabPane>
            {suspendCount && <TabPane tab={<span>已挂起{suspendCount ? <Badge count={suspendCount} /> : ""}</span>} key="to_me_suspend">
                <TaskList initLoading={dashboard.loading} initData={dashboard.data && dashboard.data.to_me_suspend} type='to_me_suspend' />
            </TabPane>}
            <TabPane tab="已处理" key="to_me_done">
                <TaskList initLoading={dashboard.loading} initData={dashboard.data && dashboard.data.to_me_done} type='to_me_done' />
            </TabPane>
        </Tabs>
    </div>
}

const TaskList = (props) => {
    const { initLoading, initData = {}, type } = props;
    const [totalCount, setTotalCount] = useState(0)
    const [items, setItems] = useState([])
    const [more, onLoadMore, clearResult] = useHttpGet('/dashboard/loadMore', {
        type: type
    })
    useEffect(() => {
        setTotalCount(initData.count || 0)
        setItems(initData.items || [])
    }, [initData.count])

    useEffect(() => {
        if (more.success && more.data) {
            setTotalCount(more.data.count)
            setItems([...items, ...more.data.items])
        }
    }, [more.data, more.loading, more.success])


    //window.dispatchEvent(new Event('resize'));
    const isLoading = initLoading || more.loading
    const hasMore = items.length < totalCount
    const loadMore =
        (!isLoading && hasMore) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={() => {
                    onLoadMore({offset: items.length, type: type, before_approval_flow_request_id: items[items.length - 1].id})
                }}>加载更多</Button>
            </div>
        ) : null;
    return <List
        loadMore={loadMore}
        dataSource={items}
        renderItem={(content, i) => {
            return <TaskCard content={content} type={type} />
        }}>
        {isLoading ? [...new Array(10)].map((_, i) => <Card key={'loading' + i} style={{ margin: 20 }} bordered={true} >
            <Skeleton active /></Card>) : undefined}
    </List>
}

const TaskCard = (props) => {
    const history = useHistory()
    const { content: { rows, created_at, status, event_name, url, ...boardInfo },type } = props
    const typeColor = {
        'order': '#3692FE',
        'money_plan':'#5BBFC1',
        'deliver':'#F59D32'
    }[event_name] ||    '#3692FE'


    const badgeColor = {
        0: { status: 'warning', label: '待审批' },
        1: { status: 'success', label: '已通过' },
        2: { status: 'error', label: '被驳回' },
        3: { status: 'default', label: '已撤销' },
        4: { status: 'error', label: '挂起等待' },
    }
    return <div className='approval-list' style={{ margin: 0 ,borderBottom:'#f0f0f0 1px solid'}} onClick={() => {
        history.push(url)
    }} >
        <Icon type={'icon-icon-test1'} className="approval-arrow"/>
        <Title level={5}>
            {boardInfo.title}
            {(type === 'to_me' || type === 'to_me_done' )&& <span> - {boardInfo.creator_name}</span>}
            <Badge style={{fontWeight:"normal",marginLeft:20}} status={badgeColor[status]['status']} text={badgeColor[status]['label']}/>
        </Title>
        {/*<div>{boardInfo.title}<CardTag status={status} isMoney={event_name==='money_plan'}/></div>*/}
        {(rows||[]).map((row, i) => {
            return <div key={'info_' + i}>
                <span style={{display:'inline-block',width:80, color:'#666'}}>{row.label}</span>
                <span span={10}>{row.content}</span>
            </div>
        })}
        <div style={{color:'#999',marginTop:'5px'}}>{moment(created_at * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>
    </div>
}

const CardTag = ({status, isMoney}) => {
    let label = ""
    if (isMoney) {
        label = ["待确认","已确认","","已撤销"][status];
    } else {
        label = ["待审批","已通过","已拒绝","已撤销"][status];
    }
    const color = ["warning", "success", "error", "default"][status];
    const icon = [
        <ClockCircleOutlined />, 
        <CheckCircleOutlined />, 
        <CloseCircleOutlined />, 
        <MinusCircleOutlined />
    ][status];
    return <Tag color={color}>{icon} {label}</Tag>
}
import React, { useEffect, useMemo, useRef, useState } from "react";

import { parseBase64 } from "../../../core/BinaryPacketParser";
import ReactJson from "react-json-view";
import { Input, Result, Spin } from "antd";
import { useHttpGet } from "../../../core/hooks";

export default function DeviceLogViewer({ content, uri, params }) {
    const [json, setJson] = useState(null);
    const [isJson, setIsJson] = useState(true);
    const [urlResponse, getContent] = useHttpGet(uri, params);

    useEffect(() => {
        if (content)
            try {
                setIsJson(true);
                setJson(JSON.parse(content));
            } catch (e) {
                setIsJson(false);
                setJson(content);
            }
    }, [content]);

    useEffect(() => {
        if (uri) {
            // 请求
            getContent(params);
        }
    }, [uri, params]);

    useEffect(() => {
        if (uri && !urlResponse.loading) {
            if (urlResponse.success) {
                setIsJson(true);
                setJson(urlResponse.data);
            } else {
                setIsJson(true);
                setJson(urlResponse);
            }
        }
    }, [urlResponse]);

    return (
        <div className={"prev-container"}>
            {urlResponse.loading ? (
                <>
                    <Spin size="large" />
                    <Result status="info" title={"正在请求"} />
                </>
            ) : isJson ? (
                <ReactJson
                    src={json}
                    theme="monokai"
                    name={false}
                    displayDataTypes={false}
                />
            ) : (
                <Input multiple>{content}</Input>
            )}
        </div>
    );
};

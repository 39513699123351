import React from "react"

import DisplayComponent from "./Display"
import {useActionExecutor} from "../../core/actionExecuter"
import {Col, Row} from "antd"

export default ({displays, actionExecutor, ...otherProps}) => {

    const [, actionResult, nActionExecutor] = useActionExecutor()

    return <Row>{displays.map((display, idx) => {
        return <Col key={idx} span={display.span || 24}>
            <DisplayComponent {...display} actionExecutor={actionExecutor || nActionExecutor} {...otherProps}/>
        </Col>
    })}</Row>
}

import { Card, Cascader, Select } from "antd"
import React, { useEffect } from "react"
import { useHttpApi, useHttpGet } from "../../../core/hooks"


export default ({ props: {key, label} = {}, valueSet, value, item_type, feature_type, rows, valueSetUpdater, onChange, onAddress, ...other }) => {
    const selValue = valueSet ? valueSet[key] : value;
    const [{ data }, getOptions] = useHttpGet('/inventory/warehouseOptions')
    const [, getAddress] = useHttpApi('/inventory/warehouseAddress', {
        method: 'GET',
        onSuccess: (address) => {
            onAddress && onAddress({
                ...address
            })
        }
    })
    useEffect(() => {
        getOptions({item_type: item_type || feature_type})
    }, [feature_type, item_type]);

    useEffect(() => {
        if (selValue && onAddress) {
            getAddress({
                warehouse_sub_id: selValue
            })
        }
        console.log("sel value", selValue)
    }, [selValue, getAddress]);

    if (!data) {
        return <span />
    }
    let itemProps = {
        onChange,
        value: selValue,
        options: data,
    }

    return <div>
        <div>{label}</div>
        <Select {...itemProps}  style={{width: '100%'}}/>
    </div>
}
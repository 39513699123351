import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Row,
  Col,
  Input,
  Button,
  Result,
  Radio,
  Modal,
  Tooltip,
  message,
  Table,
  Tag,
} from 'antd';
import {
  EditOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import ReactJson from 'react-json-view';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { useHttpGet, useHttpPost } from '../../core/hooks';
import Icon from '../../components/Icon';
import Title from 'antd/lib/typography/Title';

export default () => {
  const [form] = Form.useForm();
  const [r1, setIpWhite] = useHttpPost('/tools/setOfficeIpWhite');
  const [r0, getIpWhite] = useHttpGet('/tools/getOfficeIpWhite');
  const [inputIp, setInputIp] = useState('');
  const [modalOpenTag, setModalOpenForTag] = useState(false);
  useEffect(() => {
    if (!r1.loading && r1.success) {
      message.success('设置成功:' + r1.data.ip);
      getIpWhite();
    }
  }, [r1]);
  useEffect(() => {
    getIpWhite();
  }, []);
  return (
    <div>
      <PageHeaderWrapper title={'运维工具'} />
      <Card bordered={true} style={{ margin: 20 }} title="服务器">
        <Row>
          <Col>
            <Card
              style={{ width: 400 }}
              title="白名单"
              loading={r0.loading || r1.loading}
              extra={
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    getIpWhite();
                  }}
                ></Button>
              }
            >
              <Table
              pagination={false}
                size="small"
                dataSource={r0?.data?.result || []}
                rowKey={(row) => 'rid' + row.id}
                footer={() => {
                  return (
                    <span>
                      {r0?.data?.visitor_ip
                        ? '本机IP地址:  ' + r0?.data?.visitor_ip
                        : ''}
                    </span>
                  );
                }}
              >
                <Table.Column title="Tag" dataIndex="tag" key="ip" />
                <Table.Column title="IP地址" dataIndex="ip" key="ip" />
                <Table.Column
                  title="操作"
                  dataIndex="op"
                  key="op"
                  render={(text, record) => {
                    return (
                      <Button
                        onClick={() => {
                          setInputIp('');
                          setModalOpenForTag(record.tag);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                    );
                  }}
                />
              </Table>
            </Card>
          </Col>
        </Row>
      </Card>
      <Modal
        title={'输入 TAG 【' +modalOpenTag+ '】 的 IP 地址'}
        okText="设置此IP地址到白名单"
        open={modalOpenTag}
        destroyOnClose
        
        okButtonProps={{
          disabled: !inputIp?.match(/^(\d{1,3}\.){3}\d{1,3}$/),
        }}
        onOk={() => {
          setIpWhite({ ip: inputIp, tag: modalOpenTag });
          setModalOpenForTag(false);
        }}
        onCancel={() => {
          setModalOpenForTag(false);
        }}
      >
        <Title level={4}>修改 Tag: {modalOpenTag}</Title>
        <InputIp
        value={inputIp}
          onChange={(ip) => {
            setInputIp(ip);
          }}
        />
        {r0?.data?.visitor_ip && <div>
            本机地址： <Tag style={{cursor:'pointer'}} onClick={() => {
                setInputIp(r0?.data?.visitor_ip);
            }}>{r0?.data?.visitor_ip}</Tag>
        </div>}
        <div>
          <span style={{ color: '#777' }}>
            只能设置一个IP地址，多次设置将覆盖之前的设置
          </span>
        </div>
      </Modal>
    </div>
  );
};
function InputIp({value, onChange }) {
  const [state, setState] = useState('error');
  useEffect(() => {
    if (value.match(/^(\d{1,3}\.){3}\d{1,3}$/)) {
        setState('success');
    } else {
        setState('error');

    }
  }, [value])
  return (
    <Input
      size="large"
      autoSave="off"
      autoComplete="off"
      placeholder={'请输入IP地址'}
      style={{ width: 300 }}
      status={state}
      value={value}
      prefix={
        state === 'error' ? (
          <ExclamationCircleOutlined />
        ) : (
          <CheckCircleOutlined />
        )
      }
      onChange={(e) => {
        const value = e.target.value;
        onChange(value);
      }}
    />
  );
}

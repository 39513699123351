import React, {useCallback, useEffect, useState} from "react"
import AdminContext from "../../core/AdminContext"
import App from '../App'
import {useLocalStoredObject, registerLogoutHandler, useHttpAction} from "../../core/hooks"
import {ConfigProvider, message} from "antd"
import zhCN from "antd/es/locale/zh_CN"
import 'moment/locale/zh-cn'

const logoutAction = {uri: '/account/logout', params: {}}
export default () => {
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const [pageStates, setPageStates] = useState(null)
    const [pageMenus, setPageMenus] = useState([])
    const [account, setAccount] = useLocalStoredObject('x_qp_account')
    const [forceLogout, setForceLogou] = useState(false)

    const [, setActionResult] = useState(null)
    const [, sendActionRequest] = useHttpAction(setActionResult)

    const logoutAccount = useCallback(() => {
        setForceLogou(true)
        // sendActionRequest(logoutAction, () => {
            if (account.token) {
                delete account.token
                setAccount(account)
            }
        // }, ({desc}) => {
        //     message.error(desc)
        // })

    }, [account, setAccount])

    useEffect(() => {
        registerLogoutHandler(logoutAccount)
    }, [logoutAccount])

    return <AdminContext.Provider value={{
        account, pageStates, setPageStates, setAccount, pageMenus, setPageMenus,
        siderCollapsed, setSiderCollapsed,
        logout: logoutAccount,
        forceLogout,
    }}>
        <ConfigProvider locale={zhCN}>
            <App/>
        </ConfigProvider>
    </AdminContext.Provider>

}

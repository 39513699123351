import React, {useEffect, useState} from "react"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import {useActionExecutor} from "../../core/actionExecuter"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"

import DisplayComponent from "../../components/displays/Display"

export default (props) => {

    const {id} = useRouteQuery()

    const [analysisResponse, requestAnalysisResponse] = useHttpGet('/production/planAnalysis')

    const [, actionResult, actionExecutor] = useActionExecutor()

    const [filters, setFilters] = useState({})

    useEffect(() => {
        requestAnalysisResponse({id})
    }, [id, requestAnalysisResponse, actionResult])

    return <div>
        <PageHeaderWrapper/>
        <LoadingResponseWrapper
            {...analysisResponse}
            refresh={() => {
                requestAnalysisResponse({id})
            }}
            renderContent={({displays}) => {
                return <div className="page-content-wrapper">
                    {displays && displays.map((display, idx) => {
                        return <DisplayComponent {...display} actionExecutor={actionExecutor} key={idx}
                            fixedState={idx===displays.length-1?filters:{}} 
                            query={idx===displays.length-1?filters:{}} 
                            onQueryUpdated={idx===displays.length-1?(updates)=> {
                                setFilters(updates)
                            }:undefined}/>
                    })}
                </div>

            }}
        />
    </div>

}

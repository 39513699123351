import {getSubscribers} from "./subscriberManager"
import {shareMqttStates} from "./globals"
import {MqttStates} from "./states"
import { parseBase64 } from "../BinaryPacketParser"

export const attachSharedListeners = (client, identity, subTopic, json = true) => {


    const broadcastMqttState = (state) => {
        getSubscribers(identity).forEach(subscriber => {
            subscriber.setMqttState(state)
        })
    }

    client.on('message', (topic, message) => {
        message = json ? new TextDecoder("utf-8").decode(message) : message 
        
        const msg = json ? JSON.parse(message) : message
        console.log(identity + ':message received', msg)
        getSubscribers(identity).forEach(subscriber => {

            if (typeof subscriber.optionsRef.current.filter === 'function' &&
                !subscriber.optionsRef.current.filter(msg)
            ) {
                return;
            }
            if (subscriber.optionsRef.current.onMessage) {
                subscriber.optionsRef.current.onMessage(msg, topic);
            }
            subscriber.setLastMessage(msg);
        });
    })

    client.on('connect', (info) => {

        if (shareMqttStates[identity] === MqttStates.CONNECTED || shareMqttStates[identity] === MqttStates.SUBSCRIBED) {
            return
        }
        console.log(identity + ':connected', info)
        shareMqttStates[identity] = MqttStates.CONNECTED
        broadcastMqttState(MqttStates.CONNECTED)
        client.subscribe(subTopic, err => {
            if (!err) {
                console.log(identity + ':subscribed:' + subTopic)
                shareMqttStates[identity] = MqttStates.SUBSCRIBED
                broadcastMqttState(MqttStates.SUBSCRIBED)
            } else {
                console.log(identity + ':subscribed error:' + subTopic)
                shareMqttStates[identity] = MqttStates.ERROR
                broadcastMqttState(MqttStates.ERROR)
            }
        })
    })

    client.on("error", error => {
        shareMqttStates[identity] = MqttStates.ERROR
        broadcastMqttState(MqttStates.ERROR)
        console.log(identity + ':client error', error)
    })

}

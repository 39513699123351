import Icon, { InfoCircleFilled } from '@ant-design/icons'
import { Button, Empty, Input, InputNumber, Spin, Table, Tooltip } from 'antd'
import Form from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import { useHttpGet, useHttpPost } from '../../../core/hooks'
import LoadingResponseWrapper from '../../LoadingResponseWrapper'


export default ({leave_days: data, month, actionLoading, actionExecutor, search} ) => {
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        editing && setEditing({
            ...editing,
            saving: actionLoading,
        })
        
    }, [actionLoading])


    if (!data || Object.keys(data).length == 0) {
        return <Empty />
    }

    const types = ['事假', '病假', '年假', '福利假', '调休', '产假']
    const typeMomos = {
        '事假': '每天按平均日工资扣除100%', 
        '病假': '每天按平均日工资扣除40%',
        '福利假': '正式员工每月有一天福利假，不扣工资'
    }

    const dataSource = [];
    for(const name of Object.keys(data)) {
        const days = data[name];
        const row = {
            name: name,
        };
        days && Object.keys(days).forEach(type => {
            if (types.indexOf(type) < 0) {
                types.push(type);
            }
            row["member_id"] = days[type].member_id
            row[type+"-id"] = days[type].id
            row[type+"-days"] = days[type].leave_days
            row[type+"-calibrated"] = days[type].calibrated_days
        });
        dataSource.push(row)
    }

    let tableWidth = 1000;
    const elementRef = ref => {
            console.log("ref", ref)
            ref && (tableWidth = ref.clientWidth);
    };

    useEffect(() => {
        console.log('editing', editing)
    }, [editing])

    return <div style={{margin: 20}} ref={elementRef}>
        <Table dataSource={dataSource} pagination={false} bordered scroll={{y: 500, x: tableWidth}}>
            <Table.Column title="姓名" dataIndex='name'></Table.Column>
            {
                types.map(type => {
                    return <Table.ColumnGroup title={
                        typeMomos[type]?<Tooltip title={typeMomos[type]}>{type} <InfoCircleFilled /></Tooltip>:type}>
                        <Table.Column title={'请假'} dataIndex={type+'-days'} render={v=>v?(v+" 天"):''}></Table.Column>
                        <Table.Column title={'校准'}    onCell={(record,a,b) => {
                            return {
                                onDoubleClick: event => {
                                        setEditing({
                                            id: record[type + '-id'],
                                            member_id: record.member_id,
                                            month: record.month || month,
                                            type: type,
                                            fake: record[type + '-days'],
                                        })
                                }
                            }
                        }} dataIndex={type+'-calibrated'} render={(value, record, index) => {
                            return (editing && (record['member_id'] == editing['member_id'] && (
                                editing['type'] == type || !editing['type']
                            ))) ? editing.saving ? <Spin/> : <Input value={editing.newValue} defaultValue={value} onChange={(event) => {
                                const newValue = event.target.value
                                setEditing({
                                    ...editing,
                                    saving: false,
                                    newValue: newValue
                                })
                            }} />: parseFloat(value)?(value + " 天"):''
                        }} ></Table.Column>
                    </Table.ColumnGroup>
                })
            }
            <Table.Column title="操作" render={(v,record,i) => {
                return (editing && !editing.saving && (record['member_id'] == editing['member_id'] )) ? <div>
                    <Button type='primary' size='small'onClick={() => {

                        let p = editing.id ? {
                            id: editing.id,
                            calibrated_days: editing.newValue,
                        } : {
                            member_id: editing.member_id,
                            leave_type_name: editing.type,
                            month: editing.month,
                            calibrated_days: editing.newValue,
                        }

                        let uri = editing.id ? '/oa/calibrateLeaveDays' : '/oa/setCalibratedLeaveDays'

                        actionExecutor.generateActionHandler({
                            type: 4,
                            uri,
                            params:p,
                        })();

                    }}>保存</Button>
                    <Button type='link' size='small'onClick={() => {
                        setEditing(false)
                    }}>取消</Button>
                </div>:null
            }}></Table.Column>
        </Table>
    </div>
}
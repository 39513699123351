import React, {useEffect} from "react"
import {useHttpApi} from "../../../core/hooks"

export default (props) => {

    const {valueSet, rows, valueSetUpdater} = props
    const {source_key, target_key} = props

    const addressId = valueSet[source_key]

    const [response, sendRequest] = useHttpApi('/oa/autoFillAddress', {
        method: 'GET',
        onSuccess: (data) => {

            // find editor

            for (const {hidden, items} of rows) {

                if (hidden) {
                    continue
                }

                for (const {extraProps, key} of items) {

                    if (extraProps && extraProps.mainKey === target_key) {

                        const newValue = JSON.parse(JSON.stringify(valueSet[key]))
                        newValue[0].valueSet = data

                        const updates = {}
                        updates[key] = newValue
                        valueSetUpdater && valueSetUpdater(updates)

                        return
                    }

                }
            }

        }
    })

    //
    useEffect(() => {
        if (!addressId || addressId.length === 0) {
            return
        }
        sendRequest({address_id: addressId[addressId.length - 1]})
    }, [addressId])


    return <div>

    </div>
}

import React, {useContext, useEffect, useState} from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom"
import {Layout, Menu,  Result, Button} from "antd"
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons"

import AdminContext from "../../core/AdminContext"
import {useHttpApi, useIsInLark, useLocalStoredObject, useRouteQuery} from "../../core/hooks"
import AccountLoading from "../account/AccountLoading"
// import PageFooter from "../../components/PageFooter"
import LayoutEditorPage from "../df/LayoutEditorPage"
import LayoutDisplayPage from "../df/LayoutDisplayPage"
import MenuPage from "./MenuPage"

import {
    pages,
} from '../custom';

import Icon from "../../components/Icon"

const {SubMenu} = Menu
const {Sider, Content, Footer} = Layout

const renderMenu = ({children, title, icon, path, id}, idx) => {
    if (path && path.indexOf("/:") > 0) {
        return undefined;
    }
    if (title === '工作台') {
        icon = 'yingyongguanli'
    }
    const titleContent = !!icon ? <span>
        <Icon type={'icon-' + icon} style={{marginRight: 10}}/>
        <span>{title}</span></span> : title
    if (children && children.length) {
        return <SubMenu
            key={id+''}
            title={titleContent}>
            {children.map(renderMenu)}
        </SubMenu>
    }

    return <Menu.Item key={id}>
        <Link to={path}>{titleContent}</Link>
    </Menu.Item>
}

const findMatchMenu = function(pageMenus, pathname) {
    if (!pageMenus) return[[], 0];
    let openKeys = [[], 0];
    
    for (let menu of pageMenus) {
        if (menu.children) {
            let inSideKeys = findMatchMenu(menu.children, pathname)
            if (inSideKeys && (inSideKeys[0].length || inSideKeys[1])) {
                openKeys[0].push(menu.id + '');
                for (let key of inSideKeys[0]) {
                    openKeys[0].push(key)
                }
                openKeys[1] = inSideKeys[1];
                return openKeys
            } else {

            }
        } else {
            if (menu.path === pathname) {
                return [[], menu.id + '']
            }
        }
    }
    return openKeys
}

const findKeyPath = function(pageMenus, key) {
    if (!pageMenus) return[];
    let openKeys = [];
    
    for (let menu of pageMenus) {
        if ((menu.id + '') === (key + '')) {
            return [menu.id + '']
        }
        if (menu.children) {
            let inSideKeys = findKeyPath(menu.children, key)
            console.log('menu path inSideKeys', inSideKeys, key, menu)
            if (inSideKeys && inSideKeys.length) {
                openKeys.push(menu.id + '');
                for (let key of inSideKeys) {
                    openKeys.push(key)
                }
                return openKeys
            } else {

            }
            
        }
    }
    return openKeys
}

const pageComponentMap = {
    LayoutEditorPage,
    LayoutDisplayPage,
}

export default () => {
    const {logout, setPageStates, setPageMenus, siderCollapsed, setSiderCollapsed} = useContext(AdminContext)
    const isInLark = useIsInLark()
    const [openKeys, setOpenKeys] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [homeResponse, refreshHome] = useHttpApi('/account/home', {
        method: 'GET',
        onSuccess: ({pageStates, pageMenus}) => {
            setPageStates(pageStates)
            setPageMenus(pageMenus)
        }
    })

    function openKeyChanges(clickOpenKeys) {
        let result = []
        for(let k of clickOpenKeys) {
            if (openKeys.indexOf(k) < 0) {
                let path = findKeyPath(pageMenus, k)
                console.log('menu path', path, k)
                for (let p of path) {
                    result.push(p)
                }
            }
        }
        console.log('menu', clickOpenKeys, result)
        if (clickOpenKeys.length && !result.length) {
            setOpenKeys(clickOpenKeys)
        } else {
            setOpenKeys(result)
        }
    }

    useEffect(() => {
        refreshHome()
    }, [refreshHome])

    const {account, pageMenus, routes} = homeResponse.data||{}
    useEffect(() => {
        if (!siderCollapsed) {
            let [openKeys, selected] = findMatchMenu(pageMenus, window.location.pathname)
            console.log('menu', 'match', openKeys,selected, window.location.pathname)
            setOpenKeys(openKeys)
            setSelectedKeys(selected)
        } else {
            setOpenKeys([])
            setSelectedKeys([])
        }
    }, [pageMenus, window.location.pathname, siderCollapsed])

    console.log('menu', 'open', openKeys, window.location.pathname)


    if (homeResponse.error) {
        // error
        return <div className="full-content-center">
            <Result
                status="error"
                title={homeResponse.error.desc}
                extra={
                    <Button type="primary" key="console" onClick={refreshHome}>
                        重试
                    </Button>
                }
            /></div>
    }

    if (!homeResponse.success) {
        return <AccountLoading/>
    }



    // const dropdownMenu = <Menu>
    //     <Menu.Item key='dropdown_1' onClick={logout}>
    //         退出登录
    //     </Menu.Item>
    // </Menu>

    const MenuIconComponent = siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined
    let nroutes = [...routes]
    
    return <Router>
        <Layout id='home-layout'>
            {!isInLark ? <Sider trigger={null} collapsible collapsed={siderCollapsed} width={240} className="side-menus"
                   theme='dark'>
                <div className="logo-container">
                    <Link to='/dashboard'>
                        {siderCollapsed && <div className="logo-small-w"/>}
                        {!siderCollapsed && <div className="logo-large-w"/>}
                    </Link>
                </div>
                <Menu mode={!siderCollapsed?"inline":'horizontal'}
                    multiple={false}
                    style={{paddingBottom: '50px'}}
                    openKeys={siderCollapsed?undefined:[...(openKeys||[])]}
                    onOpenChange={siderCollapsed ? undefined : openKeyChanges}
                    selectedKeys={siderCollapsed?undefined:[selectedKeys]}
                    onSelect={siderCollapsed?undefined:setSelectedKeys}
                    theme="dark">
                    {pageMenus.map(renderMenu)}
                </Menu>
                <div className='userInfo' style={{width: siderCollapsed ? '80px' : '240px'}}>
                    <span className={!siderCollapsed ? '' : 'hide'} style={{float: 'right', cursor: 'pointer'}}
                          onClick={logout}><Icon type='icon-tuichu1'/> 退出登录</span>
                    <span className="account-entry">{account.name}</span>
                </div>

            </Sider>: null}
            {!isInLark && 
            <div className='barBox' style={{left: siderCollapsed ? '80px' : '240px', zIndex: 1}}>
                <div className='sliderCollapsedBar'>
                    <div className='barIcon' onClick={() => {
                        setSiderCollapsed(!siderCollapsed)
                    }}>
                        <Icon type={!siderCollapsed ? 'icon-icon-test' : 'icon-icon-test1'}/>
                    </div>

                </div>
            </div>}
            <Layout>
                <Content className="content-container" style={{marginLeft: isInLark ? '0' : siderCollapsed ? '80px' : '240px'}}>
                    
                    <Switch>
                        {pages.map(({component: Comp, path, props}, idx) => {
                            return <Route exact={true} key={'route_custom_' + idx} path={path}>
                                <Comp {...props}/>
                            </Route>
                        })}
                        {nroutes.map(({component, path}, idx) => {
                            const Comp = pageComponentMap[component]
                            if (!Comp) {
                                return <Route exact={true} key={'route_' + idx} path={path}>
                                    <MenuPage/>
                                </Route>
                            }
                            return <Route exact={true} key={'route_' + idx} path={path}>
                                <Comp/>
                            </Route>
                        })}

                        {/*{nroutes.map(({component, path}, idx) => {*/}
                        {/*    return <Route exact={true} key={'route_' + idx} path={path}>*/}
                        {/*        <MenuPage/>*/}
                        {/*    </Route>*/}
                        {/*})}*/}


                        <Route path='*' exact={true}>
                            <Redirect to='/dashboard'/>
                        </Route>

                        <Route path='*'>
                            <Result
                                status="404"
                                title="404"
                                subTitle="无效的连接"
                                extra={<Link to='/'>
                                    <Button type="primary">Back Home</Button>
                                </Link>}
                            />
                        </Route>
                    </Switch>
                    <div style={{height: '50px'}}></div>
                    {isInLark && <div className='userInfo' >
                        <span style={{float: 'right', cursor: 'pointer'}}
                            onClick={logout}><Icon type='icon-tuichu1'/> 退出登录</span>
                        <span className="account-entry">{account.name}</span>
                    </div>}
                </Content>
            </Layout>
        </Layout>
    </Router>
}

import { Cascader, Select, Table } from "antd"
import Title from "antd/lib/typography/Title"
import { FormatMoney } from "format-money-js"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { renderItemFieldValue } from "../../../core/domUtil"
import { useHttpGet } from "../../../core/hooks"


export default ({uri='/production/getPaymentRelatedTable', fixedQuery}) => {
    const [{
        loading,
        data: { displayFields, items, pay_summary, pay_to_account = 0 } = {}
    }, getTable, clear] = useHttpGet(uri);
    useEffect(() => {
        if (fixedQuery) {
            getTable(fixedQuery)
        }
    }, [fixedQuery])
    const {
        statement_total_money,
        pre_pay_money,
        total_pay_money,
        currency,
    } = pay_summary||{};

    const fm = new FormatMoney();
    let symbol = currency == 2 ? '$' : '￥'
    return <div>
        <Title level={5} style={{ marginBottom: 15, marginTop: 20 }}>付款明细</Title>
        <Table
            pagination={false}
            loading={loading}
            size={'small'}
            dataSource={items}
            rowKey={({ id }, index) => index}
            footer={pay_summary && pay_summary.total_pay_money ? () => <div style={{ marginLeft: 30, }}>
                {pre_pay_money && statement_total_money ? <div className={'paymentInfoSummaryItem'}>
                    <div>{'对账金额'}: {fm.from(+ statement_total_money, { symbol: symbol, decimals: 2 })}</div>
                </div>:''}
                {pre_pay_money ? <div className={'paymentInfoSummaryItem'}>
                    <div>{'核销总额'}: <span style={{ color: 'orange' }}>-{fm.from(+ pre_pay_money, { symbol: symbol, decimals: 2 })} </span></div>
                </div>:''}
                <div className={'paymentInfoSummaryItem'}>
                    <div>{'应付金额'}:  <span style={{ color: 'red' }}>{fm.from(Math.max(0, total_pay_money), { symbol: symbol, decimals: 2 })}</span></div>
                </div>
            </div>:undefined}>
            {(displayFields||[]).map((field) => {
                const { title, key, render } = field
                return <Table.Column title={title} key={'col_' + key} dataIndex={key} render={(value, record) => {
                    return renderItemFieldValue(field, record)
                }}>
                </Table.Column>
            })}
        </Table>
    </div>
}

import { Button, Result } from "antd";
import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import QuickOrder from "./quickOrder/index";
import NotFound from "./404";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={"/island/quick_order"}>
          <QuickOrder />
        </Route>
        <Route path={"*"}> 
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}

import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Upload, Modal, Button } from 'antd';
import { useHttpPost, useHttpGet, useHttpApi } from '../core/hooks';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';

export default ({
  value,
  ref_token: refTokenShortcut,
  bulket,
  onChange,
  category = 'upload_ref_default',
  keep_name = true,
}) => {
  const { file: filePassIn } = value || {};
  const { ref_token = refTokenShortcut } = filePassIn || {
    ref_token: refTokenShortcut,
  };
  console.log('render', 'ref_token', ref_token, refTokenShortcut);
  const [fileList, setFileList] = useState([]);
  const fileStatusRef = useRef({}).current;
  const setNewFilesStatus = useCallback(
    (status) => {
      setFileList(
        fileList.map((file, fileI) => {
          return file.status != 'done'
            ? {
                ...file,
                status: status,
              }
            : file;
        })
      );
      fileStatusRef.status = status;
    },
    [fileList]
  );
  const [, getRefFiles] = useHttpApi('/file/refrencedFiles', {
    method: 'GET',
    onSuccess: (data) => {
      if (data.files) {
        setFileList(data.files.map((file, fileI) => {
            return {
              uid: file.id,
              name: file.file_name || '文件#' + (fileI + 1),
              status: 'done',
              url: file.url,
              isUploaded: true
            }
          }))
      }
    }
  })
  const [, generateOssUrls] = useHttpPost('/file/generateOssSignUrls')
  const [response, uploadedOssDone] = useHttpPost('/file/uploadedToRefrence')

  const removePromise = useRef(null);
  const uploadOnComplate = useRef(null);
  const [, remove] = useHttpApi('/file/removeRefrencedFiles', {
    method: 'POST',
    onSuccess: (data) => {
      removePromise.current && removePromise.current(true);
      removePromise.current = null;
    },
    onError: (err) => {
      removePromise.current && removePromise.current(false);
      removePromise.current = null;
    },
  });
  useEffect(() => {
    console.log("uploaded!!", response, ref_token)
    if (!response.loading) {
      if (ref_token && !response.error) {  //  没失败 = 成功 或 没请求过
        getRefFiles({ref_token: ref_token})
      }
      if (response.success) {
        fileStatusRef.status = 'done';
        fileStatusRef.ref_token = response.data.ref_token;
        onChange && onChange({
          file: fileStatusRef
        })
        uploadOnComplate.current && uploadOnComplate.current(true, response.data.ref_token)
        uploadOnComplate.current = null
      } else  {
        setNewFilesStatus('error')
        uploadOnComplate.current && uploadOnComplate.current(false)
        uploadOnComplate.current = null
      }
    }
  }, [response, ref_token]);
  const fileNeedUploadList = useMemo(() => {
    return fileList && fileList.filter(v=>v.status != 'done') || []
  }, [fileList])

  const internalStartUpload = useCallback(() => {
    if (!fileNeedUploadList?.length) {
      uploadOnComplate.current && uploadOnComplate.current(true, null)
      uploadOnComplate.current = null
      return;
    }
    generateOssUrls({
      category,
      bulket: bulket || null,
      fileNames: fileNeedUploadList.map((v) => v.name),
      contentTypes: fileNeedUploadList.map((v) => v.type),
    }, (generateOssSignUrlsData) => {
      console.log("generateOssSignUrlsData: ", generateOssSignUrlsData)

      const alluploads = fileNeedUploadList.map(async (file, i) => {
        let {url, contentType, remoteFilePath} = generateOssSignUrlsData[file.name];
        return fetch(url, {
          method: 'PUT',
          body: file.originFileObj,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': contentType || file.type,
          },
        }).then(ok => {
          return remoteFilePath
        })
      });
      Promise.all(alluploads).then((remoteFilePaths) => {
        console.log("alluploads done", {remoteFilePaths})

        uploadedOssDone({
          remoteFilePathes: remoteFilePaths,
          ref_token: ref_token || '',
        }, (data) => {
          console.log("uploadedOssDone, ref_token=", data.ref_token)
        })
      })
    })
  }, [fileNeedUploadList]);
  useEffect(() => {
    fileStatusRef.status = fileNeedUploadList.length == 0 ? 'done' : 'waiting';
    if (ref_token) {
      fileStatusRef.ref_token = ref_token;
    }
    onChange &&
      onChange({
        startUpload: (_, onComplete) => {
          if (typeof _ == 'function') {
            onComplete = _;
          }
          uploadOnComplate.current = typeof _ == 'function' ? _ : onComplete;
          setNewFilesStatus('uploading');
          internalStartUpload();
        },
        file: fileStatusRef,
      });
  }, [internalStartUpload, fileNeedUploadList.length, ref_token]);
  return (
    <Upload
      fileList={fileList}
      onChange={(changed) => {
          let {file, fileList} = changed
          console.log("file change,",fileList)
          console.log("file change,", file, changed)
          setFileList(fileList)
      }}
    
      listType='text'
      multiple={true}
      onRemove={(file, o) => {
          console.log("remove", file, o)
        if (file.isUploaded) {
            return confirm("确定从列表中删除这个文件吗？", "文件将无法恢复。", (promiose) => {
              removePromise.current = promiose;
              remove({
                ref_token: ref_token,
                removed_file_ids: file.uid,
              })
            })
        }
        return true;
      }}
        beforeUpload={() => false}>
      <Button icon={<PlusOutlined />}>选择文件</Button>
  </Upload>)
};
async function confirm(title, content, executor) {
  let mePromise;
  return mePromise =  new Promise((resolve, reject) => {
    Modal.confirm({
      title: title,
      content: content,
      onOk: () => {
        if (executor) {
          executor(resolve)
        } else {
          resolve(true)
        }
      },
      onCancel: () => {
        reject()
      }
    })
  })

}
import React, { useContext } from "react";
import SignIn from "./account/SignIn";
import HomeLayout from "./home/HomeLayout";
import AdminContext from "../core/AdminContext";
// import { useLocation } from 'react-router-dom'
import Feishu from "./account/Feishu";
import Island from "./islands/Island";
// import {CrossLogin, MainSiteOpener} from './account/CrossLogin'

export default () => {
    const { account, logout, setAccount } = useContext(AdminContext);
    if (window.location.pathname.startsWith("/island")) {
        return <Island />;
    }

    if (window.location.pathname == "/feishu") {
        return <Feishu />;
    }

    if (!account || !account.token) {
        return <SignIn />;
    }

    return <HomeLayout />;
};

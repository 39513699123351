import React, {useContext, useReducer, useEffect, useState} from 'react'
import {Input, Button, Form, Alert, Descriptions, Card, Avatar, Empty, Result} from 'antd'

import { UserOutlined, NumberOutlined } from '@ant-design/icons';

import AdminContext from "../../core/AdminContext"
import {useHttpApi, useRouteQuery, useHttpPost} from "../../core/hooks"
import { Link } from 'react-router-dom';


export default () => {
    const {account, setAccount} = useContext(AdminContext)
    const [useinfo, getUserToken] = useHttpPost('/feishu/getUserInfoByCode')
    const[nextUrl, setNextUrl] = useState('/')
    const search = new URLSearchParams(window.location.search)
    const query = {}
    for (let key of search.keys()) {
        query[key] = search.get(key)
    }
    console.warn('query', query);
    useEffect(() =>{
        if (query.redirectTo) {
            let requestUri = decodeURI(query.redirectTo);
            let newQuery = {...query};
            delete newQuery['redirectTo'];
            let esc = encodeURIComponent
            let queryStr = Object.keys(newQuery).map(k => esc(k) + '=' + esc(newQuery[k])).join('&')
            if (requestUri.indexOf('?') > 0) {
                requestUri = requestUri + '&' + queryStr
            } else {
                requestUri = requestUri + '?' + queryStr
            }
            setNextUrl(requestUri)
        }
        if (query.code && !query.access_token) {
            getUserToken({code: query.code})
        }
    }, [query.code, query.redirectTo])
    useEffect(() => {
        if (useinfo.data) {
            if (window.opener) {
                window.opener.postMessage({...query, userInfo: useinfo.data})
            }
            if (useinfo.data.account) {
                setAccount(useinfo.data.account)
            }
        }
    }, [useinfo])
    useEffect(() => {
        if (window.opener) {
            return;
        }
        if (account && account.account && account.token) {
            setTimeout(() => {
                window.location.href = nextUrl ??'/'
            }, 3000);
        }
    }, [account])
    useEffect(() => {
        if (window.opener){
            window.opener.postMessage(query)
        }
    }, [])
    return <div className="login-container-wrapper" >
        <Card bordered style={{ minWidth: 300 }}loading={useinfo.loading || !useinfo.data}>
        {
            useinfo.data && useinfo.data.name ? <Descriptions bordered column={1} title='飞书登录'>
                <Descriptions.Item label="用户"><Avatar src={useinfo.data.avatar_thumb}/> {useinfo.data.name} {useinfo.data.en_name}</Descriptions.Item>
                <Descriptions.Item label="邮箱">{useinfo.data.email}</Descriptions.Item>
                <Descriptions.Item label="Open ID">{useinfo.data.open_id}</Descriptions.Item>
                <Descriptions.Item label="Refresh Token">{useinfo.data.refresh_token}</Descriptions.Item>
                <Descriptions.Item label="Rccess Token">{useinfo.data.access_token}</Descriptions.Item>
            </Descriptions> : <Result status='error' title={"获取数据失败"}>
                <Button onClick={() => {
                    window.location.href = '/'
                }}>返回首页</Button>
            </Result>
        }
    </Card></div>
        
}

import React, {useCallback, useEffect} from "react"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet} from "../../core/hooks"

export default () => {
    return <div>
        <PageHeaderWrapper/>
        <DisplayPage component="PaysTypesTable" props={{
            uri: '/approval/getPayTongji'
        }} />
        
    </div>
}

import React from "react"

import {Descriptions} from "antd"
import {renderActions, renderItemFieldValue} from "../../core/domUtil"
import {conditionsMatched} from "../../core/conditionsMatcher"

export default (props) => {

    const {
        displayedItem,
        displayFields,
        item,
        actionExecutor,
        title,
        hideActions,
        bordered,
        column,
        showPadding = false
    } = props

    const padding = showPadding ? 24 : 0

    const {_actions_ = []} = displayedItem
    return displayFields.length > 0 && <div style={{padding: padding}}>
        <Descriptions
            title={title || item._title_}
            extra={!hideActions && <div>{renderActions(_actions_, actionExecutor, {}, 5)}</div>}
            size={'small'} bordered={!!bordered} column={column ? column : 3}>
            {displayFields.length > 0 && displayFields.filter(({show_condition = []}) => {
                return conditionsMatched(show_condition, item)
            }).map((field) => {
                const {key, title} = field
                return <Descriptions.Item label={title} key={key}>
                    {renderItemFieldValue(field, displayedItem, false, null, true)}
                </Descriptions.Item>
            })}
        </Descriptions>
    </div>

}

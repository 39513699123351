import React, { useCallback, useEffect, useState } from "react"
import { Form, Input, Result, Table, Typography } from "antd"
import { useHttpGet } from "../../../core/hooks"
import { useActionExecutor } from "../../../core/actionExecuter"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import { renderActions } from "../../../core/domUtil"
export default ({ uri, query, onItemDeleted }) => {
    const [, result, actionExecutor] = useActionExecutor()
    const [tongjiResponse, getTongji] = useHttpGet(uri)
    useEffect(() => {
        if (result && result.result && !result.result.id) {
            onItemDeleted && onItemDeleted()
        } else {
            getTongji(query)
        }
    }, [result])


    const renderTableColumn = (column, index) => {
        const { title, children, key, fixed } = column

        if (children && children.length) {
            return <Table.ColumnGroup
                key={index}
                title={title}>
                {children.map(renderTableColumn)}
            </Table.ColumnGroup>
        }
        return <Table.Column
            key={key}
            title={title}
            dataIndex={key}
            fixed={fixed || key === 'title' ? 'left' : ''}
            width={!key ? undefined : title.length > 4 ? (title.length *  10 + 60) : 80}
            className={
                ''//key.startsWith('sale_money_') >= 0 ? 'forecastColumnStyle' : ''
            }
            render={(value, saleRow, index) => {
                const valueFormat = value == 0 ?
                    <span
                        style={{ color: '#bcbcbc' }}> - </span> : value
                if (saleRow.rowSpanColumnKey === key && (saleRow.rowSpan === 0 || saleRow.rowSpan)) {
                    return {
                        children: valueFormat,
                        props: {
                            rowSpan: saleRow.rowSpan
                        }
                    }
                } else {
                    return valueFormat
                }
            }}>
        </Table.Column>
    }


    let tableWidth = 1000;
    const elementRef = ref => {
        ref && (tableWidth = ref.clientWidth);
    };

    return <LoadingResponseWrapper
        {...tongjiResponse}
        refresh={getTongji}
        renderContent={({ title, columns, items = [], actions: topActions, editable: actionAllowed = false }) => {

            return <div ref={elementRef} style={{ margin: 20 }}>
                <h2 style={{display:'inline-block'}}>{title}</h2>
                {topActions && topActions.length > 0 && <div style={{ marginLeft: '10px', display:'inline-block' }}>
                    {renderActions(topActions, actionExecutor, {})}
                </div>}
                <Table
                    dataSource={items}
                    size={'small'}
                    bordered
                    scroll={{ x: tableWidth }}
                    rowClassName={record => {
                        if (record.type === 'total_summary') {
                            return 'totalSummaryCol'
                        } else if (record.type === 'type_summary') {
                            return 'typeSummaryCol'
                        }
                    }}
                    pagination={false}>
                    {columns.map(renderTableColumn)}
                </Table>
            </div>
        }}
    />

}


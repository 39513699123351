import React, {useCallback, useEffect, useState} from "react"
import {Tabs, Card, Row, Col, Divider} from "antd"

import DisplayComponent from "./Display"
import TopTabbedDisplay from "./TopTabbedDisplay"
import Title from "antd/lib/typography/Title"

export default ({flow, filter, tabs, ...otherProps}) => {
    const filters = filter ? Array.isArray(filter)?filter:filter.split(',').map((v) => isNaN(v) ? v : parseInt(v)) : undefined
    let filterdTabs = filters? tabs.filter((element, index) => {
        return filters.indexOf(index) >= 0 || filters.indexOf(element.label) >= 0
    }) : tabs
    if (!filterdTabs || !filterdTabs.length) return <div/>;
    if (!flow) {
        return <TopTabbedDisplay tabs={filterdTabs} {...otherProps}/>;
    }
    const flows = Array.isArray(flow)?flow:flow.split && flow.split(',').map((v) => isNaN(v) ? v : parseInt(v))
    const toTabs = [];
    const toFlows = [];
    if (flow === 'all' || flow === '*') {
        toFlows.push(...filterdTabs)
    } else if (flows && flows.length) {
        for (let index = 0; index < filterdTabs.length; index++) {
            const element = filterdTabs[index];
            if (flows.indexOf(index) >= 0 || flows.indexOf(element.label) >= 0) {
                toFlows.push(element)
            } else {
                toTabs.push(element)
            }
        }
    } else {
        return <TopTabbedDisplay tabs={filterdTabs} {...otherProps}/>
    }

    return <div>
        {toFlows.map(({label, content}, idx) => {
            return  <Row gutter={[16, 16]}><Col span={24} key={'display_' + idx}>
                <Divider orientation='left'>{label}</Divider>                
                <DisplayComponent {...content} />
            </Col></Row>
        })}
        <Divider></Divider>
        <TopTabbedDisplay tabs={toTabs} {...otherProps} />
    </div>



}

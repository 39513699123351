import React, {useRef} from "react"
import {useDrag, useDrop} from "react-dnd"

export default props => {

    const {itemId, moveRow, ...restProps} = props


    const [, connectDrag] = useDrag({
        item: {type: 'row', id: itemId},
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    })

    const [{isOver}, connectDrop] = useDrop({
        accept: 'row',
        drop: (droppedItem) => {
            moveRow && moveRow(droppedItem.id, itemId)
        },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    })

    const ref = useRef(null)

    connectDrop(ref)
    connectDrag(ref)
    // const

    let {className} = restProps
    if (isOver) {
        className = (className || '') + " table-row-drop-over"
    }

    return <tr ref={ref} {...restProps} className={className}/>

}

import React, { useCallback, useEffect, useState } from "react";
import { useHttpGet } from "../../core/hooks";
import { Menu } from "antd";
import Icon from "../Icon";

const { SubMenu } = Menu;

const renderOptionsMenu = (
    { children, label, icon, path, value },
    idx,
    props
) => {
    const menuKey = "" + value;
    const titleContent = !!icon ? (
        <span>
            <Icon type={"icon-" + icon} style={{ marginRight: 10 }} />
            <span>{label}</span>
        </span>
    ) : (
        label
    );
    if (children && children.length) {
        return (
            <Menu.SubMenu
                key={menuKey}
                title={titleContent}
                onTitleClick={e => {
                    props.onSelect && props.onSelect(e);
                }}
            >
                {children.map((c, i) => renderOptionsMenu(c, i, props))}
            </Menu.SubMenu>
        );
    }

    return <Menu.Item key={menuKey}>{titleContent}</Menu.Item>;
};

const LeftMenu = props => {
    const { options, withAll, ...otherProps } = props;
    return (
        <Menu
            key="leftmenu"
            mode="inline"
            {...otherProps}
            style={{ width: 210, backgroundColor: "transparent" }}
        >
            {withAll && <Menu.Item key={"all"}>全部</Menu.Item>}
            {options.map((c, i) => renderOptionsMenu(c, i, otherProps))}
        </Menu>
    );
};

const URISourcedComponent = ({ uri, options, ...otherProps }) => {
    const [{ data }, getOptions] = useHttpGet(uri);
    useEffect(() => {
        getOptions();
    }, [uri]);

    if (!data) {
        return <span />;
    }
    return <LeftMenu options={data} {...otherProps} />;
};

export default props => {
    const { uri } = props;

    if (uri && uri.length > 0) {
        return <URISourcedComponent {...props} />;
    }

    return <LeftMenu {...props} />;
};

import React, {useEffect, useState} from "react"

import LoadingResponseWrapper from "../../LoadingResponseWrapper"


import {Table, Typography} from 'antd'
import {useHttpApi, useHttpGet} from "../../../core/hooks"
import {useActionExecutor} from "../../../core/actionExecuter"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import DisplayComponent from "../Display"

const {Text} = Typography;

export default (props) => {

    const {valueSet, valueSetUpdater} = props
    const {material_id: materialIdPath, supplier_id, quantity} = valueSet
    const [materialResponse, requestMaterial] = useHttpApi('/production/showPurchaseMaterial', {
        method: 'GET',
        onSuccess: ({updates}) => {
            updates && valueSetUpdater(updates)
        }
    })

    const material_id = Array.isArray(materialIdPath) ? materialIdPath[materialIdPath.length - 1] : materialIdPath

    useEffect(() => {
        material_id > 0 && requestMaterial({
            material_id,
            supplier_id,
            quantity
        })
    }, [material_id, supplier_id, quantity])

    if (!material_id) {
        return <div/>
    }

    return <div>
        <LoadingResponseWrapper
            {...materialResponse}
            refresh={() => {
                material_id > 0 && requestMaterial({
                    material_id,
                    supplier_id,
                    quantity
                })
            }}
            renderContent={({priceDisplay, stockDisplay}) => {

                return <div>
                    <div style={{marginBottom: '8px'}}><label>报价</label></div>
                    <DisplayComponent {...priceDisplay}/>

                    <div style={{marginBottom: '8px', marginTop: '8px'}}><label>库存</label></div>
                    <DisplayComponent {...stockDisplay}/>

                </div>
            }}
        />
    </div>

}

import React, { useContext, useEffect, useState, useReducer, useCallback, useMemo } from "react"
import { useHttpGet, useRefresh } from "../../core/hooks"
import { Col, Row, Card, Button, PageHeader, Table, Statistic, Modal, Form, Input, Select, Tooltip, List, InputNumber, DatePicker, Spin, Result, Radio, Switch, Cascader } from "antd"
import { PayCircleOutlined, PlusOutlined, DeleteOutlined, DollarCircleOutlined } from "@ant-design/icons"

import TemplateSelectorModal from "../df/TemplateSelectorModal"
import InputMoney from "../InputMoney"
import UnixDatePicker from "../UnixDatePicker"
import ClientConaddTable from "./ClientConaddTable"
import { FormatMoney } from "format-money-js"

const { Column } = Table;
const { TextArea } = Input;

const OrderPackageNamePath = "order_package"
const DictContext = React.createContext({});
export default ({ form, clientId, onRefreshTotalPrice, isForSmallClient, clientType }) => {
    const refresh = useRefresh();
    const [selectorParams, setSelectorParams] = useState(false);
    const [goodsOptionsResponse, getGoodsOptions] = useHttpGet('/sale/getGoodsSelectOptions');
    const [goodsPriceResponse, getGoodsPrice] = useHttpGet('/sale/getPrices');
    const [warehouseReponse, getWarehouse] = useHttpGet('/template/itemOptions', {
        _entity_name_: "WarehouseSub",
        feature_type: 1,
    })
    const [locationsResponse, getLocations] = useHttpGet('/template/itemOptions', {
        _entity_name_: "AddressPool"
    })
    useEffect(() => {
        getWarehouse();
        getGoodsOptions();
    }, [])
    useEffect(() => {
        if (isForSmallClient) {
            getLocations();
        }
    }, [isForSmallClient])
    const goodsOptions = goodsOptionsResponse && goodsOptionsResponse.data || []
    const goodsPrices = goodsPriceResponse.data || {}
    const warehouses = warehouseReponse.data || {}
    const packages = form.getFieldValue([OrderPackageNamePath]) || []
    if (!packages.length) {
        form.setFields([
            { name: [OrderPackageNamePath, 0, 'how_take'], value: 0 }
        ])
    }
    const currency = form.getFieldValue('currency');
    const locations = locationsResponse.data || [];
    return <DictContext.Provider value={{
        form,
        currency,
        goodsOptions,
        goodsPrices,
        warehouses,
        clientId,
        needPrice: clientType != 4,
        setSelectorParams,
        locations
    }}><div>
            {
                (!goodsOptionsResponse.loading && goodsOptionsResponse.success &&
                    !warehouseReponse.loading && warehouseReponse.success)
                    ? packages.map((onePackage, index) => {
                        return <OrderPackage key={'package_' + index} packageNumber={index} setSelectorParams={setSelectorParams}
                            allowInputAddress={!!isForSmallClient}
                            onRemoveCallback={packages.length > 1 ? (ubdex) => {
                                let nowPack = form.getFieldValue([OrderPackageNamePath]);
                                nowPack.splice(ubdex, 1);
                                form.setFields([
                                    { name: [OrderPackageNamePath], value: nowPack },
                                ])
                                onRefreshTotalPrice();
                                refresh()
                            } : undefined}
                            onPriceChange={(index, price) => {
                                onRefreshTotalPrice();
                            }}

                        />
                    }) : <div className="full-content-center">
                        <Spin />
                    </div>

            }
            {isForSmallClient || <Button style={{ marginBottom: 20 }} type='link' icon={<PlusOutlined />} onClick={() => {
                let nowPack = form.getFieldValue([OrderPackageNamePath]);
                let morePack = [...nowPack, { how_take: 0, contact_id: 0, address_id: 0 }]
                form.setFields([
                    { name: [OrderPackageNamePath], value: morePack },
                ])
                refresh()
            }}>增加出货单</Button>}

            <TemplateSelectorModal
                visible={!!selectorParams} {...selectorParams ? selectorParams : {}}
                onCancel={() => { setSelectorParams(false) }} />
        </div></DictContext.Provider>
}
function OrderPackage({ packageNumber, allowInputAddress, onRemoveCallback, onPriceChange }) {
    const {
        form,
        currency,
        clientId,
        goodsOptions,
        goodsPrices = {},
        warehouses = [],
        needPrice,
    } = useContext(DictContext);

    const refresh = useRefresh()
    const getNamePathOfItem = useCallback((index, field) => {
        return [OrderPackageNamePath, packageNumber, "items", index, field]
    }, [packageNumber])
    const getField = useCallback((field) => {
        return form.getFieldValue([OrderPackageNamePath, packageNumber, field])
    }, [packageNumber])
    const setFiled = useCallback((field, value, needRefresh = true) => {
        form.setFields([
            { name: [OrderPackageNamePath, packageNumber, field], value },
        ])
        if (needRefresh) {
            refresh()
        }
    }, [packageNumber])
    const howTake = getField("how_take");

    useEffect(() => {
        if (allowInputAddress) {
            if (howTake) { // 自取
                const {recipient, phone} = getField("address") || {}
                setFiled("contact", {name: recipient, phone})
            } else { // 邮寄
                const {name, phone} = getField("contact") || {}
                const address = getField("address") || {}
                setFiled("address", {...address, recipient: name, phone})
            }
        }
    }, [howTake]) // 取货方式变了的话，应该把信息带过去

    useEffect(() => {
        /// Client ID变化后，也设置 0，再设置订单具体
        setFiled('contact_id', 0)
        setFiled('address_id', 0)
    }, [clientId])

    const items = getField("items") || []
    const totalPrice = items.reduce((last, { count, price }, index, arr) => {
        return last + ((count && price) ? (count * price) : 0);
    }, 0); 

    useEffect(() => {
        onPriceChange && onPriceChange(packageNumber, totalPrice)
    }, [totalPrice, packageNumber])
    const tableDataSource = [...items, { isEmpty: true }].map((v, i) => {
        v.rowkey = 'package_' + packageNumber + "_item" + i;
        return v;
    });

    return <Card style={{ marginBottom: 20, }} bordered={true}>
        <Table
            rowKey='rowkey'
            style={{ marginBottom: 20 }}
            title={onRemoveCallback ? () => { return <h3>出货单 #{packageNumber + 1}</h3> } : undefined}
            dataSource={tableDataSource} pagination={false}
            footer={() => {
                return totalPrice ? <div style={{ textAlign: 'right', marginRight: 40 }}>小结：{formatMoney(totalPrice, currency)}</div> : <div></div>
            }}>
            <Column width={"35%"} title="产品" dataIndex="goods_sku" key="goods_sku" render={(value, row, index) => {
                let oldProduct = form.getFieldValue(getNamePathOfItem(index, "goods_sku"))
                
                let selector = <Cascader key='prd_selector' value={value} style={{ width: "100%" }} 
                    options={goodsOptions} displayRender={(labels, nodes) => {
                        return (labels||[]).join('/')
                    }} onChange={((value) => {
                    if (oldProduct == undefined || value[value.length-1] != oldProduct[oldProduct.length-1]) {
                        form.setFields([
                            { name: getNamePathOfItem(index, "price"), value: undefined },
                            { name: getNamePathOfItem(index, "goods_sku"), value: value },
                        ])
                        refresh()
                    }
                })}>
                </Cascader>;
                return row.isEmpty ? selector : <Form.Item noStyle name={getNamePathOfItem(index, "goods_sku")} >
                    {selector}
                </Form.Item>
            }} />
            <Column width={"12%"} title="数量" dataIndex="count" key="count"
                initialValue={10}
                render={(value, row, index) => {
                    let namePath = getNamePathOfItem(index, "count")
                    return row.isEmpty ? null : <FormCustomError
                        name={namePath}
                        errors={form.getFieldError(namePath)}
                        rules={[
                            { required: true, message: '请填写数量' },
                            { type: 'number', min: 1, message: '请填写数量' }
                        ]}>
                        <InputNumber key='input_count' min={1} style={{ width: "100%" }} required onChange={((value) => {
                            refresh()
                        })}></InputNumber>
                    </FormCustomError>

                }} />
            <Column width={"15%"} title="仓库" dataIndex="warehouse" key="warehouse" required render={(value, row, index) => {
                let namePath = getNamePathOfItem(index, "warehouse")
                return row.isEmpty ? null : <FormCustomError
                    name={namePath}
                    errors={form.getFieldError(namePath)}
                    rules={[
                        { required: true, message: '请选择' },
                    ]}>
                    <Select key='select_house' style={{ width: "100%" }}>
                        {warehouses.map(house => {
                            return <Select.Option key={'house_' + house.value} value={house.value}>{house.label}</Select.Option>
                        })}
                    </Select>
                </FormCustomError>
            }} />

            {needPrice ? <Column title="建议售价" key="price_0" render={(value, row, index) => {
                return row.isEmpty ? null : <SuggestedPrice currency={currency} count={row.count || 0} priceDetail={goodsPrices[row.product]} />
            }} /> : undefined}
            {needPrice ? <Column title="最终售价" width={"12%"} dataIndex="price" key="price" render={(value, row, index) => {
                let namePath = getNamePathOfItem(index, "price")
                return row.isEmpty ? null : <FormCustomError
                    name={namePath}
                    errors={form.getFieldError(namePath)}
                    rules={[
                        { required: true, message: '请填写售价' },
                        { type: 'number', min: 1, message: '请填写数量' }
                    ]}>
                    <InputMoney prefix={currency} style={{ width: "100%" }} required onChange={((value) => {
                        refresh()
                    })} />
                </FormCustomError>
            }} /> : undefined}
            {needPrice ? <Column title="总金额" key="total" render={(text, row, index) => {
                return formatMoney(row.price * row.count, currency)
            }} /> : undefined}
            <Column title="操作" key="delete" render={(text, row, index) => {
                return row.isEmpty ? null : <Button danger icon={<DeleteOutlined />} onClick={() => {
                    items.splice(index, 1)
                    setFiled("items", items, true);
                    refresh()
                }} />
            }} />
        </Table>
        <Form.Item
            name={[OrderPackageNamePath, packageNumber, "how_take"]}
            label="物流方式">
            <Radio.Group buttonStyle="solid" onChange={(v) => { refresh() }}>
                <Radio.Button value={0}>物流寄送</Radio.Button>
                <Radio.Button value={1}>用户自提</Radio.Button>
            </Radio.Group>
        </Form.Item>
        <Form.Item
            name={[OrderPackageNamePath, packageNumber, "prepare_at"]}
            label="预计交货时间">
            <UnixDatePicker />
        </Form.Item>
        {allowInputAddress ? <Form.Item
            required
            name={[OrderPackageNamePath, packageNumber, howTake ? "contact" : "address"]}
            label="收货信息" style={{ marginBottom: 0 }}
            rules={[
                { required: true, type: 'object', message: '请输入' },
            ]}>
            <ConaddInput howTake={howTake} clientId={clientId}    />
        </Form.Item> :<Form.Item
            required
            name={[OrderPackageNamePath, packageNumber, howTake ? "contact_id" : "address_id"]}
            label="收货信息" style={{ marginBottom: 0 }}
            rules={[
                { required: true, type: 'number', min: 1, message: '请选择' },
            ]}>
            <ConaddSelector howTake={howTake} clientId={clientId} />
        </Form.Item>}
        {onRemoveCallback && <Button style={{ float: 'right' }} danger icon={<DeleteOutlined />} onClick={() => {
            onRemoveCallback && onRemoveCallback(packageNumber)
        }}>移除此单</Button>}
    </Card>
}

function SuggestedPrice({ priceDetail = {}, count, currency = '￥' }) {
    const BigIcon = currency == '￥' ? PayCircleOutlined : DollarCircleOutlined;
    const mainPriceKey = currency == '￥' ? 'price_cny' : 'price_usd';
    // const otherPriceKey = currency == '￥' ? 'price_usd' : 'price_cny';
    let priceResult = undefined;
    if (count > 0) {
        const steps = priceDetail.steps || []
        for (const step of steps) {
            if (step.count_min <= count) {
                priceResult = step;
            }
        }
    }
    if (!priceResult && priceDetail.price_cny) {
        priceResult = {
            count_min: 0, count_max: 0,
            price_cny: priceDetail.price_cny,
            price_usd: priceDetail.price_usd,
        }
    }

    return (priceResult) ? <Statistic
        value={priceResult[mainPriceKey] || '--'}
        prefix={<BigIcon />}
    /> : <Statistic
            value={'--'}
            prefix={<BigIcon />}
            suffix={''}
        />;
}
function ConaddInput({howTake, value, onChange }) {
    const {locations} = useContext(DictContext);
    const [conaddInfo, getConaddInfo, clearResponse] = useHttpGet('/sale/getConaddInfo')
    const [inputValue, setInputValue] = useState(value||{})
    const [idCache, setIdCache] = useState([undefined, undefined]) // [addId, conId]
    const onInput = useCallback((v) => {
        console.log("on input", v, "id", idCache[howTake]);
        let input = {};
        if (howTake) {
            input = {
                name: v.name || inputValue.name,
                phone: v.phone || inputValue.phone
            }
            setInputValue(input);
        } else {
            input = {
                recipient: v.recipient || inputValue.recipient,
                phone: v.phone || inputValue.phone,
                address: v.address || inputValue.address,
                address_area: v.address_area || inputValue.address_area,
            }
            setInputValue(input)
        }
        // onChange({...input, id: idCache[howTake],});
    }, [inputValue]);
    useEffect(() => {
        console.log("on inputValue update");
        onChange({...inputValue, id: idCache[howTake]});
    }, [inputValue])

    useEffect(() => {
        console.log("on howTake update", inputValue, idCache[howTake]);
        if (howTake) {
            let {name, recipient, phone} = inputValue;
            let values = {name: name || recipient, phone}
            setInputValue(values)
        } else {
            let {name, recipient, phone, address, address_area} = inputValue;
            let values = {recipient: recipient || name, phone, address, address_area};
            setInputValue(values)
        }
    }, [howTake])

    useEffect(() => {
        if (value && value.id > 0) {
            getConaddInfo({
                [howTake==1?'contact_id' : 'address_id'] : value.id
            })
        }
    }, [])
    useEffect(() => {
        if (conaddInfo.data) {
            let {id, name, recipient, phone, address, address_area} = conaddInfo.data;
            setInputValue({name, recipient, phone, address, address_area})
            setIdCache([
                (howTake == 0 ? id : undefined) ,    // con id
                (howTake == 1 ? id : undefined)        // add id
            ])
        }
    }, [conaddInfo])
    useEffect(() => {
        console.warn("mounted");
        
    }, [])
    return <div>
        <Row gutter={[16,16]}>
            <Col>
                <Input style={{width: 80}} placeholder={howTake ? "姓名" :"收件人"}
                    value={inputValue ? (howTake?inputValue.name:inputValue.recipient) : ""} required onChange={(e) => {
                    let value = e.target.value
                    if (howTake) {
                        onInput({name: value});
                    } else {
                        onInput({recipient: value});
                    }
                }}/>
            </Col>
            <Col>
                <Input    style={{width: 230}} placeholder="电话" value={inputValue && inputValue.phone} required onChange={(e) => {
                    let value = e.target.value
                    onInput({phone: value});
                }}/>
            </Col>
        </Row>
        {howTake==0 && <Row gutter={[16,16]}>
            <Col>
                <Cascader 
                    options={locations} 
                    style={{ width: 326 }}
                    placeholder="地区" 
                    value={(inputValue && inputValue.address_area) ? inputValue.address_area.split(',').map((v) => parseInt(v)) : [] }
                    required 
                    onChange={(value) => {
                        onInput({address_area: value ? value.join(',') : ""});
                    }} />
            </Col>
        </Row>}
        {howTake==0 && <Row gutter={[16,16]}>
            <Col>
                <Input    style={{width: 326}} placeholder="地址" value={inputValue && inputValue.address} required onChange={(e) => {
                    let value = e.target.value
                        onInput({address: value});
                    }} />
            </Col>
        </Row>}
    </div>
}
function ConaddSelector({howTake, clientId, value, onChange }) {
    const {setSelectorParams} = useContext(DictContext);
    const [cache, setCache] = useState(undefined)
    const [conaddInfo, getConaddInfo, clearResponse] = useHttpGet('/sale/getConaddInfo')

    useEffect(() => {
        if (!cache) {
            clearResponse();
            if (value > 0 && clientId > 0) {
                getConaddInfo({
                    [howTake==1?'contact_id' : 'address_id'] : value
                })
            }
        } else {

        }
    }, [value, cache])

    useEffect(() => {
        setCache(undefined)
    }, [howTake])

    
    const contactInfo = howTake == 1 ? cache || conaddInfo.data: undefined
    const addressInfo = howTake == 0 ? cache || conaddInfo.data : undefined
    
    return <div>
        {
            clientId ?<Button onClick={() => {
                    setSelectorParams({
                        selectedIds: [value],
                        entityName: howTake ? 'ClientContact' : 'ClientAddress',
                        moreParams: {
                            client_id: clientId
                        },
                        componentProps: {
                            readonly: false
                        },
                        title: '请选择' + (howTake ? '联系人' : '收件人'),
                        onSelect: (keys, rows) => {
                            setSelectorParams(false)
                            if (keys.length > 0) {
                                setCache(rows[0])
                                onChange(parseInt(keys[0]))
                            }
                        }
                    })
                }}>选择{howTake ? '联系人' : '收件人'}</Button> :
                <Tooltip title="请先选择客户"><Button disabled>选择{howTake ? '联系人' : '收件人'}</Button></Tooltip>
            }
            <ClientConaddTable planDetail={{how_take: howTake }} contactInfo={contactInfo} addressInfo={addressInfo} />
    </div>
}

const moneier = new FormatMoney()
function formatMoney(value, prefix = '￥') {
    if (!value) return '';
    return moneier.from(+ value, {symbol: prefix, decimals: 4})
}

function FormCustomError({ name, rules = [], errors, children }) {

    return <div className={(errors && errors.length > 0) ? 'ant-form-item-has-error' : ''}>
        <Form.Item noStyle
            rules={rules}
            name={name}>
            {children}
        </Form.Item>
    </div>
}

import { Form, InputNumber, Table } from "antd"
import React, {useCallback, useEffect, useMemo, useState} from "react"

import { FormatMoney } from "format-money-js"
import {useHttpGet} from "../../../core/hooks"


export default (props) => {
    const {valueSet, rows, onChange} = props
    const { purchase_order_id, currency, io_type, in_type, out_type} = valueSet

    const opName = io_type == 2 ? '退货' : '提货'
    const [itemCounts, setItems] = useState({})

    const [orderResponse, getOrderItems] = useHttpGet('/production/getPurchaseOrderItems');

    useEffect(() => {
        if (purchase_order_id) {
            setItems({})
            getOrderItems({
                purchase_order_id,
                task_type: io_type == 2 ? out_type: in_type,
            });
        }
    }, [purchase_order_id])

    useEffect(() => {
        onChange && onChange(JSON.stringify(itemCounts))
    }, [itemCounts])

    const { loading, error, data: items = [] } = orderResponse
    
    useEffect(() => {
        let initCount = {}
        for (let item of items) {
            initCount[item.material_id] = io_type == 2 ? 0 : item.max_tihuo_quantity
        }
        setItems(initCount)
    }, [orderResponse])

    const moneier = new FormatMoney()
    const columns = [
        {
            title: '物料编号',
            key: 'qingping_pn',
        },{
            title: '品名',
            key: 'category',
        },{
            title: '采购数量',
            key: 'quantity',
            style: 'count',
        }, {
            title: '已入库数量',
            key: 'stock_in_quantity',
            style: 'count',
        }, {
            title: opName + '数量',
            render: (value, record, index) => {
                return <InputNumber
                    min={0}
                    value={itemCounts[record.material_id]||''}
                    onChange={(v) => {
                        let materialId = record.material_id;
                        setItems({
                            ...itemCounts,
                            [materialId]: parseInt(v) || 0,
                        })
                    }}
                    ></InputNumber>
            }
        },{
            title: '含税单价(CNY)',
            key: 'price_cny',
            render: (value, record) => {
                return moneier.from(value, {symbol: '￥', decimals: 6})
            }
        },{
            title: '提货金额',
            render: (value, record, index) => {
                let materialId = record.material_id;
                let count = 0;
                if (itemCounts[materialId]) {
                    count = parseInt(itemCounts[materialId])
                }
                return moneier.from((count * record.price_cny), {symbol: '￥', decimals: 2})
            }
        },

    ]
    return <Table 
        pagination={false}
        loading={loading}
        dataSource={items}
        size={'small'}
        rowKey={({id}) => id}>
        {columns.map(({title, key, type, render}) => {
            return <Table.Column title={title} dataIndex={key} render={render?render:undefined}>
            </Table.Column>
        })}
    </Table>
}
import React, { useContext } from "react"
import {PageHeader} from "antd"
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useLocalStoredObject} from "../core/hooks"
import AdminContext from "../core/AdminContext"

function findFullMenus(path, menus, searchResult) {
    if (searchResult.found) {
        return
    }

    for (let menu of menus) {
        searchResult.menus.push(menu)
        if (menu.path === path) {
            searchResult.found = true
            return
        }

        if (menu.children && menu.children.length) {
            findFullMenus(path, menu.children, searchResult)
        }

        if (searchResult.found) {
            return
        }

        searchResult.menus.pop()
    }
}

function getFullMenus(path, menus) {
    const result = {found: false, menus: []}
    findFullMenus(path, menus, result)
    return result.menus
}

export default (props) => {
    const {pageMenus} = useContext(AdminContext)
    const [routes, updateRoutes] = useLocalStoredObject('recent_routes', [])
    const {pathname, search} = useLocation()
    const {path: lastPathname, search: lastSearch, title: lastTitle} = routes.length > 1 ? routes[routes.length - 1] : {};
    if (lastPathname != pathname) {
        const menus = getFullMenus(pathname, pageMenus);
        if (menus && menus.length > 0) {
            updateRoutes(menus.map(({path, title}) => {
                return {
                    path: path || null,
                    search,
                    breadcrumbName: title
                }
            }))
        } else {

        }
    }

    const title = routes.length ? routes[routes.length - 1].breadcrumbName : ''

    return <div className="page-header-wrapper">
        <PageHeader
            ghost={true}
            breadcrumb={{
                routes,
                itemRender: (route, params, routes, paths) => {
                    const last = routes.indexOf(route) === routes.length - 1
                    const {path, search, breadcrumbName} = route
                    return (path && path.startsWith('/') && !last) ? (
                        <Link to={{pathname:path, search: search,  state: {title: breadcrumbName}}}>{breadcrumbName}</Link>
                    ) : (
                        <span>{breadcrumbName}</span>
                    );
                }
            }}
            style={{paddingBottom:0,paddingTop:24}}
            // title={title}
            {...props}
        />
    </div>
}

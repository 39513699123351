import React, {useEffect, useState, useRef} from "react"

import {Card, Button, Typography, Row, Col, Alert} from "antd"
import { DownloadOutlined, ZoomInOutlined, ZoomOutOutlined, SettingOutlined, EditOutlined} from '@ant-design/icons';
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"
import ReactToPrint from 'react-to-print';
import {useActionExecutor} from '../../core/actionExecuter';

import ReactEcharts from 'echarts-for-react';
import  './reportStyle.css';

const {Title, Paragraph} = Typography


export default ({uri}) => {


    const [response, getDisplayData] = useHttpGet(uri)
    const [ graphScale, setGraphScale ] = useState(12)
    const [actionExecuting, actionResult, actionExecutor] = useActionExecutor()



    useEffect(() => {
        getDisplayData()
    }, [uri, actionResult])

    function changeGraphSize(){
        graphScale === 24 && setGraphScale(12);
        graphScale === 12 && setGraphScale(24);
    }

    function partToHtml(part, key) {
        const conclusion = <pre style={{marginBottom:0}}>{part.conclusion.desc}</pre>;
        const passStatus = part.conclusion.pass;
        const partEditAction = part.editAction;
        let chart1 = part.charts[0];
        const chart2 = part.charts[1];
        const extraOptions = {
            tooltip : {
                'trigger': 'axis',
                'axisPointer': {'animation':false}
            },
            dataZoom : {
                'type': 'slider',
                'xAxisIndex': [0],
                // 'startValue': (time() - $diffMap[$intervalType]) * 1000
            },
            legend : {
                top: 0,
                left: 'center',
            },
            title:''
        }

        chart1 && (chart1.option = Object.assign({},chart1.option, extraOptions)) && (chart1.height = '400px');
        chart2 && (chart2.option = Object.assign({},chart2.option, extraOptions)) ;
        return <Typography key={key}>
            <Title level={4}>

                <Button icon={<EditOutlined />} type='link' onClick={actionExecutor.generateActionHandler(partEditAction)} style={{float:'right',color:'#999'}}>修改结论</Button>
                {part.title}
            </Title>
            <Paragraph>
                <Alert
                    message={passStatus === false ? '不满足要求' : '满足要求'}
                    description={conclusion}
                    type={passStatus === false ? 'error' : 'success'}
                    showIcon
                />
                <div></div>
            </Paragraph>
            <Row style={{marginTop:20}}>
                <Col span={graphScale}>
                    <div>
                        <Title level={4} style={{fontWeight:'normal', fontSize:14}}>设备数据曲线图</Title>
                        <ReactEcharts
                            style={{height: '360px', width: '100%'}}
                            {...chart1}
                        />
                    </div>
                </Col>
                {
                    chart2 &&
                    <Col span={graphScale}>
                        <div>
                            <Title level={4} style={{fontWeight:'normal', fontSize:14}}>最大差值曲线图</Title>
                            <ReactEcharts
                                style={{height: '360px', width: '100%'}}
                                {...chart2}
                            />
                        </div>
                    </Col>
                }
            </Row>
        </Typography>;

    }

    const componentRef = useRef();
    const envAction = response.data? response.data.reports[0].envAction:null;

    return <Card bordered={false} size={'small'}>
        <div className='printButton'>
            {
                envAction && (envAction.fixedState = {}) &&
                <Button icon={<SettingOutlined />} onClick={actionExecutor.generateActionHandler(envAction)} >环境设置</Button>
            }

            <Button icon={graphScale ===12 ?<ZoomInOutlined />:<ZoomOutOutlined />} onClick={ changeGraphSize} >{graphScale === 12?'放大':'缩小'}图表</Button>
            <ReactToPrint
                trigger={() => {
                    return <Button icon={<DownloadOutlined />}>下载报告</Button>;
                }}
                documentTitle ='myfile'
                pageStyle={{paddingTop:50,paddingLeft:30,paddingBottom:50,paddingRight:50}}
                content={() => componentRef.current} />
        </div>
            <div id='element-to-print' ref={componentRef} >
                <LoadingResponseWrapper
                    {...response}
                    refresh={getDisplayData}
                    renderContent={(response) => {

                        const reports = response.reports;

                        return reports.map((partItem, key) => {
                            const title = partItem.title;
                            const brief = partItem.brief;
                            const parts = partItem.parts;

                            return <Card key={key} bordered={false}>
                                <Title level={2}>{title}</Title>
                                <div>

                                </div>
                                <div className='conditionDescription'>
                                    <pre>{brief}</pre>
                                    {
                                        parts.map((part, key) => {
                                            return partToHtml(part, key);
                                        })
                                    }
                                </div>

                            </Card>
                        })
                    }}
                />
            </div>
    </Card>
}

import React, { useCallback, useEffect, useState } from "react";
import {

  Layout,
} from "antd";
import { useLocation } from 'react-router-dom';
import {  HeartFilled } from "@ant-design/icons";

import {
  useHttpPost,
  useLocalStoredValue,
} from "../../../core/hooks";
import AppHeader from "./components/Header";
import LoginForm from "./page/LoginForm";
import OrderList from './page/OrderList'
import MakeOrder from './page/MakeOrder/index'
import './assets/styles/base.css'

const { Content, Footer } = Layout;


export default function () {
  const [user, setUser] = useState();
  const [clientToken, setClientToken] = useLocalStoredValue(
    "quick_order_client_token"
  );
  // getUserLoading为true时 不能进入login页面，
  const [getUserLoading, setGetUserLoading] = useState(false);

  const [tabIndex, setTabIndex] = useState("0");
  const [checkClintResp, checkClient] = useHttpPost("/quickOrder/checkUser");
  const location = useLocation();

  // 根据地址栏的query 来设置tabIndex
  useEffect(()=>{
    const searchParams = new URLSearchParams(location.search);
    const tabIndex = searchParams.get('tabIndex');
    if(tabIndex){
      setTabIndex(tabIndex)
       console.log('setTabIndex',tabIndex)
    }
  },[])

  useEffect(() => {
    if (clientToken && !user) {
      setGetUserLoading(true)
      // 每次进入页面 或刷新页面 都会去拿用户信息
      checkClient({ client_token: clientToken });
    }
  }, [clientToken, user]);

  useEffect(() => {

    if (checkClintResp.success) {
      if (!checkClintResp.data) {
        // 未找到有效数据，清除客户端令牌
        setClientToken(null);
        setGetUserLoading(false)
        
      } else {
        // 设置用户信息和显示名称
        const displayName = checkClintResp.data.name + (checkClintResp.data.client_name ? "@" + checkClintResp.data.client_name : "");
        checkClintResp.data.displayName = displayName;
        setUser(checkClintResp.data);
      }
    } else if (checkClintResp.error) {
      // 处理客户端检查错误情况
      setClientToken(null);
      setGetUserLoading(false)
      
    }
  }, [checkClintResp]);

  // 当没有获取用户信息时 并且缺少必要参数，回到登录页面
  if ((!clientToken || !user?.client_id) && !getUserLoading) {
    console.log('login',clientToken,user);
    return (
      <LoginForm
        {...{
          checkClintResp,
          setUser,
          setClientToken,
        }}
      />
    );
  }
  return (
    <div style={{width:'100vw',overflow:"hidden", }} className="quick_order_body">
    {/* <Layout> */}
      <AppHeader
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        user={user}
        setClientToken={setClientToken}
        setUser={setUser}
        setGetUserLoading={setGetUserLoading}
      />
      <Content
        style={{
          margin: "24px 20px 0",
        }}
      >
        {tabIndex === "1" ? (
          <OrderList client_token={clientToken}  />
        ) : (
          <MakeOrder client_token={clientToken} user={user} setTabIndex={setTabIndex}/>
        )}
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        <div className="copyright">
          <span style={{ color: "gray", marginRight: 20 }}>
            <HeartFilled /> version：1.1 / 2023-06-21
          </span>
          <br />
          <span>© 2020 青萍科技</span> &nbsp;&nbsp;
          <a href="http://www.beian.miit.gov.cn" target="_blank">
            粤ICP备19156805号
          </a>
        </div>
      </Footer>
    {/* </Layout> */}
    </div>
  );
}


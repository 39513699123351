import React, { useState } from "react";
import DisplayComponent from "../../components/displays/Display";
import { useRouteQuery } from "../../core/hooks";
import { useHistory, useRouteMatch } from "react-router-dom";
import { buildSearchParams } from "../../core/uriBuilder";

export default displayProps => {
    /**
     *  here in page, we could:
     *  1. response to query changes in url
     *  2. update url
     */

    const routeQuery = useRouteQuery();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { component, props } = displayProps;
    return (
        <DisplayComponent
            component={component}
            props={{
                ...props,
                query: routeQuery,
                navigation: history,
                url: url,
                onQueryUpdated: updates => {
                    history.push({
                        pathname: url,
                        search: buildSearchParams(routeQuery, updates),
                    });
                },
            }}
        />
    );
};

import React from "react";

import TableDisplay from "./TableDisplay";
import TreeDisplay from "./TreeDisplay";
import LeftOutlineDisplay from "./LeftOutlineDisplay";
import TopTabbedDisplay from "./TopTabbedDisplay";
import UriTopTabbedDisplay from "./UriTopTabbedDisplay";
import ProfileDisplay from "./ProfileDisplay";
import ProfileFlowDisplay from "./ProfileFlowDisplay";
import DescriptionsDisplay from "./DescriptionsDisplay";
import DescriptionRelationDisplay from "./DescriptionRelationDisplay";
import FilesRefDisplay from "./FilesRefDisplay";
import SaleForecastTable from "./sale/SaleForecastTable";
import LeftMenuDisplay from "./LeftMenuDisplay";
import RightDetailListDisplay from "./RightDetailListDisplay";
import EditorDisplay from "./EditorDisplay";
import MonthlyTopTabbedDisplay from "./MonthlyTopTabbedDisplay";
import SaleDailyStatTable from "./sale/SaleDailyStatTable";
import ApplyDetail from "./custom/ApplyDetail";
import BoMDisplay from "./BoMDisplay";
import PaymentInfoSummary from "./custom/PaymentInfoSummary";
import ShowLoanInfo from "./custom/ShowLoanInfo";
import RefundAmount from "./custom/RefundAmount";
import FileRefUploader from "../FileRefUploader";

import StockCheckTaskDisplay from "./inventory/StockCheckTaskDisplay";
import StockInOutTaskDisplay from "./inventory/StockInOutTaskDisplay";

import StockInTaskItemsContent from "./inventory/StockInTaskItemsContent";
import StockOutTaskItemsContent from "./inventory/StockOutTaskItemsContent";
// import StockQuantityDisplay from "./inventory/StockQuantityDisplay"
import StocksMaterialProjectsDisplay from "./inventory/StocksMaterialProjectsDisplay";
import StockTaskTableDisplay from "./inventory/StockTaskTableDisplay";

import GoodsSkuPriceDisplay from "./sale/GoodsSkuPriceDisplay";
import GoodsSkuPriceTable from "./sale/GoodsSkuPriceTable";
import SaleMonthlyStatTable from "./sale/SaleMonthlyStatTable";
import SaleDoveMonthlyStatTable from "./sale/SaleDoveMonthlyStatTable";
import InvoiceItemDisplay from "./sale/InvoiceItemDisplay";

import MainSubTableDisplay from "./MainSubTableDisplay";
import MaterialPurchasePanel from "./purchase/MaterialPurchasePanel";
import MaterialPurchaseItemOverview from "./purchase/MaterialPurchaseItemOverview";
import MaterialPurchaseApplyDisplay from "./purchase/MaterialPurchaseApplyDisplay";
import StatementDetailDisplay from "./purchase/StatementDetailDisplay";

import PaysTypesTable from "./sale/PaysTypesTable";
import TmallActivityTable from "./sale/TmallActivityTable";
import SaleChartTable from "./sale/SaleChartTable";
import LeaveDaysTableDisplay from "./hr/LeaveDaysTableDisplay";

import ApplyTihuoEditTable from "./purchase/ApplyTihuoEditTable";
import CreateStatementHuoDaiTable from "./payment/CreateStatementHuoDaiTable";
import StatementItemsTable from "./purchase/StatementItemsTable";
import CreateStatementItemsTable from "./payment/CreateStatementItemsTable";
import PurchaseDetailDisplay from "./purchase/PurchaseDetailDisplay";
import CreatePurchaseOption from "./purchase/CreatePurchaseOption";
import StatementSelectPrepayTable from "./payment/StatementSelectPrepayTable";

import ManufactureSubOrderDisplay from "./manufacture/ManufactureSubOrderDisplay";
import TakeMaterialEditTableDisplay from "./manufacture/TakeMaterialEditTableDisplay";
import ReturnMaterialEditTableDisplay from "./manufacture/ReturnMaterialEditTableDisplay";
import TargetInEditTableDisplay from "./manufacture/TargetInEditTableDisplay";

import PaymentRelatedStatement from "./payment/PaymentRelatedStatement";
import PaymentSelectStatement from "./payment/PaymentSelectStatement";

import SelectWarehouseDisplay from "./common/SelectWarehouseDisplay";
import SelectSupplierDisplay from "./common/SelectSupplierDisplay";
import SelectProjectDisplay from "./common/SelectProjectDisplay";
import InputDisplay from "./common/InputDisplay";
import AddressAutoFill from "./common/AddressAutoFill";
import SelectPayType from "./common/SelectPayType";

import VMIOrderItemView from "./custom/VMIOrderItemView";
import DisplayGroup from "./DisplayGroup";
import DisplayLoader from "./DisplayLoader";
import MqttChart from "./v/MqttChart";
import MqttStatistic from "./v/MqttStatistic";
import MqttStatisticGroup from "./v/MqttStatisticGroup";
import ScreenECS from "./v/ScreenECS";
import HtmlDisplay from "./HtmlDisplay";
import UserDetailDisplay from "./user/UserDetailDisplay";

import { Result, Typography } from "antd";
import UriMontylyTopDisplay from "./UriMontylyTopDisplay";
import DeviceDetailDisplay from "./device/DeviceDetailDisplay";

const { Title } = Typography;

const WrappedTitle = ({ content, ...otherProps }) => {
    return (
        <Title {...otherProps} style={{ marginTop: "20px" }}>
            {content}
        </Title>
    );
};

const ComponentsMap = {
    TableDisplay,
    TreeDisplay,
    LeftOutlineDisplay,
    TopTabbedDisplay,
    UriTopTabbedDisplay,
    ProfileDisplay,
    ProfileFlowDisplay,
    DescriptionsDisplay,
    DescriptionRelationDisplay,
    LeftMenuDisplay,
    SaleForecastTable,
    EditorDisplay,
    MonthlyTopTabbedDisplay,
    UriMontylyTopDisplay,
    SaleDailyStatTable,
    RightDetailListDisplay,
    ApplyDetail,
    BoMDisplay,
    PaymentInfoSummary,
    ShowLoanInfo,
    RefundAmount,
    StockInOutTaskDisplay,
    StockCheckTaskDisplay,
    GoodsSkuPriceDisplay,
    GoodsSkuPriceTable,
    SaleMonthlyStatTable,
    SaleDoveMonthlyStatTable,
    MainSubTableDisplay,
    LeaveDaysTableDisplay,
    MaterialPurchasePanel,
    MaterialPurchaseItemOverview,
    TmallActivityTable,
    PaysTypesTable,
    MaterialPurchaseApplyDisplay,
    ApplyTihuoEditTable,
    CreateStatementHuoDaiTable,
    StatementItemsTable,
    CreateStatementItemsTable,
    PurchaseDetailDisplay,
    CreatePurchaseOption,
    StatementSelectPrepayTable,
    ManufactureSubOrderDisplay,
    PaymentSelectStatement,
    PaymentRelatedStatement,
    SelectWarehouseDisplay,
    SelectSupplierDisplay,
    SelectProjectDisplay,
    InputDisplay,
    AddressAutoFill,
    SelectPayType,
    StockOutTaskItemsContent,
    StockInTaskItemsContent,
    StockTaskTableDisplay,
    // StockQuantityDisplay,
    StatementDetailDisplay,
    TakeMaterialEditTableDisplay,
    ReturnMaterialEditTableDisplay,
    TargetInEditTableDisplay,
    Title: WrappedTitle,
    DisplayGroup,
    DisplayLoader,
    MqttChart,
    ScreenECS,
    MqttStatistic,
    MqttStatisticGroup,
    FilesRefDisplay,
    InvoiceItemDisplay,
    StocksMaterialProjectsDisplay,
    VMIOrderItemView,
    FileRefUploader,
    HtmlDisplay,
    UserDetailDisplay,
    DeviceDetailDisplay,
    SaleChartTable,
};

const DisplayComponent = ({ component, props, ...otherProps }) => {
    const Component = ComponentsMap[component];
    const showPadding = (props || {}).showPadding !== false;

    if (!Component) {
        return (
            <Result
                status="error"
                title={component + " not defined as Display"}
            />
        );
    }
    return <Component {...props} {...otherProps} showPadding={showPadding} />;
};

export default DisplayComponent;

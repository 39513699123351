import React,  { useState } from "react"
import moment from "moment"


export default ({ planDetail: onePlan, contactInfo = {}, addressInfo = {} }) => {
  return onePlan.how_take ? <div>
    <table>
      <tbody>
        <tr>
          <td>物流方式：</td>
          <td>{onePlan.how_take_text || "客户自取"}</td>
        </tr>
        {onePlan.prepare_at && onePlan.id ? <tr>
          <td>预计交货时间：</td>
          <td>{onePlan.prepare_at ? moment(onePlan.prepare_at * 1000).format('YYYY-MM-DD'): ""}</td>
        </tr>:undefined}
        <tr>
          <td>联系人：</td>
          <td>{contactInfo.name}</td>
        </tr>
        <tr>
          <td>电&nbsp;&nbsp;&nbsp;话：</td>
          <td>{contactInfo.phone}</td>
        </tr>
        <tr>
          <td>E-mail：</td>
          <td>{contactInfo.email}</td>
        </tr>
      </tbody>
    </table>
  </div> : <div>
      <table>
        <tbody>
          <tr>
            <td>物流方式：</td>
            <td>{onePlan.how_take_text || "物流寄送"}</td>
          </tr>
          {onePlan.prepare_at && onePlan.id ? <tr>
            <td>预计交货时间：</td>
            <td>{onePlan.prepare_at ? moment(onePlan.prepare_at * 1000).format('YYYY-MM-DD'): ""}</td>
          </tr>:undefined}
          <tr>
            <td>收件人：</td>
            <td>{addressInfo.recipient}&nbsp;{addressInfo.phone}</td>
          </tr>
          <tr>
            <td>地&nbsp;&nbsp;&nbsp;址：</td>
            <td>{addressInfo.area || addressInfo.address_area}</td>
          </tr>
          <tr>
            <td></td>
            <td>{addressInfo.address}</td>
          </tr>
        </tbody>
      </table>
    </div>
}
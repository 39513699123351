import {EMPTY_VAR_STR} from "./editorUtil"

const keysRegex = /{[0-9a-zA-Z_]+}/g

function buildUri(uri, valueSet) {

    const matches = uri.match(keysRegex)

    let compiledUri = uri
    if (matches) {
        for (const bracedKey of matches) {
            const key = bracedKey.substr(1, bracedKey.length - 2)

            let v = valueSet[key]
            if (Array.isArray(v)) {
                v = v.length > 0 ? v[v.length - 1] : 0
            }

            compiledUri = compiledUri.replace(bracedKey, v || EMPTY_VAR_STR)
        }
    }
    return compiledUri
}

function buildSearchParams(origin, updates) {

    // const fixedKeys = Object.keys(fixedQuery)

    const merged = {...origin, ...updates}
    // for (let fixedKey of fixedKeys) {
    //     delete merged[fixedKey]
    // }

    const mergedKeys = Object.keys(merged)
    for (let k of mergedKeys) {
        let v = merged[k]
        if (Array.isArray(v)) {
            v = v.join(',')
        }
        if (v === undefined || v === null || v === '') {
            delete merged[k]
        }
    }

    return new URLSearchParams(merged).toString()
}

function buildQuery(params) {
    const esc = encodeURIComponent
    return Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&')
}

export {
    buildUri,
    buildSearchParams,
    buildQuery
}

import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";

import SourcedMenu from "../df/SourcedMenu";
import DisplayComponent from "./Display";

export default ({
    query,
    outline,
    fixedFilterKey,
    onQueryUpdated,
    contentDisplay,
}) => {
    const { component: contentComponent, props: componentProps } =
        contentDisplay;
    const [selectedKey, setSelectedKey] = useState(
        query[fixedFilterKey] || outline.defaultVal
    );

    useEffect(() => {
        setSelectedKey(query[fixedFilterKey] || outline.defaultVal);
    }, [query, outline, fixedFilterKey]);

    const componentQuery = { ...query };
    if (selectedKey) {
        componentQuery[fixedFilterKey] = selectedKey;
    }

    const [openKeys, setOpenKeys] = useState([]);
    useEffect(() => {
        console.log("useEffeect", openKeys);
    }, [openKeys]);

    return (
        <Card bordered={false}>
            <Row style={{ display: "flex", position: "relative" }}>
                <Col className="leftTree">
                    <div style={{ minHeight: "100vh" }}>
                        <SourcedMenu
                            key="leftSourcedMenu"
                            withAll={true}
                            selectedKeys={[
                                !selectedKey ? "all" : "" + selectedKey,
                            ]}
                            onSelect={({ key }) => {
                                setOpenKeys([...openKeys, key]);
                                const filteredValue = key;
                                if (onQueryUpdated) {
                                    const updates = {
                                        page: undefined,
                                        page_size: undefined,
                                    };
                                    updates[fixedFilterKey] =
                                        filteredValue === "all"
                                            ? undefined
                                            : filteredValue;
                                    onQueryUpdated(updates);
                                } else {
                                    setSelectedKey(filteredValue);
                                }
                            }}
                            openKeys={openKeys}
                            onOpenChange={params => {
                                console.log("onOpenChange", params);
                                setOpenKeys(params);
                            }}
                            {...outline}
                        />
                    </div>
                </Col>
                <Col flex="1 1">
                    <DisplayComponent
                        component={contentComponent}
                        props={{
                            ...componentProps,
                            query: componentQuery,
                            onQueryUpdated: onQueryUpdated
                                ? onQueryUpdated
                                : null,
                            showPadding: false,
                        }}
                    />
                </Col>
            </Row>
        </Card>
    );
};

import React, {useEffect, useState} from "react"

import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {useHttpGet} from "../../../core/hooks"
import {Button, Table} from "antd"
import {renderItemFieldValue} from "../../../core/domUtil"
import SkuPriceEditor from "../../biz/SkuPriceEditor"
import {FormatMoney} from "format-money-js"


function preProcessSkuPriceItems(items) {
    const processedItems = [];
    items.forEach(item => {
        const myPrice = item.price;
        if (myPrice && myPrice.price_cny) {
            const steps = myPrice.steps;
            processedItems.push({
                ...item,
                single_price_cny: myPrice.price_cny,
                single_price_usd: myPrice.price_usd,
                ...((steps.length > 0 && steps[0].count_max > 0) ? steps[0] : {}),
                price_count: steps.length,   //
                price_index: 0,
            });
            for (let index = 1; index < steps.length; index++) { // 这个产品如果没有[阶梯]价格 应该显示为  1，1，colSpan=3
                processedItems.push({                                   // rowSpan=price_count
                    single_price_cny: myPrice.price_cny,
                    single_price_usd: myPrice.price_usd,
                    ...steps[index],
                    price_count: steps.length,
                    price_index: index,
                });
            }
        } else {   // 这个产品没有价格 应该显示为  colSpan=2 colSpan=3  rowSpan=1
            processedItems.push({
                ...item,
                price_count: 0,
            });
        }
    });
    return processedItems;
}

const moneier = new FormatMoney()

function formatMoney(value, prefix = '￥') {
    if (!value || value == '0.00') return "--";
    return moneier.from(+ value, {symbol: prefix, decimals: 2})
}

function formatKnumber(value) {
    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}


const hasPrice = true
export default ({uri, query}) => {

    const [response, requestSkuPrices] = useHttpGet(uri)

    useEffect(() => {

        requestSkuPrices(query)

    }, [query, uri])

    const [editingGoods, setEditingGoods] = useState()


    return <LoadingResponseWrapper
        {...response}
        refresh={() => {
            requestSkuPrices(query)
        }}
        renderContent={({fields, items}) => {

            const dataSource = preProcessSkuPriceItems(items)

            return <div>
                <Table
                    size={'small'}
                    bordered={true}
                    pagination={false}
                    dataSource={dataSource}
                    rowKey={({id}) => id}>
                    {fields.map((displayField, columnIdx) => {
                        const {key, title} = displayField
                        return <Table.Column dataIndex={key} title={title} key={columnIdx}
                                             render={(cellVal, record) => {
                                                 return {
                                                     children: renderItemFieldValue(displayField, record),
                                                     props: {
                                                         rowSpan: (hasPrice && record.price_count > 0) ? (record.price_index === 0 ? record.price_count : 0) : 1,
                                                     }
                                                 }
                                             }

                                             }/>

                    })}

                    <Table.ColumnGroup title='零售价' width="15%">
                        <Table.Column key={fields.id}
                                      align={'right'} title={'人民币'} dataIndex="single_price_cny"
                                      render={(_, row, rowIndex) => {
                                          return {
                                              children: <span>{formatMoney(row.single_price_cny)}</span>,
                                              props: {
                                                  rowSpan: (row.price_count > 0) ? (row.price_index === 0 ? row.price_count : 0) : 1,
                                                  colSpan: 1,
                                              }
                                          }
                                      }}
                        />
                        <Table.Column key={fields.id}
                                      align='right' title={'美元'} dataIndex="single_price_usd"
                                      render={(_, row, rowIndex) => {
                                          return {
                                              children: <span>{formatMoney(row.single_price_usd, "$")}</span>,
                                              props: {
                                                  rowSpan: (row.price_count > 0) ? (row.price_index === 0 ? row.price_count : 0) : 1,
                                                  colSpan: 1
                                              }
                                          }
                                      }}
                        />
                    </Table.ColumnGroup>,
                    <Table.ColumnGroup title='阶梯价' width="25%">
                        <Table.Column key={fields.id}
                                      align='center' title={'数量'} dataIndex="count"
                                      render={(_, row, rowIndex) => {
                                          return {
                                              children:
                                                  <span>{row.price_cny ? `${formatKnumber(row.count_min)} ~ ${formatKnumber(row.count_max)}` : '无阶梯价格'}</span>,
                                              props: {
                                                  colSpan: hasPrice ? (row.price_cny ? 1 : 3) : 0
                                              }
                                          }
                                      }}
                        />
                        <Table.Column
                            align='right' title={'人民币'} dataIndex="price_cny" key={fields.id}
                            render={(_, row, rowIndex) => {
                                return {
                                    children: <span>{formatMoney(row.price_cny)}</span>,
                                    props: {
                                        colSpan: hasPrice ? (row.price_cny ? 1 : 0) : 0
                                    }
                                }
                            }}
                        />
                        <Table.Column
                            align='right' title={'美元'} dataIndex='price_usd' key={fields.id}
                            render={(_, row, rowIndex) => {
                                return {
                                    children: <span>{formatMoney(row.price_usd, "$")}</span>,
                                    props: {
                                        colSpan: hasPrice ? (row.price_cny ? 1 : 0) : 0
                                    }
                                }
                            }}
                        />
                    </Table.ColumnGroup>

                    <Table.Column title={'操作'} fixed={'right'} align='center'
                                  render={(_, row, rowIndex) => {
                                      return {
                                          children: <Button onClick={() => {
                                              setEditingGoods(row)
                                          }}>修改报价</Button>,
                                          props: {
                                              rowSpan: (hasPrice && row.price_count > 0) ? (row.price_index === 0 ? row.price_count : 0) : 1,
                                          }
                                      }
                                  }}
                    />


                </Table>
                <SkuPriceEditor
                    visible={editingGoods && editingGoods.id}
                    product={editingGoods ? editingGoods.name : ""}
                    skuId={editingGoods ? editingGoods.id : 0}
                    priceDetail={editingGoods ? editingGoods.price || {steps: []} : {steps: []}}
                    onClose={(refresh) => {
                        setEditingGoods(undefined)
                        if (refresh) {
                            requestSkuPrices(query)
                        }
                    }}/>
            </div>
        }}
    />

}

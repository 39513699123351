import React, {useCallback, useEffect, useState} from "react"
import {Button, Col, Descriptions, Divider, Input, message, Row, Space, Table, Typography, List} from "antd"
import {renderActions, renderItemFieldValue} from "../../../core/domUtil"
import { CheckCircleTwoTone, InfoCircleTwoTone, SaveOutlined } from "@ant-design/icons"
import SelectProjectDisplay from '../common/SelectProjectDisplay'
import TableDisplay from "../TableDisplay"
import { useOpenPage } from "../../../core/hooks"

const {Title, Paragraph} = Typography


export default (props) => {
    const { tables, selectProject } = props
    const [project, setProject] = useState(0)
    const [projectName, setProjectName] = useState('')
    const opener = useOpenPage('', {_export_: true})
    return <div>
        {
            tables.map(({uri, title}, i) => {
                if (i > 0 && !project) return null;
                return <div style={{marginBottom: 10}}>
                    <Row align='middle'>
                        <span style={{paddingTop: 8}}>
                            <Typography.Title style={{marginTop: 5}} level={3}> {(projectName ? projectName + '项目' : '')} { title }</Typography.Title>
                        </span>
                        {selectProject && i==0 ? <Col offset={1}>  按项目过滤：</Col> : null}
                        {selectProject && i==0 ? <Col span={5}><SelectProjectDisplay noneDefault={'所有物料'} value={project} onChange={(v, label) => {
                            setProject(v)
                            setProjectName(label)
                        }} /></Col> : null}
                        {i==0 && <Col offset={1}> <Button icon={<SaveOutlined />} onClick={() => {
                            opener({uri, })
                        }}>
                                下载
                        </Button> </Col>}
                    </Row>
                    <TableDisplay uri={uri} query={{ project }} pagerDisabled={true} bordered={true} />
                </div>
            })
        }
    </div>

}

import { Button, Cascader, Col, Input, InputNumber, Radio, Row, Select, Table } from "antd"
import Title from "antd/lib/typography/Title"
import { FormatMoney } from "format-money-js"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useHttpGet } from "../../../core/hooks"
import TemplateSelectorModal from "../../df/TemplateSelectorModal"
import { renderItemFieldValue } from "../../../core/domUtil"
import InputMoney from "../../InputMoney"


export default ({ props: { key, label }, payment_id, valueSet = {}, rows, valueSetUpdater, onChange, ...other }) => {
    const { id: paymentId = 0, supplier_id, related_type, currency, related_id } = valueSet

    const [selectedIds, setSelectedId] = useState([])
    const [statementCurrency, setStatementCurrency] = useState(1)
    const [selectorVisible, setSelectorVisible] = useState(false);
    const [{ loading, data: {
        displayFields, items, pay_summary, pay_to_account = 0
    } = {} }, getTable, clear] = useHttpGet('/production/getPaymentRelatedTable');
    console.log('what render', label, key, supplier_id, valueSet, other, payment_id)
    useEffect(() => {
        clear()
        setSelectedId([])
    }, [supplier_id, related_type, statementCurrency])

    useEffect(() => {
        if (supplier_id && selectedIds && related_type) {
            clear()
            getTable({
                related_id: selectedIds,
                related_type,
                payment_id: paymentId||0,
                supplier_id,
            })
        } else {
            valueSetUpdater({
                [key]: {},
                related_id: '',
            })
        }
    },[ selectedIds, supplier_id])

    useEffect(() => {
        console.log('what source to', pay_to_account)
        if (pay_summary) {
            valueSetUpdater({
                [key]: pay_summary,
                related_id: selectedIds.join(),
                to_account_id: pay_to_account,
                currency: pay_summary.currency || currency || 1,
            })
        } else if (pay_to_account){
            valueSetUpdater({
                related_id: selectedIds.join(),
                to_account_id: pay_to_account,
                currency: currency || 1,
            })
        } else {
            valueSetUpdater({
                related_id: selectedIds.join(),
                currency: currency || 1,
            })
        }
    }, [items, pay_to_account])


    const moneier = new FormatMoney()
    return <div style={{marginBottom:20}}>
        {related_type == 2 ? <Button disabled={!supplier_id} type="primary" style={{ marginBottom: 15 }} onClick={() => {
            setSelectorVisible({
                title: '选择对账单',
                uri: '/production/getPayableStatementsBySupplier',
                selector: 'checkbox',
                moreParams: {
                    supplier_id,
                    pay_currency: statementCurrency,
                }
            })
        }}>选择对账单</Button> : ''}
        {related_type == 2 ? <Radio.Group style={{marginLeft: 20}} buttonStyle="solid" defaultValue={statementCurrency} onChange={(value) => {
            setStatementCurrency(value.nativeEvent.target.value)
        }}>
            <Radio.Button value={1}>￥ 人民币</Radio.Button>
            <Radio.Button value={2}>$ 美元</Radio.Button>
        </Radio.Group> : ''/** 对于对账单，现在有两种。所以要选币种。 */}
        {related_type == 4 ? <Button disabled={!supplier_id} type="primary" style={{ marginBottom: 15 }} onClick={() => {
            setSelectorVisible({
                title: '',
                uri: '/production/getPayableServiceTaxFeeBySupplier',
                selector: 'checkbox',
                moreParams: {
                    supplier_id,
                }
            })
        }}>选择服务费用</Button> : ''}
        {related_type==1 ? <Button disabled={!supplier_id} type="primary" style={{ marginBottom: 15 }} onClick={() => {
            setSelectorVisible({
                title: '选择订单',
                uri:'/production/getPayableOrdersBySupplier',
                selector:'radio',
                moreParams: {
                    supplier_id,
                }
            })
        }}>选择订单</Button> : ''}
        <Table
            pagination={false}
            loading={loading}
            size={'small'}
            dataSource={items}
            rowKey={({ id }) => id}
            footer={pay_summary && pay_summary.total_pay_money ? () => <div style={{ paddingBottom: 20, paddingRight: 20 }}>
                <div style={{ fontSize: 15 }}>
                    总计：{moneier.from(pay_summary.total_pay_money, { symbol: '￥', decimals: 2 })}
                </div>
            </div>:undefined}>
            {(displayFields||[]).map((field) => {
                const { title, key, render } = field
                return <Table.Column title={title} key={'col_' + key} dataIndex={key} render={(value, record) => {
                        return renderItemFieldValue(field, record)
                    }}>
                </Table.Column>
            })}
        </Table>
        {related_type==1 ? <Row style={{margin: 20}}>
            <Col span={2}>
                <Title level={5}>预付金额: </Title>
            </Col>
            <Col span={5}>
                <Input
                    type='number'
                    addonBefore={currency == 2 ? '$' : '￥'}
                    // prefix={currency == 2 ? '$' : '￥'}
                    style={{ width: '100%' }}
                    size={'large'}
                    max={pay_summary && pay_summary.max_pre_pay_money || 0}
                    min={0}
                    value={valueSet['total_pre_pay_money']} onChange={(event) => {
                        let v = event.nativeEvent.target.value
                        valueSetUpdater({
                            total_pre_pay_money: v,
                            [key] : {
                                total_pre_pay_money: v,
                            },
                            related_id: selectedIds.join(),
                        })
                }}/>
            </Col>
        </Row> : ''}
        <TemplateSelectorModal
            key='task_selector'
            visible={!!selectorVisible}
            onCancel={() => { setSelectorVisible(false) }}
            componentProps={{ pagerDisabled: true }}
            onSelect={(keys, rows) => {
                setSelectorVisible(false)
                if (keys.length && keys.join(',') !== selectedIds.join(',')) {
                    // clear()
                    // getItems({
                    //     id: id || 0,
                    //     task_logs: keys,
                    //     order_type,
                    // })
                }
                if (!keys.length) {
                    // clear()
                }
                setSelectedId(keys.sort())
            }}
            {
                ...(selectorVisible||{})
            }
            selectedIds={selectedIds}/>
    </div>

}

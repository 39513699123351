import React, {useEffect, useRef} from "react"
import {retrieveValueByLabel} from "../../../core/editorUtil"
import {FormatMoney} from "format-money-js"
import {Typography} from 'antd'

const {Text} = Typography;

export default (props) => {
    const {valueSet, rows, onChange} = props

    const summaryTypes = []
    const { currency, statement_summary, related_type } = valueSet
    const refunds = useRef({}).current
    let baoxiaoMoney = 0
    let refundTotal = 0
    let payMoney = 0

    console.log('valueSet1', valueSet)

    if (related_type !== 3 && statement_summary && Object.keys(statement_summary).length) {
        const { statement_total_money, pre_pay_money, total_pay_money, total_pre_pay_money} = statement_summary
        if (total_pre_pay_money) {
            summaryTypes.push({
                title: '预付金额',
                total: total_pre_pay_money
            })
            baoxiaoMoney = total_pre_pay_money
        } else {
            if (statement_total_money) {
                summaryTypes.push({
                    title: '对账总额',
                    total: statement_total_money
                })
            }
            if (pre_pay_money) {
                summaryTypes.push({
                    title: '预付核销',
                    total: pre_pay_money
                })
                summaryTypes.push({
                    title: '对账单金额',
                    total: total_pay_money
                })
            }
            baoxiaoMoney = total_pay_money
        }
    } else {
        const feeItems = retrieveValueByLabel('费用明细', rows, valueSet)
        if (feeItems) {
            const feeSummaryType = {
                title: valueSet.scheme === '1' ? '报销金额' : '支付金额',
                total: 0
            }
            for (let feeItem of feeItems) {
                if (feeItem.deleted) continue;
                const feeItemValues = feeItem.valueSet
                feeSummaryType.total += feeItemValues.item_money_amount ? parseFloat(feeItemValues.item_money_amount) : 0
            }
            summaryTypes.push(feeSummaryType)
            baoxiaoMoney = feeSummaryType.total
        }
    }

    const loanMoneyString = retrieveValueByLabel('借款金额', rows, valueSet)
    payMoney = loanMoneyString ? parseFloat(loanMoneyString) : 0
    if (loanMoneyString !== undefined) {
        summaryTypes.push({
            title: '支付金额',
            total: payMoney
        })
    }

    const refundMoneyItems = retrieveValueByLabel('核销借款', rows, valueSet)
    if (refundMoneyItems) {
        let keys = Object.keys(refunds);
        for (let k of keys) {
            delete refunds[k]
        }
        for (let feeItem of refundMoneyItems) {
            if (feeItem.deleted) continue;
            const feeItemValues = feeItem.valueSet
            if (refunds[feeItemValues.loan_id]) { // 一个单子只核销一下
                // refunds[feeItemValues.loan_id] = "error"
                continue;
            }
            if (refundTotal == baoxiaoMoney) continue;
            const canUseMoney = feeItemValues.available_money_amount ? parseFloat(feeItemValues.available_money_amount) : 0;
            if (refundTotal + canUseMoney >= baoxiaoMoney) {
                refunds[feeItemValues.loan_id] = baoxiaoMoney - refundTotal
                refundTotal += refunds[feeItemValues.loan_id];
            } else {
                refunds[feeItemValues.loan_id] = canUseMoney
                refundTotal += refunds[feeItemValues.loan_id];
            }
        }

        summaryTypes.push({
            title: '借款核销',
            total: refundTotal
        })
        payMoney = baoxiaoMoney - refundTotal;
        if (baoxiaoMoney > 0) {
            summaryTypes.push({
                title: '应付总额',
                total: payMoney
            })
        }
    } else {
        payMoney = baoxiaoMoney - refundTotal;
        summaryTypes.push({
            title: '应付总额',
            total: payMoney
        })
    }
    useEffect(() => {
        onChange && onChange({
            baoxiaoMoney,
            payMoney,
            refundTotal,
            refunds
        })
    }, [baoxiaoMoney, refundTotal, payMoney, refunds])

    // let noticeInfo = '';
    // switch (valueSet.scheme) {
    //     //报销单
    //     case '1':
    //         noticeInfo = <div>
    //             <Text type="warning" style={{fontSize: 16}}>提请注意：</Text>
    //             <div>
    //                 1. 电子发票需要逐笔登记；<br/>
    //                 2. 费用归属于某时间段的，在费用事由写明开始日期、结束日期。
    //             </div>
    //         </div>
    //         break;
    //     //借款单
    //     case '3':
    //         noticeInfo = <div>
    //             <Text type="warning" style={{fontSize: 16}}>提请注意：</Text>
    //             <div>
    //                 1. 借款人和收款人务必一致。因特殊情况引起不一致，由借款人承担还款责任；<br/>
    //                 2. 核销方式-发票核销：指未来用发票报销，财务多退少补；<br/>
    //                 3. 核销方式-归还现金：指未来用现金或银行汇款的方式按借款金额归还公司，常见于借款用途是部门备用金。<br/>
    //             </div>
    //         </div>
    //         break;
    //     //付款单
    //     case '2':
    //         noticeInfo = <div>
    //             <Text type="warning" style={{fontSize: 16}}>提请注意：</Text>
    //             <div>
    //                 1. 申请合同首笔款项，请附上合同；<br/>
    //                 2. 电子发票需要逐笔登记；<br/>
    //                 3. 费用归属于某时间段的，在费用归属期间写明开始日期、结束日期；<br/>
    //                 4. 扣款协议指的是三方协议；<br/>
    //                 5. 劳务报酬，需要有劳务协议、身份证复印件、银行卡复印件。
    //             </div>
    //         </div>
    //         break;
    //
    // }


    return <div className={'paymentInfoSummary'}>
        
        <div style={{marginTop: 30,marginBottom:20}}>
            {summaryTypes.map(({title, total}, idx) => {

                const fm = new FormatMoney();
                const money = fm.from(+ total, { symbol: currency == 2?'$':'￥', decimals: 2});

                const color = title === '核销总额' ? 'orange': title === '应付总额'?'red':'black';
                return <div key={idx} className={'paymentInfoSummaryItem'}>
                    <div><b>{title}: </b> <span style={{color:color}}>{title === '核销总额' && total != 0 && '-'}{money}</span></div>
                </div>

            })}
        </div>
        {/*{*/}
        {/*    noticeInfo != '' && <div className='noticeInfo'>*/}
        {/*        {noticeInfo}*/}
        {/*    </div>*/}
        {/*}*/}


    </div>

}

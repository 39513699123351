import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import { useHttpAction } from "./hooks";
import presentModal from "../components/presentModal";
import { TemplateEditorModal } from "../components/df/TemplateEditor";
import { useHistory } from "react-router-dom";
import { buildQuery } from "./uriBuilder";
import MultipleLinesViewer from "../components/displays/custom/MultipleLinesViewer";
import DeviceLogViewer from "../components/displays/custom/DeviceLogViewer";

const ActionTypes = {
    DF_EDITOR: 2,
    REMOVE: 3,
    HTTP_REQUEST: 4,
    SORT_ITEMS: 9,
    OPEN_PAGE: 5,
    DOWNLOAD_JSON: 6,
    DOWNLOAD_FILE: 7,
    IMPORT_FILE: 11,
    CUSTOM_EDITOR: 12,
    DF_EDITOR_PAGE: 13,
    VIEW_LARGE_TEXT: 20,
    VIEW_JSON: 23,
};

function generateActionHandler(action, actionContainer) {
    const { confirm } = action;
    if (confirm) {
        return () => {
            Modal.confirm({
                title: "操作确认",
                content: confirm,
                onOk: () => {
                    performAction(action, actionContainer);
                },
            });
        };
    }

    return () => {
        performAction(action, actionContainer);
    };
}

function performAction(
    action,
    { setActionResult, sendActionRequest, history }
) {
    const { type, uri, title, fixedState } = action;

    switch (type) {
        case ActionTypes.REMOVE:
        case ActionTypes.SORT_ITEMS:
        case ActionTypes.HTTP_REQUEST:
            sendActionRequest(action);
            break;
        case ActionTypes.DOWNLOAD_JSON:
            // let downloadUri = action.uri

            sendActionRequest(action, metaJson => {
                const link = document.createElement("a");
                const content =
                    "data:text/plain;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(metaJson));
                link.target = "blank";
                link.setAttribute("href", content);
                link.setAttribute("download", action.title + ".json");
                link.click();
            });
            //

            break;
        case ActionTypes.DOWNLOAD_FILE:
            let { uri: downloadUri, params } = action;

            if (!params.no_params) {
                const { token } = JSON.parse(
                    window.localStorage.getItem("x_qp_account")
                );
                params.token = token;
                if (downloadUri.indexOf("?") > 0) {
                    downloadUri +=
                        "&" + buildQuery({ ...params, ...fixedState });
                } else {
                    downloadUri +=
                        "?" + buildQuery({ ...params, ...fixedState });
                }
            }

            if (downloadUri) {
                let link = document.createElement("a");
                link.href = downloadUri;
                link.target = "blank";
                link.click();
            }
            break;
        case ActionTypes.OPEN_PAGE:
            history.push(uri, { title });
            /// 这里不需要把 fixedState 加入url，因为在复杂显示下，加入更多参会导致进入页面后的query出错。
            break;
        case ActionTypes.DF_EDITOR_PAGE:
            let fullPath = uri;
            const queryStr = buildQuery(fixedState || {});
            if (queryStr && queryStr.length > 0) {
                if (fullPath.indexOf("?") > 0) {
                    fullPath += "&" + queryStr;
                } else {
                    fullPath += "?" + queryStr;
                }
            }

            history.push(fullPath, { title });
            break;
        case ActionTypes.DF_EDITOR: // df
            // case ActionTypes.CUSTOM_EDITOR: // custom
            const { editor } = action;
            const {
                defaults: { _width_ = "50vw" },
            } = editor;
            const { destroy } = presentModal({
                closable: true,
                maskClosable: false,
                width: _width_,
                action,
                // keyboard:false,
                sendActionRequest,
                setActionResult, // CUSTOM_EDITOR use this
                PresentComponent: TemplateEditorModal,
                // PresentComponent: ActionTypes.CUSTOM_EDITOR == type ? CustomEditorModal : TemplateEditorModal,
                closeModal: () => {
                    destroy();
                },
            });
            break;
        case ActionTypes.VIEW_LARGE_TEXT:
            const lines = action.content?.split("\n") ?? [];
            const { dismiss } = presentModal({
                closable: true,
                maskClosable: false,
                width: "50vw",
                content: <MultipleLinesViewer lines={lines} />,
                closeModal: () => {
                    dismiss();
                },
            });
            break;

        case ActionTypes.VIEW_JSON:
            const { content, uri: reqUrl, params: fixedParams } = action;
            const { dismissLogViewer } = presentModal({
                closable: true,
                maskClosable: false,
                width: "50vw",
                content: (
                    <DeviceLogViewer
                        content={content}
                        uri={reqUrl}
                        params={fixedParams}
                    />
                ),
                closeModal: () => {
                    dismissLogViewer();
                },
            });

            break;
        // case ActionTypes.DF_EDITOR_PAGE:
        //     let pageProps = {
        //         title: '编辑:' + title,
        //         action,
        //     }
        //     history.push(uri, pageProps)
        //     break
        default:
            break;
    }
}

function useActionExecutor() {
    const [actionResult, setActionResult] = useState(null);
    const [actionResponse, sendActionRequest] = useHttpAction(setActionResult);
    const history = useHistory();

    const actionExecutor = useMemo(() => {
        return {
            generateActionHandler: action => {
                return generateActionHandler(action, {
                    setActionResult,
                    sendActionRequest,
                    history,
                });
            },
            performAction: action => {
                return performAction(action, {
                    setActionResult,
                    sendActionRequest,
                    history,
                });
            },
        };
    }, [sendActionRequest, history, setActionResult]);

    useEffect(() => {
        if (actionResponse.success) {
            if (actionResponse.data && actionResponse.data.follow_url) {
                history.push(actionResponse.data.follow_url);
            }
        }
    }, [actionResponse]);

    return [actionResponse.loading, actionResult, actionExecutor];
}

export { useActionExecutor };

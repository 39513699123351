import React, { useState } from "react";
import { Form, Radio, Input } from "antd";

const InvoiceFormComponent = ({ handleKeyDown }) => {
  const [invoiceType, setInvoiceType] = useState("");
  const [needInvoice, setNeedInvoice] = useState("");

  const handleChange = (e) => {
    setInvoiceType(e.target.value);
  };
  const changeNeedInvoice = (e) => {
    setNeedInvoice(e.target.value);
  };
  return (
    <div className="site-item-container">
      <div className="align-center">
        <div className="item-box-title">合同与发票</div>
      </div>

      <Form.Item noStyle name="invoice_type" required>
        <div className="invoice-layout" >
          <div className="invoice-title">发票类型</div>
          <Radio.Group
            style={{ width: "100%" }}
            onChange={handleChange}
            value={invoiceType}
          >
            <Radio value={9}>数电增值税电子专用发票 </Radio>
            <Radio value={10}>数电增值税电子普通发票</Radio>
            <Radio value={6}>不开发票</Radio>
          </Radio.Group>
        </div>
      </Form.Item>

      {invoiceType !== 6 && (
        // <Form.Item noStyle name="invoice_title" required>
        //   <div className="invoice-layout">
        //     <div className="invoice-title">发票抬头</div>
        //     <Input.TextArea
        //       rows={6}
        //       placeholder={`公司名称：\n纳税人识别号：\n注册地址：\n注册电话：\n开户银行：\n银行账号`}
        //     />
        //   </div>
        // </Form.Item>
        <div className="invoice-info">
          <Form.Item label="单位名称" name="unit_name" required>
            <Input
              placeholder="单位名称"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item label="纳税人识别号" name="taxpayer_id" required>
            <Input
              placeholder="纳税人识别号"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item label="注册地址" name="reg_address">
            <Input
              placeholder="注册地址"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item label="注册电话" name="reg_phone">
            <Input
              placeholder="注册电话"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item label="开户银行" name="bank_name">
            <Input
              placeholder="开户银行"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
          <Form.Item label="开户账号" name="bank_account">
            <Input
              placeholder="开户账号"
              inputMode="text"
              onKeyDown={handleKeyDown}
            />
          </Form.Item>
        </div>
      )}

      <Form.Item noStyle name="need_contract" required>
        <div className="invoice-layout" style={{marginTop:"2rem"}}>
          <div className="invoice-title">采购合同</div>
          <Radio.Group
            style={{ width: "100%" }}
            onChange={changeNeedInvoice}
            value={needInvoice}
          >
            <Radio value={true}>需要</Radio>
            <Radio value={false}>不需要</Radio>
          </Radio.Group>
        </div>
      </Form.Item>

      {needInvoice !== false && (
        <Form.Item name="contract_client_info" noStyle>
          <div className="invoice-layout" style={{marginTop:"2rem"}}>
            <div className="invoice-title">甲方信息</div>
            <Input.TextArea
              rows={6}
              placeholder={`购方公司名称：\n购方税号：\n公司地址：\n公司电话：\n开户银行：\n银行账号`}
            />
          </div>
        </Form.Item>
      )}

      <Form.Item name="email" noStyle>
        <div className="invoice-layout" style={{marginTop:"2rem"}}>
          <div className="invoice-title">接收邮箱</div>
          <Input placeholder="您的邮箱" onKeyDown={handleKeyDown} />
        </div>
      </Form.Item>
    </div>
  );
};

export default InvoiceFormComponent;

import React, { useContext, useEffect, useState, useMemo } from "react"
import { useHttpGet, useRouteQuery } from "../../../core/hooks"
import PageHeaderWrapper from "../../PageHeaderWrapper"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import { Card, Col, Row, Descriptions, List, Table, Spin, Empty, Statistic, Steps } from "antd"
import HistoryChart from "../../biz/HistoryChart"
import { useActionExecutor } from "../../../core/actionExecuter"
import { renderActions, renderItemFieldValue } from "../../../core/domUtil"
import { FormatMoney } from "format-money-js"
import Title from "antd/lib/typography/Title"
import { Link } from "react-router-dom"
const { Step } = Steps;

const fm = new FormatMoney();
export default (props) => {
    const {uri, query, fixedQuery, onChange} = props
    const [, actionResult, actionExecutor] = useActionExecutor()
    const [response, requestProfile] = useHttpGet(uri)
    useEffect(() => {
        requestProfile({...query, ...fixedQuery})
    }, [query, requestProfile, actionResult])

    useEffect(() => {
        response && response.data && onChange && onChange(response.data.pay_summary)
    }, [response]);
    

    return <LoadingResponseWrapper
        {...response}
        refresh={requestProfile}
        renderContent={({ tables, pay_summary: { currency, statement_total_money, pre_pay_money, total_pay_money } }) => {
            let symbol = currency == 2 ? '$' : '￥'
            return <div className="page-content-wrapper" style={{padding:0,margin:0}}>
                {tables.map(({title, sub_title, displayFields, items, sum_key_name}) => {
                    return <ItemsTable displayFields={displayFields} items={items} title={title} sub_title={sub_title} sum_key_name={sum_key_name} />
                })}
                <div className={'paymentInfoSummary'}>
        
                    <div style={{marginTop: 20,marginBottom:20}}>
                        {pre_pay_money && statement_total_money && <div className={'paymentInfoSummaryItem'}>
                            <div>对账总额: {fm.from(statement_total_money, { symbol: symbol, decimals: 2})}</div>
                        </div> || ''}
                        {pre_pay_money && <div className={'paymentInfoSummaryItem'}>
                            <div>预付核销: <span style={{ color: 'orange' }}>-{fm.from(pre_pay_money, { symbol: symbol, decimals: 2})}</span></div>
                        </div> ||''  }
                        <div className={'paymentInfoSummaryItem'}>
                            <div>对账单金额: <span style={{ color: 'red' }}>{fm.from(total_pay_money, { symbol: symbol, decimals: 2})}</span></div>
                        </div>
                    </div>

                </div>
            </div>
        }}
    />
}
const ColumnWrapper = ({columnProps, record, readonly}) => {
    return renderItemFieldValue(columnProps, record, readonly)
}
const ItemsTable = ({displayFields = [],items = [], sum_key_name, title='', sub_title='', footer, size}) => {
    const filteredFields = displayFields.filter(({y}) => y === 0);
    const spanSUM = filteredFields.reduce((accumulator, field) => {
        const {span} = field;
        return accumulator + parseInt(span, 10)
    }, 0)
    const totalMoney = items.reduce((money, item) => {
        return money + parseFloat(item._raws_[sum_key_name])
    }, 0);

    const beforeSumKeyName = filteredFields.reduce((last, field, i, array) => {
        if (last) return last;
        if (field.key === sum_key_name) {
            return array[i - 1].key
        }
    }, undefined)


    return <div style={{paddingTop:15}}>
        <Title level={5}>{
            Array.isArray(title)? title.map(t => {
                return typeof t =='object' ? <Link to={t.href} target='blank'>{t.title}</Link> : t
            }) : title
        }</Title>
        <div style={{fontWeight:"bold",marginBottom:10}}>{sub_title}</div>
        <Table
        dataSource={[...items, {[sum_key_name]: totalMoney, [beforeSumKeyName]: '合计：'}]}
        bordered={true}
        pagination={false}
        size='small'
        rowKey={({id}) => id}>
        {filteredFields.map((displayField, columnIdx) => {
            const {key, title, sortable, span} = displayField
            return <Table.Column dataIndex={key} title={title} key={columnIdx}
                    width={(span / spanSUM) * 100 + '%'}
                    render={(cellVal, record, index) => {
                        if (index === items.length) {
                            if (beforeSumKeyName === displayField.key) {
                                return cellVal
                            }
                            if (sum_key_name !== displayField.key) {
                                return ''
                            }
                        }
                        
                        return <ColumnWrapper columnProps={displayField} record={record} />
                    }}/>
        })}
    </Table>
    </div>

}

import React, { useEffect, useState } from "react";
import { Radio, Table } from "antd";
import { useHttpGet } from "../../../../../core/hooks";

const SelectAddrTable = ({ selectedId, onSelectId, client_token }) => {
  const [getMyAddrResponse, getMyAddr] = useHttpGet("/quickOrder/getMyAddress", {
    client_token,
  });
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    if (getMyAddrResponse?.data?.items?.map) {
      const data = getMyAddrResponse.data.items.map(({ _raws_, ...item }) => ({
        id: _raws_.id,
        people: item.recipient,
        phone: item.phone,
        address: item.address,
        address_area: item.address_area,
        city_id: _raws_.address_area?.split(",")?.map((v) => parseInt(v)),
      }));

      setDataSource(data);
    }
  }, [getMyAddrResponse.data]);

  useEffect(() => {
    getMyAddr();
  }, []);

  return (
    <Table
      loading={!getMyAddrResponse || getMyAddrResponse.loading}
      showHeader={false}
      rowKey={(a, i) => a.id + "kk"}
      dataSource={dataSource}
    >
      <Table.Column
        title="收件人"
        render={(value, row) => (
          <div style={{ width: "100%", cursor: "pointer" }} onClick={(e) => {
            e.preventDefault();
            onSelectId(row.id, row);
          }}>
            <Radio checked={selectedId === row.id}>
              <span>
                <strong style={{ marginRight: 5 }}>{row.people}</strong>
                {row.phone}
              </span>
            </Radio>
            <div style={{ color: "gray" }}>{row.address_area + " " + row.address}</div>
          </div>
        )}
      />
    </Table>
  );
};

export default SelectAddrTable;

import React, {useCallback, useEffect, useState} from "react"
import {useHttpGet} from "../../core/hooks"
import LoadingResponseWrapper from "../LoadingResponseWrapper"
import {useActionExecutor} from "../../core/actionExecuter"

import DescriptionsDisplay from "./DescriptionsDisplay"
import TableDisplay from "./TableDisplay"

export default (props) => {

    const {uri, query, fixedQuery} = props
    const [response, requestBoM] = useHttpGet(uri)

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        requestBoM({...query, ...fixedQuery})
    }, [query, requestBoM, actionResult])

    return <LoadingResponseWrapper
        {...response}
        refresh={requestBoM}
        renderContent={({base, listProps}) => {

            return <div className="page-content-wrapper">

                <div style={{marginBottom:24}}><DescriptionsDisplay {...base} actionExecutor={actionExecutor}/></div>


                <TableDisplay {...listProps} actionExecutor={actionExecutor}/>


            </div>


        }}
    />

}

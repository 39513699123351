import Icon, { ExclamationCircleFilled, SearchOutlined } from "@ant-design/icons"
import { Alert, Badge, Button, Cascader, Input, InputNumber, message, Space, Steps, Table } from "antd"
import Highlighter from 'react-highlight-words';
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useActionExecutor } from "../../../core/actionExecuter"
import { renderActions, renderItemFieldValue } from "../../../core/domUtil"
import { useHttpAction, useHttpGet, useHttpPost } from "../../../core/hooks"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import DescriptionsDisplay from "../DescriptionsDisplay"

const { Step } = Steps

export default (props) => {

    const { uri = '/inventory/getCheckTaskDetail', query, fixedQuery, navigation, url } = props
    const [response, requestTaskDetail] = useHttpGet(uri)
    const [{ loading: selectorLoading, data: selectorOptions = [] }, requestThingTree] = useHttpGet(
        '/inventory/getItemTree'
    )
    const [saveResponse, saveCheck] = useHttpPost('/inventory/saveCheckTaskCounts')
    const [editingMap, setEditingMap] = useState({})
    const [addingMap, setAddingMap] = useState([])
    const [searchInput, setSearchInputNode] = useState(undefined)
    const [searchText, setSearchText] = useState('')

    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        requestTaskDetail({ ...query, ...fixedQuery })
    }, [query, requestTaskDetail, actionResult])

    const mainItemType = response && response.data && response.data.taskProfile && response.data.taskProfile.item.item_type || 0
    useEffect(() => {
        if (mainItemType) {
            requestThingTree({
                item_type: mainItemType
            })

        }
    }, [mainItemType])
    const itemTypeKey = mainItemType == 1 ? 'sku_id' : mainItemType == 2 ? 'material_id' : 'none_id';

    const [responsedItemIds, itemCategories] = useMemo(() => {
        let items = (response && response.data && response.data.itemsContent && response.data.itemsContent.items) || []
        let cates = [];
        let ids = [];
        
        items.forEach((item) => {
            ids.push(item[itemTypeKey])
            if (item.category && cates.indexOf(item.category) < 0) {
                cates.push(item.category)
            }
            if (item.project_code && cates.indexOf(item.project_code) < 0) {
                cates.push(item.project_code)
            }
        })
        Object.keys(editingMap).forEach((key) => {
            let info = editingMap[key][itemTypeKey];
            ids.push(info)
        })
        return [ids, cates.sort().map(cate => ({value: cate, text: cate}))];
    }, [response, editingMap])

    const hasError = useMemo(() => {
        for (let key of Object.keys(editingMap)) {
            let red = editingMap[key].red;
            if (red) {
                return true
            }
        }
        return false
    }, [editingMap])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
    };

    const committing = saveResponse.loading;

    const saveCounts = useCallback((andStartApproval = false) => {
        let counts = Object.keys(editingMap).map((key) => {
            let info = editingMap[key];
            let { id, adding, sku_id, material_id, quantity, remark } = info

            if ((sku_id || material_id) && (quantity >= 0 || remark)) {

            } else {
                return undefined
            }

            let result = {}
            if (mainItemType == 1) {
                result.sku_id = sku_id;
            }
            if (mainItemType == 2) {
                result.material_id = material_id;
            }
            if (quantity >= 0) {
                result.quantity = quantity;
            }
            if (remark) {
                result.remark = remark;
            }
            if (!adding) {
                result.id = id;
            }

            return result;
        }).filter(v => v)
        if (counts.length) {
            saveCheck({
                id: query.id,
                check_task_counts: JSON.stringify(counts),
                approval: andStartApproval?1:0
            })
            return true;
        } else { 
            if (andStartApproval) {
                saveCheck({
                    id: query.id,
                    approval: 1
                })
            }
            return false;
        }
    }, [editingMap])


    useEffect(() => {
        if (!saveResponse.loading) {
            setAddingMap([])
            setEditingMap({})
        }
        if (saveResponse.success) {
            requestTaskDetail({ ...query, ...fixedQuery })
        }

    }, [saveResponse])

    console.log('render', 1)
    return <LoadingResponseWrapper
        {...response}
        refresh={() => { requestTaskDetail({ ...query, ...fixedQuery }) }}
        renderContent={({ taskProfile, itemsContent, steps, current, editable, completed }) => {
            return <div className="page-content-wrapper">
                <div style={{ marginLeft: 80, marginRight: 80, marginBottom: 20 }}>
                    <Steps current={current}>
                        {steps.map(({ title, description }, i) => {
                            return <Step key={'stp' + i} title={title} description={description} />
                        })}
                    </Steps>
                </div>

                {taskProfile && <DescriptionsDisplay
                    title={taskProfile.item.id_code}
                    hideActions={false}
                    actionExecutor={actionExecutor}
                    {...taskProfile}
                />}

                <div style={{ marginTop: 20 }}>
                    <Table
                        size={'small'}
                        bordered={true}
                        pagination={false}
                        dataSource={[...itemsContent.items, ...addingMap]}
                        footer={editable ? () => {
                            return <Button style={{ marginRight: 10 }} onClick={() => {
                                if ((!selectorOptions || !selectorOptions.length) && mainItemType) {
                                    requestThingTree({
                                        item_type: mainItemType
                                    })
                                }
                                setAddingMap([...addingMap, { adding: true, id: new Date().getTime() }])
                            }}>添加{mainItemType == 1 ? '商品' : '物料'}</Button>
                        } : ''}
                        rowKey={({ id }) => id || 0}>

                        <Table.Column dataIndex={'status'} title={'序号'}
                            align={'center'}
                            key={itemsContent.displayFields.length + 3}
                            render={(_a, rowValue, index) => {
                                let thisItem = editingMap[rowValue.id]
                                if (thisItem && thisItem[itemTypeKey] && thisItem.red) {
                                    return <ExclamationCircleFilled style={{ color: 'red' }} />
                                }
                                return <b>{index + 1}</b>
                            }} />

                        {itemsContent.displayFields.map((displayField, columnIdx) => {
                            const { key, title } = displayField
                            const isCateOrProject = key === 'category' || key === 'project_code'
                            return <Table.Column
                                filters={isCateOrProject ? itemCategories : undefined}
                                filterDropdown={key === 'name' ? CustomFilterDropDown({
                                    dataIndex: key, handleSearch, handleReset, refInput: node => {
                                        setSearchInputNode(node)
                                    }
                                }) : undefined}
                                onFilterDropdownVisibleChange={visible => {
                                    if (visible && searchInput && key === 'name') {
                                        setTimeout(() => searchInput.select(), 100);
                                    }
                                }}
                                filterIcon={key === 'name' ? (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} /> : undefined}
                                onFilter={key === 'name' ? (value, record) => { return record.adding || (record[key] && record[key].toLowerCase().indexOf(value.toLowerCase()) >= 0) } :
                                    isCateOrProject ? (value, record) => { return record.adding || (record[key] && record[key] == value) }: false}
                                dataIndex={key} title={title} key={columnIdx}
                                align={'center'}
                                render={(cellVal, record) => {
                            let { adding, quantity_snapshot, id = 0, quantity = '', remark, sku_id = 0, material_id = 0 } = record
                            let editingInfo = editable ? { adding, sku_id, material_id, id, quantity, remark, ...editingMap[id] } : undefined
                            if (adding) {
                                switch (key) {
                                    case 'category':
                                    case 'project_code':
                                        return {
                                            children: <Cascader
                                                disabled={committing}
                                                showSearch={{
                                                    filter:  (inputValue, path) => path.some(option => (option.label || '').toLowerCase().indexOf(inputValue.toLowerCase()) > -1),
                                                    matchInputWidth: false,
                                                }}
                                                style={{ width: '100%'}}
                                                options={selectorOptions}
                                                onChange={(v) => {
                                                    let thisItem = editingMap[id] || {};
                                                    let itemId = (Array.isArray(v)) ? v.length && v[v.length - 1] || 0 : v
                                                    if (isNaN(itemId)) {
                                                        return
                                                    }
                                                    let red = false;
                                                    if (!red) {
                                                        red = responsedItemIds.indexOf(itemId) >= 0 
                                                    }

                                                    thisItem[itemTypeKey] = itemId
                                                    thisItem['red'] = red
                                                    editingMap[id] = thisItem
                                                    setEditingMap({ ...editingMap })
                                                }}
                                            />,
                                            props: {
                                                colSpan: 3
                                            }
                                        }

                                    case 'name':
                                    case 'goods_name':
                                    case 'sku_name':
                                    case 'description':
                                        return {
                                            props: {
                                                colSpan: 0
                                            }
                                        }
                                }
                            }
                            if (key === 'name' && searchText) {
                                return <Highlighter
                                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                    searchWords={[searchText]}
                                    autoEscape
                                    textToHighlight={cellVal ? cellVal.toString() : ''}
                                />
                            }
                            if (editable || key === 'check_result') {
                                switch (key) {
                                    case 'check_result':
                                        if (editingInfo) {
                                            quantity = editingInfo.quantity
                                        }

                                        if (quantity === null) {
                                            return <span />
                                        }
                                        const d = quantity - quantity_snapshot
                                        if (d > 0) {
                                            return <span style={{ color: 'green' }}>+{d}</span>
                                        } else if (d < 0) {
                                            return <span style={{ color: 'red' }}>{d}</span>
                                        }
                                        return <span />
                                    case 'remark':
                                        return <Input
                                            disabled={committing}
                                            value={editingInfo ? editingInfo.remark : JSON.stringify(editingInfo)}
                                            style={{ width: 200 }} onChange={(e) => {
                                                let value = (typeof e === 'object' && e && e.target) ? e.target.value : e
                                                editingInfo.remark = value
                                                editingMap[id] = editingInfo
                                                setEditingMap({ ...editingMap })
                                            }} />

                                    case 'quantity':
                                        return <InputNumber
                                            disabled={committing}
                                            value={editingInfo ? editingInfo.quantity : cellVal}
                                            type={"number"}
                                            min={0}
                                            style={{ width: 100 }} onChange={(e) => {
                                                let value = (typeof e === 'object' && e && e.target) ? e.target.value : e
                                                editingInfo.quantity = value;
                                                editingMap[id] = editingInfo
                                                setEditingMap({ ...editingMap })
                                            }} />

                                    case 'quantity_snapshot':
                                        return <span style={{ cursor: 'pointer' }} onClick={() => {
                                            editingInfo.quantity = cellVal
                                            editingMap[id] = editingInfo
                                            setEditingMap({ ...editingMap })
                                        }}>
                                            {renderItemFieldValue(displayField, record)}
                                        </span>
                                    default:
                                        return renderItemFieldValue(displayField, record)
                                }
                            }
                            return renderItemFieldValue(displayField, record)
                        }} />

                        })}

                    </Table>
                </div>

                {editable && <div style={{ marginLeft: 80, marginRight: 80, marginTop: 40 }}>
                    <Button style={{ marginRight: 10 }}
                        disabled={hasError || committing || itemsContent.items.length === 0 || itemsContent.items.filter(({ id, quantity }) => {
                            return quantity === null && (!editingMap[id] || editingMap[id] && editingMap[id].quantity === null)
                        }).length > 0} size='middle'
                        type='primary' onClick={() => {
                            saveCounts(true)
                        }}>提交审核</Button>
                    <Button style={{ marginRight: 10 }}
                        disabled={hasError || committing || Object.keys(editingMap).length == 0} type='primary'
                        onClick={() => {
                            /// 
                            let commit = saveCounts()
                            if (!commit) {
                                message.error('内容没有变化')
                            } else {
                            }

                        }}>保存草稿</Button>


                    <Alert style={{ marginTop: 20 }} message="完成所有项目的填写之后可提交审核" type="info" showIcon />

                </div>}
            </div>

        }}
    />

}


const CustomFilterDropDown = ({ dataIndex, handleSearch, handleReset, refInput}) => {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (<div style={{ padding: 8 }}>
        <Input
            ref={refInput}
            placeholder={`搜索`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div style={{flexDirection:'row', display: 'flex'}}>
            <Button onClick={() => handleReset(clearFilters)} size="small" type='link'>
                重置
            </Button>
            <div style={{flex: 1}}></div>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}>搜索</Button>
        </div>
    </div>)
}
import React, { useEffect } from "react"
import {retrieveValueByLabel} from "../../../core/editorUtil"
import {FormatMoney} from "format-money-js"
import { Descriptions, message, Spin, Typography } from "antd"
import { useHttpGet } from "../../../core/hooks"
import DescriptionsItem from "antd/lib/descriptions/Item"
import Modal from "antd/lib/modal/Modal"

export default (props) => {
    const {valueSet, onChange, ...others} = props
    const refundAmount = (valueSet.summary && valueSet.summary.refunds) ? valueSet.summary.refunds[valueSet.loan_id] : 0
    useEffect(() => {
        onChange && onChange(refundAmount)
    }, [refundAmount])
    return null

}

import React, { useContext, useEffect, useState, useMemo, useReducer, useRef } from "react"
import { useHttpGet, useRouteQuery } from "../../../core/hooks"
import PageHeaderWrapper from "../../PageHeaderWrapper"
import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import { Card, Col, Row, Descriptions, List, Table, Spin, Empty, Statistic, Steps, InputNumber, Select, Checkbox, Button, Divider, message } from "antd"
import HistoryChart from "../../biz/HistoryChart"
import { useActionExecutor } from "../../../core/actionExecuter"
import { renderActions, renderItemFieldValue } from "../../../core/domUtil"
import UnixDatePicker from "../../UnixDatePicker"
import { useRouteMatch } from "react-router-dom"
import DisplayComponent from "../Display"
import TableDisplay from "../TableDisplay"
import DescriptionsDisplay from "../DescriptionsDisplay"
import { FormatMoney } from "format-money-js"
import Title from "antd/lib/typography/Title"
import SelectWarehouseDisplay from "../common/SelectWarehouseDisplay"
import Modal from "antd/lib/modal/Modal"
import presentModal from "../../presentModal"

const { Step } = Steps;

const fm = new FormatMoney();

const TYPES = ['SMT', 'assembly', 'assembly_SMT']
const createTypedValueMap = (defaultValue) => {
    return TYPES.reduce((p, c, i) => {
        p[c] = typeof defaultValue === 'object' ? {...defaultValue} : defaultValue
        return p
    }, {})

}
const TypeValueKeyKeyReducer = (state, action) => {
    if (action.type === 'clear') {
        return createTypedValueMap({ items: [] })
    }
    if (action.type === 'init') {
        console.log('manc', 'init action.value', action.value);
        Object.keys(action.value).forEach((key, i) => {
            if (action.value[key].items) {
                action.value[key].items.forEach((v, i) => {
                    v.amount_cny = ((v.plan_quantity * v.price_cny) || 0).toFixed(2)
                })
            }
        })
        return { ...action.value }
    }
    if (!action.key) return state
    let target = { ...state[action.key] };
    let targetItems = [...(target['items'] || [])];
    switch (action.type) {
        case 'item_remove':
            if (action.index >= 0) {
                targetItems.splice(action.index, 1)
            }
            break
        case 'item_add':
            targetItems.push(action.value || {})
            break
        case 'item_value':
            if (action.index >= 0 && action.index < targetItems.length && action.field) {
                targetItems[action.index][action.field] = action.value
                let count = targetItems[action.index].plan_quantity;
                let price = targetItems[action.index].price_cny;
                targetItems[action.index]['amount_cny'] = ((count * price) || 0).toFixed(2)
            }
            break
        case 'value':
            if (action.field) {
                target[action.field] = action.value
            }
            break
        default: return state;
    }
    target['items'] = targetItems
    console.log('manc', 'targetItems', targetItems);
    return { ...state, [action.key]: target };
}
export default (props) => {
    const { props: { key, label }, onChange, valueSet, valueSetUpdater } = props
    const [inputs, dispatchInput] = useReducer(TypeValueKeyKeyReducer, {})
    const { id, plan_id, project_id } = valueSet;
    const [, actionResult, actionExecutor] = useActionExecutor()
    const [response, requestProfile, clear] = useHttpGet('/production/getManufactureOrderInitInfo')
    const [infoResponse, requestInfo] = useHttpGet('/production/getManufactureTargetInfo')
    const [showType, setShowType] = useState(createTypedValueMap(true))
    const inputSkuAndPCBA = id || !plan_id;
    const nameMap = useRef({ 'material_id': {}, 'sku_id': {} }).current
    useEffect(() => {
        if (project_id) {
            requestProfile({
                id: id || 0,
                project_id,
                plan_id: plan_id || 0,
            })
        } else {
            clear()
        }
    }, [requestProfile, actionResult, id, plan_id, project_id])
    useEffect(() => {
        dispatchInput({type: 'clear'})
    }, [dispatchInput])
    useEffect(() => {
        if (infoResponse.loading) return;
        let hasMaterialIds = Object.keys(nameMap['material_id']);
        let noHasMaterialIds = (inputs.SMT||{items:[]}).items
                .map(i => i.material_id ? (i.material_id + '') : undefined)
                .filter(i => i && hasMaterialIds.indexOf(i) < 0);

        let hasSkuIds = Object.keys(nameMap['sku_id']);
        let noHasSkuIds = (inputs.assembly||{items:[]}).items
                .map(i => i.sku_id ? (i.sku_id + '') : undefined)
                .filter(i => i && hasSkuIds.indexOf(i) < 0);
        let noHasSkuIds2 = (inputs.assembly_SMT || { items: [] }).items
                .map(i => i.sku_id ? (i.sku_id + '') : undefined)
                .filter(i => i && hasSkuIds.indexOf(i) < 0 && noHasSkuIds.indexOf(i) < 0);
        for (let i of noHasSkuIds2) {
            noHasSkuIds.push(i);
        }
        
        let params = {}
        let count = noHasSkuIds.length + noHasMaterialIds.length;
        if (noHasSkuIds && noHasSkuIds.length) {
            params.sku_ids = noHasSkuIds
        }
        if (noHasMaterialIds && noHasMaterialIds.length) {
            params.material_ids = noHasMaterialIds
        }
        if (count) {
            requestInfo(params)
        }
    }, [inputs])
 
    useEffect(() => {
        if (!nameMap || !infoResponse.data || infoResponse.loading)return;
        let priceChange = {}
        for(let key in infoResponse.data) {
            priceChange[key] = {}
            for (let targetId in infoResponse.data[key]) {
                nameMap[key][targetId] = infoResponse.data[key][targetId]
                priceChange[key][targetId] = nameMap[key][targetId]['price_cny']
            }
        }
        let priceChanged = false;
        for(let key of Object.keys(priceChange)) {
            let keyToTypeName = key === 'material_id' ? 'SMT' : 'assembly'
            let allPrices = priceChange[key]
            let typeInputs = inputs[keyToTypeName]['items']
            for(let item of typeInputs) {
                if (allPrices[item[key]]) {
                    priceChanged = true;
                    item['price_cny'] = allPrices[item[key]]
                }
            }
        }
        if (priceChanged) {
            dispatchInput({
                type : 'init', value: inputs
            })
        } else {
            setShowType({...showType})
        }
    }, [infoResponse])

    useEffect(() => {
        if (!id) {
            valueSetUpdater({
                plan_id: 0
            })
        }
    }, [requestProfile, actionResult, id, project_id])


    useEffect(() => {
        let hasHttpResponse = (response && response.data && !response.loading && response.success)
        let orders = hasHttpResponse ? response.data.orders: {}

        // setShowType({
        //     SMT: orders.SMT && orders.SMT.items,
        //     assembly: orders.assembly && orders.assembly.items,
        //     assembly_SMT: orders.assembly_SMT && orders.assembly_SMT.items,
        // })


        setShowType({
            SMT: orders.SMT && orders.SMT.yes,
            assembly: orders.assembly && orders.assembly.yes,
            assembly_SMT: orders.assembly_SMT && orders.assembly_SMT.yes,
        })

        const renderKeys = TYPES  //inputSkuAndPCBA ? TYPES : Object.keys(orders)

        if (hasHttpResponse) {
            let newinputs = {};
            renderKeys.forEach((key) => {
                let targetKey = key === 'SMT' ? 'material_id' : 'sku_id';
                let items = (orders[key] || {}).items || [];
                let needKeys = ['id', 'bom_id', 'material_id', 'sku_id', 'plan_quantity', 'price_cny',
                    'bom_href', 'note', 'qingping_pn']
                newinputs[key] = {
                    warehouse_sub_id: orders[key]['warehouse_sub_id'],
                    expect_lead_time: orders[key]['expect_lead_time'],
                }
                newinputs[key]['items'] = items.length ? items.map(item => {
                    let re = {}
                    for (let ikey of needKeys) {
                        re[ikey] = item[ikey]
                    }
                    return re
                }): [{}]
            })
            dispatchInput({
                type: 'init', value: newinputs
            })
        } else {
            if (inputSkuAndPCBA) {
                dispatchInput({ type: 'init', value: createTypedValueMap({ items: [{}]})})
            } else {
                dispatchInput({ type: 'clear' })
            }
        }
    }, [response, inputSkuAndPCBA]);

    useEffect(() => {
        let values = {}
        for(let key in showType) {
            if (showType[key]) {
                values[key] = inputs[key]
            }
        }
        onChange(JSON.stringify(values))
    }, [inputs, showType])

    return <LoadingResponseWrapper
        {...response}
        refresh={() => {
            requestProfile({
                id: id || 0,
                project_id,
                plan_id: plan_id || 0,
            })
        }}
        renderContent={({ orders = {}, displayFields = {}, }) => {
            const renderKeys = TYPES ///inputSkuAndPCBA ? TYPES : Object.keys(orders)
            const itemsMap = {}
            renderKeys.forEach((key) => {
                let targetKey = key === 'SMT' ? 'material_id' : 'sku_id';
                const thisItems = inputs[key]?[...(inputs[key]['items'] || [])].map(item => {
                    let displayItem = (nameMap[targetKey][item[targetKey]]) || {}
                    Object.keys(item).forEach(k => {
                        displayItem[k] = item[k]
                    })
                    return displayItem;
                }):[];
                itemsMap[key] = thisItems;
            })
            return <div>
                <Title level={5}>{label}</Title>
                {renderKeys.map(key => {
                    const manufacture = orders[key];
                    const tDisplayFields = displayFields[key] || displayFields['assembly'];
                    if (inputSkuAndPCBA) {
                        for (let field of tDisplayFields) {
                            if (field.key === 'qingping_pn') {
                                field.style = 'select'
                                field.key = 'material_id'
                                field.source = { options: orders[key].options }
                            } else if (field.key === 'sku_code') {
                                field.style = 'select'
                                field.key = 'sku_id'
                                field.source = { options: orders[key].options }
                            } else if (field.key === 'bom_id') {
                                field.style = 'select'
                                field.key = 'bom_id'
                                let targetKey = key === 'SMT' ? 'material_id' : 'sku_id';
                                field.source = { getOptions: (record) => {
                                    if (!nameMap[targetKey] || !nameMap[targetKey][record[targetKey]]) {
                                        return []
                                    }
                                    return nameMap[targetKey][record[targetKey]]['bom_options']
                                } }
                            }
                        }
                    } else {
                        for (let field of tDisplayFields) {
                            if (field.key === 'bom_id') {
                                field.style = 'link'
                                field.key = 'bom_href'
                            }
                        }
                    }
                    return <div key={'block_' + key}>
                        <Divider orientation='left'>
                            <Title level={5}>{manufacture ? manufacture.title : key} <Checkbox checked={showType[key]} onClick={(e) => {
                                setShowType({
                                    ...showType,
                                    [key]: !showType[key]
                                })
                            }} />
                            </Title>
                        </Divider>
                        {showType[key] && <ItemsTable
                            key={key}
                            displayFields={tDisplayFields}
                            items={itemsMap[key]}
                            onInput={(inputkey, rowIndex, value) => {
                                dispatchInput({
                                    'type': 'item_value',
                                    'key': key,
                                    'index': rowIndex,
                                    'field': inputkey,
                                    'value': value,
                                })
                            }}
                            inputValue={(inputkey, row) => inputs[key]['items'][row] && inputs[key]['items'][row][inputkey]}
                            onRemove={(record, rowIndex) => {
                                dispatchInput({
                                    'type': 'item_remove',
                                    'key': key,
                                    'index': rowIndex,
                                })
                            }}
                            onAdd={inputSkuAndPCBA ? (value) => {
                                dispatchInput({
                                    'type': 'item_add',
                                    'key': key,
                                    'value': value,
                                })
                            }: undefined}
                        /> || ''}
                        {showType[key] ?
                            <div className='ManuSupplierInfo'>
                                <div className="item">
                                    <span className="title">到货仓库</span>
                                    <div style={{ display: 'inline-block', width: 240, verticalAlign: 'top' }}>
                                        <SelectWarehouseDisplay item_type={key.toUpperCase() === 'SMT' ? 2 : 1}
                                            value={inputs[key]['warehouse_sub_id']}
                                            onChange={(value) => {
                                                dispatchInput({
                                                    'type': 'value',
                                                    'key': key,
                                                    'field': 'warehouse_sub_id',
                                                    'value': value,
                                                })
                                            }}
                                            onAddress={(data) => {
                                            }} />
                                    </div>
                                </div>
                                <div className="item">
                                    <span className="title">交付日期</span>
                                    <UnixDatePicker
                                        value={inputs[key]['expect_lead_time']}
                                        onChange={(unix) => {
                            
                                            dispatchInput({
                                                'type': 'value',
                                                'key': key,
                                                'field': 'expect_lead_time',
                                                'value': unix,
                                            })
                                        }} style={{ width: 240 }}/>
                                </div>
                            </div> : ''
                        }
                    </div>
                })}
            </div>
        }}
    />
}
const ColumnWrapper = ({ columnProps, record, readonly }) => {
    return renderItemFieldValue(columnProps, record, readonly)
}
const ItemsTable = ({ displayFields = [], items = [], title, oter, onInput, inputValue, onRemove, onAdd }) => {
    const filteredFields = displayFields.filter(({ y }) => y === 0);
    const spanSUM = filteredFields.reduce((accumulator, field) => {
        const { span } = field;
        return accumulator + parseInt(span, 10)
    }, 0) + 1
    let itemsToDisplay = onAdd ? [...(items || []), { id: -1, 'type': 'add' }] : (items || [])
    return <div>
        <Table
            dataSource={itemsToDisplay}
            bordered={true}
            pagination={false}
            size='small'
            rowKey={(k, index) => {
                return index
            }}>
            {filteredFields.map((displayField, columnIdx) => {
                const { key, title, sortable, span } = displayField
                return <Table.Column dataIndex={key} title={title} key={columnIdx}
                    width={(span / spanSUM) * 100 + '%'}
                    render={(cellVal, record, index) => {
                        if (record.type === 'add') return '';
                        if (displayField.style === 'select') {
                            return <Select
                                showSearch={true}
                                autoComplete='off'
                                optionFilterProp='label'
                                style={{ width: '100%' }}
                                options={displayField.source.getOptions?
                                    displayField.source.getOptions(record):displayField.source.options}
                                value={inputValue(key, index)}
                                onChange={(v) => {
                                    if (key === 'material_id') {
                                        onInput('bom_id', index, '')    
                                    }
                                    onInput(key, index, v)
                                }} />
                        } else if (displayField.style === 'input') {
                            return <InputNumber defaultValue={cellVal} value={inputValue(key, index)} onChange={(value) => {
                                onInput(key, index, value);
                            }} />
                        }
                        return <ColumnWrapper columnProps={displayField} record={record} />
                    }} />
            })}
            <Table.Column title={'操作'} key={'opt'}
                width={(1 / spanSUM) * 100 + '%'}
                render={(cellVal, record, index) => {
                    if (record.type === 'add') {
                        return <Button type='link' onClick={() => {
                            onAdd({});
                        }}>增加</Button>
                    } else return <Button type='link' onClick={() => {
                        onRemove(record, index);
                    }}>删除</Button>
                }} />
        </Table>
    </div>

}
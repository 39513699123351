import { Input, InputNumber } from "antd"
import React, { useEffect, useState } from "react"
import { useHttpAction, useHttpApi, useHttpGet } from "../../../core/hooks"


export default ({ props: { key, label, disabled }, valueSet, uri, rows, valueSetUpdater, onChange, extraProps = {} }) => {
    const [{ data }, getHttpValue] = useHttpAction(({ result }) => {
        if (onChange) {
            onChange(result)
        } else {
            setJustShowValue(result)
        }
    })
    const [justShowValue, setJustShowValue] = useState('')
    const value = justShowValue || valueSet[key];

    const { params, lines, ...otherProps } = extraProps
    const reqParams = {}
    if (params) {
        let paramNames = params.split(',')
        for (let paramName of paramNames) {
            let toName = paramName;
            if (paramName.indexOf(':') > 0) {
                [paramName, toName] = paramName.split(':')
            }
            reqParams[toName] = valueSet[paramName]
        }
    }
    useEffect(() => {
        if (uri && uri.length > 0 && (params && Object.keys(reqParams).length) || !params) {
            getHttpValue({
                uri,
                params: reqParams,
            })
        }
    }, [uri, JSON.stringify(reqParams)]);
    let itemProps = {
        onChange,
        value,
        disabled,
        options: data,
        ...otherProps
    }

    return <div>
        {label ? <div style={{ marginBottom: 8 }}>{label}</div> : null}
        {lines > 1 ?
            <Input.TextArea autoComplete='off' autosize="true" rows={lines || 5}
                {...itemProps} /> :
            <Input  {...itemProps} style={{ width: '100%' }} autoComplete='off' />}
    </div>
}
import React, {useContext, useEffect} from "react"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import {Descriptions, Tabs} from "antd"
import {useActionExecutor} from "../../core/actionExecuter"
import {renderActions, renderItemFieldValue} from "../../core/domUtil"
import {useRouteMatch} from "react-router-dom"
import ConsistencyReport from "../../components/eet/ConsistencyReport"

// import TemplateDisplayTable from "../../components/df/TemplateDisplayTable"
import AdminContext from "../../core/AdminContext"

// const ComponentsMap = {
//     TemplateDisplayTable
// }

export default (props) => {
    const {desBorderedColumn} = props;
    const {pageStates} = useContext(AdminContext)
    let query = useRouteQuery()
    const {path, url} = useRouteMatch()
    let actionState = {}
    if (pageStates[path]) {
        const {fixedQuery, fixedState} = pageStates[path]
        query = {
            ...query,
            ...fixedQuery
        }
        actionState = fixedState
    }

    const [response, getProfile] = useHttpGet('/eet/getReport')
    const [, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        getProfile(query)
    }, [actionResult, url])

    const headerProps = {}
    let displayItem = null, displayedFields = null
    if (response.data) {
        const {detail, title} = response.data
        let {displayedItem, displayFields} = detail
        displayedFields = displayFields
        displayItem = displayedItem
        if (displayedItem._actions_ && displayedItem._actions_.length) {
            headerProps.extra = <div>{renderActions(displayedItem._actions_, actionExecutor, actionState, 10)}</div>
        }
        headerProps.title = title
    }

    return <div>
        <PageHeaderWrapper
            {...headerProps}>
            {displayedFields && displayedFields.length > 0 &&
            <Descriptions size={'small'} bordered={desBorderedColumn > 0} column={desBorderedColumn || 3}>
                {displayedFields.map((field) => {
                    const {key, title} = field
                    return <Descriptions.Item label={title} key={key}>
                        {renderItemFieldValue(field, displayItem)}
                    </Descriptions.Item>
                })}
            </Descriptions>}
        </PageHeaderWrapper>

        <div className="page-content-wrapper">
            <LoadingResponseWrapper
                {...response}
                refresh={getProfile}
                renderContent={({tabs}) => {
                    return <Tabs size="large">
                        {tabs.map(({name, title, component, props}) => {
                            return <Tabs.TabPane tab={title} key={name}>
                                <ConsistencyReport {...props}/>
                            </Tabs.TabPane>
                        })}
                    </Tabs>


                }}
            />
        </div>

    </div>
}

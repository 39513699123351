import {useEffect, useRef, useState, useCallback} from 'react';
import {uniqIdFromAccess} from "./identityBuilder"
import {createOrJoinMqtt} from "./createOrJoin"
import {MqttStates} from "./states"
import {shareMqttStates} from "./globals"

export const useMqtt = (access, options) => {

    const mqttIdentity = uniqIdFromAccess(access)
    const [lastMessage, setLastMessage] = useState(null)
    const [mqttState, setMqttState] = useState(shareMqttStates[mqttIdentity] || MqttStates.CONNECTING)
    const pendingMessageQueue = useRef([])
    const mqttClientRef = useRef(null)

    const optionsRef = useRef(options)
    optionsRef.current = options

    const publish = useCallback((message) => {
        if (mqttClientRef.current && shareMqttStates[mqttIdentity] === MqttStates.SUBSCRIBED) {
            console.log('send message', message)
            mqttClientRef.current.publish(access.pubTopic, typeof message === 'string' ? message : JSON.stringify(message))
        } else {
            console.log('push message to queue', message)
            pendingMessageQueue.current.push(message)
        }
    }, [mqttIdentity])

    const subscribe = useCallback((topic) => {
        if (mqttClientRef.current) {
            if (shareMqttStates[mqttIdentity] === MqttStates.SUBSCRIBED || shareMqttStates[mqttIdentity] === MqttStates.CONNECTED) {
                mqttClientRef.current.subscribe(topic, err => {
                    if (!err) {
                        console.log(mqttIdentity + ':subscribed:' + topic)
                        shareMqttStates[mqttIdentity] = MqttStates.SUBSCRIBED
                    } else {
                        console.log(mqttIdentity + ':subscribing:' + topic + ' with error:' + err)
                        shareMqttStates[mqttIdentity] = MqttStates.ERROR
                    }
                })
            } else {
                console.log('bad state of mqtt', shareMqttStates[mqttIdentity])
            }
        } else {
            console.log('bad state of mqtt', shareMqttStates[mqttIdentity], 'null client')
        }
    })

    useEffect(() => {
        return createOrJoinMqtt(
            access,
            mqttClientRef,
            optionsRef,
            setMqttState,
            setLastMessage)

    }, [mqttIdentity])

    useEffect(() => {
        if (mqttState === MqttStates.SUBSCRIBED) {
            pendingMessageQueue.current.splice(0).forEach((message) => {
                publish(message)
            })
        }
    }, [mqttState])

    return {
        publish,
        subscribe,
        lastMessage,
        mqttState,
    }

}

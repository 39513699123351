import React, { useCallback, useEffect, useState } from "react";

import { Button, Card, Col, Descriptions, Row, Statistic, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
    AppleFilled,
    AppleOutlined,
    FacebookFilled,
    FacebookOutlined,
    GoogleCircleFilled,
    GoogleOutlined,
    GoogleSquareFilled,
    ReloadOutlined,
    WechatFilled,
    WechatOutlined,
} from "@ant-design/icons";
import { useHttpGet } from "../../../core/hooks";
import { useActionExecutor } from "../../../core/actionExecuter";
import LoadingResponseWrapper from "../../LoadingResponseWrapper";
import moment from "moment";
import Title from "antd/lib/typography/Title";
const KeyToInfo = {
    temperature: { name: '温度', unit: "℃" },
    humidity: { name: '湿度', unit: "%" },
    prob_temperature: { name: '外接温度', unit: "℃", mute: true },
    prob_humidity: { name: '外接湿度', unit: "%", mute: true },
    pressure: { name: '气压', unit: "kPa" },
    pm25: { name: 'PM2.5', unit: "μg/m3" },
    pm10: { name: 'PM10', unit: "μg/m3" },
    co2: { name: 'CO2', unit: "ppm" },
    co2_percent: { name: 'CO2', unit: "%" },
    tvoc: { name: 'TVOC', unit: "ppb" },
    tvoc_index: { name: 'VOC指数', unit: "指数" },
    noise: { name: '噪音', unit: "dB" },
    lumen: { name: '光照度', unit: "lux" },
    signal: { name: '信号', unit: "dB" }, // 在 Hodor 和 Parrot 中，signal 代表开关状态
    battery: { name: '电量', unit: "%" },
};
export default ({ dispaly_title, fixedQuery, uri, id }) => {
    const [detail, getDetail] = useHttpGet(uri);
    const [, actionResult, actionExecutor] = useActionExecutor();
    const refreshPage = useCallback(() => {
        getDetail();
    }, [getDetail]);

    useEffect(() => {
        getDetail();
    }, []);
    const {data: currentData = {}, scenes = [], settings, mqtt = false} = detail?.data || {}
    const [mqttState, setMqtt] = useState({connected: false, supported: false, config: null, get: '', update: ''})
    useEffect(() => {
        if (mqtt) {
            setMqtt({
                supported: true, 
                connected: false,
                ...mqtt,
            })
        }
    }, [mqtt])
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card style={{}} bordered={true}>
                        <Row gutter={[10, 40]}>
                            <h2>读数</h2>
                            <Button
                                style={{display:'inline'}}
                                onClick={() => {
                                    refreshPage()
                                }}
                                icon={<ReloadOutlined spin={detail?.loading} />}
                            ></Button>
                        </Row>                        
                        <Row gutter={[10, 40]}>
                            {Object.entries(KeyToInfo).map(
                                ([key, info], index) => {
                                    if (!currentData.hasOwnProperty(key)) {
                                        return null;
                                    }
                                    return (
                                        <Col key={"block" + key} span={5}>
                                            <Card>
                                                <Statistic
                                                    title={info.name}
                                                    value={currentData[key]}
                                                    precision={2}
                                                    suffix={
                                                        <span
                                                            style={{
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            {info.unit}
                                                        </span>
                                                    }
                                                    valueStyle={{
                                                        fontSize: 30,
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                            </Card>
                                        </Col>
                                    );
                                }
                            )}
                        </Row>
                        <h5>
                            数据时间：
                            {currentData.timestamp
                                ? moment(currentData.timestamp * 1000).format(
                                      "YYYY MM-DD, HH:mm:ss"
                                  )
                                : "--"}
                        </h5>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

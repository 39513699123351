import { Cascader, Select } from "antd"
import React, { useEffect, useMemo } from "react"
import { useHttpGet } from "../../../core/hooks"


export default ({ props: {key, label}, valueSet, category, rows, valueSetUpdater, onChange, ...other }) => {
    const {
        pay_type,
        defer_pay_day,
    } = { ...other, ...valueSet};

    const [response, getPayType] = useHttpGet('/production/getPayTypeOptions')

    useEffect(() => {
        getPayType()
    }, [])

    useEffect(() => {
        valueSetUpdater && valueSetUpdater({
            ...valueSet,
            pay_type, 
            defer_pay_day: defer_pay_day|| 30
        })
    }, [response])

    return <div>
        <div style={{marginTop: 0, marginBottom: 8}}>{label}</div>
        <Cascader
            style={{width: '100%'}}
            value={pay_type==3?[3, defer_pay_day] : pay_type==5?[5,5]: [0,pay_type]} 
            options={response ? response.data : undefined}
            changeOnSelect={false}
            onChange={(value, k) => {
                valueSetUpdater && valueSetUpdater({
                    ...valueSet,
                    pay_type: value[0] === 3 ? 3 : value[1],
                    defer_pay_day: value[0] === 3 ? value[1] : 0
                })
            }}/>
    </div>
}
import React, {useCallback, useEffect, useReducer, useState} from 'react'

import {RightOutlined, PlusOutlined, CloseOutlined} from '@ant-design/icons'
import {Dropdown, Menu} from "antd"
import Text from 'antd/lib/typography/Text'

let NodeComponents

const FlowNodeAddButton = ({node, onNodeAdd}) => {

    const menu = <Menu onClick={({key}) => {
        onNodeAdd && onNodeAdd(node, key)
    }}>
        <Menu.Item key={'approval'}>添加节点</Menu.Item>
        <Menu.Item key={'condition'}>添加分支</Menu.Item>
    </Menu>

    return <div className="add-node-btn">
        <Dropdown overlay={menu} placement={'bottomLeft'}>
            <div className="add-btn"><PlusOutlined/></div>
        </Dropdown>
    </div>
}

const FlowNodeStart = ({node, onNodeAdd}) => {
    return <div className="flow-editor-node start">
        <div className="flow-editor-node-container node-content-text-only">
            <div>开始</div>
        </div>
        <div className="bottom-v-line"/>
        <FlowNodeAddButton node={node} onNodeAdd={onNodeAdd}/>
    </div>
}

const FlowNodeEnd = () => {
    return <div className="flow-editor-node end">
        <div className="flow-editor-node-container node-content-text-only">
            <div>结束</div>
        </div>
    </div>
}

const FlowNodeCondition = ({node, onNodeRemove, onNodeEdit, onNodeAdd}) => {
    const {condition_content} = node
    return <div className="flow-editor-node condition">
        <div className="flow-editor-node-container">
            {/*<div className="node-title node-title-condition">*/}
            {/*    <span className="node-title-name">条件 1</span>*/}
            {/*    <span className="node-title-priority">优先级 1</span>*/}
            {/*</div>*/}
            <div className="node-content node-content-placeholder" onClick={() => {
                onNodeEdit && onNodeEdit(node)
            }}>
                <div className="node-content-text-only node-content-fullwidth">
                    {/*<div className="node-content-text-only">*/}
                    <div>{condition_content || '空'}</div>
                    {/*</div>*/}
                </div>
                {/*<RightOutlined/>*/}
            </div>
            <CloseOutlined className="delete-btn" onClick={() => {
                onNodeRemove && onNodeRemove(node)
            }}/>
        </div>
        <div className="bottom-v-line"/>
        <FlowNodeAddButton node={node} onNodeAdd={onNodeAdd}/>
    </div>
}

const FlowNodeApproval = ({node, onNodeRemove, onNodeEdit, onNodeAdd}) => {

    const {detailItems, invalid, id, title, custom_tag} = node

    return <div className={"flow-editor-node approval " + (invalid ? "has-error" : '')}>
        <div className="flow-editor-node-container">
            <div className="node-title">
                <div className="node-title-name">审批[{id}]{title}</div>
            </div>
            <div className={"node-content " + (invalid ? 'node-content-placeholder' : '')} onClick={() => {
                onNodeEdit && onNodeEdit(node)
            }}>
                {invalid && <div className="node-detail">
                    <div className="node-detail-item node-detail-item-placeholder">请完善信息</div>
                </div>}

                {!invalid && <div className="node-detail multiple-items">
                    {detailItems.map((detailItem, idx) => {
                        return <div className="node-detail-item" key={idx}>{detailItem}</div>
                    })}
                    <div className="node-detail-item" key={'tag'}>TAG: {custom_tag}</div>
                </div>}

                <RightOutlined/>
            </div>
            <CloseOutlined className="delete-btn" onClick={() => {
                onNodeRemove && onNodeRemove(node)
            }}/>
        </div>
        <div className="bottom-v-line"/>
        <FlowNodeAddButton node={node} onNodeAdd={onNodeAdd}/>
    </div>
}


const FlowNodeBranch = ({node, ...otherProps}) => {
    const {nodes} = node
    return <div className={'flow-editor-node branch' + (node._parent_id_ > 0 ? '' : ' root-branch')}>
        <div className={'top-line-mask'}/>
        <div className={'top-v-line'}/>
        <div className={'nodes'}>
            {nodes.map((node, idx) => {
                const {component} = node
                const Comp = NodeComponents[component]
                return <Comp node={node} {...otherProps} key={idx}/>
            })}
        </div>
        <div className={'bottom-v-line'}/>
        <div className={'bottom-line-mask'}/>
    </div>
}


const FlowNodeRoute = ({node, ...otherProps}) => {
    const {nodes} = node
    const {onNodeAdd} = otherProps
    return <div className={'flow-editor-node route'}>
        <div className="top-h-line"/>
        <div className="add-branch" onClick={() => {
            onNodeAdd && onNodeAdd(node, 'branch')
        }}>
            <div className="add-branch-inner">添加条件</div>
        </div>
        <div className={'branches'}>
            {nodes.map((branchNode, idx) => {
                return <FlowNodeBranch key={idx} node={branchNode} {...otherProps}/>
            })}
        </div>
        <div className="bottom-h-line"/>
        <div className="bottom-v-line"/>
        <FlowNodeAddButton node={node} onNodeAdd={onNodeAdd}/>
    </div>
}


NodeComponents = {
    FlowNodeStart,
    FlowNodeEnd,
    FlowNodeCondition,
    FlowNodeApproval,
    FlowNodeRoute,
    FlowNodeBranch
}

export default ({nodes, ...otherProps}) => {

    return <div className={'root-editor'}>
        <div className={'flow-editor'}>
            <div className={'root-node-wrap'}>
                <FlowNodeBranch {...otherProps} node={{id: 0, nodes}}/>
            </div>
        </div>
    </div>

}

import React from "react"
import { InputNumber } from "antd"

export default ({ style = {}, prefix = "￥", value, onChange = () => { }, ...others}) =>  {
  return <InputNumber
    style={style}
    value={value}
    formatter={value => value==''?'':`${prefix} ${`${value}`.replace(/[^\d.]/g, '')}`}
    parser={value => value.replace(/[^\d.]/g, '')}
    // parser={value => value.replace(prefix != '￥' ? /\$\s?|(,*)/g : /\￥\s?|(,*)/g, '')}
    onChange={onChange}
    {...others}
  />
}

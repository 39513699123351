import React, {useCallback, useEffect} from "react"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import { Button } from "antd"
import { renderActions, renderActionWith } from "../../core/domUtil"
import { useActionExecutor } from "../../core/actionExecuter"
import { useHistory, useRouteMatch } from "react-router-dom"
import { buildSearchParams } from "../../core/uriBuilder"

const buildDisplayProps = (tabs, tabsExtra, reload) => {
    return {
        component: 'TopTabbedDisplay',
        props: {
            tabs : tabs.map((tab) => {
                let query = {
                    activity_id: tab.value
                };

                return {
                    ...tab,
                    content: {
                        component: 'TmallActivityTable',
                        props: {
                            uri: '/sale/getActivityTable' ,
                            query,
                            onItemDeleted: reload
                        }
                    }
                }
            }),
            tabsExtra ,
            fixedFilterKey: 'activity_id',
        }
    }
}

export default () => {

    const [activitiesResponse, getActivites] = useHttpGet('/sale/getTmallActivities')
    const [, actionResult, actionExecutor] = useActionExecutor()
    const history = useHistory()
    const {url} = useRouteMatch()
    const routeQuery = useRouteQuery()
    let newId = actionResult && actionResult.result && actionResult.result.id;
    const refreshPage = useCallback((focusTo) => {
        getActivites()
        if (focusTo) {
            history.push({
                pathname: url,
                search: buildSearchParams(routeQuery, {
                    activity_id: focusTo > 0 ? focusTo : ''
                })
            })
        }
    }, [getActivites])

    useEffect(() => {
        getActivites()
    }, [])
    useEffect(() => {
        refreshPage(newId)
    }, [newId])
    return <div>
        <PageHeaderWrapper/>
        <LoadingResponseWrapper
            {...activitiesResponse}
            refresh={refreshPage}
            renderContent={({options: activities, _actions_ = []}) => {
                const displayProps = buildDisplayProps(activities, 
                    renderActions(_actions_, actionExecutor, {}, 3, {type: 'primary'}),
                    () => {refreshPage(-1)}
                )
                return <DisplayPage {...displayProps}/>
            }}
        />
    </div>
}

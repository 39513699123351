import React, { useEffect, useState } from "react";
import LeftMenuDisplay from "./LeftMenuDisplay";
import { useHttpGet } from "../../core/hooks";
import DisplayComponent from "./Display";
import { Card, Col, Row, List, Pagination } from "antd";
import { useActionExecutor } from "../../core/actionExecuter";

export default ({
    listItemUri = "/template/listItem",
    itemRender = item => {
        return <span>#{item.id}</span>;
    },
    contentDisplay,
    fixedFilterKey,
    query = {},
    onQueryUpdated,
}) => {
    const [, actionResult, actionExecutor] = useActionExecutor();
    const [leftListResponse, requestItems] = useHttpGet(listItemUri);
    const { component: contentComponent, props: componentProps } =
        contentDisplay;
    const [selectedKey, setSelectedKey] = useState(query[fixedFilterKey]);
    const { page = 1, page_size: pageSize = 20, id, ...otherQuery } = query;

    useEffect(() => {
        requestItems({
            // ...search,
            ...otherQuery,
            page: page,
            page_size: pageSize,
            // _sorts_: sortBy
        });
    }, [page, pageSize, actionResult]);

    return (
        <div style={{ paddingLeft: 10 }}>
            <Row>
                <Col className="leftRightPageLeftList">
                    <List
                        loading={leftListResponse.loading}
                        dataSource={
                            (leftListResponse.data &&
                                leftListResponse.data.items) ||
                            []
                        }
                        renderItem={item => {
                            return (
                                <div
                                    style={{
                                        height: 100,
                                        cursor: "pointer",
                                        padding: "15px 20px",
                                        backgroundColor:
                                            item.id == selectedKey
                                                ? "#f0f0f0"
                                                : "",
                                    }}
                                    onClick={() => {
                                        onQueryUpdated &&
                                            onQueryUpdated({
                                                [fixedFilterKey]: item.id,
                                            });
                                        setSelectedKey(item.id);
                                    }}
                                >
                                    {itemRender(item)}
                                </div>
                            );
                        }}
                    />
                </Col>
                <Col flex={"1 1"} className="leftRightPageRightDetail">
                    <DisplayComponent
                        component={contentComponent}
                        props={{
                            ...componentProps,
                            [fixedFilterKey]: selectedKey,
                            showPadding: false,
                            actionExecutor,
                            actionResult,
                        }}
                    />
                </Col>
            </Row>

            <Pagination
                style={{ margin: 10 }}
                {...{
                    hideOnSinglePage: false,
                    current: parseInt(page),
                    pageSize: parseInt(pageSize),
                    size: "small",
                    total:
                        (leftListResponse.data &&
                            leftListResponse.data.count) ||
                        0,
                    defaultPageSize: 20,
                    onChange: (page, pageSize) => {
                        onQueryUpdated &&
                            onQueryUpdated({
                                page: page,
                                page_size: pageSize,
                            });
                    },
                }}
            />
        </div>
    );
};

import { Form, InputNumber, Table } from "antd"
import React, {useCallback, useEffect, useState} from "react"

import { FormatMoney } from "format-money-js"
import {useHttpGet} from "../../../core/hooks"
import { renderItemFieldValue } from "../../../core/domUtil"


export default (props) => {
    const {valueSet, rows, onChange} = props
    const {item_ids, type} = valueSet

    const [itemCounts, setItems] = useState({})

    const [itemsResponse, getItems] = useHttpGet('/production/getUnpaidItems');

    console.log('valueSet', valueSet)
    useEffect(() => {
        if (item_ids) {
          getItems({
              item_ids: item_ids,
              type: type?type:1,
          });
        }
    }, [item_ids, type])

    const {loading, error, data: items = []} = itemsResponse

    useEffect(() => {
      if (items.length) {
        onChange(items[0].supplier_id)
      }
    }, [items.length])

    const moneier = new FormatMoney()
    const columns = type != 2 ? [
        {
            title: '物料编号',
            key: 'qingping_pn',
        },{
            title: '订单编号',
            key: 'order_code_id',
        },{
            title: '交货日期',
            key: 'date',
        },{
            title: '交货数量',
            key: 'quantity',
            style: 'count',
        },{
            title: '含税单价',
            key: 'price_cny',
            style: 'currency',
        },{
            title: '含税总价',
            key: 'price_amount',
            style: 'currency',
        },
    ]: [
        {
            title: '物料编号',
            key: 'qingping_pn',
        },{
            title: '订单编号',
            key: 'order_code_id',
        },{
            title: '货代合同',
            key: 'freight_order_code_id',
        },{
            title: '创建日期',
            key: 'date',
        },{
            title: '交货数量',
            key: 'quantity',
            style: 'count',
        },{
            title: '含税单价',
            key: 'price_cny',
            style: 'currency',
        },{
            title: '服务费',
            key: 'service_fee_amount',
            style: 'currency',
        },{
            title: '含税总价',
            key: 'total_price_cny',
            style: 'currency',
        },
    ]
    return <Table 
        pagination={false}
        loading={loading}
        size={'small'}
        dataSource={items}
        rowKey={({id}) => id}>
        {columns.map((field) => {
            const {title, key, render} = field
            return <Table.Column title={title} dataIndex={key} render={render?render:(value, record) => {
                return renderItemFieldValue(field, record, true)
            }}>
            </Table.Column>
        })}
    </Table>
}
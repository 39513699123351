import React, { useEffect } from "react"
import {retrieveValueByLabel} from "../../../core/editorUtil"
import {FormatMoney} from "format-money-js"
import { Descriptions, message, Spin, Typography } from "antd"
import { useHttpGet } from "../../../core/hooks"
// import DescriptionsItem from "antd/lib/descriptions/Item"
// import Modal from "antd/lib/modal/Modal"

export default (props) => {
    const [loan, getLoan] = useHttpGet('/approval/getLoan');
    const {valueSet, rows, onChange, ...others} = props
    useEffect(() => {
        if (valueSet.loan_id) {
            getLoan({loan_id: valueSet.loan_id})
        }
    }, [valueSet.loan_id])
    useEffect(() => {
        onChange && onChange(loan.data?loan.data.available_money_amount:0)
    }, [loan.data])
    const refundAmount = valueSet.refund_money_amount ? valueSet.refund_money_amount : 0
    const fm = new FormatMoney()
    return valueSet.loan_id?(loan.loading ? <Spin/>: loan.data ? <Descriptions bordered column={4} size={'small'}>
        <Descriptions.Item label='借款单'>{loan.data.code_name}</Descriptions.Item>
        <Descriptions.Item label='借款单金额'>{fm.from(loan.data.loan_money_amount, { decimals: 2})}</Descriptions.Item>
        <Descriptions.Item label='待归还金额'>{fm.from(loan.data.available_money_amount, {decimals: 2})}</Descriptions.Item>
        <Descriptions.Item label='核销金额'>{(refundAmount) ? fm.from(refundAmount, {decimals: 2}): '--'}</Descriptions.Item>
    </Descriptions>:null):null

}

import React from "react";
import {
  Form,
  Cascader,
  Input,
  InputNumber,
  Button,
  Table,
  Select,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";

const PurchaseOrderTable = ({
  prds,
  setPrds,
  prdsResponse,
  autoFocusIdx,
  handleKeyDown,
}) => {
  console.log("prdsResponse?.data", prdsResponse?.data);
  //添加采购设备
  const handleAddProduct = () => {
    // Ensure prdsResponse and its nested properties are available
    // const data = prdsResponse?.data;
    // if (!data || !data.length) return;

    // const [{ value: parentValue, children }] = data;
    // const [{ value: childValue, price }] = children || [];

    // if (!parentValue || !childValue) return;

    // const newProduct = {
    //   count: 1,
    //   price: price || 0,
    //   product_id: parentValue,
    //   sku_id: childValue,
    // };
    const newProduct = {
      count: 0,
      price: 0,
      product_id: undefined,
      sku_id: undefined,
    };

    setPrds((prevPrds) => [...prevPrds, newProduct]);
  };

  //合计金额
  const calculateTotal = () => {
    if (!prdsResponse?.data) return 0;

    let total = 0;
    prds.forEach(({ count, price }) => {
      total += (count || 0) * price; // 计算每个产品的金额并累加
    });
    return total || "--";
  };

  function getPrice(productValue, childValue) {

    const product = prdsResponse?.data?.find(p => p.value === productValue);

    if (!product || !product.children) return 0;

    const child = product.children.find(c => c.value === childValue);

    return child ? child.price : 0;
  }

  const columns = [
    {
      title: "产品",
      dataIndex: "prdId",
      key: "prdId",
      render: (text, record, index) => (
        <Form.Item key={`prd_${index}`} noStyle>
          <Cascader
            className="table_product_cascader"
            options={prdsResponse?.data}
            placeholder="选择要购买的产品"
            allowClear={false}
            value={(record.product_id&&record.sku_id)?[record.product_id, record.sku_id]:''} //默认值
            onChange={(v) => {
              console.log("pardV", v);
              const newPrds = [...prds];
              newPrds[index] = {
                ...newPrds[index],
                product_id: v?.[0],
                sku_id: v?.[1],
                count:1,
                price:getPrice(v?.[0],v?.[1])
              };
              setPrds(newPrds);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "数量",
      dataIndex: "number",
      key: "number",
      width: 100,
      render: (text, record, index) => (
        <Form.Item
          key={`prd_${index}_count`}
          rules={[{ required: true, message: "请输入正确的购买数量" }]}
          noStyle
        >
          <InputNumber
            min={0}
            autoFocus={index === autoFocusIdx}
            value={record.count}
            onChange={(value) => {
              const newPrds = [...prds];
              newPrds[index] = { ...newPrds[index], count: value };
              setPrds(newPrds);
            }}
            onKeyDown={handleKeyDown}
          />
        </Form.Item>
      ),
    },
    {
      title: "单价",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (text, record) => (
        <div>
          <div className="table_price">单价：</div> ¥ {record.price || "--"}
        </div>
      ),
    },
    {
      title: "总价",
      key: "total",
      width: 100,
      render: (text, record) => (
        <div>
          <div className="table_price">总价：</div>¥{" "}
          {record.price * (record.count || 0) || "--"}
        </div>
      ),
    },
    {
      title: "操作",
      key: "action",
      width: 100,
      render: (text, record, index) => (
        <div>
          <div
            className="center"
            onClick={() => {
              const newPrds = [...prds];
              newPrds.splice(index, 1);
              setPrds(newPrds);
            }}
            style={{
              width: 35,
              height: 35,
              background: "#f2f2f2",
              borderRadius: 10,
              cursor: "pointer",
            }}
          >
            <DeleteFilled style={{ color: "#262626" }} />
          </div>
        </div>
      ),
    },
  ];

  const prdsMobileTable = (prds) => {
    return prds?.map(({ product_id, sku_id, count, price }, i) => (
      <div className="center clearfix mobileTable" key={`mobile_table_${i}`}>
        <div
          style={{ width: "100%", marginBottom: "0.625rem" }}
          className="center space-between"
        >
          {`产品${i + 1}`}{" "}
          <div>
            <div
              className="center"
              onClick={() => {
                prds.splice(i, 1);
                setPrds([...prds]);
              }}
              style={{
                width: "2.3rem",
                height: "2.3rem",
                background: "#f2f2f2",
                borderRadius: 10,
                cursor: "pointer",
              }}
            >
              <DeleteFilled style={{ color: "#262626" }} />
            </div>
          </div>
        </div>
        <Form.Item key={`prd_${i}`} label={`产品${i + 1}`} noStyle>
          <Cascader
            style={{ width: "100%", marginBottom: "0.625rem" }}
            options={prdsResponse?.data}
            placeholder="选择要购买的产品"
            allowClear={false}
            value={(product_id&&sku_id)?[product_id, sku_id]:''}
            onChange={(v) => {
              prds.splice(i, 1, {
                ...prds[i],
                product_id: v?.[0],
                sku_id: v?.[1],
                count: 1,
                price:getPrice(v?.[0],v?.[1])
              });
              setPrds([...prds]);
            }}
          />
        </Form.Item>
        <Form.Item label="数量">
          <Input
            value={count}
            onChange={(e) => {
              let value = e.target.value;
              if (!isNaN(value) && value !== "" && parseFloat(value) >= 0) {
                value = parseFloat(value);
              } else {
                value = 0;
              }
              prds.splice(i, 1, { ...prds[i], count: value || 0 });
              setPrds([...prds]);
            }}
            onKeyDown={handleKeyDown}
            inputMode="text"
          />
        </Form.Item>
        <div style={{ width: "100%" }}>单价: ¥ {price || "--"}</div>
        <br />
        <div style={{ width: "100%" }}>小记: ¥ {price * (count || "--")}</div>
      </div>
    ));
  };

  return (
    <div className="site-item-container item-PO">
      <div className="align-center">
        <div className="item-box-title">采购清单</div>
      </div>

      {prds.length === 0 && (
        <div className="center" style={{ height: "12.25rem" }}>
          <Button
            type="primary"
            onClick={handleAddProduct}
            shape="round"
            icon={<PlusOutlined />}
            size="large"
          >
            添加商品
          </Button>
        </div>
      )}

      {prds.length > 0 && (
        <div>
          <div className="webTable">
            <Table columns={columns} dataSource={prds} pagination={false} />
          </div>
          {prdsMobileTable(prds)}

          <div
            className="align-center"
            style={{ height: "3.125rem", marginTop: "1.25rem" }}
          >
            <Button
              type="button"
              onClick={handleAddProduct}
              shape="round"
              icon={<PlusOutlined />}
              size="default"
            >
              添加
            </Button>
            <div>合计 ¥ {calculateTotal()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseOrderTable;

import React from "react"
import * as ReactDOM from 'react-dom'
import {ConfigProvider, Modal} from 'antd'

import zhCN from "antd/es/locale/zh_CN"
import destroyFns from "antd/es/modal/destroyFns"

const ModalDialog = (props) => {


    // const {close, zIndex, afterClose, closable, visible, keyboard, centered, getContainer, maskStyle, title} = props
    // const prefixCls = props.prefixCls || 'ant-modal'
    // const contentPrefixCls = "".concat(prefixCls, "-confirm"); // 默认为 true，保持向下兼容
    //
    // const width = props.width || 416
    // const style = props.style || {}
    // const mask = props.mask === undefined ? true : props.mask; // 默认为 false，保持旧版默认行为

    // const maskClosable = props.maskClosable === undefined ? false : props.maskClosable
    // const transitionName = props.transitionName || 'fade'
    // const maskTransitionName = props.maskTransitionName || 'fade'
    // const classString = classNames(contentPrefixCls, "".concat(contentPrefixCls, "-").concat(props.type), props.className)


    const {content, ...otherProps} = props

    return <Modal {...otherProps}>
        {content}
    </Modal>
}

export default (props) => {

    const div = document.createElement('div')
    document.body.appendChild(div)

    const {PresentComponent = ModalDialog} = props

    const render = (modalProps) => {
        ReactDOM.render(<ConfigProvider locale={zhCN}>
            <PresentComponent {...modalProps}/>
        </ConfigProvider>, div)
    }

    const destroy = () => {
        const unmountResult = ReactDOM.unmountComponentAtNode(div)

        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div)
        }

        if (props.onCancel) {
            props.onCancel()
        }

        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i] // eslint-disable-next-line no-use-before-define
            if (fn === close) {
                destroyFns.splice(i, 1)
                break
            }
        }
    }

    let modalProps
    const close = () => {
        modalProps = Object.assign(Object.assign({}, props), {
            visible: false,
            afterClose: destroy,
        })
        render(modalProps)
    }

    modalProps = Object.assign(Object.assign({}, props), {
        close,
        visible: true,
        onCancel: () => {
            return close({
                triggerCancel: true
            })
        }
    })

    const update = (newConfig) => {
        modalProps = Object.assign(Object.assign({}, modalProps), newConfig)
        render(modalProps)
    }

    render(modalProps)
    destroyFns.push(close)

    return {
        destroy: close,
        update: update
    }
}

import React, {useEffect} from "react"

import LoadingResponseWrapper from "../../LoadingResponseWrapper"
import {MqttStates} from "../../../core/mqtt/states"

import {useChartRequest} from "../../../core/v/useChartRequest"
import _ from 'lodash'
import {Statistic} from "antd"

export default ({mqtt, request, refreshInterval, valuePath, title, inverted = false}) => {

    const {sendRequest, response, state} = useChartRequest(mqtt, request)

    const wrappedResponse = {
        loading: !response,
        error: state === MqttStates.ERROR ? 'mqtt error' : null,
        data: response
    }

    useEffect(() => {

        if (!refreshInterval) {
            return
        }

        const interval = setInterval(() => {

            sendRequest(request)
        }, refreshInterval * 1000);

        return () => {
            clearInterval(interval);
        }
    }, [refreshInterval, request.requestId]);

    return <LoadingResponseWrapper
        {...wrappedResponse}
        refresh={() => {
            // publish()
            sendRequest(request)
        }}
        renderContent={({metricSet}) => {

            // const value = _.get(metricSet, valuePath)

            const [metricName, dataType, target, field] = valuePath.split('.')

            const timingValuePath = [metricName, 'dataTiming', target].join('.')

            const timingValues = _.get(metricSet, timingValuePath)

            let value = 0
            let increase = 0

            if (timingValues) {
                const valueLength = timingValues.length
                value = valueLength ? timingValues[valueLength - 1][field] : 0
                if (valueLength >= 2) {
                    increase = value - timingValues[valueLength - 2][field]
                }
                if (increase > 0) {
                    increase = '+' + increase
                }
            }

            const color = inverted ? (increase > 0 ? '#cf1322' : '#3f8600') : (increase > 0 ? '#3f8600' : '#cf1322')


            return <Statistic
                title={title}
                value={value}
                suffix={increase !== 0 && <div style={{color}}>
                    {increase}
                </div>
                }

            />


        }}
    />


}

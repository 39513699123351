import React, {useEffect} from "react"
import {useHttpGet, useHttpPost, useRouteQuery} from "../../core/hooks"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import GridLayout, {WidthProvider} from "react-grid-layout"
import {Col, Row} from "antd"
import {useActionExecutor} from "../../core/actionExecuter"
import {renderActions} from "../../core/domUtil"

const AutoWidthGridLayout = WidthProvider(GridLayout)

function getLayoutAndElements({elements, minSpan}) {

    const layout = []
    const items = []
    for (let element of elements) {
        const {id, span, x, y} = element
        layout.push({
            i: 'ele_' + id,
            w: span,
            h: 1,
            minW: minSpan,
            minH: 1,
            maxH: 1,
            y,
            x,
        })
        items.push(element)
    }

    return {layout, elements: items}
}

export default () => {

    const query = useRouteQuery()
    const [layoutResponse, getDisplayLayout] = useHttpGet('/template/getDisplayLayout')
    const [, actionResult, actionExecutor] = useActionExecutor()
    const [, saveDisplayLayout] = useHttpPost('/template/saveDisplayLayout')

    useEffect(() => {
        getDisplayLayout(query)
    }, [query.id, actionResult])

    const headerProps = {}
    if (layoutResponse.data) {
        const {display, actions, fixedState} = layoutResponse.data
        headerProps.title = display.name
        if (actions) {
            headerProps.extra = <div>{renderActions(actions, actionExecutor, fixedState, 10)}</div>
        }
    }


    return <div>
        <PageHeaderWrapper
            {...headerProps}
        />

        <div className="page-content-wrapper">
            <LoadingResponseWrapper
                {...layoutResponse}
                refresh={getDisplayLayout}
                renderContent={({display, fixedState}) => {

                    const {layout, elements} = getLayoutAndElements(display)

                    const layoutChangeHandler = {
                        last: layout,
                        onLayoutChange: (changedLayout) => {
                            let changed = false
                            const lastLayout = layoutChangeHandler.last
                            for (let idx in changedLayout) {
                                const last = lastLayout[idx]
                                const current = changedLayout[idx]
                                if ((last.w !== current.w) || (last.y !== current.y) || (last.x !== current.x)) {
                                    changed = true
                                    break
                                }
                            }

                            layoutChangeHandler.last = changedLayout

                            if (!changed) {
                                console.log('layout not changed')
                                return
                            }
                            saveDisplayLayout({
                                id: query.id,
                                layout: JSON.stringify(changedLayout)
                            })
                        }
                    }

                    return <Row gutter={[16, 16]} type="flex">

                        <Col span={24} style={{backgroundColor: 'white'}}>

                            <AutoWidthGridLayout
                                layout={layout}
                                compactType={'horizontal'}
                                rowHeight={80}
                                onLayoutChange={layoutChangeHandler.onLayoutChange}
                                cols={24}>
                                {elements.map((item) => {
                                    const {id, title,  _actions_} = item

                                    return <div className={'layout-display-item'} key={'ele_' + id}>

                                        <div className={'layout-display-item-top'}>
                                            <div className={'layout-display-item-label'}>{title}</div>
                                        </div>
                                        <div className={'layout-display-item-bottom'}>
                                            {_actions_ && _actions_.length > 0 &&
                                            <div>{renderActions(_actions_, actionExecutor, fixedState, 3)}</div>}
                                        </div>
                                    </div>
                                })}

                            </AutoWidthGridLayout>

                        </Col>

                    </Row>
                }}
            />
        </div>
    </div>
}

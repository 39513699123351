import React, {useCallback, useEffect} from "react"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import LoadingResponseWrapper from "../../components/LoadingResponseWrapper"
import DisplayPage from "../home/DisplayPage"
import {useHttpGet} from "../../core/hooks"

const buildDisplayProps = (tabs) => {
    return {
        component: 'MonthlyTopTabbedDisplay',
        props: {
            tabs,
            tabContentProps: {
                component: 'SaleForecastTable',
                fixedFilterKey: 'project_id',
                uri: '/sale/getMonthlyForecast'
            }
        }
    }
}

export default () => {

    const [projectsResponse, getProjects] = useHttpGet('/common/projectOptions', {is_sale: 1})
    const refreshPage = useCallback(() => {
        getProjects()
    }, [getProjects])

    useEffect(() => {
        getProjects()
    }, [])

    return <div>
        <PageHeaderWrapper/>
        <LoadingResponseWrapper
            {...projectsResponse}
            refresh={refreshPage}
            renderContent={(projects) => {
                const displayProps = buildDisplayProps(projects)
                return <DisplayPage {...displayProps}/>
            }}
        />
    </div>
}

import React, { useEffect, useState } from "react";
import { Button, Result, Form, Input, message } from "antd";
import { useHttpGet, useHttpPostJson } from "../../../../../core/hooks";
import { useHistory } from "react-router-dom";
import UserInfoBox from "./UserInfoBox.jsx";
import UserAddressForm from "./UserAdderss.jsx";
import PurchaseOrderTable from "./PurchaseOrderTable.jsx";
import PaymentForm from "./PaymentForm";
import InvoiceForm from "./InvoiceForm.jsx";
import "./index.css";

const MakeOrder = ({ client_token, user, setTabIndex }) => {
  const [prds, setPrds] = useState([]);
  const [addressId, setAddrId] = useState(0);
  const [subLoading, setSubLoading] = useState(false);
  const [autoFocusIdx, setAutoFocusIdx] = useState(-1);
  const [startUploadFunc, setStartUploadFunc] = useState(null);
  const [form] = Form.useForm();
  const history = useHistory();

  const setAddrContent = (content) => {
    setAddrId(content.id);
    form.setFieldsValue({
      use_address_id: content.id,
      city_id: content.city_id,
      name: content.people,
      phone: content.phone,
      address: content.address,
    });
  };

  useEffect(() => {
    if (client_token) {
      form.setFieldsValue({ client_token });
    }
  }, [client_token]);

  const [prdsResponse, getPrds] = useHttpGet(
    "/quickOrder/getGoodsSelectOptions",
    {},
    {
      Authorization: "Bearer" + " " + client_token,
    }
  );

  const [addrResponse, getAddressess] = useHttpGet(
    "/quickOrder/getAddressOptions",
    {},
    {
      Authorization: "Bearer" + " " + client_token,
    }
  );

  const [saveOrderResp, saveOrder, resetPage] = useHttpPostJson(
    "/quickOrder/newOrder",
    {
      version: "1.1",
    },
    {
      Authorization: "Bearer" + " " + client_token,
    }
  );

  useEffect(() => {
    console.log("prds", prds);
    const focusNumberIdx = prds.findIndex((v, i) => !v.count);
    setAutoFocusIdx(focusNumberIdx);

    const vv = prds.map((p, i) => ({
      product_id: p?.product_id,
      sku_id: p?.sku_id,
      count: p?.count || 0,
      name: `prd_${i}_id`,
    }));
    const fields = [...vv];

    form.setFields(fields);

    console.log("setting===", prds, "fields====", fields, "vv===", [...vv]);
  }, [prds]);

  useEffect(() => {
    getPrds();
    getAddressess();
  }, []);

  useEffect(() => {
    console.log("prd prdsResponse, ", prdsResponse);
  }, [prdsResponse.error]);

  useEffect(() => {
    console.log("prd saveOrderResp, ", saveOrderResp);
    setSubLoading(false);
  }, [saveOrderResp]);

  if (saveOrderResp.success) {
    return (
      <div className="login-container-wrapper">
        <div className="login-container">
          <Result
            status="success"
            //
            title={
              <>
                <span>下单成功</span>

                <p>{saveOrderResp?.data?.code_name || 13213}</p>
              </>
            }
            subTitle={
              <>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    setAddrContent({});
                    setPrds([]);
                    form.resetFields();
                    resetPage();
                    setTabIndex("1");
                    history.push(`?tabIndex=1`);
                  }}
                >
                  查看订单
                </Button>
                <Button
                  onClick={() => {
                    setAddrContent({});
                    setPrds([]);
                    form.resetFields();
                    resetPage();
                  }}
                >
                  继续下单
                </Button>
              </>
            }
          />
        </div>
      </div>
    );
  }
  function changeValues(data) {
    // 从原始数据中提取合同与发票相关的信息
    const {
      need_contract,
      invoice_type,
      email,
      contract_client_info,
      unit_name,
      taxpayer_id,
      reg_address,
      reg_phone,
      bank_name,
      bank_account,
    } = data;

    // 创建一个新的对象，用于包装合同与发票信息
    const contract_invoice = {
      need_contract: need_contract === "true" ? true : false,
      invoice_type: Number(invoice_type),
      // invoice_title,
      email,
      contract_client_info,
      invoice_info:
        Number(invoice_type) !== 6
          ? {
              unit_name,
              taxpayer_id,
              reg_address,
              reg_phone,
              bank_name,
              bank_account,
            }
          : {},
    };

    // 创建一个新的数据对象，复制原始数据
    const newData = { ...data };

    // 从新数据对象中删除合同与发票相关的字段
    delete newData.need_contract;
    delete newData.invoice_type;
    delete newData.email;
    delete newData.contract_client_info;
    delete newData.bank_account;
    delete newData.bank_name;
    delete newData.reg_phone;
    delete newData.reg_address;
    delete newData.unit_name
    delete newData.taxpayer_id;

    // 将合同与发票信息添加到新的数据对象中
    newData.contract_invoice = contract_invoice;
    newData.purchase_items = prds.filter((e) => e.count !== 0) || [];

    return newData;
  }

  const onFinish = async (values) => {
    console.log("values", values);
    setSubLoading(true);
    try {
      let updatedValues = { ...values };
      // if (startUploadFunc) {
      //   const fileRefToken = await new Promise((resolve, reject) => {
      //     startUploadFunc((result, token) => {
      //       // console.log("onFinish token", token);
      //       if (token) {
      //         resolve(token);
      //       } else {
      //         resolve(null);
      //       }
      //     });
      //   });

      //   console.log("token2", fileRefToken);
      //   form.setFieldsValue({ fileRefToken });
      //   updatedValues.file_ref_token = fileRefToken;
      // } 
      // 保存订单
      // console.log("onFinish values", changeValues(updatedValues));
      console.log('values',changeValues(updatedValues));
      await saveOrder(changeValues(updatedValues));
    } catch (error) {
      // 处理错误
      setSubLoading(false);
      message.error("订单处理出错:", error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setSubLoading(false);
    console.log("Failed:", errorInfo);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 阻止 Enter 键的默认行为（如提交表单）
      e.stopPropagation(); // 阻止事件继续传播
    }
  };

  return (
    <div className="site-layout-background">
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 4 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* userInfo */}
        <UserInfoBox
          companyName={user?.client_name || "--"}
          contactName={user?.name || "--"}
          contactPhone={user?.phone || "--"}
        />

        {/* userAddress */}
        <UserAddressForm
          addressId={addressId}
          addrResponse={addrResponse}
          onUpdateAddress={setAddrId}
          client_token={client_token}
          setAddrContent={setAddrContent}
          handleKeyDown={handleKeyDown}
        />

        {/* 采购清单 */}
        <PurchaseOrderTable
          autoFocusIdx={autoFocusIdx}
          prds={prds}
          setPrds={setPrds}
          prdsResponse={prdsResponse}
          handleKeyDown={handleKeyDown}
        />

        {/* 支付方式 */}
        <PaymentForm
          startUploadFunc={startUploadFunc}
          setStartUploadFunc={setStartUploadFunc}
        />

        {/* 合同与发票 */}
        <InvoiceForm handleKeyDown={handleKeyDown} />

        {/* 订单备注 */}
        <div className="site-item-container">
          <div className="align-center">
            <div className="item-box-title">订单备注</div>
          </div>

          <Form.Item name="order_remark" noStyle>
            <Input.TextArea
              style={{ marginBottom: "1.25rem" }}
              placeholder="输入备注"
              rows={2}
            />
          </Form.Item>
        </div>

        {/* 隐藏字段 */}
        <Form.Item hidden name="prd_count" value={prds.length} />
        <Form.Item hidden name="use_address_id" value={addressId} />
        <Form.Item hidden name="client_token" />

        {/* 提交按钮 */}
        <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
          <button
            className="submit-btn"
            disabled={subLoading}
            style={subLoading ? { backgroundColor: "#ced4da" } : {}}
          >
            {subLoading ? "提交中..." : "提交"}
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MakeOrder;

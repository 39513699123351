import React, { useEffect, useState, useCallback, useRef } from "react"
import { useHttpGet, useHttpPostJson, useRefresh, useRouteQuery } from "../../core/hooks"
import { Col, Row, Divider, Card, Button, PageHeader, Table, Form, Input, Select, Tooltip, Spin, Result, Radio, message } from "antd"
import { useHistory, useLocation } from "react-router-dom"

import {    PlusOutlined, DeleteOutlined, CheckCircleFilled, WarningFilled } from "@ant-design/icons"

import moment from "moment"
import InputMoney from "../../components/InputMoney"
import TemplateSelectorModal from "../../components/df/TemplateSelectorModal"
import OrderGoodsEditor from "../../components/biz/OrderGoodsEditor"
import UnixDatePicker from "../../components/UnixDatePicker"
import { FormatMoney } from "format-money-js"
const { Column } = Table;
const OrderPackageNamePath = "order_package"
export default () => {
    const history = useHistory()
    const {pathname} = useLocation()
    const [form] = Form.useForm();
    const refresh = useRefresh();
    const [selectorParams, setSelectorParams] = useState(false);
    const [clientInfo, setClient] = useState({});
    const [contactInfo, setContact] = useState({});
    const [clientError, setClientError] = useState(false);
    const [loadContactResponse, getDefaultContact] = useHttpGet('/sale/getDefaultContact')
    const [saveOrderResponse, saveOrder] = useHttpPostJson('/sale/saveOrder');

    let query = useRouteQuery()
    const [isForSmallClient, setIsForSmallClient] = useState(!!query.small_client);
    useEffect(() => {
        setIsForSmallClient(!!query.small_client)
    }, [query.small_client])
    const [orderDetailResponse, getOrderDetail, clearOrderResponse] = useHttpGet('/sale/getOrderSimpleInfo', {
        order_id: query.id
    })
    const [nowTotalPrice, setTotal] = useState(0);
    const currency = form.getFieldValue('currency');
    const refreshTotalPrice = useCallback(() => {
        const allpackages = form.getFieldValue([OrderPackageNamePath]) || []
        const eachPackagePrices = allpackages.map(({ items = [] }, index) => {
            return (items.reduce((last, { count, price }, index, arr) => {
                return last + ((count && price) ? (count * price) : 0);
            }, 0));
        });
        const allPackagePrice = eachPackagePrices.reduce((last, curren) => {
            return last + (curren ? curren : 0);
        }, 0)
        setTotal(parseFloat(allPackagePrice.toFixed(4)))
    }, [])

    useEffect(() => {
        form.setFields([{ name: ['client_id'], value: clientInfo.id }])
        if (clientInfo.id > 0) {
            if (clientInfo.id != contactInfo.client_id) {
                getDefaultContact({
                    'client_id': clientInfo.id,
                    'contact_id': clientInfo._raws_.contact_id
                })
                setContact({})
                form.setFields([{ name: ['contact_id'], value: undefined }])
            }
        }
    }, [clientInfo.id])
    useEffect(() => {
        form.setFields([
            { name: ['contact_id'], value: contactInfo.id }
        ])
        if (contactInfo.id > 0) {
            setClientError(false);
        }
    }, [contactInfo.id])
    useEffect(() => {
        if (!loadContactResponse.loading && loadContactResponse.success) {
            setContact(loadContactResponse.data || {})
        }
    }, [loadContactResponse.loading, loadContactResponse.success])
    useEffect(() => {
        if (query.id) {
            getOrderDetail()
        } else {
            if (orderDetailResponse.success) {
                clearOrderResponse()
            }
            form.resetFields()
            setClientError();
            setContact({})
            setClient({})
            refreshTotalPrice()
        }
    }, [query.id])

    useEffect(() => {
        if (!orderDetailResponse.loading && orderDetailResponse.success) {
            let client = orderDetailResponse.data.client;
            if (client.type !== 0) {
                setContact(client.contact);
                setClient(client);
                setTimeout(() => {
                    // 设置完后，再设备 订单具体 
                    // 因为clientID一变，Package里的address contact就为0了。
                    form.setFieldsValue(orderDetailResponse.data.order);
                    refresh();
                }, 1000);
            } else {
                form.setFieldsValue(orderDetailResponse.data.order);
                form.setFields([
                    {name: ['contact', 'id'], value: client.contact.id}, // 保存就直接更新了
                    {name: ['contact', 'client_id'], value: client.contact.client_id}, // 保存就直接更新了
                    {name: ['contact', 'name'], value: client.contact.name},
                    {name: ['contact', 'phone'], value: client.contact.phone}
                ])
            }
            setIsForSmallClient(client.type == 0);
            
        }
    }, [orderDetailResponse.loading, orderDetailResponse.success])

    
    useEffect(() => {
        if (!saveOrderResponse.loading) {
            if (saveOrderResponse.success && saveOrderResponse.data) {
                const {order_id} = saveOrderResponse.data;
                if (order_id) {
                    history.replace('/sale/order/' + order_id);
                }
            } else {
                if (saveOrderResponse.desc) {
                    message.error(saveOrderResponse.desc)
                }
            }
        }
    }, [saveOrderResponse])

    const clientType = (clientInfo._raws_||{type:clientInfo.type||0}).type
    const showLoading =    query.id && !orderDetailResponse.data;

    if (orderDetailResponse.error) {
        return <div className="full-content-center">
                        <Result
                                status="error"
                                title={orderDetailResponse.error.desc}
                                extra={
                                        <Button type="primary" key="console" onClick={() => {
                                            if (query.id) {
                                                getOrderDetail()
                                            }
                                        }}>重试</Button>
                                }
                        /></div>
    }
    return <div key={query.id ? 'edit-order-' + query.id : 'create-order'}>
        <PageHeader
            ghost={false}
            title={query.id ? "编辑订单" : "新建订单"}
            subTitle={undefined}
            extra={[
                // <Button key="edit_price" onClick={() => { setShowEditPrice(true) }}>修改报价</Button>,
            ]}>

        </PageHeader>
        <Card bordered={true} style={{ margin: 20 }}>
            <Row>
                <h3>客户信息</h3>
            </Row>
            <Form labelCol={{ span: 2 }} wrapperCol={{ span: 12 }} onFinish={(v) => {
                console.log("form onFinish", v);
                if (query.id) {
                    v.order_id = query.id;
                }
                saveOrder(v)
            }} onFinishFailed={(all) => {
                let { errorFields } = all
                form.scrollToField(errorFields[0].name)
                let clientInfoError = false
                for (const failedField of errorFields) {
                    if (failedField.name[0] == 'client_id' || failedField.name[0] == 'contact_id') {
                        clientInfoError = true;
                        break;
                    }
                }
                setClientError(clientInfoError);
                refresh()
            }} form={form} initialValues={{
                currency: "￥",
            }} onFieldsChange={(chagneds, all) => {
                let totalPriceChanged = false;
                for (const changedField of chagneds) {
                    let lastName = changedField.name[changedField.name.length - 1];
                    if (changedField.name[0] == 'currency') {
                        refresh()
                    } else if (lastName == 'count' || lastName == 'price') {
                        if (changedField.touched !== false) {
                            totalPriceChanged = true;
                        }
                    }
                }
                if (totalPriceChanged) {
                    refreshTotalPrice();
                }
            }} >
                <Card style={{ marginBottom: 0, borderColor: clientError ? 'red' : undefined }} bordered={false} >
                    {!isForSmallClient ? 
                        <Row key="client_select">
                        <Col span={8}>
                            <Form.Item label="客户" labelAlign="left" labelCol={{span: 3}} wrapperCol={{span: 18}}>
                                <span>
                                    {clientInfo.id ? <span>{clientInfo.code_name} {clientInfo.name}</span> : <span>未选择</span>}
                                    <Form.Item noStyle rules={[{ required: true, message: "请选客户" }]}
                                                        name="client_id"></Form.Item>
                                    {showLoading ? <Spin /> : <Button type='link' onClick={() => {
                                        setSelectorParams({
                                            selectedIds: clientInfo.id ? [clientInfo.id] : [],
                                            title: '请选择客户公司',
                                            // entityName: 'ClientInfo',
                                            url: '/sale/clients',
                                            onSelect: (keys, rows) => {
                                                setSelectorParams(false)
                                                if (keys.length > 0) {
                                                    setClient(rows[0]);
                                                }
                                            }
                                        })
                                    }}>选择客户</Button>}
                                </span>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="联系人" labelAlign="left"labelCol={{span: 3}} wrapperCol={{span: 18}}>
                                <span>
                                    {contactInfo.id ? <span>{contactInfo.name}&nbsp;-&nbsp;{contactInfo.phone}</span> : <span>未选择</span>}
                                    <Form.Item noStyle rules={[{ required: true, message: "请选择联系人" }]}
                                                        name="contact_id"></Form.Item>
                                    {showLoading || loadContactResponse.loading ? <Spin /> : <Button type='link' disabled={!clientInfo || !clientInfo.id} onClick={() => {
                                        setSelectorParams({
                                            selectedIds: contactInfo.id ? [contactInfo.id] : [],
                                            entityName: 'ClientContact',
                                            moreParams: {
                                                client_id: clientInfo.id
                                            },
                                            componentProps: {
                                                readonly: false
                                            },
                                            title: '请选择联系人',
                                            onSelect: (keys, rows) => {
                                                setSelectorParams(false)
                                                if (keys.length > 0) {
                                                    setContact(rows[0]);
                                                }
                                            }
                                        })
                                    }}>选择联系人</Button>}
                                </span>
                            </Form.Item>
                        </Col>
                    </Row> : <Row key="client_input">
                        <Col span={8}>
                            <Form.Item noStyle name={["contact","id"]} note="编辑的时候，记下id，可以直接保存"/> 
                            <Form.Item noStyle name={["contact","client_id"]} note="编辑的时候，记下id，可以直接保存"/> 
                            <Form.Item label="姓名" name={["contact","name"]} labelCol={{span: 5}}
                                rules={[{required: true, message: '请填写联系人'}]}>
                                <Input style={{width:"20"}} required/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="电话" name={["contact","phone"]} labelCol={{span: 5}}
                                rules={[{required: true, message: '请填写联系电话'}]}>
                                <Input required/>
                            </Form.Item>
                        </Col>
                        <Col>
                            {!query.id    && <Button type='link' onClick={() => {
                                        history.replace(pathname);
                                    }}>客户订单</Button>}
                        </Col>
                    </Row>}
                </Card>
                <Row>
                    <Col span={10}>
                        <h2 style={{ display: 'inline' }}>销售详情</h2>
                    </Col>
                    <Col span={14}>
                        <div style={{ textAlign: 'right', margin: 10 }}>
                            <span>使用货币: </span>
                            <Form.Item noStyle name='currency'>
                                <Radio.Group buttonStyle="solid" defaultValue={currency}>
                                    <Radio.Button value={"￥"}>￥ 人民币</Radio.Button>
                                    <Radio.Button value={"$"}>$ 美元</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
                {showLoading ? <LoadingRow />
                        : <OrderGoodsEditor
                                form={form}
                                clientId={clientInfo ? clientInfo.id: 0}
                                clientType={clientType}
                                isForSmallClient={isForSmallClient}
                                onRefreshTotalPrice={() => {
                                    refreshTotalPrice()
                                }} />}
                <Row>
                    <h2>备注</h2>
                </Row>
                <Row>
                    <Form.Item noStyle name={'note'} style={{ marginBottom: 12 }}>
                            <Input.TextArea />
                    </Form.Item>
                </Row>
                {clientType == 4 ? undefined: <Row>
                    <h2>货款详情</h2>
                </Row>}
                {clientType == 4 ? undefined: showLoading ? <LoadingRow /> :    <MoneyPlanTable 
                    form={form} currency={currency} total={nowTotalPrice} allowMultiPlans={!isForSmallClient}/>}
                <Divider />
                <Form.Item label=" " colon={false} wrapperCol={{offset: 18}}>
                    <Button size='large' loading={false} type="primary" htmlType="submit">
                        预览订单
                    </Button>
                </Form.Item>
            </Form>
        </Card>
        <TemplateSelectorModal
            visible={!!selectorParams} {...(selectorParams ? selectorParams : {})}
            onCancel={() => { setSelectorParams(false) }} />
    </div>
}
function MoneyPlanTable({ currency = '￥', total = 0, form, allowMultiPlans = true }) {
    const MoneyPlanNamePath = "money_plans"
    const refresh = useRefresh()
    const [defaccount, setDefAccount] = useState(0);
    const [accountsReponse, getAccounts] = useHttpGet('/template/itemOptions', {
        _entity_name_: "BankAccount",
        status: 0
    })
    useEffect(() => {
        getAccounts()
    }, [])

    const moneyPlans = form.getFieldValue([MoneyPlanNamePath]) || []
    const otherFee = parseFloat(form.getFieldValue('other_fee')) || 0
    const finalTotal = (parseFloat(total) + otherFee)
    const nowTotal = moneyPlans.reduce((t, { amount = 0 }) => {
        return t + parseFloat(amount)
    }, 0)
    useEffect(() => {
        form.setFields([{ name: ['money_plan_total'], value: nowTotal}])
        form.setFields([{ name: ['order_price'], value: total}])
        refresh();
    }, [nowTotal, total])
    useEffect(() => {
        if (defaccount == 0 && accountsReponse.data && accountsReponse.data.length > 0) {
            setDefAccount(accountsReponse.data[0].value);
        }
    }, [accountsReponse.data])

    if (defaccount) {
        if (moneyPlans.length == 0) {
            let newPlans = [{ pay_time: 0, pay_at: moment().startOf('day').unix(), account: defaccount }];
            form.setFields([
                { name: [MoneyPlanNamePath], value: newPlans },
            ])
            refresh();
        } else if (moneyPlans.length && !allowMultiPlans) {
            if (moneyPlans[0].amount != finalTotal) {
                let newPlans = [{ pay_time: 0, pay_at: moment().startOf('day').unix(), account: defaccount, amount: finalTotal }];
                form.setFields([
                    { name: [MoneyPlanNamePath], value: newPlans },
                ])
                refresh();
            }
        }
    }

    if (accountsReponse.loading) {
        return <div className="full-content-center">
            <Spin />
        </div>
    } else if (accountsReponse.error) {
        return <div className="full-content-center">
            <Result
                status="error"
                title={accountsReponse.error.desc}
                extra={<Button type="primary" key="console" onClick={() => {
                    getAccounts()
                }}>重试</Button>}
            /></div>
    }
    moneyPlans.forEach((v, i) => {
        v.rowKey = "money_" + i;
    })
    return <Card style={{ marginBottom: 20, }}>
        <Row>
            <Col span={2} offset={1}><h4>其它费用</h4></Col>
            <Col span={10}>
                    <Form.Item noStyle name={'other_fee'}>
                            <InputMoney 
                                prefix={currency} style={{ width: "100%" }} placeHolder={'如 快递费、手续费等'} onChange={() => { refresh() }} />
                    </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={2} offset={1}><h4>订单总额</h4></Col>
            <Col span={10}>
                <h2 style={{ color: 'red' }}>{formatMoney(total, currency)}</h2>
            </Col>
        </Row>
        {finalTotal > 0 ? <Row>
            <Col span={2} offset={1}>
        <h4>回款总额</h4>
                <Form.Item noStyle name={["money_plan_total"]} />
                <Form.Item noStyle name={["order_price"]} />
            </Col>
            <Col span={10}>
                <h2>
                    {formatMoney(nowTotal, currency)}
                    {nowTotal == finalTotal ? <CheckCircleFilled style={{ color: '#52c41a' }} /> : <WarningFilled style={{color:'red' }}/>}
                </h2>
            </Col>
        </Row>:<Form.Item name={["money_plan_total"]} rules={[{
                type: 'integer', min: 1, message: '本单金额为0', required: true
            }]} />}
        {finalTotal > 0 && <Row>
            <Col span={2} offset={1}><h4>回款单</h4></Col>
            <Col span={18}>
                <Row>{nowTotal > finalTotal ? "多了" + formatMoney(nowTotal - finalTotal) + "" : nowTotal < finalTotal ? "还差" + formatMoney(finalTotal - nowTotal) : ""}</Row>
                <Table rowKey='rowKey' pagination={false} dataSource={moneyPlans}>
                    <Column width={"20%"} title="回款金额" dataIndex='amount' render={(value, row, index) => {
                        return <Form.Item noStyle name={[MoneyPlanNamePath, index, "amount"]}>
                            <InputMoney 
                                prefix={currency} style={{ width: "100%" }} onChange={() => { refresh() }}
                                disabled={!allowMultiPlans} />
                        </Form.Item>
                    }}></Column>
                    <Column width={"15%"} title="" dataIndex='pay_time' render={(value, row, index) => {
                        return <Form.Item noStyle name={[MoneyPlanNamePath, index, "pay_time"]}>
                            <Select style={{ width: "100%" }}    onChange={() => { refresh() }} disabled={!allowMultiPlans} >
                                <Select.Option value={0}>出货前</Select.Option>
                                <Select.Option value={1}>出货后</Select.Option>
                            </Select></Form.Item>
                    }}></Column>
                    <Column title="回款日期" dataIndex='date' render={(value, row, index) => {
                        return <Form.Item noStyle name={[MoneyPlanNamePath, index, "pay_at"]}>
                            <UnixDatePicker disabledDate={(m) => {
                                if (row.pay_time == 0) return false;
                                else return m.isBefore()
                            }} />
                        </Form.Item>
                    }}></Column>
                    <Column width={"25%"} title="收款账户" dataIndex='account' render={(v, row, index) => {
                        return <Form.Item noStyle name={[MoneyPlanNamePath, index, "account"]}>
                            <Select style={{ width: "100%" }} onChange={(selectValue) => {
                                setDefAccount(selectValue)
                            }}>
                                {(accountsReponse.data || []).map(account => {
                                    return <Select.Option key={'account_' + account.value} value={account.value}>{account.label}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>
                    }}>
                    </Column>
                    <Column title="凭证" render={(v, row, index) => {
                        return row.pay_time === 0 ? <Tooltip title="此功能正在开发中"><Button>上传</Button></Tooltip> : <span>付款后上传</span>
                    }}>
                    </Column>
                    {allowMultiPlans && <Column title="操作" key="delete" render={(text, row, index) => {
                        return <Button danger icon={<DeleteOutlined />} onClick={() => {
                            let newPlans = [...moneyPlans];
                            newPlans.splice(index, 1)
                            form.setFields([{ name: [MoneyPlanNamePath], value: newPlans },])
                            refresh();
                        }} />
                    }} />}
                </Table>
                {allowMultiPlans && <Button style={{ marginBottom: 20 }} type='link' icon={<PlusOutlined></PlusOutlined>} onClick={() => {
                    let newPlans = [...moneyPlans, { pay_time: 0, pay_at: moment().startOf('day').unix(), account: defaccount }];
                    form.setFields([
                        { name: [MoneyPlanNamePath], value: newPlans },
                    ])
                    refresh();
                }} disabled={nowTotal >= finalTotal} >增加回款单</Button>}
            </Col>
        </Row>}
    </Card>
}

const moneier = new FormatMoney()
function formatMoney(value, prefix = '￥') {
    return moneier.from(+ value, {symbol: prefix, decimals: 4})
}
function LoadingRow() {
    return <Row>
        <Col span={24}>
            <Card style={{ marginBottom: 20, }}>
                <div className="full-content-center">
                    <Spin />
                </div>
            </Card>
        </Col>
    </Row>
}
import React, { useCallback, useEffect, useState } from "react";
import { useHttpAction, useHttpGet } from "../../../core/hooks";
import { useActionExecutor } from "../../../core/actionExecuter";
import {
    Button,
    Col,
    Descriptions,
    Divider,
    Input,
    message,
    Row,
    Space,
    Table,
    Typography,
    List,
    Card,
} from "antd";
import LoadingResponseWrapper from "../../LoadingResponseWrapper";
import DescriptionsDisplay from "../DescriptionsDisplay";
import { renderActions, renderItemFieldValue } from "../../../core/domUtil";
import { conditionsMatched } from "../../../core/conditionsMatcher";
import { CheckCircleTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import TableDisplay from "../TableDisplay";

const { Title, Paragraph } = Typography;

export default props => {
    const { uri, query, fixedQuery, readonly } = props;

    const [response, requestTaskDetail] = useHttpGet(uri);

    const [, actionResult, actionExecutor] = useActionExecutor();

    useEffect(() => {
        requestTaskDetail({ ...query, ...fixedQuery, readonly });
    }, [query, requestTaskDetail, actionResult]);

    return (
        <LoadingResponseWrapper
            {...response}
            refresh={requestTaskDetail}
            renderContent={({
                _actions_,
                recipient,
                itemsContent,
                logs,
                _revert_action_,
            }) => {
                return (
                    <div className="page-content-wrapper">
                        <Row gutter={10}>
                            <Col span={24}>
                                {!readonly && _actions_ && (
                                    <div
                                        className="actions-bar"
                                        style={{
                                            marginBottom: "22px",
                                            marginTop: "22px",
                                        }}
                                    >
                                        {renderActions(
                                            _actions_,
                                            actionExecutor,
                                            {},
                                            10,
                                            { type: "primary", size: "smal" }
                                        )}
                                    </div>
                                )}
                                {recipient && (
                                    <DescriptionsDisplay
                                        title={"收货信息"}
                                        actionExecutor={actionExecutor}
                                        bordered={false}
                                        column={4}
                                        {...recipient}
                                    />
                                )}

                                {itemsContent && (
                                    <Typography style={{ marginTop: 24 }}>
                                        <Title level={5}>
                                            {itemsContent.title}
                                        </Title>
                                        <Paragraph>
                                            <Table
                                                size={"small"}
                                                bordered={true}
                                                pagination={false}
                                                dataSource={itemsContent.items}
                                                rowKey={({ id }) => id}
                                            >
                                                <Table.Column
                                                    dataIndex={"status"}
                                                    title={""}
                                                    align={"center"}
                                                    key={
                                                        itemsContent
                                                            .displayFields
                                                            .length + 3
                                                    }
                                                    render={(_a, _b, index) => {
                                                        return (
                                                            <b>{index + 1}</b>
                                                        );
                                                    }}
                                                />

                                                {itemsContent.displayFields.map(
                                                    (
                                                        displayField,
                                                        columnIdx
                                                    ) => {
                                                        const { key, title } =
                                                            displayField;

                                                        return (
                                                            <Table.Column
                                                                dataIndex={key}
                                                                title={title}
                                                                key={columnIdx}
                                                                align={"center"}
                                                                render={(
                                                                    cellVal,
                                                                    record
                                                                ) => {
                                                                    return renderItemFieldValue(
                                                                        displayField,
                                                                        record
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}

                                                <Table.Column
                                                    dataIndex={"status"}
                                                    title={""}
                                                    align={"center"}
                                                    key={
                                                        itemsContent
                                                            .displayFields
                                                            .length
                                                    }
                                                    render={(
                                                        cellVal,
                                                        record
                                                    ) => {
                                                        return record.waiting_quantity <=
                                                            0 ? (
                                                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                        ) : (
                                                            <InfoCircleTwoTone twoToneColor="#f2a537" />
                                                        );
                                                    }}
                                                />
                                            </Table>
                                        </Paragraph>
                                    </Typography>
                                )}
                            </Col>
                        </Row>
                        <TaskInoutOptLogs
                            logs={logs}
                            revertAction={_revert_action_}
                            actionExecutor={actionExecutor}
                            itemTypeName={itemsContent.title}
                        />
                    </div>
                );
            }}
        />
    );
};

function TaskInoutOptLogs({ logs, revertAction, actionExecutor, itemTypeName = '物料信息' }) {
    if (!logs?.length) return <Card>无记录</Card>;
    return (
        <div style={{ marginTop: 24 }}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Title level={5}>{"操作记录"}</Title>
                {revertAction &&
                    renderActions([revertAction], actionExecutor, {}, 5, {
                        type: "link",
                    })}
            </div>
            <List
                itemLayout="horizontal"
                dataSource={logs}
                renderItem={({
                    id,
                    action_type,
                    executor,
                    opt,
                    date_str,
                    data,
                    remark,
                    logistics,
                    _revert_action_: itemRevertAction,
                }) => (
                    <List.Item>
                        <Card
                            size="small"
                            style={{
                                width: "100%",
                                opacity: action_type === 7 ? 0.3 : 1,
                            }}
                            title={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        {date_str}
                                        {opt} 操作人：
                                        {executor?.name}
                                    </div>
                                    {itemRevertAction &&
                                        renderActions(
                                            [itemRevertAction],
                                            actionExecutor,
                                            {},
                                            5,
                                            {
                                                type: "link",
                                            }
                                        )}
                                </div>
                            }
                        >
                            <Row gutter={[10, 20]}>
                                {data && (
                                    <Col span={14}>
                                        <Table
                                            dataSource={data}
                                            size="small"
                                            pagination={false}
                                            bordered
                                        >
                                            <Table.Column
                                                title={opt + itemTypeName}
                                                dataIndex={"item_name"}
                                            ></Table.Column>
                                            <Table.Column
                                                title={opt + "数量"}
                                                dataIndex={"processed_quantity"}
                                            ></Table.Column>
                                        </Table>
                                    </Col>
                                )}
                                <Col span={10}>
                                    <Card
                                        size="small"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <div>
                                            <Descriptions
                                                column={1}
                                                labelStyle={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <Descriptions.Item label="备注">
                                                    {remark}
                                                </Descriptions.Item>
                                                {logistics?.length && (
                                                    <Descriptions.Item label="快递信息">
                                                        {logistics?.map(
                                                            ({
                                                                name,
                                                                number,
                                                                status,
                                                            }) => {
                                                                return (
                                                                    <div>
                                                                        {name +
                                                                            ": " +
                                                                            number}

                                                                        {status
                                                                            ? "[" +
                                                                              status +
                                                                              "]"
                                                                            : ""}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>
                        {/* <List.Item.Meta
                            title={}
                            description={
                                
                            }
                        ></List.Item.Meta> */}
                    </List.Item>
                )}
            />
        </div>
    );
}

import React, {useEffect} from "react"

import LoadingResponseWrapper from "../LoadingResponseWrapper"
import {useHttpGet} from "../../core/hooks"
import {Table} from "antd"
import {renderActions, renderItemFieldValue} from "../../core/domUtil"
import {useActionExecutor} from "../../core/actionExecuter"

export default ({uri, query, fixedState, showPadding = false, readonly = false}) => {

    const [response, requestMaterialPrices] = useHttpGet(uri)

    const [executing, actionResult, actionExecutor] = useActionExecutor()

    useEffect(() => {
        requestMaterialPrices(query)
    }, [query, uri, actionResult])


    const renderTableColumn = (column, index) => {
        const {children, title, key, align} = column
        if (children && children.length) {
            return <Table.ColumnGroup
                key={'grp_'+index}
                align={align}
                title={title}>
                {children.map(renderTableColumn)}
            </Table.ColumnGroup>
        }

        return <Table.Column dataIndex={key} title={title} key={index} align={align}

                             render={(cellVal, record) => {
                                 return {
                                     children: renderItemFieldValue(column, record, readonly),
                                     props: record.spans[key] ? record.spans[key] : {
                                         colSpan: 1,
                                         rowSpan: 1
                                     }
                                 }
                             }}/>

    }


    return <LoadingResponseWrapper
        {...response}
        refresh={() => {
            requestMaterialPrices(query)
        }}
        renderContent={({actions, columns, items}) => {

            return <div style={{padding: showPadding ? '24px' : '0'}}>
                {!readonly && <div className="action-search-bar" style={{marginTop: '5px'}}>
                    {
                        actions && actions.length > 0 && <div className="actions-bar"
                                                              style={{marginBottom: '22px'}}>{renderActions(actions,
                            actionExecutor, fixedState, 3, {type: 'primary'})}</div>
                    }
                </div>}
                <Table
                    size={'small'}
                    bordered={true}
                    pagination={false}
                    dataSource={items}
                    rowKey={({id}) => id}>

                    {columns.map(renderTableColumn)}

                    {!readonly && <Table.Column dataIndex={'actions'} title={'操作'} key={'ac'} fixed={'right'}
                                  render={(cellVal, record) => {
                                      return {
                                          children:
                                              <div>{renderActions(record.actions || [], actionExecutor, fixedState, 3)}</div>,
                                          props: record.spans['actions'] ? record.spans['actions'] : {
                                              colSpan: 1,
                                              rowSpan: 1
                                          }
                                      }
                                  }}/>}

                </Table>
            </div>

        }}
    />

}
